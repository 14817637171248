import React from "react";
import { Fab, makeStyles } from "@material-ui/core";
import Loading from "./Loading";
import { LIGHT_GRAY2, LIGHT_GRAY, SECONDARY_COLOR } from "../../theme";

const FloatingButton = props => {
  const { icon, text, handleClick, ariaLabel, loading, disabled, isWeb } = props;
  const classes = useStyles({ isWeb, text });

  return (
    <Fab
      color="primary"
      aria-label={ariaLabel}
      onClick={handleClick}
      className={classes.fab}
      disabled={disabled || loading}
    >
      {loading ? (
        <Loading size={20} loading={loading} className={classes.loadingPosition} />
      ) : (
        <>
          <span className={`${classes.iconAlign} ${disabled ? classes.disabled : null}`}>
            {icon}
          </span>
          {text && <span className={`${disabled ? classes.disabled : null} ml-2`}>{text}</span>}
        </>
      )}
    </Fab>
  );
};

const useStyles = makeStyles(theme => ({
  fab: props => ({
    position: "fixed",
    bottom: theme.spacing(18),
    right: props.isWeb ? theme.spacing(4) : theme.spacing(3.5),
    "&.MuiFab-root": {
      width: props.text ? "129px" : "56px",
      borderRadius: props.text ? "28px" : "50%",
      "&.Mui-disabled": {
        backgroundColor: LIGHT_GRAY2
      }
    }
  }),
  disabled: {
    color: LIGHT_GRAY
  },
  iconAlign: {
    verticalAlign: "middle",
    display: "flex"
  },
  loadingPosition: {
    margin: theme.spacing(2),
    position: "relative",
    color: SECONDARY_COLOR
  }
}));

export default FloatingButton;
