// Types
const UPDATE_CHECKLIST = "booking/UPDATE_CHECKLIST";
const DELETE_CHECKLIST = "booking/DELETE_CHECKLIST";

export const INITIAL_STATE = {
  nextStep: 1,
  checklistModelId: null,
  mileage: null,
  fuelLevel: 25,
  checklistObservations: "",
  questionsResponses: null,
  stepQuestions: null,
  checkInResponses: null,
  checkOutResponses: null,
  checklistImages: []
};

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_CHECKLIST: {
      return {
        ...state,
        ...action.payload
      };
    }
    case DELETE_CHECKLIST: {
      return { ...INITIAL_STATE };
    }
    default: {
      return state;
    }
  }
}

// Action creators

export const updateChecklist = checklist => {
  return {
    type: UPDATE_CHECKLIST,
    payload: checklist
  };
};

export const deleteChecklist = () => {
  return {
    type: DELETE_CHECKLIST
  };
};
