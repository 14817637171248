import React, { useState, useEffect, useCallback } from "react";
import { addMinutes, format } from "date-fns";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Redirect, useLocation } from "react-router-dom";
import {
  ChevronsDown,
  ChevronLeft,
  ChevronsUp,
  User,
  Calendar,
  File,
  MapPin,
  Info
} from "react-feather";
import { Grid, makeStyles, Typography, Divider, IconButton, Box } from "@material-ui/core";
import { Layout } from "../../layout/";
import {
  routes,
  messages,
  checklistTolerance,
  MOBILE_WINDOW_WIDTH
} from "../../../shared/utils/Constants";
import { carTypes, bookingStatus, bookingStatusStyle } from "../../../shared/utils/enum";
import { checkIfCheckListEnabled, formatStringField } from "../../../shared/utils/Utils";
import { DARK_GRAY, GRAY, PRIMARY_COLOR, SECONDARY_COLOR, THIN_BLUE2 } from "../../../theme";
import * as routerActions from "../../../store/RouterStore";
import * as bookingActions from "../../../store/BookingStore";
import * as checklistActions from "../../../store/ChecklistStore";
import Loading from "../../../shared/components/Loading";
import DetailsButtons from "./DetailsButtons";
import BookingDetailsManager from "../BookingDetailsManager";
import useChecklistResponse from "../customHooks/useChecklistResponse";
import ptBR from "date-fns/locale/pt-BR";

const BookingDetails = props => {
  const {
    match: { params: routeParams },
    user,
    carId,
    window,
    checklist,
    updateBooking,
    updateChecklist,
    updateNavigationRoute
  } = props;
  const { bookingId } = routeParams;
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const [redirect, setRedirect] = useState(null);
  const [booking, setBooking] = useState();
  const [loadingPrimaryButton, setLoadingPrimaryButton] = useState(false);
  const [loadingSecondaryButton, setLoadingSecondaryButton] = useState(false);
  const [warningMessage, setWarningMessage] = useState();

  window.onbeforeunload = function () {
    return messages.ALERT_RELOAD_PAGE;
  };

  const carInfo = booking && ` ${booking.carLicensePlate} | ${booking.carType} ${booking.carModel}`;
  const lastStatus = booking?.historyStatus[booking.historyStatus.length - 1];
  const lastStatusId = lastStatus?.status?.id;
  const isFinalized = lastStatusId === bookingStatus.FINALIZED;
  const isActive = [bookingStatus.APPROVED, bookingStatus.CHECKLIST_STARTED].includes(lastStatusId);
  const isCanceled = [
    bookingStatus.CANCELED,
    bookingStatus.SECURITY_RISK,
    bookingStatus.EXPIRED
  ].includes(lastStatusId);
  const carStatus = bookingStatusStyle.find(item => item.statusId === lastStatusId);
  const checkoutDateTime = isFinalized && lastStatus?.createdAt;
  const checkinDateTime = booking?.checkInResponseDate;
  const checkinStart =
    booking && format(addMinutes(new Date(booking.startJourney), -checklistTolerance), "HH:mm");
  const checkinFinish =
    booking && format(addMinutes(new Date(booking.startJourney), checklistTolerance), "HH:mm");
  const checkinAvailable = messages.CHECKIN_AVAILABLE.replace("{0}", checkinStart).replace(
    "{1}",
    checkinFinish
  );
  const bookingCarType = carTypes.find(item => item.id === booking?.carTypeId);
  const userId = user?.id;

  const getBookingById = useCallback(async () => {
    const bookingDetails = await BookingDetailsManager.getBookingById(bookingId);
    if (bookingDetails && bookingDetails.driverId) {
      const driverData = await BookingDetailsManager.getUserById(bookingDetails.driverId);

      if (driverData)
        setBooking({
          ...bookingDetails,
          driverLicenseExpireDate: driverData.license?.expireDate,
          driverCourseExpireDate: driverData.course?.expireDate
        });
      else setRedirect(<Redirect to="/notFound" />);
    } else setRedirect(<Redirect to="/notFound" />);
  }, [bookingId]);

  useEffect(() => {
    !carId && setRedirect(<Redirect to={"/listBookings"} />);
  }, [carId]);

  useEffect(() => {
    if (bookingId) getBookingById();
  }, [bookingId, getBookingById]);

  useEffect(() => {
    if (booking && userId)
      if (booking.schedulerId !== userId && booking.driverId !== userId)
        setRedirect(<Redirect to={routes.deniedAccess} />);
  }, [booking, userId]);

  useEffect(() => {
    if (booking)
      updateBooking({
        id: booking.id,
        carModel: booking.carModel,
        carLicensePlate: booking.carLicensePlate,
        siteName: booking.site.name,
        carDocument: booking.carDocument,
        driverId: booking.driverId
      });
  }, [booking, updateBooking]);

  useEffect(() => {
    if (checklist.checkInResponses || checklist.checkOutResponses)
      updateNavigationRoute({ route: history.location.pathname });
  }, [checklist.checkInResponses, checklist.checkOutResponses, history, updateNavigationRoute]);

  useEffect(() => {
    if ([bookingStatus.SECURITY_RISK, bookingStatus.CANCELED].includes(lastStatusId))
      setWarningMessage(messages.WARNING_BOOKING_CANCELED);
    else if ([bookingStatus.APPROVED, bookingStatus.CHECKLIST_STARTED].includes(lastStatusId))
      setWarningMessage(checkinAvailable);
    else if (bookingStatus.EXPIRED === lastStatusId) setWarningMessage(messages.BOOKING_EXPIRED);
    else if (bookingStatus.IN_PROGRESS === lastStatusId)
      setWarningMessage(messages.CHECKOUT_AVAILABLE);
    else setWarningMessage();
  }, [lastStatusId, checkinAvailable]);

  const {
    handleGetCheckInInfos,
    handleGetCheckOutInfos,
    loadingCheckInResponses,
    loadingCheckOutResponses
  } = useChecklistResponse(
    setRedirect,
    bookingId,
    checklist,
    updateChecklist,
    checkoutDateTime,
    checkinDateTime
  );

  const handleRedirect = () => {
    setRedirect(<Redirect to={`${routes.listBookings}?tab=${query.get("tab")}`} />);
  };

  const refreshBookingDetails = async () => {
    if (bookingId) getBookingById();
  };

  const classes = useStyles({ colorStatus: carStatus?.color });

  const createItem = (field, data, Icon) => {
    return (
      <Grid container alignItems="center">
        <Icon className={classes.infoIcon} />
        <Grid className="ml-4">
          <Typography className={classes.fieldContent}>{formatStringField(data)}</Typography>
          <Typography variant="caption" className={classes.fieldName}>
            {field}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getImageGridSize = () => (window.width < MOBILE_WINDOW_WIDTH ? 6 : undefined);
  const getTitleVariant = () => (window.width < MOBILE_WINDOW_WIDTH ? "h5" : "h4");
  const getSubtitleVariant = () => (window.width < MOBILE_WINDOW_WIDTH ? "subtitle1" : "h5");
  const getHeaderVariant = () => (window.width < MOBILE_WINDOW_WIDTH ? "subtitle2" : "h6");
  const getContentSpacing = () => (window.width < MOBILE_WINDOW_WIDTH ? 3 : 5);
  const getWarningVariant = () => (window.width < MOBILE_WINDOW_WIDTH ? "caption" : "body2");
  const getButtonsGridSize = () => (window.width < MOBILE_WINDOW_WIDTH ? 12 : 6);

  return booking && user ? (
    <Layout showHeader>
      <Grid container className="mt-8 mb-8">
        <Grid container alignItems="center" className="mr-12 pr-2">
          <IconButton onClick={() => handleRedirect()} className="ml-2" aria-label="Voltar">
            <ChevronLeft size={32} color={PRIMARY_COLOR} />
          </IconButton>
          <Typography variant={getTitleVariant()} component="h2" className={classes.pageTitle}>
            Detalhes da reserva
          </Typography>
        </Grid>
        {checkIfCheckListEnabled(booking.startJourney) && isActive && (
          <Typography
            variant={getSubtitleVariant()}
            component="h3"
            className={`mt-2 ${classes.subtitle}`}
          >
            Antes de iniciar o check-in, você precisa conferir os itens abaixo:
          </Typography>
        )}
      </Grid>
      <Grid className="mb-8">
        <Box className={`mr-8 ml-8 ${classes.box}`}>
          <Grid>
            <Grid item container alignItems="center" className="pt-8">
              <Grid item xs={getImageGridSize()}>
                {bookingCarType && (
                  <img
                    src={carTypes.find(type => type.id === bookingCarType.id).src}
                    className={`ml-8 mr-8 ${classes.imageFit}`}
                    alt={carTypes.find(type => type.id === bookingCarType.id).alt}
                  />
                )}
              </Grid>
              <Grid item xs container>
                <Grid item className={classes.carInfoGrid}>
                  <Grid>
                    <Typography variant="overline" component="h4">
                      Dados do veículo
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant={getHeaderVariant()} component="h4" className="mr-8">
                      {carInfo}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs className={`pr-8 ${classes.statusAlign}`}>
                  <Grid>
                    <Typography variant="overline" component="h4" className={classes.statusHeader}>
                      Status
                    </Typography>
                  </Grid>
                  <Grid container>
                    {carStatus && (
                      <Typography
                        variant={getHeaderVariant()}
                        component="h4"
                        className={classes.statusIcon}
                      >
                        {carStatus.icon} {carStatus.text}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="p-8">
              <Divider className="divider" />
            </Grid>
            <Grid container spacing={getContentSpacing()} className={`pl-8 pr-8`}>
              <Grid
                item
                container
                spacing={getContentSpacing()}
                className={classes.containerContent}
              >
                <Grid item>
                  {createItem(
                    "Retirada",
                    format(new Date(booking.startJourney), "dd/MM/yyyy 'ás' HH:mm", {
                      locale: ptBR
                    }),
                    ChevronsUp
                  )}
                </Grid>
                <Grid item>
                  {createItem(
                    "Devolução",
                    format(new Date(booking.finishJourney), "dd/MM/yyyy 'às' HH:mm", {
                      format: ptBR
                    }),
                    ChevronsDown
                  )}
                </Grid>
                <Grid item>{createItem("Reservado por", booking.schedulerName, User)}</Grid>
                <Grid item>{createItem("Condutor(a)", booking.driverName, User)}</Grid>
              </Grid>
              <Grid
                item
                container
                spacing={getContentSpacing()}
                className={classes.containerContent}
              >
                <Grid item>
                  {createItem(
                    "Vencimento CNH",
                    format(new Date(booking.driverLicenseExpireDate), "dd/MM/yyyy"),
                    Calendar
                  )}
                </Grid>
                <Grid item>
                  {createItem(
                    "Vencimento CDD",
                    format(new Date(booking.driverCourseExpireDate), "dd/MM/yyyy"),
                    Calendar
                  )}
                </Grid>
                <Grid item>{createItem("Documento do Veículo", booking.carDocument, File)}</Grid>
                <Grid item>{createItem("Unidade", booking.site?.name, MapPin)}</Grid>
              </Grid>
            </Grid>
            <Grid className="p-8">
              <Divider className="divider" />
            </Grid>
            <Grid className="pl-8 pr-8">
              <Typography variant="body1" className={classes.fieldContent}>
                {booking.destination}
              </Typography>
              <Typography variant="caption" className={classes.fieldName}>
                Local de destino
              </Typography>
            </Grid>
            <Grid item container xs={12} alignItems="center" className="p-8">
              <Grid item xs className={`pt-8 ${classes.infoAlign}`}>
                {!!warningMessage && (
                  <Typography variant={getWarningVariant()} className={classes.infoContent}>
                    {<Info size={15} className={classes.iconContent} />}
                    {warningMessage}
                  </Typography>
                )}
              </Grid>
              {!isCanceled && (
                <Grid item xs={getButtonsGridSize()} className="pt-8">
                  <DetailsButtons
                    setRedirect={setRedirect}
                    isCanceled={isCanceled}
                    isFinalized={isFinalized}
                    hasCheckInResponseId={!!booking.checkInResponseId}
                    refreshBookingDetails={() => refreshBookingDetails()}
                    hasCheckOutResponseId={!!booking.checkOutResponseId}
                    checklistIsEnabled={checkIfCheckListEnabled(booking.startJourney)}
                    isActive={lastStatusId !== bookingStatus.EXPIRED}
                    startJourney={booking.startJourney}
                    handleGetCheckInInfos={handleGetCheckInInfos}
                    handleGetCheckOutInfos={handleGetCheckOutInfos}
                    loadingPrimaryButton={loadingPrimaryButton || loadingCheckInResponses}
                    loadingSecondaryButton={loadingSecondaryButton || loadingCheckOutResponses}
                    setLoadingPrimaryButton={setLoadingPrimaryButton}
                    setLoadingSecondaryButton={setLoadingSecondaryButton}
                  />
                </Grid>
              )}
            </Grid>
            {redirect}
          </Grid>
        </Box>
      </Grid>
    </Layout>
  ) : (
    <Grid className="pt-8">
      <Loading loading />
      {redirect}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  pageTitle: {
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      margin: "auto"
    }
  },
  subtitle: {
    marginLeft: "64px",
    marginRight: "64px",
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      textAlign: "center"
    }
  },
  box: {
    backgroundColor: THIN_BLUE2,
    borderRadius: "8px"
  },
  statusAlign: {
    [theme.breakpoints.up(MOBILE_WINDOW_WIDTH)]: {
      textAlign: "right"
    }
  },
  statusHeader: {
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      display: "none"
    }
  },
  statusIcon: props => ({
    color: `${props.colorStatus}`,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(MOBILE_WINDOW_WIDTH)]: {
      marginLeft: "auto",
      "& > svg": {
        marginRight: "10px",
        marginTop: "4px"
      }
    },
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      "& > svg": {
        marginRight: "8px",
        marginBottom: "auto",
        marginTop: "auto",
        width: "14px"
      }
    }
  }),
  infoAlign: {
    display: "flex"
  },
  infoIcon: {
    color: SECONDARY_COLOR
  },
  fieldName: {
    color: GRAY
  },
  fieldContent: {
    color: DARK_GRAY
  },
  infoContent: {
    color: SECONDARY_COLOR,
    display: "flex",
    "& > svg": {
      marginRight: "16px"
    }
  },
  iconContent: {
    minInlineSize: "fit-content",
    [theme.breakpoints.up(MOBILE_WINDOW_WIDTH)]: {
      margin: "auto"
    }
  },
  imageFit: {
    width: "-webkit-fill-available"
  },
  carInfoGrid: {
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      paddingBottom: "12px"
    }
  },
  containerContent: {
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      display: "block"
    }
  }
}));

const mapDispatchToProps = dispatch => {
  return {
    updateBooking: data => dispatch(bookingActions.updateBooking(data)),
    updateChecklist: data => dispatch(checklistActions.updateChecklist(data)),
    updateNavigationRoute: data => dispatch(routerActions.updateNavigationRoute(data))
  };
};

const mapStateToProps = state => {
  return {
    user: state.user,
    carId: state.booking.carId,
    checklist: state.checklist,
    window: state.window
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);
