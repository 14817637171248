import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import Demo from "./features/pallete";
import { Login } from "./features/login";
import ListBookingsScreen from "./features/listBookings";
import { BookingDetails, BookingDetailsByFac } from "./features/bookingDetails";
import DeniedAccess from "./shared/components/DeniedAccess";
import PageNotFound from "./shared/components/PageNotFound";
import DefaultRoute from "./shared/components/DefaultRoute";
import UserNotFound from "./shared/components/UserNotFound";
import { AuthenticationState, AzureAD } from "react-aad-msal";
import { authProvider } from "./authProvider";
import {
  storageKeyExpiresOn,
  storageKeyToken,
  SAFETY_TITLE,
  FLEET_TITLE,
  routes
} from "./shared/utils/Constants";
import * as actions from "./store/UserStore";
import store from "./store";
import { accessType, loginErrorType } from "./shared/utils/enum";
import LoginManager from "./features/login/LoginManager";
import PrivateCarManager from "./features/layout/PrivateCarManager";
import CalendarByFac from "./features/calendarByFac";
import {
  SelectDriver,
  SelectSite,
  SelectCarAndTripType,
  SelectDate,
  SelectDetails,
  ConfirmLongTrip,
  ConfirmShortTrip
} from "./features/newBooking";
import {
  ConfirmChecklist,
  CheckCarInformation,
  CheckCarInterior,
  CheckCarExterior,
  CheckMandatoryEquipment,
  ChecklistSecurityRisk
} from "./features/checklist";
import ExpiredSection from "./features/expiredSectionModal";
import UserManagement from "./features/userManagement";
import { CheckInView, CheckOutView } from "./features/checklistView";
import SecurityReport from "./features/securityReport";
import ReportChecklist from "./features/reportChecklist";
import LogoutProvider from "./contexts/LogoutContext";
import ListChecklistsAndReports from "./features/listChecklistsAndReports";
import ChecklistDetails from "./features/checklistDetails";
import ReportDetails from "./features/reportDetails";
import ChecklistsAndReportsManagement from "./features/checklistsAndReportsManagement";
import FleetManagement from "./features/fleetManagement";
import VehicleHistory from "./features/vehicleHistory";
import VehicleDetails from "./features/vehicleDetails";
import UserDetails from "./features/userDetails";
import UserHistory from "./features/userHistory";
import CarReviewManagement from "./features/carReviewManagement";
import UserManagementManager from "./features/userManagement/UserManagementManager";

const Routes = props => {
  const { authenticateUser, isAuthenticated, accessToken, userProfile, updateUser, emailUser } =
    props;

  const [loginError, setLoginError] = useState();

  useEffect(() => {
    const isSafetyProfile = [
      accessType.commercial,
      accessType.executive,
      accessType.backoffice
    ].includes(userProfile);

    const isSafetyRoute = window.location.pathname.includes(routes.safetyLogin);

    document.title = isSafetyRoute || isSafetyProfile ? SAFETY_TITLE.en : FLEET_TITLE.en;
  }, [userProfile]);

  useEffect(() => {
    const getUserData = async () => {
      const user = await LoginManager.authenticate();

      if (user?.requestError) setLoginError(loginErrorType.default);
      else {
        const isCommercialOrExecutive = [accessType.commercial, accessType.executive].includes(
          user?.profile?.profileName
        );
        const isBackoffice = user?.profile?.profileName === accessType.backoffice;
        const shouldGetCar = isCommercialOrExecutive || isBackoffice;

        const privateCar =
          shouldGetCar && (await PrivateCarManager.getPrivateCarByUserId(user?.id));
          
        const userManagement = (await UserManagementManager.getUserById(user?.id))?.management;
          if (isCommercialOrExecutive && !privateCar) {
              setLoginError(loginErrorType.commercial);
          } else if (privateCar) {
              updateUser({
                  ...user,
                  profile: user?.profile?.profileName,
                  privateCar: {
                      id: privateCar.car.id,
                      model: privateCar.carModel.model,
                      document: privateCar.car.carLicensing.carDocument,
                      licensePlate: privateCar.car.carLicensing.carLicensePlate
                  },
                  management: userManagement
              });
          } else
          updateUser({
            ...user,
            profile: user?.profile?.profileName,
            management: userManagement
          });
      }
    };

    if (isAuthenticated && accessToken) getUserData();
  }, [isAuthenticated, updateUser, emailUser, accessToken]);

  const PrivateRoute = ({ Component, access, ...rest }) => {
    const hasAccess = Array.isArray(access) ? access.includes(userProfile) : userProfile === access;

    return (
      <Route
        {...rest}
        render={componentProps =>
          hasAccess ? (
            <Component {...componentProps} userProfile={userProfile} />
          ) : (
            <Redirect to="/deniedAccess" />
          )
        }
      />
    );
  };

  return (
    <AzureAD provider={authProvider} reduxStore={store}>
      {({ _login, logout, authenticationState, _error, _accountInfo }) => {
        if (authenticationState === AuthenticationState.Authenticated && !accessToken) {
          authProvider.getAccessToken().then(token => {
            sessionStorage.setItem(storageKeyExpiresOn, token.expiresOn);
            sessionStorage.setItem(storageKeyToken, token.accessToken);
            authenticateUser();
          });
        }

        return (
          <LogoutProvider logout={logout}>
            {authenticationState !== AuthenticationState.Authenticated && (
              <Switch>
                <Route exact path="/login/" component={Login} />
                <Route exact path="/safety/login/" component={() => <Login isSafety />} />
                <Route exact path="/safety" component={() => <Redirect to="/safety/login/" />} />
                <Route exact path="/pallete/" component={Demo} />
                <Route
                  render={r => {
                    return (
                      <Redirect
                        to={{
                          pathname: "/login/",
                          state: { from: r.location }
                        }}
                      />
                    );
                  }}
                />
              </Switch>
            )}

            {loginError && (
              <Switch>
                <Route
                  exact
                  path="/UserNotFound/"
                  component={() => <UserNotFound loginError={loginError} />}
                />
                <Redirect to="/UserNotFound/" />
              </Switch>
            )}

            {authenticationState === AuthenticationState.Authenticated &&
              accessToken &&
              userProfile && (
                <>
                  <ExpiredSection logout={logout} />
                  <Switch>
                    <Route exact path="/" component={DefaultRoute} />
                    <Route exact path="/login/" component={Login} />
                    <PrivateRoute
                      exact
                      path="/newBooking/step=1"
                      Component={SelectDriver}
                      access={[accessType.driver, accessType.siteFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/newBooking/step=2"
                      Component={SelectSite}
                      access={[accessType.driver, accessType.siteFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/newBooking/step=3"
                      Component={SelectCarAndTripType}
                      access={[accessType.driver, accessType.siteFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/newBooking/step=4"
                      Component={SelectDate}
                      access={[accessType.driver, accessType.siteFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/newBooking/step=5"
                      Component={SelectDetails}
                      access={[accessType.driver, accessType.siteFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/newBooking/confirmLongTrip"
                      Component={ConfirmLongTrip}
                      access={[accessType.driver, accessType.siteFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/newBooking/confirmShortTrip"
                      Component={ConfirmShortTrip}
                      access={[accessType.driver, accessType.siteFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/listBookings"
                      Component={ListBookingsScreen}
                      access={[accessType.driver, accessType.siteFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/bookingDetails/:bookingId"
                      Component={BookingDetails}
                      access={[accessType.driver, accessType.siteFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/bookingDetailsByFac/:bookingId"
                      Component={BookingDetailsByFac}
                      access={accessType.siteFacilities}
                    />
                    <PrivateRoute
                      exact
                      path="/checklist/confirm"
                      Component={ConfirmChecklist}
                      access={[
                        accessType.driver,
                        accessType.siteFacilities,
                        accessType.commercial,
                        accessType.backoffice,
                        accessType.executive
                      ]}
                    />
                    <PrivateRoute
                      exact
                      path="/checklist/securityRisk"
                      Component={ChecklistSecurityRisk}
                      access={[
                        accessType.driver,
                        accessType.siteFacilities,
                        accessType.commercial,
                        accessType.backoffice,
                        accessType.executive
                      ]}
                    />
                    <PrivateRoute
                      exact
                      path="/checklist/step=1"
                      Component={CheckCarInformation}
                      access={[
                        accessType.driver,
                        accessType.siteFacilities,
                        accessType.commercial,
                        accessType.backoffice,
                        accessType.executive
                      ]}
                    />
                    <PrivateRoute
                      exact
                      path="/checklist/step=2"
                      Component={CheckCarInterior}
                      access={[
                        accessType.driver,
                        accessType.siteFacilities,
                        accessType.commercial,
                        accessType.backoffice,
                        accessType.executive
                      ]}
                    />
                    <PrivateRoute
                      exact
                      path="/checklist/step=3"
                      Component={CheckCarExterior}
                      access={[
                        accessType.driver,
                        accessType.siteFacilities,
                        accessType.commercial,
                        accessType.backoffice,
                        accessType.executive
                      ]}
                    />
                    <PrivateRoute
                      exact
                      path="/checklist/step=4"
                      Component={CheckMandatoryEquipment}
                      access={[
                        accessType.driver,
                        accessType.siteFacilities,
                        accessType.commercial,
                        accessType.backoffice,
                        accessType.executive
                      ]}
                    />
                    <PrivateRoute
                      exact
                      path="/calendar"
                      Component={CalendarByFac}
                      access={accessType.siteFacilities}
                    />
                    <PrivateRoute
                      exact
                      path="/users"
                      Component={UserManagement}
                      access={[
                        accessType.siteFacilities,
                        accessType.centralFacilities,
                        accessType.adminFacilities
                      ]}
                    />
                    <PrivateRoute
                      exact
                      path="/checklistViews/checkin"
                      Component={CheckInView}
                      access={[accessType.driver, accessType.siteFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/checklistViews/checkout"
                      Component={CheckOutView}
                      access={[accessType.driver, accessType.siteFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/securityReport"
                      Component={SecurityReport}
                      access={[accessType.commercial, accessType.backoffice, accessType.executive]}
                            />
                    <PrivateRoute
                        exact
                        path="/reportChecklist"
                        Component={ReportChecklist}
                        access={[accessType.teamChecklist, accessType.centralFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/listChecklists"
                      Component={ListChecklistsAndReports}
                      access={[accessType.commercial, accessType.backoffice, accessType.executive]}
                    />
                    <PrivateRoute
                      exact
                      path="/checklistDetails/:checklistId"
                      Component={ChecklistDetails}
                      access={[accessType.commercial, accessType.backoffice, accessType.executive]}
                    />
                    <PrivateRoute
                      exact
                      path="/reportDetails/:reportId"
                      Component={ReportDetails}
                      access={[accessType.commercial, accessType.backoffice, accessType.executive]}
                    />
                    <PrivateRoute
                      exact
                      path="/reportsManagement"
                      Component={ChecklistsAndReportsManagement}
                      access={[accessType.backoffice]}
                    />
                    <PrivateRoute
                      exact
                      path="/fleetManagement"
                      Component={FleetManagement}
                      access={[accessType.centralFacilities, accessType.adminFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/vehicleHistory"
                      Component={VehicleHistory}
                      access={[accessType.centralFacilities, accessType.adminFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/vehicleDetails"
                      Component={VehicleDetails}
                      access={[accessType.centralFacilities, accessType.adminFacilities]}
                    />
                    <PrivateRoute
                      exact
                      path="/userDetails"
                      Component={UserDetails}
                      access={[
                        accessType.centralFacilities,
                        accessType.siteFacilities,
                        accessType.adminFacilities
                      ]}
                    />
                    <PrivateRoute
                      exact
                      path="/userHistory"
                      Component={UserHistory}
                      access={[
                        accessType.centralFacilities,
                        accessType.siteFacilities,
                        accessType.adminFacilities
                      ]}
                    />
                    <PrivateRoute
                      exact
                      path="/carReviewManagement"
                      Component={CarReviewManagement}
                      access={[accessType.centralFacilities, accessType.adminFacilities]}
                    />
                    <Route exact path="/deniedAccess" component={DeniedAccess} />
                    <Route exact path="/notFound" component={PageNotFound} />
                    <Redirect to="/notFound" />
                  </Switch>
                </>
              )}
          </LogoutProvider>
        );
      }}
    </AzureAD>
  );
};

const mapStateToProps = state => {
  return {
    emailUser: state.authentication.account && state.authentication.account.userName,
    accessToken: state.authentication.accessToken,
    isAuthenticated: state.user.isAuthenticated,
    userProfile: state.user.profile
  };
};

const mapDispatchToProps = dispatch => {
    return {
    authenticateUser: () => dispatch(actions.authenticateUser()),
    updateUser: data => dispatch(actions.updateUser(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
