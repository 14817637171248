import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid, makeStyles, Typography, Tab, Tabs } from "@material-ui/core";
import { PRIMARY_COLOR, PALE_BLUE_GRAY } from "../../../theme";
import { ChevronLeft, ChevronRight } from "react-feather";
import { dateTimeViews, dateChange } from "../../../shared/utils/enum";
import { formatDateTime } from "../../../shared/utils/Utils";
import DayView from "./DayDateViewType";
import WeekView from "./WeekDateViewType";
import useCalendarScreen from "../customHooks/useCalendarScreen";
import { format } from "date-fns";
import { Layout } from "../../layout/";

const CalendarByFac = props => {
  const { poleId } = props;

  const classes = useStyles();
  const [selectedDateViewTab, setSelectedDateViewTab] = useState(0);
  const [selectedDateViewType, setSelectedDateViewType] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedWeek, setSelectedWeek] = useState({
    newDate: new Date(),
    formattedDate: ""
  });
  const { dateTimeBookings, fleetList, listBookings } = useCalendarScreen(
    selectedDateViewTab,
    selectedDate,
    selectedWeek,
    setSelectedDateViewType,
    poleId
  );

  const changeDate = index => {
    const selectedDateView =
      selectedDateViewTab === dateTimeViews.dayView.id
        ? selectedDate
        : dateTimeViews.weekView.id && selectedWeek.newDate;
    const newDateTime =
      selectedDateViewType && formatDateTime(selectedDateView, selectedDateViewTab, index);

    newDateTime && setSelectedDateViewType(newDateTime.formattedDate);
    selectedDateViewTab === dateTimeViews.dayView.id
      ? setSelectedDate(newDateTime.newDate)
      : dateTimeViews.weekView.id && setSelectedWeek(newDateTime);
  };

  const getLabelTabColor = index =>
    selectedDateViewTab === index ? classes.typographyLabelSelected : classes.typographyLabel;

  const getLabelTab = (dateTimeView, index) => {
    return (
      <Typography variant="subtitle2" className={getLabelTabColor(index)}>
        {dateTimeView}
      </Typography>
    );
  };

  return (
    <Layout showHeader>
      <Grid item container xs={12} className="pl-4 pr-6 pb-2 pt-6">
        <Grid item container xs={9} className="pl-4 pt-2">
          <Grid item xs={1} className="pt-1">
            <ChevronLeft
              className={`${classes.alignBegin} hover-icon`}
              onClick={
                selectedDateViewTab === dateTimeViews.weekView.id
                  ? () => changeDate(dateChange.previousWeek)
                  : () => changeDate(dateChange.previousDateTime)
              }
              role="button"
              aria-label="Anterior"
            />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" align="center">
              {selectedDateViewType}
            </Typography>
          </Grid>
          <Grid item xs={1} className="pt-1">
            <ChevronRight
              className={`${classes.alignEnd} hover-icon`}
              onClick={
                selectedDateViewTab === dateTimeViews.weekView.id
                  ? () => changeDate(dateChange.nextWeek)
                  : () => changeDate(dateChange.nextDateTime)
              }
              role="button"
              aria-label="Próximo"
            />
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.contentAligment}>
          <Tabs
            value={selectedDateViewTab}
            onChange={(_, newValue) => setSelectedDateViewTab(newValue)}
            className={classes.alignEnd}
          >
            {Object.entries(dateTimeViews).map((view, index) => {
              return (
                <Tab
                  key={view[1].id}
                  id={view[1].id}
                  label={getLabelTab(view[1].type, index)}
                  className={classes.tabSize}
                />
              );
            })}
          </Tabs>
        </Grid>
      </Grid>
      <Grid item xs={12} className="pl-8 pr-4 pt-2">
        {dateTimeBookings &&
        fleetList.length > 0 &&
        selectedDateViewTab === dateTimeViews.dayView.id ? (
          <DayView
            reloadData={listBookings}
            bookings={dateTimeBookings}
            currentDate={selectedDate}
            currentViewName={dateTimeViews.dayView.en_type}
            fleet={fleetList}
          />
        ) : (
          selectedDateViewTab === dateTimeViews.weekView.id && (
            <WeekView
              bookings={dateTimeBookings}
              currentDate={format(selectedWeek.newDate, "yyyy-MM-dd")}
              currentViewName={dateTimeViews.weekView.en_type}
              fleet={fleetList}
            />
          )
        )}
      </Grid>
    </Layout>
  );
};

const useStyles = makeStyles(theme => ({
  alignBegin: {
    display: "flex",
    marginRight: "auto"
  },
  alignEnd: {
    display: "flex",
    marginLeft: "auto"
  },
  typographyLabelSelected: {
    color: PRIMARY_COLOR
  },
  typographyLabel: {
    color: PRIMARY_COLOR
  },
  tabSize: {
    minWidth: "calc(100%/2)"
  },
  contentAligment: {
    border: `1px solid ${PALE_BLUE_GRAY}`,
    borderRadius: theme.spacing(1)
  }
}));

const mapStateToProps = state => {
  return {
    poleId: state.user.pole.id
  };
};

export default connect(mapStateToProps, null)(CalendarByFac);
