import React from "react";
import FloatingDropdown from "../../../shared/components/FloatingDropdown";
import { Grid, makeStyles, Divider } from "@material-ui/core";
import FilterOption from "../../../shared/components/ManagementPage/FilterOption";
import FiltersButton from "../../../shared/components/ManagementPage/FiltersButton";
import { useManagementFilters } from "../../../shared/components/ManagementPage/customHooks/useManagementFilters";
import FilterMenuButtons from "../../../shared/components/ManagementPage/FilterMenuButtons";

const CarReviewFilters = props => {
  const { filtersData, applyFilters } = props;
  const classes = useStyles();

  const {
    selectedFilters,
    showFloatFilters,
    setShowFloatFilters,
    anyFilterAreSelected,
    handleClear,
    areAllSelected,
    arePartiallySelected,
    isDisabled,
    handleAllCheckbox,
    handleSpecificCheckbox,
    handleApply
  } = useManagementFilters({ filtersData, applyFilters });

  return (
    <>
      <FiltersButton
        anyFilterAreSelected={anyFilterAreSelected()}
        onClick={() => setShowFloatFilters(true)}
        handleClear={handleClear}
      />

      <FloatingDropdown
        enableDividers
        disabled={!showFloatFilters}
        className={`${classes.floatDropdown} mt-2`}
        onOutsideClick={() => setShowFloatFilters(false)}
      >
        <FilterOption
          label="Tipo de Frota"
          expandible
          checked={areAllSelected("fleetTypeList")}
          inderteminate={arePartiallySelected("fleetTypeList")}
          handleChange={e => handleAllCheckbox(e.target.checked, "fleetTypeList")}
        >
          {selectedFilters.fleetTypeList?.map((fleetType, index) => (
            <Grid item xs={12} key={fleetType.id + fleetType.name}>
              <Divider className="divider" />
              <FilterOption
                className="pl-8"
                checked={fleetType.isSelected}
                handleChange={e =>
                  handleSpecificCheckbox(fleetType.id, e.target.checked, "fleetTypeList")
                }
                label={fleetType.name}
                enableDividers={index > 0}
              />
            </Grid>
          ))}
        </FilterOption>
        <FilterOption
          label="Fornecedor"
          expandible
          checked={areAllSelected("providerList")}
          inderteminate={arePartiallySelected("providerList")}
          handleChange={e => handleAllCheckbox(e.target.checked, "providerList")}
        >
          {selectedFilters.providerList?.map((provider, index) => (
            <Grid item xs={12} key={provider.id + provider.name}>
              <Divider className="divider" />
              <FilterOption
                className="pl-8"
                checked={provider.isSelected}
                handleChange={e =>
                  handleSpecificCheckbox(provider.id, e.target.checked, "providerList")
                }
                label={provider.name}
                enableDividers={index > 0}
              />
            </Grid>
          ))}
        </FilterOption>
        <FilterOption
          label="Status de Revisão"
          expandible
          checked={areAllSelected("reviewStatusList")}
          inderteminate={arePartiallySelected("reviewStatusList")}
          handleChange={e => handleAllCheckbox(e.target.checked, "reviewStatusList")}
          disabled={isDisabled("reviewStatusList")}
        >
          {selectedFilters.reviewStatusList?.map((status, index) => (
            <Grid item xs={12} key={status.id + status.name}>
              <Divider className="divider" />
              <FilterOption
                className="pl-8"
                checked={status.isSelected}
                handleChange={e =>
                  handleSpecificCheckbox(status.id, e.target.checked, "reviewStatusList")
                }
                label={status.name}
                enableDividers={index > 0}
                disabled={status.disabled}
              />
            </Grid>
          ))}
        </FilterOption>
        <Grid container justify="center" className="pt-8 pb-8">
          <FilterMenuButtons handleApply={handleApply} handleClear={handleClear} />
        </Grid>
      </FloatingDropdown>
    </>
  );
};

const useStyles = makeStyles(() => ({
  floatDropdown: {
    maxWidth: 320,
    width: "100%"
  }
}));

export default CarReviewFilters;
