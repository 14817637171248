import React, { useEffect, useState, useRef } from "react";
import { Divider, FormControl, InputLabel, Select, Collapse } from "@material-ui/core";
import { connect } from "react-redux";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import useSecurityReport from "../customHooks/useSecurityReport";
import { securityReportOtherCases, officeList } from "../../../shared/utils/enum";
import GeneralInformation from "../../../shared/components/GeneralInformation";
import CustomTextField from "../../../shared/components/CustomTextField";

const FirstStep = props => {
  const { user, securityReport, setSecurityReport, setDisabledNextButton } = props;
  const [showOtherPrincipalInvolved, setShowOtherPrincipalInvolved] = useState(false);
  const [showLocalOffice, setShowLocalOffice] = useState(false);
  const [showLocalSite, setShowLocalSite] = useState(false);
  const [showOtherLocal, setShowOtherLocal] = useState(false);

  const fields = ["Nome", "Diretoria/Regional", "Gerência"];
  const data = [user.name, user.management?.regional?.name, user.management?.name];

  const { principalInvolvedList, listPrincipalInvolved, localList, listLocal, siteList, listSite } =
    useSecurityReport();

  const showOrHideAnotherLocalOptions = value => {
    const siteIsRequired = value === securityReportOtherCases.LOCAL_SITE;
    if (siteIsRequired) listSite();

    setShowLocalOffice(value === securityReportOtherCases.LOCAL_OFFICE);
    setShowLocalSite(siteIsRequired);
    setShowOtherLocal(value === securityReportOtherCases.LOCAL_OTHER);
  };

  const showOrHideOtherPrincipalInvolvedOptions = value => {
    setShowOtherPrincipalInvolved(value === securityReportOtherCases.PRINCIPAL_INVOLVED_OTHER);
  };

  const refListPrincipalInvolved = useRef(listPrincipalInvolved);
  const refListLocal = useRef(listLocal);
  const refSetDisabledNextButton = useRef(setDisabledNextButton);
  const refLocalId = useRef(securityReport.localId);
  const refPrincipalInvolvedId = useRef(securityReport.principalInvolvedId);
  const refShowOrHideAnotherLocalOptions = useRef(showOrHideAnotherLocalOptions);
  const refShowOrHideOtherPrincipalInvolvedOptions = useRef(
    showOrHideOtherPrincipalInvolvedOptions
  );
  const refSetSecurityReport = useRef(setSecurityReport);
  const refUserId = useRef(user.id);
  const refSecurityReport = useRef(securityReport);

  useEffect(() => {
    refSetSecurityReport.current({ ...refSecurityReport.current, userId: refUserId.current });
    refShowOrHideAnotherLocalOptions.current(refLocalId.current);
    refShowOrHideOtherPrincipalInvolvedOptions.current(refPrincipalInvolvedId.current);

    async function getData() {
      await refListPrincipalInvolved.current();
      await refListLocal.current();
    }

    getData();
  }, []);

  useEffect(() => {
    const disabledByPrincipalInvolved =
      !securityReport.principalInvolvedId > 0 ||
      (showOtherPrincipalInvolved && !securityReport.principalInvolvedDescription);

    const disabledByLocal =
      !securityReport.localId > 0 ||
      (showOtherLocal && !securityReport.localDescription) ||
      (showLocalOffice && (!securityReport.officeId || !securityReport.officeId > 0)) ||
      (showLocalSite && (!securityReport.siteId || !securityReport.siteId > 0));

    refSetDisabledNextButton.current(
      disabledByPrincipalInvolved || disabledByLocal || !securityReport.occurrenceDateTime
    );
  }, [showLocalOffice, showOtherLocal, showLocalSite, showOtherPrincipalInvolved, securityReport]);

  const handleChangePrincipalInvoled = value => {
    value = parseInt(value);

    showOrHideOtherPrincipalInvolvedOptions(value);
    setSecurityReport({
      ...securityReport,
      principalInvolvedId: value,
      principalInvolvedDescription: null
    });
  };

  const handleChangeLocal = value => {
    value = parseInt(value);

    showOrHideAnotherLocalOptions(value);
    setSecurityReport({
      ...securityReport,
      localId: value,
      officeId: null,
      siteId: null,
      localDescription: null
    });
  };

  return (
    <>
      <Divider className="divider-2 mb-6" />

      <GeneralInformation fields={fields} data={data} />

      <Divider className="divider-2 mb-6 mt-3" />

      <FormControl fullWidth variant="outlined" className="mb-6">
        <InputLabel>Principal envolvido na ocorrência</InputLabel>
        <Select
          inputProps={{
            "aria-label": "Selecione o principal envolvido na ocorrência"
          }}
          label="Principal envolvido na ocorrência"
          variant="outlined"
          native
          value={securityReport.principalInvolvedId || 0}
          onChange={e => handleChangePrincipalInvoled(e.target.value)}
        >
          <option value={0}>Selecione o envolvido</option>
          {principalInvolvedList?.map(principalInvolved => (
            <option key={principalInvolved.id} value={principalInvolved.id}>
              {principalInvolved.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <Collapse in={showOtherPrincipalInvolved}>
        <CustomTextField
          maxLength={255}
          className="mb-6"
          label="Descreva o principal envolvido"
          placeholder="Descreva o principal envolvido"
          value={securityReport.principalInvolvedDescription || ""}
          onChange={e =>
            setSecurityReport({ ...securityReport, principalInvolvedDescription: e.target.value })
          }
        />
      </Collapse>

      <FormControl fullWidth variant="outlined" className="mb-6">
        <InputLabel>Local</InputLabel>
        <Select
          inputProps={{
            "aria-label": "Selecione o local da ocorrência"
          }}
          label="Local"
          variant="outlined"
          native
          value={securityReport.localId || 0}
          onChange={e => handleChangeLocal(e.target.value)}
        >
          <option value={0}>Selecione o local da ocorrência</option>
          {localList?.map(local => (
            <option key={local.id} value={local.id}>
              {local.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <Collapse in={showOtherLocal}>
        <CustomTextField
          maxLength={255}
          className="mb-6"
          label="Descreva o local"
          placeholder="Descreva o local"
          value={securityReport.localDescription || ""}
          onChange={e => setSecurityReport({ ...securityReport, localDescription: e.target.value })}
        />
      </Collapse>

      <Collapse in={showLocalOffice}>
        <FormControl fullWidth variant="outlined" className="mb-6">
          <InputLabel>Escritório</InputLabel>
          <Select
            inputProps={{
              "aria-label": "Selecione o escritório"
            }}
            label="Escritório"
            variant="outlined"
            native
            value={securityReport.officeId || 0}
            onChange={e =>
              setSecurityReport({ ...securityReport, officeId: parseInt(e.target.value) })
            }
          >
            <option value={0}>Selecione o escritório</option>
            {officeList.map(office => (
              <option key={office.id} value={office.id}>
                {office.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </Collapse>

      <Collapse in={showLocalSite}>
        <FormControl fullWidth variant="outlined" className="mb-6">
          <InputLabel>Planta</InputLabel>
          <Select
            inputProps={{
              "aria-label": "Selecione a planta"
            }}
            label="Planta"
            variant="outlined"
            native
            value={securityReport.siteId || 0}
            onChange={e =>
              setSecurityReport({ ...securityReport, siteId: parseInt(e.target.value) })
            }
          >
            <option value={0}>Selecione a planta</option>
            {siteList.map(site => (
              <option key={site.id} value={site.id}>
                {site.fullName}
              </option>
            ))}
          </Select>
        </FormControl>
      </Collapse>

      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} className="mb-6">
        <KeyboardDateTimePicker
          fullWidth
          value={securityReport.occurrenceDateTime || null}
          onChange={value => setSecurityReport({ ...securityReport, occurrenceDateTime: value })}
          label="Data e horário da ocorrência"
          disableFuture
          variant="outlined"
          placeholder="dd/mm/aaaa - 00:00"
          inputVariant="outlined"
          cancelLabel="CANCELAR"
          ampm={false}
          format="dd/MM/yyyy - HH:mm"
          KeyboardButtonProps={{
            "aria-label": "Data e horário da ocorrência"
          }}
          inputProps={{
            "aria-label": "Informe a data e horário da ocorrência"
          }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(FirstStep);
