import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableHead,
  Grid
} from "@material-ui/core";
import Loading from "../../components/Loading";
import { LIGHT_GRAY2 } from "../../../theme";
import moment from "moment";
import { Calendar } from "react-feather";
import CustomizedTooltip from "../../components/CustomizedTooltip";
import CalendarEvents from "./CalendarEvents";
import { obterIntervalosDePeriodo } from "../../utils/Utils";

const AppointmentsCalendar = props => {
  const classes = useStyles();
  const {
    carregandoCalendario,
    recarregarCalendario,
    agenda,
    removerEvento,
    handleAddSchedule,
    listaEventos,
    showHeader
  } = props;

  const horariosFixos = useMemo(() => {
    const horarioInicio = moment({ hour: 0 });
    const horarioFim = moment({ hour: 23, minute: 59 });

    return obterIntervalosDePeriodo(horarioInicio, horarioFim, 15);
  }, []);

  const handleExcluir = id => {
    removerEvento(id);
    recarregarCalendario(true);
  };

  const agendaTratada = useMemo(() => {
    let tabelaCalendario = [];

    agenda.forEach(a => {
      tabelaCalendario.push({
        data: a.data,
        diaSemanaExtenso: "Quarta Feira",
        diaSemana: moment(a.data).format("D"),
        inicioJornada: moment(a.inicioJornada).format("HH:mm"),
        fimJornada: moment(a.fimJornada).format("HH:mm"),
        colunas: a.intervalos
          ? a.intervalos.map(i => {
              const momentInicio = moment(i.dataHoraInicio);
              const roundHorarioInicio = momentInicio
                .clone()
                .add(-(momentInicio.minutes() % 15), "minutes");

              return {
                horarioReferencia: roundHorarioInicio.format("HH:mm"),
                horarioInicio: momentInicio.format("HH:mm"),
                horarioFim: moment(i.dataHoraFim).format("HH:mm"),
                rowSpan: Math.ceil(
                  moment(i.dataHoraFim).diff(moment(i.dataHoraInicio), "minutes") / 15
                ),
                colSpan: a.intervalos.some(e => {
                  let eventoMenorOuIgual =
                    moment(i.dataHoraFim).isAfter(moment(e.dataHoraInicio)) &&
                    moment(roundHorarioInicio).isSameOrBefore(moment(e.dataHoraInicio));
                  let eventoMaior =
                    e.dataHoraInicio < i.dataHoraInicio && e.dataHoraFim > i.dataHoraInicio;

                  return e.id !== i.id && (eventoMenorOuIgual || eventoMaior);
                })
                  ? 1
                  : 2,
                evento: listaEventos && listaEventos.find(x => x.id === i.idMotivo),
                idsAgendamento: i.idsAgendamento,
                idBloqueio: i.id,
                textoTooltip: [
                  {
                    horaInicio: momentInicio.format("HH:mm"),
                    descricao:
                      listaEventos && listaEventos.find(x => x.id === i.idMotivo).descricao,
                    usuarioUltimaAlteracao: i.usuarioUltimaAlteracao
                  }
                ]
              };
            })
          : []
      });
    });
    return tabelaCalendario;
  }, [agenda, listaEventos]);

  return (
    <div className={classes.root}>
      {carregandoCalendario ? (
        <Grid item xs={12}>
          <Loading loading={carregandoCalendario} />
        </Grid>
      ) : (
        <Paper>
          {agendaTratada && (
            <Table style={{ height: "500px", tableLayout: "fixed" }}>
              <colgroup>
                <col width="20%" />
                <col width="80%" />
              </colgroup>
              {showHeader && (
                <TableHead>
                  <TableRow style={{ height: "20px" }}>
                    <TableCell
                      align="center"
                      className={`${classes.celulasPeriodo} ${classes.flutuante}`}
                    >
                      <Calendar className={classes.icone} />
                    </TableCell>
                    {agendaTratada &&
                      agendaTratada.map((dias, index) => (
                        <CustomizedTooltip
                          key={index}
                          titulo={`${moment(dias.data).format("DD")} de ${moment(dias.data).format(
                            "MMMM"
                          )} de ${moment(dias.data).format("YYYY")}`}
                        >
                          <TableCell
                            colSpan={2}
                            key={index}
                            align="center"
                            className={`${classes.celulasPeriodo} mt-2 ${classes.flutuante}`}
                          >
                            <Typography variant="h3">{dias.diaSemana}</Typography>
                            <Typography variant="body1">{dias.diaSemanaExtenso}</Typography>
                          </TableCell>
                        </CustomizedTooltip>
                      ))}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {horariosFixos.map(horario => (
                  <CalendarEvents
                    key={horario.format("HH:mm")}
                    horarioReferencia={horario}
                    agenda={agendaTratada}
                    handleExcluir={handleExcluir}
                    handleAddSchedule={handleAddSchedule}
                  />
                ))}
              </TableBody>
            </Table>
          )}
        </Paper>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    "& > *": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%",
      height: "100%"
    },
    "& .MuiTableCell-root": {
      borderBottom: "hidden"
    },
    "& tr .MuiTableCell-body:first-child": {
      borderRight: "0.1em solid #E3E8F1",
      padding: "0.5em"
    },
    "& .MuiPaper-root": {
      boxShadow: "none"
    }
  },
  celulasPeriodo: {
    width: "60px",
    paddingBottom: "10px"
  },
  icone: {
    width: "24px",
    height: "26px",
    margin: "auto",
    backgroundColor: LIGHT_GRAY2
  },
  flutuante: {
    position: "sticky",
    top: "0",
    zIndex: 1,
    backgroundColor: "rgb(255, 255, 255, 0.9)"
  }
}));

export default AppointmentsCalendar;
