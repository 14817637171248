import { MsalAuthProvider, LoginType } from "react-aad-msal";

export const authConfig = {
  auth: {
    authority: "https://login.microsoftonline.com/ef8a53ea-1a1c-4189-b792-c832dcaea568", // Instance + TenantId
    clientId: "91fca96b-2198-4cfa-b7a7-66c16adc16e3", // App Registration - Fleet
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    validateAuthority: true,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
};

export const authParams = {
  scopes: [
    "5601168d-476e-428e-b383-0e54de3294da/Acesso" // Scope do App Registration do BackEnd - Fleet
  ]
};

export const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin
};

export const authProvider = new MsalAuthProvider(authConfig, authParams, options);
