import { combineReducers } from "redux";
import {reducer as notificationStore} from "./NotificationStore";
import {reducer as userStore} from "./UserStore";
import {reducer as authStore} from "./AuthStore";
import {reducer as bookingStore} from "./BookingStore";
import {reducer as windowSizeStore} from "./WindowSizeStore";
import {reducer as checklistStore} from "./ChecklistStore";
import {reducer as routerStore} from "./RouterStore";
import {reducer as managementPageStore} from "./ManagementPageStore";

const reducers = combineReducers({
  notification: notificationStore,
  user: userStore,
  authentication: authStore,
  booking: bookingStore,
  checklist: checklistStore,
  window: windowSizeStore,
  router: routerStore,
  managementPage: managementPageStore
});

export default reducers;
