import {
  CheckCircle,
  XCircle,
  FileText,
  AlertTriangle,
  MinusCircle,
  UserCheck,
  Disc,
  Calendar,
  FilePlus,
  Users,
  LogOut,
  Send,
  Clipboard
} from "react-feather";
import {
  DARK_GREEN,
  RED,
  SECONDARY_COLOR,
  ORANGE,
  DARK_GRAY,
  WHITE,
  DARK_YELLOW,
  GRAY,
  LINCOLN_GREEN,
  UPSDELL_RED,
  GOLDENROD,
  BANANA_MANIA,
  BUBBLE_GUM,
  MENTOL,
  LIGHT_GRAY,
  YELLOW,
  LIGHT_GREEN,
  THIN_YELLOW,
  THIN_RED,
  THIN_GREEN,
  LIGHT_BLUE2
} from "../../theme";
import { ReactComponent as UserCheckIcon } from "../img/UserCheckIcon.svg";
import { ReactComponent as UsersIcon } from "../img/UsersIcon.svg";
import { ReactComponent as WindIcon } from "../img/WindIcon.svg";
import { ReactComponent as WatchIcon } from "../img/WatchIcon.svg";
import SaveiroIcon from "../img/Saveiro.png";
import GolIcon from "../img/Gol.png";
import KiaBongoIcon from "../img/KiaBongo.png";
import AmbulanceIcon from "../img/Ambulancia.png";
import { ReactComponent as CarIcon } from "../img/CarIcon.svg";
import { ReactComponent as PickUpIcon } from "../img/PickupTruckIcon.svg";
import { routes, sidebarItemsNames } from "./Constants";
import { ReactComponent as CustomCarIcon } from "../img/CustomCarIcon.svg";
import { ReactComponent as ErrorIcon } from "../img/Error.svg";
import { ReactComponent as SuccessIcon } from "../img/Success.svg";
import { ReactComponent as WarnIcon } from "../img/Warning.svg";
import { ReactComponent as InfoIcon } from "../img/Info.svg";

export const environment = {
  production: { code: "production" },
  homologation: { code: "homologation" },
  rollout: { code: "rollout" },
  development: { code: "development" },
  local: { code: "local" }
};

export const formType = {
  checklist: "Checklist",
  booking: "Booking",
  securityReport: "Security Report"
};

export const driverType = {
  SELF_DRIVER: {
    id: 1,
    name: "Esta reserva é para mim",
    icon: UserCheckIcon
  },
  OTHER_DRIVER: {
    id: 2,
    name: "Esta reserva é para outra pessoa",
    icon: UsersIcon
  }
};

export const tripTypeList = [
  {
    id: 2,
    name: "Viagem de curta duração (menor que 8 horas)",
    icon: WindIcon
  },
  {
    id: 1,
    name: "Viagem longa duração (maior que 8 horas corridas)",
    icon: WatchIcon
  }
];

export const tripType = {
  long: 1,
  short: 2,
  maintenance: 3
};

export const weekDay = {
  0: "Domingo, ",
  1: "Segunda-feira, ",
  2: "Terça-feira, ",
  3: "Quarta-feira, ",
  4: "Quinta-feira, ",
  5: "Sexta-feira, ",
  6: "Sábado, "
};

export const carType = {
  passenger: {
    id: 1,
    text: "Carro de passeio",
    icon: CarIcon,
    alt: "Icone de carro"
  },
  pickup: {
    id: 2,
    text: "Caminhonete",
    icon: PickUpIcon,
    alt: "Icone de caminhonete"
  },
  truck: {
    id: 4,
    text: "Caminhão",
    icon: PickUpIcon,
    alt: "Icone de caminhão"
  },
  ambulance: {
    id: 5,
    text: "Ambulância",
    icon: PickUpIcon,
    alt: "Ícone de ambulância"
  }
};

export const bookingStatus = {
  APPROVED: 1,
  DISAPPROVED: 2,
  ON_APPROVAL: 3,
  CANCELED: 4,
  FINALIZED: 5,
  EXPIRED: 6,
  CHECKLIST_STARTED: 7,
  IN_PROGRESS: 8,
  SECURITY_RISK: 9
};

export const checklistStatus = {
  APPROVED: 1,
  SECURITY_RISK: 2
};

export const bookingStatusStyle = [
  {
    statusId: bookingStatus.APPROVED,
    text: "Aprovada",
    icon: <CheckCircle size={20} />,
    color: DARK_GREEN
  },
  {
    statusId: bookingStatus.CANCELED,
    text: "Cancelada",
    icon: <XCircle size={20} />,
    color: RED
  },
  {
    statusId: bookingStatus.CHECKLIST_STARTED,
    text: "Checklist iniciado",
    icon: <FileText size={20} />,
    color: SECONDARY_COLOR
  },
  {
    statusId: bookingStatus.SECURITY_RISK,
    text: "Risco de segurança",
    icon: <AlertTriangle size={20} />,
    color: ORANGE
  },
  {
    statusId: bookingStatus.IN_PROGRESS,
    text: "Em andamento",
    icon: <MinusCircle size={20} />,
    color: SECONDARY_COLOR
  },
  {
    statusId: bookingStatus.FINALIZED,
    text: "Finalizada",
    icon: <UserCheck size={20} />,
    color: SECONDARY_COLOR
  },
  {
    statusId: bookingStatus.EXPIRED,
    text: "Expirada",
    icon: <Disc size={20} />,
    color: DARK_GRAY
  }
];

export const tabsMyBookings = {
  active: {
    label: "ativas",
    value: 0
  },
  finished: {
    label: "finalizadas",
    value: 1
  },
  canceled: {
    label: "canceladas",
    value: 2
  }
};

export const checklistTypeAnswer = {
  ok: 1,
  notOk: 2
};

export const marksFuelLevel = [
  {
    value: 25,
    label: "Baixo"
  },
  {
    value: 50,
    label: "Médio"
  },
  {
    value: 75,
    label: "Alto"
  },
  {
    value: 100,
    label: "Cheio"
  }
];

export const accessType = {
  siteFacilities: "Site Facilities",
  driver: "Driver",
  commercial: "Commercial",
  backoffice: "Backoffice",
  executive: "Executive",
  centralFacilities: "Central Facilities",
  adminFacilities: "Admin Facilities",
  teamChecklist: "Team Checklist"
};

export const dateTimeViews = {
  dayView: { id: 0, type: "Dia", en_type: "Day" },
  weekView: { id: 1, type: "Semana", en_type: "Week" }
};

export const dateChange = {
  previousDateTime: -1,
  nextDateTime: +1,
  previousWeek: -7,
  nextWeek: +7
};

export const calendarView = {
  startDayHour: 0,
  endDayHour: 24,
  cellDuration: 60,
  cellHeight: 100
};

export const grouping = [
  {
    resourceName: "priorityId"
  }
];

export const calendarLanguage = {
  ptBR: "pt-BR"
};

export const carTypes = [
  {
    id: 1,
    src: GolIcon,
    alt: "Ícone Gol"
  },
  {
    id: 2,
    src: SaveiroIcon,
    alt: "Ícone Saveiro"
  },
  {
    id: 4,
    src: KiaBongoIcon,
    alt: "Ícone Kia Bongo"
  },
  {
    id: 5,
    src: AmbulanceIcon,
    alt: "Ícone Ambulância"
  }
];

export const maxAllowedMileage = 999999;

export const checklistTypes = {
  CHECKIN: 1,
  CHECKOUT: 2,
  RECURRING: 3
};

export const deliveryVersion = [
  {
    version: "8.0",
    delivery: "FAC with driver profile and checkout"
  },
  {
    version: "9.0",
    delivery: "User management and booking expiration routine"
  },
  {
    version: "10.0",
    delivery: "Checkout view and cache configuration"
  },
  {
    version: "10.1",
    delivery: "Fixed bug in cache configuration"
  },
  {
    version: "11.0",
    delivery: "User creation and checkin visualization"
  },
  {
    version: "12.0",
    delivery: "User editing and dts correction"
  },
  {
    version: "13.0",
    delivery: "Commercial checklist"
  },
  {
    version: "14.0",
    delivery: "Security report"
  },
  {
    version: "1.15.0",
    delivery: "Checklist visualization for commercial"
  },
  {
    version: "1.16.0",
    delivery: "Report visualization and Pentest fixes"
  },
  {
    version: "1.16.1",
    delivery: "Removed route authorization"
  },
  {
    version: "1.17.0",
    delivery: "Export reports and checklists"
  },
  {
    version: "1.18.0",
    delivery: "Files upload and checklist adjustments"
  },
  {
    version: "1.18.1",
    delivery: "Refactoring and system improvements"
  },
  {
    version: "1.19.0",
    delivery: "Car activation and inactivation"
  },
  {
    version: "2.0.0",
    delivery: "Car registration by central FAC"
  },
  {
    version: "2.1.0",
    delivery: "Car listing and editing"
  },
  {
    version: "2.1.1",
    delivery: "Bug fix for inexistent carTypes"
  },
  {
    version: "2.2.0",
    delivery: "Car history and integration with AD"
  },
  {
    version: "3.0.0",
    delivery: "Commercial Safety delivery"
  },
  {
    version: "3.1.0",
    delivery: "Car list filters and Car details page"
  },
  {
    version: "3.2.0",
    delivery: "User list and registration rework"
  },
  {
    version: "3.3.0",
    delivery: "User edition rework and user inactivation via AD"
  },
  {
    version: "3.4.0",
    delivery: "User history and filters"
  },
  {
    version: "3.4.1",
    delivery: "Usability improvements and bug fixes"
  },
  {
    version: "3.5.0",
    delivery: "Driver notifications and improvements"
  },
  {
    version: "3.5.1",
    delivery: "UX and code improvements"
  },
  {
    version: "3.6",
    delivery: "Car reviews"
  },
  {
    version: "3.7",
    delivery: "Blocking checkout, car review history and routines migration"
  },
  {
    version: "4.0",
    delivery: "FAC Admin profile and UX, business rules and security (PENTEST) improvements"
  },
  {
    version: "4.0.1",
    delivery: "BUG fixes for security report and improving techincal points"
  },
  {
    version: "5.0",
    delivery: "Car contract monthly rate import feature and adjusting cors"
  },
  {
    version: "5.1.0",
    delivery: "Import car contracts features"
  },
  {
    version: "5.1.1",
    delivery: "BUG fix for car contracts without maintenance provider registered"
  },
  {
    version: "5.2.0",
    delivery: "Paraguay mercosul license plate validation"
  }
];

export const profileStyle = [
  {
    id: 1,
    label: "Condutor Unidade",
    profile: "Driver",
    borderColor: SECONDARY_COLOR
  },
  {
    id: 2,
    label: "FAC Unidade",
    profile: "Site Facilities",
    borderColor: ORANGE
  },
  {
    id: 3,
    label: "Condutor Comercial",
    profile: "Commercial",
    borderColor: DARK_GREEN
  },
  {
    id: 4,
    label: "Backoffice",
    profile: "Backoffice",
    borderColor: RED
  },
  {
    id: 5,
    label: "Condutor Executivo",
    profile: "Executive",
    borderColor: DARK_YELLOW
  },
  {
    id: 6,
    label: "FAC Central",
    profile: "Central Facilities",
    borderColor: GRAY
  },
  {
    id: 7,
    label: "FAC Admin",
    profile: "Admin Facilities",
    borderColor: GRAY
    }
    ,
    {
        id: 8,
        label: "Team Checklist",
        profile: "Team Checklist",
        borderColor: GRAY
    }
];

export const userManagementProfiles = {
  driver: {
    value: 1,
    name: "Driver",
    label: "Condutor Unidade"
  },
  facilities: {
    value: 2,
    name: "Site Facilities",
    label: "FAC Unidade"
  },
  commercial: {
    value: 3,
    name: "Commercial",
    label: "Condutor Comercial"
  },
  executive: {
    value: 5,
    name: "Executive",
    label: "Condutor Executivo"
  },
  centralFAC: {
    value: 6,
    name: "Central Facilities",
    label: "FAC Central"
  },
  adminFac: {
    value: 7,
    name: "Admin Facilities",
    label: "FAC Admin"
  },
  teamCList: {
    value: 8,
    name: "Team Checklist",
    label: "Team Checklist"
  }
};

export const driverLicenseTypes = [
  {
    value: 2,
    label: "B"
  },
  {
    value: 5,
    label: "C"
  },
  {
    value: 6,
    label: "D"
  },
  {
    value: 7,
    label: "E"
  },
  {
    value: 3,
    label: "AB"
  },
  {
    value: 8,
    label: "AC"
  },
  {
    value: 4,
    label: "AD"
  },
  {
    value: 9,
    label: "AE"
  }
];

export const bookingCarInformationFields = [
  "Nome do condutor:",
  "Vencimento CNH:",
  "Vencimento Curso Dir. Defensiva:",
  "Placa:",
  "Modelo/Fabricante:",
  "Documento do veículo:",
  "Unidade:"
];

export const commercialCarInformationFields = [
  "Nome do condutor:",
  "Diretoria/Regional:",
  "Gerência:",
  "Vencimento CNH:",
  "Vencimento Curso Dir. Defensiva:",
  "Documento do veículo:"
];

export const loginErrorType = {
  default: 1,
  commercial: 2
};

export const officeList = [
  { id: 1, name: "Porto Alegre" },
  { id: 2, name: "São Paulo" }
];

export const securityReportOtherCases = {
  PRINCIPAL_INVOLVED_OTHER: 3,
  LOCAL_OFFICE: 2,
  LOCAL_SITE: 5,
  LOCAL_OTHER: 4,
  SUBJECT_OTHER: 33
};

export const sidebarItems = [
  {
    icon: (
      <CustomCarIcon
        fill={WHITE}
        stroke={WHITE}
        style={{ width: 35, height: 35 }}
        alt="Gestão de frotas"
      />
    ),
    name: sidebarItemsNames.fleetManagement,
    route: routes.fleetManagement,
    profiles: [accessType.centralFacilities, accessType.adminFacilities]
  },
  {
    icon: <Clipboard color={WHITE} size={28} className="pl-1" />,
    name: sidebarItemsNames.reportsManagement,
    route: routes.reportsManagement,
    profiles: [accessType.backoffice]
  },
  {
    icon: <Calendar color={WHITE} size={28} className="pl-1" />,
    name: sidebarItemsNames.calendar,
    route: routes.calendar,
    profiles: [accessType.siteFacilities]
  },
  {
    icon: <Users color={WHITE} size={28} className="pl-1" />,
    name: sidebarItemsNames.usersManagement,
    route: routes.users,
    profiles: [accessType.siteFacilities, accessType.centralFacilities, accessType.adminFacilities]
  },
  {
    icon: <Clipboard color={WHITE} size={28} className="pl-1" />,
    name: sidebarItemsNames.carReviewManagement,
    route: routes.carReviewManagement,
    profiles: [accessType.centralFacilities, accessType.adminFacilities]
    },
    {
        icon: <Clipboard color={WHITE} size={28} className="pl-1" />,
        name: sidebarItemsNames.reportChecklists,
        route: routes.reportChecklist,
        profiles: [accessType.teamChecklist]
    },
  {
    icon: <FileText color={WHITE} size={28} className="pl-1" />,
    name: sidebarItemsNames.listBookings,
    route: routes.listBookings,
    profiles: [accessType.siteFacilities, accessType.driver]
  },
  {
    icon: <FilePlus color={WHITE} size={28} className="pl-1" />,
    name: sidebarItemsNames.newBooking,
    route: routes.bookingSteps.SELECT_DRIVER.route,
    profiles: [accessType.siteFacilities, accessType.driver]
  },
  {
    icon: <FileText color={WHITE} size={28} className="pl-1" />,
    name: sidebarItemsNames.listChecklists,
    route: routes.listChecklists,
    profiles: [accessType.commercial, accessType.backoffice, accessType.executive]
  },
  {
    icon: (
      <CustomCarIcon
        fill={WHITE}
        stroke={WHITE}
        style={{ width: 35, height: 35 }}
        alt="Fazer checklist do veículo"
      />
    ),
    name: sidebarItemsNames.checklistCar,
    route: routes.checklistSteps.CHECK_CAR_INFORMATION.route,
    profiles: [accessType.commercial, accessType.backoffice, accessType.executive]
  },
  {
    icon: <AlertTriangle color={WHITE} size={28} className="pl-1" />,
    name: sidebarItemsNames.securityReport,
    route: routes.securityReport,
    profiles: [accessType.commercial, accessType.backoffice, accessType.executive]
  },
  {
    icon: <Send color={WHITE} size={28} className="pl-1" />,
    name: sidebarItemsNames.feedback,
    route: null,
    profiles: [
      accessType.siteFacilities,
      accessType.commercial,
      accessType.driver,
      accessType.backoffice,
      accessType.executive,
      accessType.centralFacilities,
      accessType.adminFacilities
    ]
  },
  {
    icon: <LogOut color={WHITE} size={28} className="pl-1" />,
    name: sidebarItemsNames.logout,
    route: null,
    profiles: [
      accessType.siteFacilities,
      accessType.commercial,
      accessType.driver,
      accessType.backoffice,
      accessType.executive,
      accessType.centralFacilities,
      accessType.adminFacilities
    ]
  }
];

export const tabsListChecklists = {
  checklists: {
    label: "Meus checklists",
    value: 0
  },
  securityReports: {
    label: "Meus relatos",
    value: 1
  }
};

export const dropzoneErrorTypes = {
  attachmentLimit: "too-many-files",
  fileSizeLimit: "file-too-large",
  invalidFileType: "file-invalid-type"
};

export const expireStatus = {
  invalid: {
    label: "VENCIDO",
    style: {
      backgroundColor: BUBBLE_GUM,
      borderColor: BUBBLE_GUM,
      textColor: UPSDELL_RED
    }
  },
  valid: {
    label: "VÁLIDO",
    style: {
      backgroundColor: MENTOL,
      borderColor: MENTOL,
      textColor: LINCOLN_GREEN
    }
  }
};

export const carStatus = [
  {
    id: 1,
    name: "Aguardando entrega",
    style: {
      backgroundColor: BANANA_MANIA,
      borderColor: BANANA_MANIA,
      textColor: GOLDENROD
    }
  },
  {
    id: 2,
    name: "Aguardando definições",
    style: {
      backgroundColor: BANANA_MANIA,
      borderColor: BANANA_MANIA,
      textColor: GOLDENROD
    }
  },
  {
    id: 3,
    name: "Aguardando leilão / devolução",
    style: {
      backgroundColor: BANANA_MANIA,
      borderColor: BANANA_MANIA,
      textColor: GOLDENROD
    }
  },
  {
    id: 4,
    name: "Baixado",
    style: {
      backgroundColor: BUBBLE_GUM,
      borderColor: BUBBLE_GUM,
      textColor: UPSDELL_RED
    }
  },
  {
    id: 5,
    name: "Em uso",
    style: {
      backgroundColor: MENTOL,
      borderColor: MENTOL,
      textColor: LINCOLN_GREEN
    }
  }
];

export const noStatusStyle = {
  name: "Sem status",
  style: {
    backgroundColor: LIGHT_GRAY,
    borderColor: LIGHT_GRAY,
    textColor: DARK_GRAY
  }
};

export const selectMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  PaperProps: {
    style: {
      maxHeight: "200px"
    }
  },
  getContentAnchorEl: null
};

export const driverProfiles = [
  userManagementProfiles.driver.value,
  userManagementProfiles.commercial.value,
  userManagementProfiles.executive.value
];

export const toastTypes = {
  error: {
    type: "error",
    primaryColor: THIN_RED,
    secondaryColor: RED,
    icon: <ErrorIcon />
  },
  warning: {
    type: "warning",
    primaryColor: THIN_YELLOW,
    secondaryColor: YELLOW,
    icon: <WarnIcon />
  },
  success: {
    type: "success",
    primaryColor: THIN_GREEN,
    secondaryColor: LIGHT_GREEN,
    icon: <SuccessIcon />
  },
  information: {
    type: "information",
    primaryColor: LIGHT_BLUE2,
    secondaryColor: SECONDARY_COLOR,
    icon: <InfoIcon />
  }
};

export const reviewStatus = {
  expired: { id: 1, label: "Em atraso" },
  toExpire: { id: 2, label: "A vencer" },
  upToDate: { id: 3, label: "Em dia" }
};
