import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid, Box, makeStyles } from "@material-ui/core";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = props => {
  const classes = useStyles();
  const { children, showHeader, pathRedirect, formType, profile, keepData, headerTitle } = props;

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Grid>
      {showHeader && (
        <>
          <Header
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            pathRedirect={pathRedirect}
            formType={formType}
            profile={profile}
            keepData={keepData}
            headerTitle={headerTitle}
          />
          <Box className={classes.headerArea} />
        </>
      )}
      <Sidebar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} formType={formType} />
      {children}
    </Grid>
  );
};
const useStyles = makeStyles(theme => ({
  headerArea: theme.mixins.toolbar
}));

const mapStateToProps = state => {
  return {
    profile: state.user.profile
  };
};

export default connect(mapStateToProps, null)(Layout);
