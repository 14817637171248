import { IconButton } from "@material-ui/core";
import { ChevronDown, ChevronUp } from "react-feather";

const VerticalChevron = props => {
  const { value, setValue, className, chevronClasseName, color, size } = props;

  const handleExpand = () => {
    setValue(state => !state);
  };

  return (
    <IconButton size={size} onClick={handleExpand} className={className}>
      {value ? (
        <ChevronUp aria-label="Colapsar" className={chevronClasseName} color={color} />
      ) : (
        <ChevronDown aria-label="Expandir" className={chevronClasseName} color={color} />
      )}
    </IconButton>
  );
};

export default VerticalChevron;
