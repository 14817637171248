import React from "react";
import { Grid, Typography, Box, makeStyles } from "@material-ui/core";
import { SECONDARY_COLOR, hexToRgba } from "../../../theme";
import { Plus, Cloud } from "react-feather";

const HistoryCardTag = props => {
  const { isCreation, isFromAd, contentLabel, DefaultIcon } = props;

  const classes = useStyles();

  const getTagLabel = () => {
    if (isCreation) return `CADASTRO DE ${contentLabel.toUpperCase()}`;
    else if (isFromAd) return "INTEGRAÇÃO AD";
    else return `EDIÇÃO DE ${contentLabel.toUpperCase()}`;
  }

  return (
    <Box align="center" className={`m-4 ${classes.boxLabel}`}>
      <Grid container alignItems="center">
        <TagIcon isCreation={isCreation} isFromAd={isFromAd} DefaultIcon={DefaultIcon} />
        <Typography component="span" className={`ml-2 ${classes.labelText}`}>
          {getTagLabel()}
        </Typography>
      </Grid>
    </Box>
  );
};

const TagIcon = ({ isCreation, isFromAd, DefaultIcon }) => {
  if (isCreation) return (
    <Plus size={20} color={SECONDARY_COLOR} aria-label="Cadastro" className="ml-1 mr-1" />
  );
  else if (isFromAd) return (
    <Cloud size={20} color={SECONDARY_COLOR} aria-label="Integração AD" className="ml-1 mr-1" />
  );
  else return (
    <DefaultIcon size={24} color={SECONDARY_COLOR} aria-label="Edição" className="ml-1 mr-1" />
  );
}

const useStyles = makeStyles(() => ({
  boxLabel: {
    borderRadius: 4,
    padding: 5,
    width: "fit-content",
    backgroundColor: hexToRgba(SECONDARY_COLOR, 0.2)
  },
  labelText: {
    fontSize: 12,
    letterSpacing: 1.5,
    fontWeight: 600,
    color: SECONDARY_COLOR
  }
}));

export default HistoryCardTag;
