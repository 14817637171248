import React, { useState, useRef } from "react";
import { Tooltip } from "@material-ui/core";

const TooltipItem = props => {
  const {
    placement,
    disabled,
    onlyWhenOverflown,
    tooltip,
    followCursor,
    className,
    children
  } = props;

  const [popperOffsetX, setPopperOffsetX] = useState(0);
  const [popperOffsetY, setPopperOffsetY] = useState(0);
  const [contentIsOverflowing, setContentIsOverflowing] = useState(false);
  const contentRef = useRef();

  const shouldRender = !disabled && (!onlyWhenOverflown || contentIsOverflowing);

  const handleMouseMove = event => {
    onlyWhenOverflown && setContentIsOverflowing((
      contentRef.current?.offsetHeight < contentRef.current?.scrollHeight ||
      contentRef.current?.offsetWidth < contentRef.current?.scrollWidth
    ));

    if (shouldRender && followCursor) {
      const contentRect = contentRef.current.getBoundingClientRect();
      const cursorX = event.clientX;
      const cursorY = event.clientY;

      //The default offset is half of the content's width
      setPopperOffsetX(cursorX - contentRect.x - (contentRect.width / 2) + 4);

      //Value 14 is the popper's margin
      setPopperOffsetY(contentRect.y - cursorY - 14 + 4);
    }
  }

  return (
    <Tooltip
      title={
        <span className={className}>{tooltip}</span>
      }
      PopperComponent={shouldRender ? undefined : () => null}
      placement={followCursor ? undefined : placement}
      onMouseMove={handleMouseMove}
      ref={contentRef}
      PopperProps={{
        popperOptions: {
          modifiers: {
            offset: followCursor && {
              enabled: true,
              offset: `${popperOffsetX}px, ${popperOffsetY}px`,
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipItem;
