import React from "react";
import MaskedInput from "react-text-mask";
import { addThousandSeparator } from "../utils/Utils";

export const MaskedInputFactory = ({ id, mask }) => {
  return props => {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        id={id}
        mask={mask}
        guide={false}
        ref={input => inputRef(input?.inputElement)}
        {...other}
      />
    );
  };
};

export const createFormattedNumberMask = ({
  decimalPlaces = 0,
  decimalSeparator = ",",
  prefix
} = {}) => {
  function formattedNumberMask(rawValue = "") {
    const firstDigitIndex = rawValue.search(/\d/);
    const containsDecimalSeparator = rawValue.indexOf(decimalSeparator) > -1;
    const decimalSeparatorIndex = rawValue.search(decimalSeparator);

    let integerPart = rawValue.substring(
      firstDigitIndex,
      containsDecimalSeparator ? decimalSeparatorIndex : undefined
    );
    integerPart = addThousandSeparator(integerPart.replace(/\D/g, ""));

    let mask = integerPart.split("").map(char => (/\d/.test(char) ? /\d/ : char));

    if (containsDecimalSeparator && decimalPlaces > 0) {
      mask.push(",");
      mask = mask.concat(
        Array.from({ length: decimalPlaces }, () => {
          return /\d/;
        })
      );
    }

    if (prefix) {
      mask = [...prefix.split("")].concat(mask);
    }

    return mask;
  }

  formattedNumberMask.instanceOf = "createFormattedNumberMask";

  return formattedNumberMask;
};
