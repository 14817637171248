import BookingService from "../../services/BookingService";
import ChecklistService from "../../services/ChecklistService";
import UserService from "../../services/UserService";

class BookingDetailsManager {
  async getBookingById(bookingId) {
    return BookingService.getBoookingById(bookingId).then(answer => answer && answer.data);
  }

  async cancelBookingById(bookingId, userId) {
    return BookingService.cancelBookingById(bookingId, userId).then(
      answer => answer && answer.data
    );
  }

  async finalizeBookingById(bookingId, checklist) {
    return BookingService.finalizeBookingById(bookingId, checklist).then(
      answer => answer && answer.data
    );
  }

  async listChecklistModelQuestionsByCarId(carId, checklistTypeId) {
    return ChecklistService.listChecklistModelQuestionsByCarId(carId, checklistTypeId).then(
      answer => answer && answer.data
    );
  }

  async startBookingChecklist(bookingId, userId) {
    return BookingService.startBookingChecklist(bookingId, userId).then(
      answer => answer && answer.data
    );
  }

  async getChecklistResponseByBookingId(bookingId, checklistTypeId) {
    return BookingService.getChecklistResponseByBookingId(bookingId, checklistTypeId).then(
      answer => answer && answer.data
    );
  }

  async getUserById(userId) {
    return UserService.getUserById(userId).then(answer => answer && answer.data);
  }
}

export default new BookingDetailsManager();
