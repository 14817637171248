import { useState, useCallback } from "react";
import { CAR_MODEL_YEAR_ZERO, messages } from "../../../shared/utils/Constants";
import FleetManagementManager from "../FleetManagementManager";
import SharedManager from "../../../shared/methods/SharedManager";
import { formatNumberToCurrencyString, carShouldHaveDriver } from "../../../shared/utils/Utils";
import useToaster from "../../../shared/customHooks/useToaster";
const yearList = Array.from(
  { length: new Date().getFullYear() - CAR_MODEL_YEAR_ZERO + 3 },
  (_, i) => {
    return new Date().getFullYear() + 2 - i;
  }
);

export const vehicleModal = {
  defaultValues: {
    licensePlate: "",
    oldLicensePlate: "",
    fleetTypeId: "",
    carTypeId: "",
    siteId: "",
    brandId: "",
    oldBrandId: "",
    carModelId: "",
    oldCarModelId: "",
    year: "",
    providerId: "",
    contractStartDate: null,
    contractValidity: null,
    oldContractValidity: null,
    contractMonthlyRate: "",
    contractNumber: "",
    oldContractNumber: "",
    carDocument: "",
    oldCarDocument: "",
    chassisNumber: "",
    oldChassisNumber: "",
    colorId: "",
    oldColorId: "",
    odometer: "",
    oldOdometer: "",
    fuelId: "",
    oldFuelId: "",
    licenseStatusId: "",
    oldLicenseStatusId: "",
    maintenanceProviderId: "",
    oldMaintenanceProviderId: "",
    immobilized: "",
    oldImmobilized: "",
    hasFuelCard: false,
    hasTollTag: false,
    isBorder: false,
    has35PercentDiscount: false,
    isBulletProof: false,
    statusId: "",
    statusDetailsId: "",
    oldStatusDetailsId: "",
    statusStartDate: null,
    oldStatusStartDate: null,
    statusCost: "",
    oldStatusCost: "",
    statusReason: "",
    oldStatusReason: "",
    driverAdUser: "",
    reasonForEdit: "",
    isFinalStep: false
  }
};

const useCreateOrUpdateVehicle = (setValue, editableCarId, setError) => {
  const [siteList, setSiteList] = useState([]);
  const [siteIsLoading, setSiteIsLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [brandIsLoading, setBrandIsLoading] = useState(false);
  const [carModelList, setCarModelList] = useState([]);
  const [carModelIsLoading, setCarModelIsLoading] = useState(false);
  const [colorList, setColorList] = useState([]);
  const [colorIsLoading, setColorIsLoading] = useState(false);
  const [fuelList, setFuelList] = useState([]);
  const [fuelIsLoading, setFuelIsLoading] = useState(false);
  const [licenseStatusList, setLicenseStatusList] = useState([]);
  const [licenseStatusIsLoading, setLicenseStatusIsLoading] = useState(false);
  const [statusDetailsList, setStatusDetailsList] = useState([]);
  const [statusDetailsIsLoading, setStatusDetailsIsLoading] = useState(false);
  const [driverData, setDriverData] = useState({});
  const [driverDataIsLoading, setDriverDataIsLoading] = useState(false);
  const [registrationIsLoading, setRegistrationIsLoading] = useState(false);
  const [vehicleDataIsLoading, setVehicleDataIsLoading] = useState(false);
  const [licensePlateIsLoading, setLicensePlateIsLoading] = useState(false);
  const [isDriver, setIsDriver] = useState(false);
  const { showWarningToast } = useToaster();

  const loadingInitialData =
    siteIsLoading ||
    brandIsLoading ||
    colorIsLoading ||
    fuelIsLoading ||
    licenseStatusIsLoading ||
    vehicleDataIsLoading;

  const listSites = useCallback(async () => {
    setSiteIsLoading(true);

    const data = await FleetManagementManager.listSites();
    if (data) setSiteList(data);

    setSiteIsLoading(false);
  }, []);

  const listBrands = useCallback(async () => {
    setBrandIsLoading(true);

    const data = await FleetManagementManager.listBrands();
    if (data) setBrandList(data);

    setBrandIsLoading(false);
  }, []);

  const listCarModels = useCallback(async brandId => {
    setCarModelIsLoading(true);

    const data = await FleetManagementManager.listCarModels(brandId);
    if (data) setCarModelList(data);

    setCarModelIsLoading(false);
  }, []);

  const listColors = useCallback(async () => {
    setColorIsLoading(true);

    const data = await FleetManagementManager.listColors();
    if (data) setColorList(data);

    setColorIsLoading(false);
  }, []);

  const listFuels = useCallback(async () => {
    setFuelIsLoading(true);

    const data = await FleetManagementManager.listFuels();
    if (data) setFuelList(data);

    setFuelIsLoading(false);
  }, []);

  const listLicenseStatus = useCallback(async () => {
    setLicenseStatusIsLoading(true);

    const data = await FleetManagementManager.listLicenseStatus();
    if (data) setLicenseStatusList(data);

    setLicenseStatusIsLoading(false);
  }, []);

  const listStatusDetails = useCallback(async statusId => {
    if (!statusId) return;

    setStatusDetailsIsLoading(true);

    const data = await FleetManagementManager.listStatusDetails(statusId);
    if (data) setStatusDetailsList(data);

    setStatusDetailsIsLoading(false);
  }, []);

  const loadDriverData = useCallback(
    async adUser => {
      setDriverDataIsLoading(true);

      const data = await FleetManagementManager.getDriverOrResponsible(adUser, isDriver);
      const driverCars = await SharedManager.listFleets(adUser);

      if (data) {
        setDriverData(data);
        if (driverCars && driverCars.length) {
          showWarningToast(messages.CAR_ALREADY_ASSOCIATED);
        }
      } else setDriverData({});

      setDriverDataIsLoading(false);
      return !!data;
    },
    [isDriver, showWarningToast]
  );

  const loadInitialData = useCallback(() => {
    listBrands();
    listColors();
    listFuels();
    listLicenseStatus();
    listSites();
  }, [listSites, listBrands, listColors, listFuels, listLicenseStatus]);

  const resetDriverData = useCallback(() => {
    setDriverData({});
  }, []);

  const getVehicleDataById = useCallback(
    async callback => {
      setVehicleDataIsLoading(true);

      const vehicle = await FleetManagementManager.getVehicleById(editableCarId);
      let driver;
      if (vehicle) {
        if (!!vehicle.carInformation?.carModel?.carBrand?.id) {
          listCarModels(vehicle.carInformation?.carModel?.carBrand?.id);
        }
        if (!vehicle.privateCar?.endValidity) {
          driver = await FleetManagementManager.getUserById(vehicle.privateCar?.user?.id);
        }
      }

      callback(vehicle, driver);
      setVehicleDataIsLoading(false);
    },
    [editableCarId, listCarModels]
  );

  const getVehicleByLicensePlate = useCallback(
    async licensePlate => {
      setLicensePlateIsLoading(true);

      const vehicle = await FleetManagementManager.getVehicleByLicensePlate(licensePlate);
      if (vehicle)
        setError(
          "licensePlate",
          { message: messages.LICENSE_PLATE_ALREADY_EXISTS },
          { type: "matches" }
        );

      setLicensePlateIsLoading(false);
    },
    [setError]
  );

  const setSchemaValues = useCallback(
    async (editableCar, editableDriver) => {
      const fleetTypeId = editableCar?.carInformation?.fleet?.fleetType?.id;
      setValue("fleetTypeId", fleetTypeId);
      setIsDriver(carShouldHaveDriver(fleetTypeId));

      setValue("carTypeId", editableCar.carInformation?.fleet?.carType?.id);
      setValue("statusId", editableCar.historyCarStatus?.status?.id);
      setValue("licensePlate", editableCar.carLicensing?.carLicensePlate);
      setValue("oldLicensePlate", editableCar.carLicensing?.carLicensePlate);
      setValue(
        "contractMonthlyRate",
        formatNumberToCurrencyString(editableCar.carContract?.monthlyRate)
      );
      setValue("driverAdUser", editableCar.privateCar?.user?.adUser);
      setValue("siteId", editableCar.carInformation?.site?.id);
      setValue("year", editableCar.carInformation?.year);
      setValue("brandId", editableCar.carInformation?.carModel?.carBrand?.id);
      setValue("oldBrandId", editableCar.carInformation?.carModel?.carBrand?.id);
      setValue("carModelId", editableCar.carInformation?.carModel?.id);
      setValue("oldCarModelId", editableCar.carInformation?.carModel?.id);
      setValue("carDocument", editableCar.carLicensing?.carDocument?.toString());
      setValue("oldCarDocument", editableCar.carLicensing?.carDocument?.toString());
      setValue("hasFuelCard", editableCar.carLicensing?.hasFuelCard);
      setValue("hasTollTag", editableCar.carLicensing?.hasTollTag);
      setValue("isBorder", editableCar.carLicensing?.isBorder);
      setValue("has35PercentDiscount", editableCar.carLicensing?.has35PercentDiscount);
      setValue("isBulletProof", editableCar.carLicensing?.isBulletProof);
      setValue("licenseStatusId", editableCar.carLicensing?.carLicensingStatus?.id);
      setValue("oldLicenseStatusId", editableCar.carLicensing?.carLicensingStatus?.id);
      setValue("contractNumber", editableCar.carContract?.contractNumber?.toString());
      setValue("oldContractNumber", editableCar.carContract?.contractNumber?.toString());
      setValue("providerId", editableCar.carContract?.carProvider?.id);
      setValue("maintenanceProviderId", editableCar.carContract?.carMaintenanceProvider?.id);
      setValue("oldMaintenanceProviderId", editableCar.carContract?.carMaintenanceProvider?.id);
      setValue("contractStartDate", editableCar.carContract?.startDate);
      setValue("contractValidity", editableCar.carContract?.validity);
      setValue("oldContractValidity", editableCar.carContract?.validity);
      setValue("chassisNumber", editableCar.carInformation?.chassisNumber?.toString());
      setValue("oldChassisNumber", editableCar.carInformation?.chassisNumber?.toString());
      setValue("colorId", editableCar.carInformation?.color?.id);
      setValue("oldColorId", editableCar.carInformation?.color?.id);
      setValue("odometer", editableCar.carInformation?.odometer?.toString());
      setValue("oldOdometer", editableCar.carInformation?.odometer?.toString());
      setValue("fuelId", editableCar.carInformation?.fuel?.id);
      setValue("oldFuelId", editableCar.carInformation?.fuel?.id);
      setValue("immobilized", editableCar.carInformation?.immobilized?.toString());
      setValue("oldImmobilized", editableCar.carInformation?.immobilized?.toString());

      setValue("statusReason", editableCar.historyCarStatus?.reason);
      setValue("oldStatusReason", editableCar.historyCarStatus?.reason);
      setValue("statusStartDate", editableCar.historyCarStatus?.startDate);
      setValue("oldStatusStartDate", editableCar.historyCarStatus?.startDate);

      const statusCost =
        editableCar.historyCarStatus?.cost &&
        formatNumberToCurrencyString(editableCar.historyCarStatus?.cost);

      setValue("statusCost", statusCost);
      setValue("oldStatusCost", statusCost);

      await listStatusDetails(editableCar.historyCarStatus?.status?.id);
      setValue("statusDetailsId", editableCar.historyCarStatus?.statusDetails?.id);
      setValue("oldStatusDetailsId", editableCar.historyCarStatus?.statusDetails?.id);

      if (editableDriver) {
        setDriverData(editableDriver);
        setValue("driverAdUser", editableDriver.adUser);
      }
    },
    [listStatusDetails, setValue]
  );

  const createOrUpdateVehicle = async (data, driverId, callback) => {
    setRegistrationIsLoading(true);

    const response = !!editableCarId
      ? await FleetManagementManager.updateVehicle(editableCarId, data, driverId)
      : await FleetManagementManager.createVehicle(data, driverId);

    if (response) callback();

    setRegistrationIsLoading(false);
  };

  return {
    optionsData: {
      siteList,
      brandList,
      carModelList,
      yearList,
      colorList,
      fuelList,
      licenseStatusList,
      statusDetailsList
    },
    loadInitialData,
    listCarModels,
    listStatusDetails,
    loadingInitialData,
    carModelIsLoading,
    statusDetailsIsLoading,
    driverData,
    driverDataIsLoading,
    loadDriverData,
    resetDriverData,
    createOrUpdateVehicle,
    registrationIsLoading,
    vehicleDataIsLoading,
    getVehicleDataById,
    setSchemaValues,
    isDriver,
    setIsDriver,
    getVehicleByLicensePlate,
    licensePlateIsLoading
  };
};

export default useCreateOrUpdateVehicle;
