import React from "react";
import { Grid } from "@material-ui/core";
import HistorySection from "./HistorySection";

const HistoryCardContentFields = props => {
  const {
    data,
    isNewData,
    isCreation,
    isLoadingDriver,
    isDriverExpanded,
    setIsDriverExpanded,
    isCarInformationExpanded,
    setIsCarInformationExpanded,
    isCarContractExpanded,
    setIsCarContractExpanded
  } = props;

  return (
    <Grid container>
      <HistorySection
        isExpanded={isCarInformationExpanded}
        setIsExpanded={setIsCarInformationExpanded}
        isNewData={isNewData}
        sectionLabel="Dados de veículo"
        data={data.vehicleData}
        isCreation={isCreation}
      />

      <HistorySection
        isExpanded={isCarContractExpanded}
        setIsExpanded={setIsCarContractExpanded}
        isNewData={isNewData}
        sectionLabel="Dados de contrato"
        data={data.carContractData}
        isCreation={isCreation}
      />

      <HistorySection
        isExpanded={isDriverExpanded}
        setIsExpanded={setIsDriverExpanded}
        isNewData={isNewData}
        isLoadingData={isLoadingDriver}
        sectionLabel="Dados do condutor"
        data={data.driver}
        isCreation={isCreation}
      />
    </Grid>
  );
};

export default HistoryCardContentFields;
