import React from "react";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import Modal from "../Modal";
import { GRAY, RED } from "../../../theme";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const TimeSelectionModal = props => {
  const {
    title,
    label,
    subtitle,
    titleAlign,
    openedModal,
    titleVariant,
    primaryAction,
    defaultEndTime,
    confirmDisabled,
    handleOpenClose,
    secondaryAction,
    defaultStartTime,
    primaryButtonText,
    secondaryButtonText,
    handleChangeEndTime,
    handleChangeStartTime
  } = props;
  const classes = useStyles();

  return (
    openedModal && (
      <Modal
        primaryButton={{
          action: () => primaryAction(),
          text: primaryButtonText,
          variant: "contained",
          color: "primary",
          disabled: confirmDisabled
        }}
        secondaryButton={{
          action: () => secondaryAction(),
          text: secondaryButtonText,
          variant: "outlined"
        }}
        openedModal={openedModal}
        closeModal={() => handleOpenClose()}
        title={title}
        titleAlign={titleAlign}
        titleVariant={titleVariant}
        closeIcon
      >
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Typography variant="body1" align="left" className={`${classes.colorSubtitle} pb-4`}>
              {subtitle}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TimePicker
                cancelLabel="CANCELAR"
                label="Hora início"
                value={defaultStartTime}
                minutesStep={15}
                onChange={handleChangeStartTime}
                ampm={false}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TimePicker
                cancelLabel="CANCELAR"
                label="Hora fim"
                value={defaultEndTime}
                minutesStep={15}
                onChange={handleChangeEndTime}
                ampm={false}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            {confirmDisabled && (
              <Typography variant="caption" className={classes.warningInfo}>
                {label}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Modal>
    )
  );
};

const useStyles = makeStyles({
  colorSubtitle: {
    color: GRAY
  },
  warningInfo: {
    color: RED
  }
});

export default TimeSelectionModal;
