import { Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { forwardRef } from "react";
import { GRAY, RED } from "../../theme";

const CustomTextField = forwardRef((props, ref) => {
  const {
    size,
    rows,
    label,
    color,
    error,
    fieldProps,
    required,
    disabled,
    onChange,
    multiline,
    maxLength,
    className,
    helperText,
    value = "",
    inputProps,
    InputProps,
    placeholder,
    requiredLabel,
    fullWidth = true,
    variant = "outlined",
    autoComplete = "off"
  } = props;

  const lengthPercent = 0.7 * maxLength;
  const classes = useStyles({ isRed: value?.length > lengthPercent, color });

  return (
    <Grid container className={className}>
      <TextField
        {...fieldProps}
        ref={ref}
        type="text"
        rows={rows}
        size={size}
        error={error}
        label={label}
        value={value}
        variant={variant}
        required={required}
        onChange={onChange}
        disabled={disabled}
        multiline={multiline}
        fullWidth={fullWidth}
        inputProps={{
          "aria-label": label,
          maxLength: maxLength,
          ...inputProps
        }}
        InputProps={{
          ...InputProps,
          className: `${classes.InputProps} ${InputProps?.className}`
        }}
        helperText={helperText}
        placeholder={placeholder}
        autoComplete={autoComplete}
        InputLabelProps={{ shrink: true }}
        className={`${fieldProps?.className} ${classes.textField}`}
      />
      {(maxLength || requiredLabel) && (
        <Grid container>
          {requiredLabel && (
            <Typography variant="caption" align="left" className="m-1">
              {requiredLabel}
            </Typography>
          )}
          {maxLength && (
            <Typography variant="caption" className={classes.charCount} align="right">
              {value?.length}/{maxLength}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
});

const useStyles = makeStyles(() => ({
  charCount: {
    flex: 1,
    color: ({ isRed, color }) => (isRed ? RED : color || GRAY)
  },
  textField: ({ color }) => ({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: color
      }
    }
  }),
  InputProps: ({ color }) => ({
    color: color
  })
}));

export default CustomTextField;
