import React from "react";
import FloatingDropdown from "../../../shared/components/FloatingDropdown";
import { Grid, makeStyles, Divider } from "@material-ui/core";
import FilterOption from "../../../shared/components/ManagementPage/FilterOption";
import FiltersButton from "../../../shared/components/ManagementPage/FiltersButton";
import { useManagementFilters } from "../../../shared/components/ManagementPage/customHooks/useManagementFilters";
import FilterMenuButtons from "../../../shared/components/ManagementPage/FilterMenuButtons";

const UserFilters = props => {
  const { filtersData, applyFilters, isCentralFac, isAdminFac } = props;
  const classes = useStyles();

  const {
    selectedFilters,
    showFloatFilters,
    setShowFloatFilters,
    anyFilterAreSelected,
    handleClear,
    areAllSelected,
    arePartiallySelected,
    handleAllCheckbox,
    handleSpecificCheckbox,
    handleApply
  } = useManagementFilters({ filtersData, applyFilters });

  return (
    <>
      <FiltersButton
        anyFilterAreSelected={anyFilterAreSelected()}
        onClick={() => setShowFloatFilters(true)}
        handleClear={handleClear}
      />
      <FloatingDropdown
        enableDividers
        disabled={!showFloatFilters}
        className={`${classes.floatDropdown} mt-2`}
        onOutsideClick={() => setShowFloatFilters(false)}
      >
        <FilterOption
          label="Perfil"
          expandible
          checked={areAllSelected("profileList")}
          inderteminate={arePartiallySelected("profileList")}
          handleChange={e => handleAllCheckbox(e.target.checked, "profileList")}
        >
          {selectedFilters.profileList?.map((profile, index) => (
            <Grid item xs={12} key={profile.id + profile.label}>
              <Divider className="divider" />
              <FilterOption
                className="pl-8"
                checked={profile.isSelected}
                handleChange={e =>
                  handleSpecificCheckbox(profile.id, e.target.checked, "profileList")
                }
                label={profile.label}
                enableDividers={index > 0}
              />
            </Grid>
          ))}
        </FilterOption>
        <FilterOption
          label="Status CNH"
          expandible
          checked={areAllSelected("licenseStatus")}
          inderteminate={arePartiallySelected("licenseStatus")}
          handleChange={e => handleAllCheckbox(e.target.checked, "licenseStatus")}
        >
          {selectedFilters.licenseStatus?.map((licenseStatus, index) => (
            <Grid item xs={12} key={licenseStatus.id + licenseStatus.label}>
              <Divider className="divider" />
              <FilterOption
                className="pl-8"
                checked={licenseStatus.isSelected}
                handleChange={e =>
                  handleSpecificCheckbox(licenseStatus.id, e.target.checked, "licenseStatus")
                }
                label={licenseStatus.label}
                enableDividers={index > 0}
              />
            </Grid>
          ))}
        </FilterOption>
        <FilterOption
          label="Status CDD"
          expandible
          checked={areAllSelected("courseStatus")}
          inderteminate={arePartiallySelected("courseStatus")}
          handleChange={e => handleAllCheckbox(e.target.checked, "courseStatus")}
        >
          {selectedFilters.courseStatus?.map((courseStatus, index) => (
            <Grid item xs={12} key={courseStatus.id + courseStatus.label}>
              <Divider className="divider" />
              <FilterOption
                className="pl-8"
                checked={courseStatus.isSelected}
                handleChange={e =>
                  handleSpecificCheckbox(courseStatus.id, e.target.checked, "courseStatus")
                }
                label={courseStatus.label}
                enableDividers={index > 0}
              />
            </Grid>
          ))}
        </FilterOption>
        {(isCentralFac || isAdminFac) && (
          <FilterOption
            label="Veículo vinculado"
            expandible
            checked={areAllSelected("hasCar")}
            inderteminate={arePartiallySelected("hasCar")}
            handleChange={e => handleAllCheckbox(e.target.checked, "hasCar")}
          >
            {selectedFilters.hasCar?.map((hasCarOption, index) => (
              <Grid item xs={12} key={hasCarOption.id + hasCarOption.label}>
                <Divider className="divider" />
                <FilterOption
                  className="pl-8"
                  checked={hasCarOption.isSelected}
                  handleChange={e =>
                    handleSpecificCheckbox(hasCarOption.id, e.target.checked, "hasCar")
                  }
                  label={hasCarOption.label}
                  enableDividers={index > 0}
                />
              </Grid>
            ))}
          </FilterOption>
        )}
        <FilterOption
          label="Situação"
          expandible
          checked={areAllSelected("userSituation")}
          inderteminate={arePartiallySelected("userSituation")}
          handleChange={e => handleAllCheckbox(e.target.checked, "userSituation")}
        >
          {selectedFilters.userSituation?.map((userSituation, index) => (
            <Grid item xs={12} key={userSituation.id + userSituation.label}>
              <Divider className="divider" />
              <FilterOption
                className="pl-8"
                checked={userSituation.isSelected}
                handleChange={e =>
                  handleSpecificCheckbox(userSituation.id, e.target.checked, "userSituation")
                }
                label={userSituation.label}
                enableDividers={index > 0}
              />
            </Grid>
          ))}
        </FilterOption>
        <Grid container justify="center" className="pt-8 pb-8">
          <FilterMenuButtons handleApply={handleApply} handleClear={handleClear} />
        </Grid>
      </FloatingDropdown>
    </>
  );
};

const useStyles = makeStyles(() => ({
  floatDropdown: {
    maxWidth: 320,
    width: "100%"
  }
}));

export default UserFilters;
