import { envConfig as developer } from "./developer";
import { envConfig as homologation } from "./homologation";
import { envConfig as production } from "./production";

export function configureEnvironment() {
  const hostname = window && window.location && window.location.hostname;

  if (hostname.startsWith("app-fleetmanagement-hml-001.azurewebsites.net")) {
    return homologation;
  } else if (
    hostname.startsWith("app-fleetmanagement-prd-001.azurewebsites.net") ||
    hostname.startsWith("frotas.yarabrasil.com.br")
  ) {
    return production;
  } else {
    return developer;
  }
}
