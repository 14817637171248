import PrivateCarService from "../../services/PrivateCarService";
import ChecklistService from "../../services/ChecklistService";

class PrivateCarManager {
  async getPrivateCarByUserId(userId) {
    return PrivateCarService.getPrivateCarByUserId(userId).then(
      response => response && response.data
    );
  }

  async listChecklistModelQuestionsByCarId(carId, checklistTypeId) {
    return ChecklistService.listChecklistModelQuestionsByCarId(carId, checklistTypeId).then(
      response => response && response.data
    );
  }
}

export default new PrivateCarManager();
