import React from "react";
import {
  Grid,
  FormControl,
  Checkbox,
  Typography,
  FormControlLabel,
  makeStyles
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { GRAY, WHITE, SECONDARY_COLOR, DARK_GRAY } from "../../../../theme";

const CheckboxSegment = props => {
  const { control, shouldDisable } = props;
  const classes = useStyles();

  const Label = (text, isSelectd) => {
    const cssClass = isSelectd ? classes.selectedLabel : classes.label;
    return <Typography className={cssClass}>{text}</Typography>;
  };

  return (
    <Grid item container spacing={2} alignItems="center" className="pb-2">
      <Grid item container xs={3} direction="column">
        <Grid item xs className={`mb-2 ${classes.border} ${classes.borderEnabled}`}>
          <Controller
            name="hasFuelCard"
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth variant="outlined">
                  <FormControlLabel
                    label={Label("Cartão Combustível", field.value)}
                    className={classes.field}
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{
                          "aria-label": "Selecione se o veículo possui cartão combustível"
                        }}
                      />
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid
          item
          xs
          className={`mt-2 ${classes.border} ${
            shouldDisable ? classes.borderDisabled : classes.borderEnabled
          }`}
        >
          <Controller
            name="isBulletProof"
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth variant="outlined" disabled={shouldDisable}>
                  <FormControlLabel
                    label={Label("Blindado", field.value)}
                    className={classes.field}
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{
                          "aria-label": "Selecione se o veículo é blindado"
                        }}
                      />
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={9} container className={classes.gridDisplay}>
        <Grid item xs className={`mr-2 ${classes.border} ${classes.borderEnabled}`}>
          <Controller
            name="hasTollTag"
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth variant="outlined">
                  <FormControlLabel
                    label={Label("TAG Pedágio", field.value)}
                    className={classes.field}
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{
                          "aria-label": "Selecione se o veículo possui tag pedágio"
                        }}
                      />
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid
          item
          xs
          className={`ml-2 mr-2 ${classes.border} ${
            shouldDisable ? classes.borderDisabled : classes.borderEnabled
          }`}
        >
          <Controller
            name="isBorder"
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth variant="outlined" disabled={shouldDisable}>
                  <FormControlLabel
                    label={Label("Fronteira", field.value)}
                    className={classes.field}
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{
                          "aria-label": "Selecione se o veículo é fronteira"
                        }}
                      />
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid
          item
          xs
          className={`ml-2 ${classes.border} ${
            shouldDisable ? classes.borderDisabled : classes.borderEnabled
          }`}
        >
          <Controller
            name="has35PercentDiscount"
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth variant="outlined" disabled={shouldDisable}>
                  <FormControlLabel
                    label={Label("Desconto 35%", field.value)}
                    className={classes.field}
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{
                          "aria-label": "Selecione se o veículo possui 35% de desconto"
                        }}
                      />
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  label: {
    color: GRAY
  },
  selectedLabel: {
    color: DARK_GRAY,
    fontWeight: 700
  },
  border: {
    border: "1px solid",
    color: "rgba(0,0,0,0.23)",
    borderRadius: "4px"
  },
  borderEnabled: {
    backgroundColor: WHITE,
    "&:hover": {
      color: SECONDARY_COLOR
    }
  },
  borderDisabled: {
    "&:hover": {
      color: "rgba(0,0,0,0.23)"
    }
  },
  gridDisplay: {
    display: "flex",
    marginBottom: "auto"
  },
  field: {
    margin: "unset",
    paddingRight: "2px",
    paddingBottom: "7.0px",
    paddingTop: "7.0px"
  }
}));

export default CheckboxSegment;
