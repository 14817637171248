import React from "react";
import { List } from "@material-ui/core";
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";
import { sidebarItems, accessType } from "../../../shared/utils/enum";
import { sidebarItemsNames } from "../../../shared/utils/Constants";

const SidebarListItems = props => {
  const { onClickItem } = props;
  const user = useSelector(state => state.user);

  const removeItem = item => {
    return accessType.backoffice && !user.privateCar && item === sidebarItemsNames.checklistCar;
  };
  const filteredItems = sidebarItems.filter(
    item => item.profiles.includes(user.profile) && !removeItem(item.name)
  );

  return (
    <List>
      {filteredItems.map((item, index) => (
        <SidebarItem
          key={item.name + index}
          icon={item.icon}
          name={item.name}
          handleClick={() => onClickItem(item.name, item.route)}
          divider={index < filteredItems.length - 1}
        />
      ))}
    </List>
  );
};

export default SidebarListItems;
