import store from "../../store";
import { showNotification, NOTIFY_ERROR } from "../../store/NotificationStore";
import { unauthenticateUser } from "../../store/UserStore";
import { messages, storageKeyExpiresOn } from "./Constants";
import axios from "axios";
import ptBR from "date-fns/locale/pt-BR";
import { format } from "date-fns";

export function handleRequestError(error) {
  if (axios.isCancel(error)) return;

  let message = error.response && error.response.data && error.response.data.message;

  store.dispatch(showNotification(NOTIFY_ERROR, message || messages.ERROR_MESSAGE_DEFAULT));
}

export function verifyTokenIsValid() {
  const expireItem = sessionStorage.getItem(storageKeyExpiresOn);

  const expiresOn = expireItem && new Date(expireItem);
  const currentDate = new Date();

  const shouldCleanStorage = !!expiresOn && currentDate.getTime() > expiresOn.getTime();
  const hasValidToken = !!expiresOn && currentDate.getTime() <= expiresOn.getTime();

  if (shouldCleanStorage) {
    sessionStorage.clear();
    store.dispatch(unauthenticateUser());
  }

  return hasValidToken;
}

export function base64ToBlob(base64, type) {
  const byteArray = Buffer.from(base64, "base64").buffer;

  return new Blob([byteArray], {
    type: type
  });
}

export function downloadFile(data, contentType, fileName) {
  if (!data || !contentType) return;

  const exportedFile = new Blob([data], {
    type: contentType
  });

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(exportedFile, fileName);
  } else {
    const URLfile = URL.createObjectURL(exportedFile);
    const link = document.createElement("a");
    link.setAttribute("download", fileName);
    link.href = URLfile;
    link.click();
  }
}

export function downloadResponseFile(response, fileName) {
  if (!response || !response.data) return;

  downloadFile(response.data, response.headers["content-type"], fileName);
}

export function handleImportResponse(file, response) {
  let message = "";
  let errorFile;

  if (response.hasError) {
    switch (response.status) {
      case 400:
        message = response.message;
        break;
      case 422:
        errorFile = base64ToBlob(response.data?.base64Content, file.type);
        break;
      default:
        message = messages.ERROR_MESSAGE_TRY_AGAIN;
        break;
    }
  }

  return {
    hasValidationError: response.status !== 204,
    validationError: message,
    file: {
      name:
        "ERR_" +
        format(new Date(), "dd.MM.yyyy'_'HH-mm'_'", {
          locale: ptBR
        }) +
        file.name,
      type: file.type,
      content: errorFile || file
    }
  };
}
