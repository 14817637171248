import React from "react";
import { Grid } from "@material-ui/core";
import { Controller } from "react-hook-form";
import CustomTextField from "../CustomTextField";

const EditionSegment = props => {
  const { control } = props;

  return (
    <Grid item xs={12}>
      <Controller
        name="reasonForEdit"
        control={control}
        render={({ field }) => {
          return (
            <CustomTextField
              required
              fieldProps={field}
              {...field}
              label="Motivo da edição"
              placeholder="Descreva aqui..."
              inputProps={{
                "aria-label": "Digite o motivo da edição"
              }}
              maxLength={255}
              multiline
              rows={3}
            />
          );
        }}
      />
    </Grid>
  );
};

export default EditionSegment;
