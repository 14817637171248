import { LIST_BOOKINGS_LIMIT } from "../../shared/utils/Constants";
import BookingService from "../../services/BookingService";

class ListBookingsManager {
  async listBookingsByUserId(userId) {
    return BookingService.listBookingsByUserId(userId, LIST_BOOKINGS_LIMIT).then(
      answer => answer && answer.data
    );
  }
}

export default new ListBookingsManager();
