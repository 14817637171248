import React, { useState } from "react";
import { Typography, Button, Grid, makeStyles } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { Camera } from "react-feather";
import { SECONDARY_COLOR, RED, GRAY, LIGHT_GRAY } from "../../../theme";
import ImagePreview from "./ImagePreview";
import { dropzoneErrorTypes } from "../../utils/enum";
import { messages } from "../../utils/Constants";

const ImageDropzone = props => {
  const { imagesState = [], onUploadSuccess, maxFilesToAttach, maxFileSize, acceptedTypes } = props;

  const classes = useStyles();

  const [fileSizeWarning, setFileSizeWarning] = useState(false);
  const [maxFilesAttached, setMaxFilesAttached] = useState(false);
  const [invalidTypeWarning, setInvalidTypeWarning] = useState(false);

  const isDisabled = imagesState.length === maxFilesToAttach;
  const remainingImagesCount = maxFilesToAttach - imagesState.length;

  const clearWarnings = () => {
    if (fileSizeWarning || maxFilesAttached || invalidTypeWarning) {
      setFileSizeWarning(false);
      setMaxFilesAttached(false);
      setInvalidTypeWarning(false);
    }
  };

  const addingPreview = images => {
    const addPreview = images.map(image =>
      Object.assign(image, {
        preview: URL.createObjectURL(image)
      })
    );
    onUploadSuccess([...imagesState, ...addPreview]);
  };

  const onDrop = acceptedFiles => {
    if (acceptedFiles.length + imagesState.length <= maxFilesToAttach) {
      addingPreview(acceptedFiles);
      clearWarnings();
    } else {
      addingPreview(acceptedFiles.slice(0, remainingImagesCount));
      setMaxFilesAttached(true);
    }
  };

  const onDropRejected = rejectedFiles => {
    const rejectionCode = rejectedFiles[0].errors[0].code;
    if (rejectionCode === dropzoneErrorTypes.attachmentLimit) {
      const imagesToInclude = rejectedFiles.slice(0, remainingImagesCount);

      addingPreview(imagesToInclude.map(res => res.file));
      setMaxFilesAttached(true);
    }
    if (rejectionCode === dropzoneErrorTypes.fileSizeLimit) setFileSizeWarning(true);
    if (rejectionCode === dropzoneErrorTypes.invalidFileType) setInvalidTypeWarning(true);
  };

  const removeFile = imageIndex => {
    const spreadImages = [...imagesState];
    spreadImages.splice(imageIndex, 1);
    onUploadSuccess(spreadImages);
    clearWarnings();
  };

  const getWarningMessage = () => {
    let message = "";

    if (fileSizeWarning) message = messages.MAX_IMAGE_SIZE.replace("{0}", maxFileSize);
    else if (invalidTypeWarning) message = messages.INVALID_FILE_TYPE;

    if (maxFilesAttached || isDisabled)
      return fileSizeWarning || invalidTypeWarning
        ? messages.MAX_IMAGES_COUNT.concat(" ", message)
        : messages.MAX_IMAGES_COUNT;

    return message;
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedTypes,
    maxFiles: maxFilesToAttach,
    disabled: isDisabled,
    maxSize: maxFileSize * 1000000,
    onDropRejected,
    multiple: true,
    onDrop
  });

  return (
    <Grid>
      <Button
        {...getRootProps({
          className: `${classes.button} p-5 pl-3 mt-4`,
          disabled: isDisabled
        })}
      >
        <input {...getInputProps()} />
        <Camera color={isDisabled ? LIGHT_GRAY : SECONDARY_COLOR} className="mr-4" />

        <Typography
          className={isDisabled ? classes.subtittleDisabled : classes.subtittle}
          variant="subtitle2"
        >
          Incluir imagens
        </Typography>
      </Button>

      <Grid container className={classes.infoTextsBox} justify="space-between">
        <Typography variant="caption" className={classes.warningTexts}>
          {getWarningMessage()}
        </Typography>

        <Typography variant="caption" className={classes.maxSizeText} align="right">
          Tamanho máx. {maxFileSize}MB
        </Typography>
      </Grid>
      <Grid className="mt-8">
        {imagesState.map((image, index) => {
          return (
            <ImagePreview
              image={image}
              key={image.name + index}
              divider={index === 0}
              handleRemoveImage={() => removeFile(index)}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  button: {
    border: "2px solid",
    width: "100%",
    borderRadius: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    cursor: "pointer",
    borderColor: SECONDARY_COLOR,
    "&.Mui-disabled": {
      borderColor: LIGHT_GRAY,
      backgroundColor: "transparent"
    }
  },
  subtittle: {
    color: SECONDARY_COLOR
  },
  subtittleDisabled: {
    color: LIGHT_GRAY
  },
  warningTexts: {
    color: RED
  },
  maxSizeText: {
    flex: 1,
    color: GRAY,
    minWidth: 120
  },
  infoTextsBox: {
    flexWrap: "nowrap"
  }
}));

export default ImageDropzone;
