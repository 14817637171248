export const storageKeyToken = "ProjetoBase-token";
export const storageKeyBffToken = "ProjetoBase-bff-token";
export const storageKeyIdSession = "ProjetoBase-idSessao";
export const storageKeyExpiresOn = "ProjetoBase-expiresOn";

export const routes = {
  root: "/",
  logIn: "/login",
  safetyLogin: "/safety",
  listBookings: "/listBookings",
  bookingDetails: "/bookingDetails",
  bookingDetailsByFac: "/bookingDetailsByFac",
  confirmTrip: {
    longTrip: "/newBooking/confirmLongTrip",
    shortTrip: "/newBooking/confirmShortTrip"
  },
  checklistSecurityRisk: "/checklist/securityRisk",
  confirmChecklist: "/checklist/confirm",
  checklistSteps: {
    CHECK_CAR_INFORMATION: { id: 1, route: "/checklist/step=1" },
    CHECK_CAR_INTERIOR: { id: 2, route: "/checklist/step=2" },
    CHECK_CAR_EXTERIOR: { id: 3, route: "/checklist/step=3" },
    CHECK_MANDATORY_EQUIPMENT: { id: 4, route: "/checklist/step=4" },
    CHECKLIST_GENERAL_OBSERVATIONS: { id: 5, route: "/checklist/step=5" }
  },
  calendar: "/calendar",
  users: "/users",
  deniedAccess: "/deniedAccess",
  bookingSteps: {
    SELECT_DRIVER: { id: 1, route: "/newBooking/step=1" },
    SELECT_SITE: { id: 2, route: "/newBooking/step=2" },
    SELECT_CAR_AND_TRIP: { id: 3, route: "/newBooking/step=3" },
    SELECT_DATE: { id: 4, route: "/newBooking/step=4" },
    SELECT_DETAILS: { id: 5, route: "/newBooking/step=5" }
  },
  checkInView: "/checklistViews/checkin",
  checkOutView: "/checklistViews/checkout",
  securityReport: "/securityReport",
  listChecklists: "/listChecklists?tab=0",
  listReports: "/listChecklists?tab=1",
  checklistDetails: "/checklistDetails",
  reportDetails: "/reportDetails",
  reportsManagement: "/reportsManagement",
  fleetManagement: "/fleetManagement",
  carReviewManagement: "/carReviewManagement",
  reportChecklist: "/reportChecklist",
  notFound: "/notFound"
};

export const checklistTolerance = 15;
export const adUserSize = 7;
export const carLicensePlateSize = 7;
export const adUserExample = "a123456";
export const licensePlateExample = "abc1d23";
export const feedbackFleetManagementLink = "https://forms.office.com/r/GDRkRuwAcw";
export const feedbackSafetyReportLink = "https://forms.office.com/r/cqbYvDHPnt";
export const LIST_BOOKINGS_LIMIT = 200;
export const MEDIUM_WINDOW_WIDTH = 1240;
export const SMALL_WINDOW_WIDTH = 1000;
export const MOBILE_WINDOW_WIDTH = 480;
export const CALENDAR_UPDATE_INTERVAL = 5000;
export const CAR_MODEL_YEAR_ZERO = 1975;
export const SHARED_FLEET_TYPE_ID = 1;
export const EXCLUSIVE_FLEET_TYPE_ID = 4;
export const RELEASED_CAR_STATUS_ID = 4;
export const CAR_PROVIDER_YARA_ID = 10;
export const SAFETY_TITLE = { en: "Safety Report", pt: "Segurança Comercial" };
export const FLEET_TITLE = { en: "Fleet Management", pt: "Gestão de Frotas" };

export const sidebarItemsNames = {
  calendar: "Calendário",
  usersManagement: "Gestão de usuários",
  newBooking: "Solicitar reservas",
  listBookings: "Minhas reservas",
  listChecklists: "Meus checklists e relatos",
  checklistCar: "Fazer checklist do veículo",
  securityReport: "Fazer um relato de segurança",
  feedback: "Envie seu feedback",
  reportsManagement: "Gestão de checklists e relatos",
  reportChecklists: "Relatório de checklists",
  fleetManagement: "Gestão de frotas",
  carReviewManagement: "Gestão de revisões",
  logout: "Sair"
};

export const messages = {
  ERROR_MESSAGE_DEFAULT: "Ocorreu um erro ao executar a operação.",
  ERROR_MESSAGE_TRY_AGAIN: "Ocorreu um erro ao executar a operação, tente novamente.",
  MAINTENANCE_TITLE: "Manutenção",
  INACTIVATE_CAR_TITLE: "Inativar carro",
  INACTIVATE_CAR_WITH_BOOKING_IN_PROGRESS:
    "Existe uma reserva em andamento. Prosseguindo com a ação, o carro ficará inativo a partir do fim da atual reserva. Deseja continuar?",
  INACTIVATE_CAR:
    "Tem certeza que deseja inativar e cancelar todas as reservas do carro selecionado?",
  REACTIVATE_CAR_TITLE: "Ativar carro",
  REACTIVATE_CAR_TODAY: "Tem certeza que deseja ativar esse veículo?",
  REACTIVATE_CAR_DATETIME: "O carro ficará ativo a partir das {0}.",
  REACTIVATE_CAR_FUTURE_DAY: "Tem certeza que deseja ativar o carro nesse dia?",
  WARNING_BOOKING_CANCELED:
    "Reserva cancelada. Para mais informações, procure o FAC da sua unidade",
  CHECK_BASIC_CAR_INFO: "Antes de iniciar, você precisa conferir os itens abaixo:",
  WARNING_CHECKLIST_INFO:
    "Caso algo te impeça de começar o checklist ou a retirada do carro, procure o Facilities da unidade.",
  ALERT_RELOAD_PAGE: "Você tem certeza que deseja sair? Os dados preenchidos serão perdidos.",
  INVALID_MAX_TIME: "Horário selecionado é superior a 8h.",
  UNAVAILABLE_SCHEDULE: "Horário selecionado está indisponível.",
  INVALID_PAST_TIME: "Não é possível realizar uma reserva no passado.",
  BOOKING_EXPIRED:
    "Sua reserva expirou e não é possível iniciar o checklist. Faça uma nova reserva para utilizar o veículo.",
  CHECKIN_AVAILABLE:
    "O check-in estará disponível das {0} até {1} do dia do início da reserva. Após esse horário, a reserva expirará automaticamente e você precisará fazer uma nova reserva.",
  CHECKOUT_AVAILABLE: "O check-out só estará disponível a partir do início da sua reserva.",
  LICENSE_PLATE_ALREADY_EXISTS: "Placa já cadastrada no sistema, tente outra.",
  NO_BOOKINGS: "Você não possui nenhuma reserva no momento",
  NO_CHECKLISTS: "Você não possui nenhum checklist no momento",
  NO_REPORTS: "Você não possui nenhum relato no momento",
  NO_SELECTED_BOOKINGS: "Não há reservas {0}",
  NO_USERS: "Nenhum usuário encontrado.",
  NO_CARS: "Não foram encontrados dados para a pesquisa realizada. Tente novamente!",
  NO_AVAILABLE_CARS:
    "Oops... infelizmente no momento não há carros disponíveis para esta unidade, tente novamente mais tarde.",
  NO_AVAILABLE_SITES:
    "Oops... infelizmente no momento não há unidades disponíveis no seu polo, tente novamente mais tarde.",
  FIELD_REQUIRED_FOR_DRIVER: "Campo obrigatório para condutor",
  FIELD_REQUIRED: "Campo obrigatório",
  REQUIRED_NUMERICAL_CHARACTERS: "Obrigatório {0} caracteres numéricos",
  INVALID_EMAIL: "Email inválido",
  INVALID_LICENSE_PLATE: "Placa inválida",
  INVALID_AD_USER: "ID Yara inválido",
  INVALID_CURRENCY_VALUE: "Valor inválido.",
  INVALID_CAR_DOCUMENT: "Documento inválido.",
  INVALID_CHASSIS: "Chassi inválido. Deve conter 17 characteres.",
  INVALID_SAP_IMMOBILIZED: "Código do SAP inválido. Deve conter 8 dígitos.",
  INVALID_NULL_CONTRACT_VALIDITY: "Não é permitido remover o dado de vigência",
  INVALID_NULL_CONTRACT_NUMBER: "Não é permitido remover o dado de nº do contrato",
  INVALID_NULL_ODOMETER: "Não é permitido remover o dado de odômetro",
  INVALID_NULL_CAR_LICENSING: "Não é permitido remover o dado de licenciamento",
  INVALID_NULL_CAR_DOCUMENT: "Não é permitido remover o dado de renavan",
  INVALID_NULL_BRAND: "Não é permitido remover o dado de marca",
  INVALID_NULL_MODEL: "Não é permitido uma marca sem um modelo associado",
  INVALID_NULL_CHASSIS: "Não é permitido remover o dado de chassi",
  INVALID_NULL_IMMOBILIZED: "Não é permitido remover o código SAP de imobilizado",
  INVALID_NULL_COLOR: "Não é permitido remover o dado de cor",
  INVALID_NULL_FUEL: "Não é permitido remover o dado de combustível",
  INVALID_NULL_MAINTENANCE_PROVIDER: "Não é permitido remover o dado de manutenção",
  INVALID_NULL_STATUS_DETAILS: "Não é permitido remover o dado de destinação",
  INVALID_NULL_STATUS_START_DATE: "Não é permitido remover o dado de data da baixa",
  INVALID_NULL_STATUS_COST: "Não é permitido remover o dado de valor da baixa",
  INVALID_NULL_STATUS_REASON: "Não é permitido remover o dado de motivo da baixa",
  SUCCESS_CREATED_USER: "Usuário cadastrado com sucesso!",
  SUCCESS_CREATED_VEHICLE: "Veículo cadastrado com sucesso!",
  SUCCESS_UPDATED_VEHICLE: "Veículo atualizado com sucesso!",
  SUCCESS_UPDATED_USER: "Usuário atualizado com sucesso!",
  CONFIRMATION_CANCEL_CREATE_TITLE: "Cancelar cadastro",
  CONFIRMATION_CANCEL_CREATE_SUBTITLE: "Tem certeza que deseja cancelar o cadastro?",
  CONFIRMATION_CANCEL_EDIT_TITLE: "Cancelar edição",
  CONFIRMATION_CANCEL_EDIT_SUBTITLE: "Tem certeza que deseja cancelar a edição?",
  ALERT_TITLE: "Atenção!",
  ALERT_DROP_SUBTITLE:
    "Informações de CNH e curso de direção defensiva não podem ser removidos, apenas atualizados!",
  OK_BUTTON_MESSAGE: "OK",
  YES_BUTTON_MESSAGE: "SIM",
  NO_BUTTON_MESSAGE: "NÃO",
  CANCEL_BUTTON_MESSAGE: "CANCELAR",
  CONTINUE_BUTTON_MESSAGE: "CONTINUAR",
  RETURN_BUTTON_MESSAGE: "VOLTAR AO PASSO ANTERIOR",
  REGISTER_BUTTON_MESSAGE: "CADASTRAR",
  UPDATE_BUTTON_MESSAGE: "SALVAR",
  REGISTER_USER: "Cadastrar usuário",
  UPDATE_USER: "Editar usuário",
  REGISTER_VEHICLE: "Cadastrar veículo",
  UPDATE_VEHICLE: "Editar veículo",
  INVALID_DATE: "Informe uma data válida 00/00/0000",
  INVALID_PAST_DATE: "Não é permitida data no passado",
  INVALID_FUTURE_DATE: "Não é permitida data no futuro",
  CHECK_CAR_INFORMATION_TITLE: "Dados do veículo",
  WARNING_COMMERCIAL_CHECKLIST_INFO:
    "Caso os dados acima estejam incorretos, procure seu gestor ou o RH.",
  SECURITY_RISK_COMMERCIAL_MESSAGE:
    "Procure o fornecedor ou FAC escritório para esclarecimento de dúvidas!",
  SECURITY_RISK_DEFAULT_MESSAGE:
    "Procure o FAC para devolução das chaves e/ou esclarecimento de dúvidas!",
  LOGIN_ERROR_DEFAULT_MESSAGE:
    "Você não possui cadastro em nosso sistema. Procure o FAC da sua unidade ou seu gestor para solicitar sua inclusão.",
  LOGIN_ERROR_COMMERCIAL_MESSAGE:
    "Não há um carro privado vinculado ao seu usuário. Por favor, procure o FAC escritório ou o seu gestor para mais informações.",
  SECURITY_REPORT_ALERT_MESSAGE:
    "No caso da ocorrência de um acidente com lesão corporal, contate imediatamente o seu gestor direto, pessoalmente ou por telefone, e comunique a segurança.",
  START_DATE_GREATHER_THAN_END_DATE: "A data final deve ser maior ou igual à data inicial",
  MAX_IMAGES_COUNT: "Você atingiu o limite de imagens. Exclua uma para anexar outra.",
  MAX_IMAGE_SIZE: "A imagem selecionada deve ter no máximo {0}MB.",
  AD_EDITION_REASON_INACTIVATION: "Desligamento do Usuário.",
  AD_EDITION_REASON: "Atualização via AD.",
  CAR_ALREADY_ASSOCIATED:
    "Atenção, já existe um carro vinculado a este condutor. Antes de prosseguir, confirme os vínculos deste condutor.",
  FILE_TOO_LARGE: "Tamanho do arquivo excedido.",
  FILE_TOO_SMALL: "Arquivo sem conteúdo.",
  INVALID_FILE_TYPE: "O formato do arquivo não é suportado.",
  FILE_UPLOAD_ERROR: "Arquivo não importado, faça o download e verifique os erros!"
};
