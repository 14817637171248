import React, { useState, useEffect } from "react";
import { Box, Switch, Grid, Typography, makeStyles } from "@material-ui/core";
import { carTypes } from "../../../shared/utils/enum";
import { PRIMARY_COLOR, GRAY } from "../../../theme";
import { getNextQuarterDateTime } from "../../../shared/utils/Utils";
import { CALENDAR_UPDATE_INTERVAL } from "../../../shared/utils/Constants";

const HeaderCarCalendar = ({
  handleChangeActivation,
  carId,
  fleetSite,
  currentDate,
  maintenanceBooking,
  carLicensePlate,
  carTypeId,
  siteTagColor
}) => {
  const classes = useStyles({ maintenanceBooking, siteTagColor });

  const [maintenanceStart] = useState(new Date(maintenanceBooking?.startJourney));
  const [maintenanceFinish] = useState(new Date(maintenanceBooking?.finishJourney));
  const [maintenanceHasStarted, setMaintenanceHasStarted] = useState(
    new Date() >= maintenanceStart
  );
  const [maintenanceAboutToFinish, setMaintenanceAboutToFinish] = useState(
    getNextQuarterDateTime(new Date()) >= maintenanceFinish
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMaintenanceAboutToFinish(getNextQuarterDateTime(new Date()) >= maintenanceFinish);
      setMaintenanceHasStarted(new Date() >= maintenanceStart);
    }, CALENDAR_UPDATE_INTERVAL);

    return () => clearInterval(intervalId);
  }, [maintenanceStart, maintenanceFinish]);

  return (
    <Grid>
      <Grid item xs={12} className={classes.containerTitle}>
        {fleetSite && (
          <Box className={classes.badgeSite}>
            <Typography variant="overline" align="center" className={classes.fleetName}>
              {fleetSite}
            </Typography>
          </Box>
        )}
        <Typography variant="subtitle2">{carLicensePlate}</Typography>
      </Grid>
      <Grid item xs={12}>
        {carTypeId && (
          <img
            src={carTypes.find(type => type.id === carTypeId).src}
            alt={carTypes.find(type => type.id === carTypeId).alt}
          />
        )}
      </Grid>
      <Grid item container xs={12}>
        <Typography variant="caption" component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="caption" className={classes.switchActiveTextColor}>
                Ativo
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                disabled={
                  maintenanceAboutToFinish ||
                  (maintenanceStart.getDay() === new Date().getDay() &&
                    maintenanceStart > new Date()) ||
                  currentDate < new Date().setHours(0, 0, 0)
                }
                checked={!!maintenanceBooking}
                onChange={() => {
                  handleChangeActivation(!!maintenanceBooking, carId, currentDate);
                }}
                color="primary"
              />
            </Grid>
            <Grid item>
              <Typography variant="caption" className={classes.switchInactiveTextColor}>
                Inativo
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
      {maintenanceBooking && !maintenanceHasStarted && (
        <Grid item xs={12}>
          <Typography variant="caption" align="center" className={classes.timeLabel}>
            Inativo a partir das {`${maintenanceStart.toTimeString().substring(0, 5)}`}
          </Typography>
        </Grid>
      )}
      {maintenanceAboutToFinish && (
        <Grid item xs={12}>
          <Typography variant="caption" align="center" className={classes.timeLabel}>
            Ativo a partir das {`${maintenanceFinish.toTimeString().substring(0, 5)}`}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  containerTitle: {
    display: "flex",
    "&  > div": {
      marginRight: "10px"
    }
  },
  badgeSite: ({ siteTagColor }) => ({
    borderRadius: "4px",
    height: "22px",
    width: "55px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "10px",
    fontWeight: 400,
    border: `1px solid ${siteTagColor}`
  }),
  fleetName: ({ siteTagColor }) => ({
    color: siteTagColor
  }),
  switchActiveTextColor: ({ maintenanceBooking }) => ({
    color: maintenanceBooking ? GRAY : PRIMARY_COLOR
  }),
  switchInactiveTextColor: ({ maintenanceBooking }) => ({
    color: maintenanceBooking ? PRIMARY_COLOR : GRAY
  }),
  timeLabel: {
    color: GRAY
  }
}));

export default HeaderCarCalendar;
