import DriverService from "../../services/DriverService";
import SiteService from "../../services/SiteService";
import BookingService from "../../services/BookingService";

class BookingManager {
  async getDriverListByName() {
    return DriverService.getDriverListByName().then(answer => answer && answer.data);
  }

  async listSite(isRollouted) {
    return SiteService.listSite(isRollouted).then(answer => answer && answer.data);
  }

  async getCarModelList(siteId) {
    return SiteService.getCarModelList(siteId).then(answer => answer && answer.data);
  }

  async saveBooking(data) {
    return BookingService.saveBooking(data).then(response => response && response.data);
  }

  async listUnavailableSchedules(startDate, timezone, siteId, carModelId) {
    return SiteService.listUnavailableSchedules(startDate, timezone, siteId, carModelId).then(
      response => response && response.data
    );
  }

  async getUserAvailability(userId) {
    return BookingService.getUserAvailability(userId).then(response => response && response.data);
  }
}

export default new BookingManager();
