import React, { useEffect, useState, useRef, useCallback } from "react";
import AppointmentsCalendar from "../../../shared/components/Calendar/AppointmentsCalendar";
import { Divider, IconButton, InputAdornment, Grid, makeStyles } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { PRIMARY_COLOR, LIGHT_GRAY2, GRAY } from "../../../theme";
import FloatingButton from "../../../shared/components/FloatingButton";
import TimeSelectionModal from "../../../shared/components/Calendar/TimeSelectionModal";
import { addDays, format, differenceInMinutes, differenceInDays, subDays } from "date-fns";
import { ChevronLeft, ChevronRight, Calendar, Plus } from "react-feather";
import BookingManager from "../BookingManager";
import Loading from "../../../shared/components/Loading";
import ConfirmationModal from "../../../shared/components/ConfirmationModal";
import { messages } from "../../../shared/utils/Constants";
import {
  checkIfDateIsContainedInListSchedules,
  getNextQuarterDateTime,
  groupDates,
  roundToStartOrEndOfDay
} from "../../../shared/utils/Utils";
import * as actions from "../../../store/BookingStore";
import { useDispatch } from "react-redux";

const ShortTrip = ({ siteId, carModelId, handleStartDateTime, handleEndDateTime, booking }) => {
  const [startDateTime, setStartDateTime] = useState(booking.startDateTime);
  const [endDateTime, setEndDateTime] = useState(booking.endDateTime);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openTimeSelectionModal, setOpenTimeSelectionModal] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [listUnavailableSchedules, setListUnavailableSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [labelInformation, setLabelInformation] = useState();
  const [intervals, setIntervals] = useState([]);
  const [newScheduleAlreadyAdded, setNewScheduleAlreadyAdded] = useState(
    booking.startDateTime && booking.endDateTime
  );
  const [openDeleteScheduleModal, setOpenDeleteScheduleModal] = useState(false);
  const [disabledPreviousDate, setDisabledPreviousDate] = useState(true);

  const dateString = format(selectedDate, "dd 'de' MMMM 'de' yyyy", { locale: ptBR }).toString();

  const dispatch = useDispatch();

  const classes = useStyles({
    dateStringSize: dateString.length,
    loading
  });

  const deleteAlreadySelectedDate = () => {
    const validate = async () => {
      if (booking.startDateTime && booking.endDateTime && startDateTime && endDateTime) {
        const isContained =
          (await checkIsContained(booking.startDateTime, booking.endDateTime)) &&
          (await checkIsContained(startDateTime, endDateTime));
        isContained && clearAllDateStates();
      }
    };

    validate();
  };

  const checkIsContained = async (start, finish) => {
    let finishIsContained = false;

    const startIsContained = checkIfDateIsContainedInListSchedules(
      listUnavailableSchedules,
      startDateTime,
      endDateTime
    );

    if (start.getDate() !== finish.getDate()) {
      const timezoneFinish = parseInt(format(finish, "X"));
      const schedulesForFinish = await listSchedules(finish, timezoneFinish);

      finishIsContained = checkIfDateIsContainedInListSchedules(
        schedulesForFinish,
        startDateTime,
        endDateTime
      );
    }

    return startIsContained || finishIsContained;
  };

  const refSiteId = useRef(siteId);
  const refCarModelId = useRef(carModelId);
  const refSelectedDate = useRef(selectedDate);
  const refCheckIsContained = useRef(checkIsContained);
  const refdeleteAlreadySelectedDate = useRef(deleteAlreadySelectedDate);

  useEffect(() => {
    refSiteId.current = siteId;
    refCarModelId.current = carModelId;
    refSelectedDate.current = selectedDate;
    refCheckIsContained.current = checkIsContained;
    refdeleteAlreadySelectedDate.current = deleteAlreadySelectedDate;
  });

  const listSchedules = async (dateTime, timezone) =>
    BookingManager.listUnavailableSchedules(
      dateTime,
      timezone,
      refSiteId.current,
      refCarModelId.current
    );

  useEffect(() => {
    setDisabledPreviousDate(
      format(selectedDate, "dd/MM/yyyy") === format(new Date(), "dd/MM/yyyy")
    );

    async function getData() {
      setLoading(true);
      const timezone = parseInt(format(selectedDate, "X"));

      const data = await listSchedules(selectedDate, timezone);

      if (data) {
        const groupedDates = groupDates(data);
        setListUnavailableSchedules(groupedDates);
        setLoading(false);
      }
    }

    if (selectedDate && refSiteId.current && refCarModelId.current) getData();
  }, [selectedDate]);

  const clearAllDateStates = () => {
    handleStartDateTime(null);
    handleEndDateTime(null);
    setStartDateTime(null);
    setEndDateTime(null);
    setNewScheduleAlreadyAdded(false);
    dispatch(actions.updateBooking({ ...booking, startDateTime: null, endDateTime: null }));
  };

  const confirmCancelSchedule = () => {
    const auxIntervals = intervals.filter(item => item.idMotivo !== 2);
    auxIntervals.length === 0 ? setIntervals([]) : setIntervals(auxIntervals);

    setOpenDeleteScheduleModal(false);
    clearAllDateStates();
  };

  const handleAddNewInterval = () => {
    if (startDateTime && endDateTime) {
      handleStartDateTime(startDateTime);
      handleEndDateTime(endDateTime);
      setNewScheduleAlreadyAdded(true);
      setOpenTimeSelectionModal(false);
    }
  };

  const handleCancelNewInterval = () => {
    clearAllDateStates();
    setOpenTimeSelectionModal(false);
  };

  const handleChangeStartDateTime = newStartDateTime => {
    const diffInDays = differenceInDays(endDateTime, newStartDateTime);
    let newEndDateTime = diffInDays > 0 ? subDays(endDateTime, diffInDays) : endDateTime;

    setStartDateTime(newStartDateTime);
    setEndDateTime(
      newStartDateTime >= newEndDateTime ? addDays(newEndDateTime, 1) : newEndDateTime
    );
  };

  const handleChangeEndDateTime = newEndDateTime => {
    const diffInDays = differenceInDays(newEndDateTime, startDateTime);
    newEndDateTime = diffInDays > 0 ? subDays(newEndDateTime, diffInDays) : newEndDateTime;

    newEndDateTime = startDateTime >= newEndDateTime ? addDays(newEndDateTime, 1) : newEndDateTime;
    setEndDateTime(newEndDateTime);
  };

  const addNewInterval = useCallback(
    list => {
      const startInterval =
        startDateTime && roundToStartOrEndOfDay(startDateTime, false, refSelectedDate.current);
      const finishInterval =
        endDateTime && roundToStartOrEndOfDay(endDateTime, true, refSelectedDate.current);

      const isStartInterval =
        startInterval && selectedDate.toDateString() === startInterval.toDateString();
      const isLastInterval =
        finishInterval && selectedDate.toDateString() === finishInterval.toDateString();

      if (isStartInterval && isLastInterval) {
        const interval = {
          dataHoraInicio: startInterval,
          dataHoraFim: finishInterval,
          idMotivo: 2
        };
        list.push(interval);
      }
    },
    [startDateTime, endDateTime, selectedDate]
  );

  const roundSelectedSchedule = (selectedDateTime, roundUp) => {
    const newStartTime = roundUp
      ? getNextQuarterDateTime(new Date(selectedDateTime))
      : selectedDateTime;
    const newEndTime = getNextQuarterDateTime(newStartTime);

    setStartDateTime(newStartTime);
    setEndDateTime(newEndTime);
  };

  const handleAddSchedule = selectedTime => {
    if (newScheduleAlreadyAdded) return setOpenDeleteScheduleModal(true);

    const newSelectedTime = new Date(selectedTime);
    const selectedDateTime = new Date(selectedDate);
    const actualDateTime = new Date();

    selectedDateTime.setHours(newSelectedTime.getHours());
    selectedDateTime.setMinutes(newSelectedTime.getMinutes());
    selectedDateTime.setSeconds(0);
    selectedDateTime.setMilliseconds(0);

    const isPastDate = selectedDateTime <= actualDateTime;

    roundSelectedSchedule(isPastDate ? actualDateTime : selectedDateTime, isPastDate);
    setOpenTimeSelectionModal(true);
  };

  useEffect(() => {
    const listIntervals =
      listUnavailableSchedules &&
      listUnavailableSchedules.map(({ startJourney, finishJourney }, index) => ({
        dataHoraInicio: roundToStartOrEndOfDay(startJourney, false, refSelectedDate.current),
        dataHoraFim: roundToStartOrEndOfDay(finishJourney, true, refSelectedDate.current),
        id: index,
        idMotivo: 7
      }));

    addNewInterval(listIntervals);
    setIntervals(listIntervals);
  }, [listUnavailableSchedules, addNewInterval]);

  useEffect(() => {
    async function validations() {
      if (startDateTime && endDateTime) {
        const isContained = await refCheckIsContained.current(startDateTime, endDateTime);
        const isLongDuration = differenceInMinutes(endDateTime, startDateTime) > 480;
        const isInThePast = new Date() > new Date(startDateTime);
        isLongDuration && setLabelInformation(messages.INVALID_MAX_TIME);
        isContained && setLabelInformation(messages.UNAVAILABLE_SCHEDULE);
        isInThePast && setLabelInformation(messages.INVALID_PAST_TIME);

        setConfirmDisabled(isLongDuration || isContained || isInThePast);
      }
    }

    validations();
    refdeleteAlreadySelectedDate.current();
  }, [endDateTime, startDateTime, listUnavailableSchedules]);

  return (
    <Grid>
      <Grid className={classes.containerDate}>
        <ChevronLeft
          onClick={() => !disabledPreviousDate && setSelectedDate(addDays(selectedDate, -1))}
          className={`${classes.chevrons} mr-6`}
          color={disabledPreviousDate ? LIGHT_GRAY2 : PRIMARY_COLOR}
          size={24}
        />

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <DatePicker
            minDate={new Date()}
            className={classes.root}
            cancelLabel="CANCELAR"
            value={selectedDate}
            onChange={value => setSelectedDate(value)}
            format="dd 'de' MMMM 'de' yyyy"
            InputProps={{
              className: classes.multilineColor,
              disableUnderline: true,
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <Calendar color={PRIMARY_COLOR} size={24} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </MuiPickersUtilsProvider>

        <ChevronRight
          onClick={() => setSelectedDate(addDays(selectedDate, 1))}
          className={`${classes.chevrons} ml-6`}
          size={24}
        />
      </Grid>

      <Divider />

      <Grid className={`ml-4 mr-4 ${classes.containerCalendar}`}>
        {loading && <Loading loading />}

        {!loading && intervals && (
          <React.Fragment>
            <AppointmentsCalendar
              carregandoCalendario={false}
              recarregarCalendario={() => null}
              showHeader={false}
              handleAddSchedule={handleAddSchedule}
              agenda={[
                {
                  data: selectedDate,
                  intervalos: intervals
                }
              ]}
              removerEvento={() => setOpenDeleteScheduleModal(true)}
              listaEventos={[
                {
                  cor: GRAY,
                  descricao: `🕒 Horário indisponível`,
                  id: 7,
                  exclusaoPermitida: false
                },
                {
                  cor: "#2777B8",
                  descricao: `Horário selecionado`,
                  id: 2,
                  exclusaoPermitida: true
                }
              ]}
            />
            <FloatingButton
              icon={<Plus />}
              handleClick={() => {
                if (newScheduleAlreadyAdded) return setOpenDeleteScheduleModal(true);
                roundSelectedSchedule(selectedDate, true);
                setOpenTimeSelectionModal(true);
              }}
            />
            <TimeSelectionModal
              title="Selecionar horário"
              subtitle="Selecione a hora de início e fim da reserva:"
              openedModal={openTimeSelectionModal}
              primaryAction={() => handleAddNewInterval()}
              secondaryAction={() => handleCancelNewInterval()}
              handleOpenClose={() => handleCancelNewInterval()}
              handleChangeEndTime={newEndDateTime => handleChangeEndDateTime(newEndDateTime)}
              handleChangeStartTime={newStartDateTime =>
                handleChangeStartDateTime(newStartDateTime)
              }
              primaryButtonText="CONFIRMAR"
              secondaryButtonText="VOLTAR"
              confirmDisabled={confirmDisabled}
              defaultEndTime={endDateTime}
              defaultStartTime={startDateTime}
              titleVariant="h5"
              titleAlign="left"
              label={labelInformation}
            />
            <ConfirmationModal
              openedModal={openDeleteScheduleModal}
              title={"Você possui um horário selecionado."}
              subtitle={"Deseja excluir para então adicionar um novo horário?"}
              secondaryButtonText="NÃO"
              primaryButtonText="SIM"
              shouldCancel
              secondaryAction={() => setOpenDeleteScheduleModal(false)}
              handleOpenClose={() => setOpenDeleteScheduleModal(false)}
              primaryAction={() => confirmCancelSchedule()}
            />
          </React.Fragment>
        )}
      </Grid>
      <Divider />
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  containerCalendar: props => ({
    height: "calc(100vh - 310px)",
    overflow: "auto",
    display: "flex",
    alignItems: `${props.loading ? "center" : "normal"}`
  }),
  root: props => ({
    "& .MuiInputBase-root": {
      padding: 0,
      "& .MuiButtonBase-root": {
        padding: 0,
        marginLeft: 16
      },
      "& .MuiInputBase-input": {
        paddingTop: 24,
        paddingBottom: 24,
        textAlign: "end",
        color: PRIMARY_COLOR,
        fontWeight: 600,
        fontSite: 18,
        width: `${props.dateStringSize - 4}ch`,
        cursor: "pointer"
      }
    }
  }),
  containerDate: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  chevrons: {
    cursor: "pointer"
  }
}));

export default ShortTrip;
