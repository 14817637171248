import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Grid, IconButton } from "@material-ui/core";
import { Trash } from "react-feather";

const CardEventsCalendar = props => {
  const { handleExcluir, evento, exclusaoPermitida } = props;
  const classes = useStyles({ color: evento.evento.cor });

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={exclusaoPermitida ? 10 : 12} align="center">
          <Typography variant="body2" className={classes.fonteEvento}>
            {evento.evento.descricao}
          </Typography>
        </Grid>
        {exclusaoPermitida && (
          <Grid item xs={2} align="right" className="pr-2">
            <IconButton
              className={classes.botaoAcao}
              onClick={handleExcluir}
              title="Excluir evento"
            >
              <Trash size={15} color={evento.evento.cor} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles(() => ({
  botaoAcao: {
    padding: "0px"
  },
  paper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F4F4F4"
  },
  fonteEvento: props => ({
    color: props.color
  })
}));

export default CardEventsCalendar;
