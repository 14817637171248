import React from "react";
import { Grid, Typography, makeStyles, IconButton } from "@material-ui/core";
import { PRIMARY_COLOR } from "../../../theme";
import { ChevronLeft, Maximize2, Minimize2 } from "react-feather";
import { useHistory } from "react-router-dom";

const HistoryPageHeader = props => {
  const { allCardsExpanded, handleExpandAllClick } = props;

  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container>
      <Grid item container xs={10} alignItems="center" className={classes.headerRow}>
        <IconButton
          onClick={() => history.goBack()}
          className="ml-2 mr-2 mt-4"
          aria-label="Voltar"
        >
          <ChevronLeft size={32} color={PRIMARY_COLOR} />
        </IconButton>
        <Typography variant="h4" component="h2" className="mt-3">
          Histórico de Edições
        </Typography>
      </Grid>
      <Grid item container xs={2} className={classes.headerRow}>
        <Grid
          container
          alignItems="center"
          justify="flex-end"
          className={classes.expandAll}
          onClick={handleExpandAllClick}
        >
          {allCardsExpanded ? (
            <Minimize2 size={24} className="mr-2" />
          ) : (
            <Maximize2 size={24} className="mr-2" />
          )}
          <Typography component="span" className="mr-2">
            {`${allCardsExpanded ? "RECOLHER" : "EXPANDIR"}`} TODOS
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  headerRow: {
    height: "58px"
  },
  expandAll: {
    cursor: "pointer"
  }
}));

export default HistoryPageHeader;