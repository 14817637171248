import SiteService from "../../services/SiteService";
import CarService from "../../services/CarService";
import UserService from "../../services/UserService";
import { formatVehicleData } from "./dataFormattingAndValidating";
import { formatFleetFilters } from "../../shared/utils/Utils";
import { downloadResponseFile, handleImportResponse } from "../../shared/utils/RequestUtil";

class FleetManagementManager {
  async listSites() {
    const response = await SiteService.listSite(true);

    return response && response.data;
  }

  async listBrands() {
    const response = await CarService.listBrands();

    return response && response.data;
  }

  async listCarModels(brandId) {
    const response = await CarService.listModelsByBrandId(brandId);

    return response && response.data;
  }

  async listColors() {
    const response = await CarService.listColors();

    return response && response.data;
  }

  async listFuels() {
    const response = await CarService.listFuels();

    return response && response.data;
  }

  async listLicenseStatus() {
    const response = await CarService.listLicenseStatus();

    return response && response.data;
  }

  async listStatus() {
    const response = await CarService.listStatus();

    return response && response.data;
  }

  async listStatusDetails(statusId) {
    const response = await CarService.listStatusDetailsByStatusId(statusId);

    return response && response.data;
  }

  async getDriverOrResponsible(adUser, isDriver) {
    const response = await UserService.getUserByAdUser(adUser, isDriver);

    return response && response.data;
  }

  async createVehicle(data, driverId) {
    const vehicle = formatVehicleData(data, driverId);

    const response = await CarService.createVehicle(vehicle);

    return response && response.data;
  }

  async getVehicleById(carId) {
    const response = await CarService.getVehicleById(carId);

    return response && response.data;
  }

  async getVehicleByLicensePlate(carLicensePlate) {
    const response = await CarService.getVehicleByLicensePlate(carLicensePlate);

    return response && response.data;
  }

  async updateVehicle(carId, data, driverId) {
    const vehicle = formatVehicleData(data, driverId);

    const response = await CarService.updateVehicle(carId, vehicle, data.reasonForEdit);

    return response && response.data;
  }

  async getUserById(userId) {
    if (!userId) return;
    const response = await UserService.getUserById(userId);

    return response && response.data;
  }

  async exportCarsReport(searchParam, filtersData) {
    const filters = formatFleetFilters(filtersData);

    const response = await CarService.getCarsReport(searchParam, filters);
    downloadResponseFile(response, "Relatório de Carros.xlsx");
  }

  async uploadMonthlyRateSheet(file) {
    const response = await CarService.uploadMonthlyRateSheet(file);

    return handleImportResponse(file, response);
  }
}

export default new FleetManagementManager();
