import { FormHelperText, IconButton, makeStyles, Select } from "@material-ui/core";
import React, { forwardRef } from "react";
import { X } from "react-feather";
import { SECONDARY_COLOR } from "../../theme";

export const CustomSelect = forwardRef((props, ref) => {
  const { children, handleClearField, required, helperText, clear = true, ...muiProps } = props;

  const classes = useStyles();
  const showClearIcon = !!muiProps.value && !required && clear;

  const ClearIcon = () => (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        handleClearField();
      }}
      className={`${classes.clearIcon} mr-1`}
    >
      <X size={20} color={SECONDARY_COLOR} />
    </IconButton>
  );

  return (
    <>
      <Select {...muiProps} ref={ref} IconComponent={showClearIcon ? ClearIcon : undefined}>
        {children}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </>
  );
});

const useStyles = makeStyles(() => ({
  clearIcon: {
    position: "absolute",
    right: 0
  }
}));
