import React from "react";
import { Helmet } from "react-helmet";
import { configureEnvironment } from "../../config/env";

const Clarity = () => {
  const clarityKey = configureEnvironment().REACT_APP_CLARITY_KEY;

  if (clarityKey) {
    return (
      <Helmet>
        <script>
          {`
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${clarityKey}");
          `}
        </script>
      </Helmet>
    );
  }

  return <></>;
};

export default Clarity;
