import { useState, useEffect, useCallback } from "react";

export const useManagementFilters = props => {
  const { filtersData, applyFilters } = props;

  const [selectedFilters, setSelectedFilters] = useState(filtersData);
  const [showFloatFilters, setShowFloatFilters] = useState(false);

  const handleSpecificCheckbox = useCallback((id, checkValue, filterKey) => {
    setSelectedFilters(state => ({
      ...state,
      [filterKey]: state[filterKey].map(item =>
        item.id === id ? { ...item, isSelected: checkValue } : item
      )
    }));
  }, []);

  const handleAllCheckbox = useCallback((checkValue, filterKey) => {
    setSelectedFilters(state => ({
      ...state,
      [filterKey]: state[filterKey].map(item => ({
        ...item,
        isSelected: item.disabled ? item.isSelected : checkValue
      }))
    }));
  }, []);

  const arePartiallySelected = useCallback(
    filterKey => {
      const selectedCount = selectedFilters[filterKey]?.filter(item => item.isSelected).length;
      return (
        selectedCount > 0 &&
        selectedCount < selectedFilters[filterKey]?.filter(item => !item.disabled).length
      );
    },
    [selectedFilters]
  );

  const areAllSelected = useCallback(
    filterKey => {
      const selectedCount = selectedFilters[filterKey]?.filter(
        item => item.isSelected && !item.disabled
      ).length;
      return selectedCount === selectedFilters[filterKey]?.filter(item => !item.disabled).length;
    },
    [selectedFilters]
  );

  const isDisabled = useCallback(
    filterKey => {
      return (
        selectedFilters[filterKey]?.filter(item => item.disabled && item.isSelected).length > 0
      );
    },
    [selectedFilters]
  );

  const anyFilterAreSelected = useCallback(() => {
    const filterKeys = Object.keys(filtersData);

    const noFiltersSelected = filterKeys?.every(
      key => !filtersData[key]?.filter(item => item.isSelected).length
    );

    return !noFiltersSelected;
  }, [filtersData]);

  const handleApply = useCallback(() => {
    applyFilters(selectedFilters);
    setShowFloatFilters(false);
  }, [selectedFilters, applyFilters]);

  const handleClear = useCallback(() => {
    let clearedFilters = { ...selectedFilters };

    Object.keys(clearedFilters).forEach(key => {
      clearedFilters[key] = clearedFilters[key].map(item => ({
        ...item,
        isSelected: item.disabled && item.isSelected
      }));
    });

    setSelectedFilters(clearedFilters);
    applyFilters(clearedFilters);
    setShowFloatFilters(false);
  }, [applyFilters, selectedFilters]);

  useEffect(() => {
    setSelectedFilters(filtersData);
  }, [filtersData, showFloatFilters]);

  useEffect(() => {
    const handleEnterPressed = ev => {
      if (ev.key === "Enter") handleApply();
    };

    if (showFloatFilters) document.addEventListener("keypress", handleEnterPressed);

    return () => {
      document.removeEventListener("keypress", handleEnterPressed);
    };
  }, [showFloatFilters, handleApply]);

  return {
    selectedFilters,
    showFloatFilters,
    setShowFloatFilters,
    anyFilterAreSelected,
    handleClear,
    areAllSelected,
    arePartiallySelected,
    isDisabled,
    handleAllCheckbox,
    handleSpecificCheckbox,
    handleApply
  };
};
