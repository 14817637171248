import React from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { RED, WHITE } from "../../../../theme";
import Loading from "../../../../shared/components/Loading";
import { messages } from "../../../../shared/utils/Constants";
import FormButton from "../../../../shared/components/FormButton";

const ActionSegment = props => {
  const { secondaryAction, loading, handleSubmit, disableSubmit, isEditableUser } = props;
  const classes = useStyles();

  const messageText = isEditableUser
    ? messages.UPDATE_BUTTON_MESSAGE
    : messages.REGISTER_BUTTON_MESSAGE;

  return (
    <Grid item container xs={12} className="pt-2 pb-2 pr-4">
      <Grid item xs={5} />
      <Grid item container xs={7}>
        <Grid item xs={6} className="pr-2">
          <Button
            aria-label={
              isEditableUser ? "Cancelar edição de usuário" : "Cancelar cadastro de usuário"
            }
            fullWidth
            className={classes.cancelButton}
            onClick={secondaryAction}
          >
            {messages.CANCEL_BUTTON_MESSAGE}
          </Button>
        </Grid>
        <Grid item xs={6} className="pl-2">
          <FormButton onClick={handleSubmit}>
            <Button
              aria-label={isEditableUser ? "Salvar usuário" : "Cadastrar usuário"}
              disabled={disableSubmit}
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
            >
              {loading ? <Loading loading className={classes.loading} size={15} /> : messageText}
            </Button>
          </FormButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: RED
  },
  loading: {
    display: "flex",
    margin: "auto",
    color: WHITE
  }
}));

export default ActionSegment;
