import React, { useRef, useState } from "react";
import { TextField, InputAdornment, IconButton, makeStyles } from "@material-ui/core";
import { Search, X } from "react-feather";
import { useDebounce } from "../customHooks/useDebounce";

const SearchTextField = props => {
  const { onChange, label, initialValue } = props;

  const classes = useStyles();
  const textFieldRef = useRef();
  const [value, setValue] = useState(initialValue || "");

  useDebounce({
    value,
    callback: onChange,
    delay: 500,
    triggerOnEnter: true,
    textFieldRef
  });

  const SearchAdornment = ({ size }) => (
    <InputAdornment position="end">
      {value.length > 0 ? (
        <IconButton onClick={() => setValue("")} className="mr-1">
          <X size={size} />
        </IconButton>
      ) : (
        <Search size={size} className="mr-4" />
      )}
    </InputAdornment>
  );

  return (
    <TextField
      className={classes.adornment}
      fullWidth
      type="text"
      aria-label={label}
      label={label}
      inputRef={textFieldRef}
      variant="outlined"
      InputProps={{
        endAdornment: <SearchAdornment size={20} />
      }}
      value={value}
      onChange={e => setValue(e.target.value)}
    />
  );
};

const useStyles = makeStyles(() => ({
  adornment: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0
    }
  }
}));
export default SearchTextField;
