import { isBefore } from "date-fns";
import * as Yup from "yup";
import { carShouldHaveResponsible, isValidDate } from "../../../../shared/utils/Utils";
import {
  CAR_PROVIDER_YARA_ID,
  RELEASED_CAR_STATUS_ID,
  messages
} from "../../../../shared/utils/Constants";

const currencyFieldRegex = /(^(R\$)?[0-9.]+(,\d\d)?$)?/;

const createValidationSchema = isEdition => {
  return Yup.object()
    .shape(
      {
        licensePlate: Yup.string()
          .matches(/(^[a-zA-Z]{3}[a-zA-Z0-9]{2}\d{2}$)/, messages.INVALID_LICENSE_PLATE)
          .required(messages.FIELD_REQUIRED),
        fleetTypeId: Yup.number().min(1, messages.FIELD_REQUIRED),
        carTypeId: Yup.mixed().when(["fleetTypeId"], {
          is: fleetTypeId => carShouldHaveResponsible(fleetTypeId),
          then: Yup.number().min(1, messages.FIELD_REQUIRED)
        }),
        siteId: Yup.mixed().when(["fleetTypeId"], {
          is: fleetTypeId => carShouldHaveResponsible(fleetTypeId),
          then: Yup.number().min(1, messages.FIELD_REQUIRED)
        }),
        year: Yup.number().min(1, messages.FIELD_REQUIRED),
        brandId: Yup.mixed().when(["brandId", "oldBrandId"], {
          is: (brandId, oldBrandId) => !!oldBrandId && !brandId,
          then: Yup.number()
            .nullable()
            .test({
              test: value => !!value,
              message: messages.INVALID_NULL_BRAND
            })
        }),
        carModelId: Yup.mixed().when(["brandId", "carModelId", "oldCarModelId"], {
          is: (brandId, carModelId, oldCarModelId) => (!!oldCarModelId && !carModelId) || !!brandId,
          then: Yup.number()
            .nullable()
            .test({
              test: value => !!value,
              message: messages.INVALID_NULL_MODEL
            })
        }),
        providerId: Yup.number().min(1, messages.FIELD_REQUIRED),
        maintenanceProviderId: Yup.mixed().when(
          ["maintenanceProviderId", "oldMaintenanceProviderId"],
          {
            is: (maintenanceProviderId, oldMaintenanceProviderId) =>
              !!oldMaintenanceProviderId && !maintenanceProviderId,
            then: Yup.number()
              .nullable()
              .test({
                test: value => !!value,
                message: messages.INVALID_NULL_MAINTENANCE_PROVIDER
              })
          }
        ),
        contractStartDate: Yup.date()
          .typeError(messages.INVALID_DATE)
          .required(messages.FIELD_REQUIRED)
          .test({
            test: value =>
              value
                ? isBefore(new Date(value).setHours(0, 0, 0), new Date().setHours(23, 59, 0))
                : true,
            message: messages.INVALID_FUTURE_DATE
          }),
        contractValidity: Yup.mixed().when(["contractValidity", "oldContractValidity"], {
          is: (contractValidity, oldContractValidity) =>
            !!oldContractValidity && (!contractValidity || !isValidDate(contractValidity)),
          then: Yup.date()
            .typeError(messages.INVALID_DATE)
            .nullable()
            .test({
              test: value => !!value && isValidDate(value),
              message: messages.INVALID_NULL_CONTRACT_VALIDITY
            }),
          otherwise: Yup.date().typeError(messages.INVALID_DATE).nullable()
        }),
        odometer: Yup.mixed().when(["odometer", "oldOdometer"], {
          is: (odometer, oldOdometer) => !!oldOdometer && !odometer,
          then: Yup.string()
            .nullable()
            .test({
              test: value => !!value,
              message: messages.INVALID_NULL_ODOMETER
            })
        }),
        colorId: Yup.mixed().when(["colorId", "oldColorId"], {
          is: (colorId, oldColorId) => !!oldColorId && !colorId,
          then: Yup.number()
            .nullable()
            .test({
              test: value => !!value,
              message: messages.INVALID_NULL_COLOR
            })
        }),
        fuelId: Yup.mixed().when(["fuelId", "oldFuelId"], {
          is: (fuelId, oldFuelId) => !!oldFuelId && !fuelId,
          then: Yup.number()
            .nullable()
            .test({
              test: value => !!value,
              message: messages.INVALID_NULL_FUEL
            })
        }),
        contractNumber: Yup.mixed().when(["contractNumber", "oldContractNumber"], {
          is: (contractNumber, oldContractNumber) => !!oldContractNumber && !contractNumber,
          then: Yup.string()
            .nullable()
            .test({
              test: value => !!value,
              message: messages.INVALID_NULL_CONTRACT_NUMBER
            })
        }),
        licenseStatusId: Yup.mixed().when(["licenseStatusId", "oldLicenseStatusId"], {
          is: (licenseStatusId, oldLicenseStatusId) => !!oldLicenseStatusId && !licenseStatusId,
          then: Yup.number()
            .nullable()
            .test({
              test: value => !!value,
              message: messages.INVALID_NULL_CAR_LICENSING
            })
        }),
        contractMonthlyRate: Yup.mixed().when(["providerId"], {
          is: providerId => providerId !== CAR_PROVIDER_YARA_ID,
          then: Yup.string()
            .matches(currencyFieldRegex, messages.INVALID_CURRENCY_VALUE)
            .required(messages.FIELD_REQUIRED),
          otherwise: Yup.string().matches(currencyFieldRegex, messages.INVALID_CURRENCY_VALUE)
        }),
        carDocument: Yup.mixed().when(["carDocument", "oldCarDocument"], {
          is: (carDocument, oldCarDocument) =>
            (!!oldCarDocument && !carDocument) || carDocument?.length,
          then: Yup.string()
            .nullable()
            .matches(/[\d+]/, messages.INVALID_CAR_DOCUMENT)
            .test({
              test: value => !!value,
              message: messages.INVALID_NULL_CAR_DOCUMENT
            })
        }),
        chassisNumber: Yup.mixed().when(["chassisNumber", "oldChassisNumber"], {
          is: (chassisNumber, oldChassisNumber) =>
            (!!oldChassisNumber && !chassisNumber) || chassisNumber?.length,
          then: Yup.string()
            .nullable()
            .matches(/[0-9a-zA-Z]{17}/, messages.INVALID_CHASSIS)
            .test({
              test: value => !!value,
              message: messages.INVALID_NULL_CHASSIS
            })
        }),
        immobilized: Yup.mixed().when(["immobilized", "oldImmobilized"], {
          is: (immobilized, oldImmobilized) =>
            (!!oldImmobilized && !immobilized) || immobilized?.length,
          then: Yup.string()
            .nullable()
            .matches(/\d{8}/, messages.INVALID_SAP_IMMOBILIZED)
            .test({
              test: value => !!value,
              message: messages.INVALID_NULL_IMMOBILIZED
            })
        }),
        statusId: Yup.number().min(1, messages.FIELD_REQUIRED),
        statusDetailsId: Yup.mixed().when(["statusId", "statusDetailsId", "oldStatusDetailsId"], {
          is: (statusId, statusDetailsId, oldStatusDetailsId) =>
            !!oldStatusDetailsId && !statusDetailsId && statusId === RELEASED_CAR_STATUS_ID,
          then: Yup.number()
            .nullable()
            .test({
              test: value => !!value,
              message: messages.INVALID_NULL_STATUS_DETAILS
            })
        }),
        statusStartDate: Yup.mixed().when(["statusId", "statusStartDate", "oldStatusStartDate"], {
          is: (statusId, statusStartDate, oldStatusStartDate) =>
            !!oldStatusStartDate &&
            (!statusStartDate || !isValidDate(statusStartDate)) &&
            statusId === RELEASED_CAR_STATUS_ID,
          then: Yup.date()
            .typeError(messages.INVALID_DATE)
            .nullable()
            .test({
              test: value => !!value && isValidDate(value),
              message: messages.INVALID_NULL_STATUS_START_DATE
            }),
          otherwise: Yup.date()
            .typeError(messages.INVALID_DATE)
            .nullable()
            .test({
              test: value =>
                value
                  ? isBefore(new Date(value).setHours(0, 0, 0), new Date().setHours(23, 59, 0))
                  : true,
              message: messages.INVALID_FUTURE_DATE
            })
        }),
        statusCost: Yup.mixed().when(["statusId", "statusCost", "oldStatusCost"], {
          is: (statusId, statusCost, oldStatusCost) =>
            !!oldStatusCost && !statusCost && statusId === RELEASED_CAR_STATUS_ID,
          then: Yup.string()
            .nullable()
            .matches(currencyFieldRegex, messages.INVALID_CURRENCY_VALUE)
            .test({
              test: value => !!value,
              message: messages.INVALID_NULL_STATUS_COST
            })
        }),
        statusReason: Yup.mixed().when(["statusId", "statusReason", "oldStatusReason"], {
          is: (statusId, statusReason, oldStatusReason) =>
            !!oldStatusReason && !statusReason && statusId === RELEASED_CAR_STATUS_ID,
          then: Yup.string()
            .nullable()
            .test({
              test: value => !!value,
              message: messages.INVALID_NULL_STATUS_REASON
            })
        }),
        driverAdUser: Yup.string()
          .nullable()
          .notRequired()
          .when(["isFinalStep", "driverAdUser"], {
            is: (isFinalStep, value) => isFinalStep && value?.length,
            then: Yup.string().matches(/(^[ACac]{1}\d{6}$)/, messages.INVALID_AD_USER)
          }),
        reasonForEdit: isEdition
          ? Yup.string().when(["isFinalStep"], {
              is: true,
              then: Yup.string().required(messages.FIELD_REQUIRED)
            })
          : null
      },
      [
        ["carDocument", "carDocument"],
        ["chassisNumber", "chassisNumber"],
        ["immobilized", "immobilized"],
        ["statusCost", "statusCost"],
        ["driverAdUser", "driverAdUser"],
        ["contractValidity", "contractValidity"],
        ["odometer", "odometer"],
        ["licenseStatusId", "licenseStatusId"],
        ["brandId", "brandId"],
        ["carModelId", "carModelId"],
        ["colorId", "colorId"],
        ["fuelId", "fuelId"],
        ["maintenanceProviderId", "maintenanceProviderId"],
        ["contractNumber", "contractNumber"],
        ["statusDetailsId", "statusDetailsId"],
        ["statusStartDate", "statusStartDate"],
        ["statusReason", "statusReason"]
      ]
    )
    .required();
};

export default createValidationSchema;
