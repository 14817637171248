import React, { createContext, useContext } from "react";

const LogoutContext = createContext();

export default function LogoutProvider({ children, logout }) {
  return (
    <LogoutContext.Provider
      value={{
        logout
      }}
    >
      {children}
    </LogoutContext.Provider>
  );
}

export const useLogoutContext = () => {
  const { logout } = useContext(LogoutContext);
  return logout;
};
