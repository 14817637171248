import { Typography, Grid, makeStyles } from "@material-ui/core";
import { formatStringField } from "../../../shared/utils/Utils";
import TooltipItem from "../../../shared/components/TooltipItem";
import { DARK_GRAY, GRAY } from "../../../theme";

const VehicleMainDetails = props => {
  const { mainData } = props;

  const classes = useStyles();

  return (
    <Grid item xs className={`ml-8 mr-4 ${classes.overflow}`}>
      <TooltipItem tooltip="Dados do Veículo" onlyWhenOverflown>
        <Typography variant="h6" component="h4" className={`${classes.header} ${classes.overflow}`}>
          Dados do Veículo
        </Typography>
      </TooltipItem>
      <Grid item container xs={12} className="mt-6">
        <Grid className="mr-6">
          <Grid item xs={12} className="mb-4">
            <Typography variant="body1" className={classes.header}>
              {formatStringField(mainData.carLicensing?.carLicensePlate)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Placa
            </Typography>
          </Grid>
        </Grid>
        <Grid className="mr-6">
          <Grid item xs={12} className="mb-4">
            <Typography variant="body1" className={classes.header}>
              {formatStringField(mainData.carInformation.fleet?.fleetType?.name)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Tipo da Frota
            </Typography>
          </Grid>
        </Grid>
        <Grid className="mr-6">
          <Grid item xs={12} className="mb-4">
            <Typography variant="body1" className={classes.header}>
              {mainData.carInformation.year > 0 ? mainData.carInformation.year : "--"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Ano
            </Typography>
          </Grid>
        </Grid>
        <Grid className={`mr-6 ${classes.overflow}`}>
          <Grid item xs={12} className="mb-4">
            <TooltipItem
              tooltip={mainData.carInformation.carModel?.carBrand?.name}
              onlyWhenOverflown
            >
              <Typography variant="body1" className={`${classes.header} ${classes.overflow}`}>
                {formatStringField(mainData.carInformation.carModel?.carBrand?.name)}
              </Typography>
            </TooltipItem>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Marca
            </Typography>
          </Grid>
        </Grid>
        <Grid className={`mr-6 ${classes.overflow}`}>
          <Grid item xs={12} className="mb-4">
            <TooltipItem tooltip={mainData.carInformation.carModel?.model} onlyWhenOverflown>
              <Typography variant="body1" className={`${classes.header} ${classes.overflow}`}>
                {formatStringField(mainData.carInformation.carModel?.model)}
              </Typography>
            </TooltipItem>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Modelo
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} className="mt-6">
        <Grid className="mr-6">
          <Grid item xs={12} className="mb-4">
            <Typography variant="body1" className={classes.header}>
              {formatStringField(mainData.carInformation.site?.name)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Unidade da Frota
            </Typography>
          </Grid>
        </Grid>
        <Grid className={`mr-6 ${classes.overflow}`}>
          <Grid item xs={12} className="mb-4">
            <TooltipItem tooltip={mainData.carInformation.chassisNumber} onlyWhenOverflown>
              <Typography variant="body1" className={`${classes.header} ${classes.overflow}`}>
                {formatStringField(mainData.carInformation.chassisNumber)}
              </Typography>
            </TooltipItem>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Chassi
            </Typography>
          </Grid>
        </Grid>
        <Grid className="mr-6">
          <Grid item xs={12} className="mb-4">
            <Typography variant="body1" className={classes.header}>
              {mainData.carInformation.odometer ? `${mainData.carInformation.odometer}km` : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Odômetro
            </Typography>
          </Grid>
        </Grid>
        <Grid className="mr-6">
          <Grid item xs={12} className="mb-4">
            <Typography variant="body1" className={classes.header}>
              {formatStringField(mainData.carInformation.fuel?.name)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Combustível
            </Typography>
          </Grid>
        </Grid>
        <Grid className="mr-6">
          <Grid item xs={12} className="mb-4">
            <Typography variant="body1" className={classes.header}>
              {formatStringField(mainData.carInformation.color?.name)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Cor
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} className="mt-6">
        <Grid item className="mr-6">
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.header}>
              {formatStringField(mainData.carInformation.immobilized)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Imobilizado
            </Typography>
          </Grid>
        </Grid>
        <Grid item className="mr-6">
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.header}>
              {formatStringField(mainData.carLicensing?.carDocument)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Renavan
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={`mr-6 ${classes.overflow}`}>
          <Grid item xs={12}>
            <TooltipItem
              tooltip={mainData.carLicensing?.carLicensingStatus?.name}
              onlyWhenOverflown
            >
              <Typography variant="body1" className={`${classes.header} ${classes.overflow}`}>
                {formatStringField(mainData.carLicensing?.carLicensingStatus?.name)}
              </Typography>
            </TooltipItem>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Licenciamento
            </Typography>
          </Grid>
        </Grid>
        <Grid item className="mr-6">
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.header}>
              {mainData.carLicensing?.hasFuelCard ? "Sim" : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Cartão Combustível
            </Typography>
          </Grid>
        </Grid>
        <Grid item className="mr-6">
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.header}>
              {mainData.carLicensing?.hasTollTag ? "Sim" : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Tag Pedágio
            </Typography>
          </Grid>
        </Grid>
        <Grid item className="mr-6">
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.header}>
              {mainData.carLicensing?.isBorder ? "Sim" : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Fronteira
            </Typography>
          </Grid>
        </Grid>
        <Grid item className="mr-6">
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.header}>
              {mainData.carLicensing?.has35PercentDiscount ? "Sim" : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Desconto 35%
            </Typography>
          </Grid>
        </Grid>
        <Grid item className="mr-6">
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.header}>
              {mainData.carLicensing?.isBulletProof ? "Sim" : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Blindado
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={`mr-6 ${classes.overflow}`}>
          <Grid item xs={12}>
            <TooltipItem tooltip={mainData.historyCarStatus?.status?.name} onlyWhenOverflown>
              <Typography variant="body1" className={`${classes.header} ${classes.overflow}`}>
                {formatStringField(mainData.historyCarStatus?.status?.name)}
              </Typography>
            </TooltipItem>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Status
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  header: {
    color: DARK_GRAY
  },
  overflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  subtitle: {
    color: GRAY
  }
}));

export default VehicleMainDetails;
