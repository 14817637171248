import { toastTypes } from "../shared/utils/enum";
export const NOTIFY_ERROR = "notification/NOTIFY_ERROR";
export const NOTIFY_SUCCESS = "notification/NOTIFY_SUCCESS";
export const NOTIFY_WARNING = "notification/NOTIFY_WARNING";
export const NOTIFY_INFORMATION = "notification/NOTIFY_INFORMATION";
export const HIDE_NOTIFICATION = "notification/HIDE_NOTIFICATION";

const INITIAL_STATE = {
  message: "",
  type: toastTypes.success.type,
  showNotification: false
};

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NOTIFY_ERROR:
      return {
        ...state,
        message: action.payload.message,
        type: toastTypes.error.type,
        showNotification: true
      };

    case NOTIFY_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        type: toastTypes.success.type,
        showNotification: true
      };

    case NOTIFY_WARNING:
      return {
        ...state,
        message: action.payload.message,
        type: toastTypes.warning.type,
        showNotification: true
      };

    case NOTIFY_INFORMATION:
      return {
        ...state,
        message: action.payload.message,
        type: toastTypes.information.type,
        showNotification: true
      };

    case HIDE_NOTIFICATION:
      return {
        ...state,
        message: "",
        showNotification: false
      };

    default:
      return state;
  }
}

export const showNotification = (type, message) => {
  return {
    type: type,
    payload: { message }
  };
};

export const hideNotification = () => {
  return {
    type: HIDE_NOTIFICATION
  };
};
