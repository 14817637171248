import React from "react";
import { Grid, TextField, InputAdornment, CircularProgress } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { adUserExample, adUserSize } from "../../../../shared/utils/Constants";
import { profileStyle } from "../../../../shared/utils/enum";
import CustomTextField from "../../../../shared/components/CustomTextField";
import { MaskedInputFactory } from "../../../../shared/components/MaskedInputFactory";

const UserSegment = props => {
  const { control, errors, driverData, driverDataIsLoading, isDriver } = props;
  const userTitle = isDriver ? "Condutor" : "Responsável";

  return (
    <Grid container spacing={2}>
      <Grid item container spacing={2}>
        <Grid item xs={4}>
          <Controller
            name="driverAdUser"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  fullWidth
                  {...field}
                  label={`ID do ${userTitle}`}
                  error={errors && !!errors[field.name]}
                  helperText={errors && errors[field.name] ? errors[field.name].message : null}
                  placeholder={adUserExample}
                  type="text"
                  variant="outlined"
                  inputProps={{
                    "aria-label": `Digite o ID Yara do ${userTitle}`,
                    maxsength: adUserSize
                  }}
                  autoComplete="off"
                  InputProps={{
                    inputComponent: YaraIdMask,
                    endAdornment: driverDataIsLoading && (
                      <InputAdornment position="end">
                        <CircularProgress size={20} className="mr-3" />
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={!!field.value ? { shrink: true } : null}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs>
          <CustomTextField label={`Nome do ${userTitle}`} value={driverData.name} disabled />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs>
          <CustomTextField label={`Cargo do ${userTitle}`} value={driverData.role?.name} disabled />
        </Grid>
        <Grid item xs>
          <CustomTextField
            label="Perfil"
            value={profileStyle.find(e => e.profile === driverData.profile?.profileName)?.label}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item xs>
        <CustomTextField label="E-mail" value={driverData.email} disabled />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs>
          <CustomTextField label={`Polo do ${userTitle}`} value={driverData.pole?.name} disabled />
        </Grid>
        <Grid item xs>
          <CustomTextField
            label={`Região/Diretoria do ${userTitle}`}
            value={driverData.management?.regional.name}
            disabled
          />
        </Grid>
        <Grid item xs>
          <CustomTextField
            label={`Gerência do ${userTitle}`}
            value={driverData.management?.name}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs>
          <CustomTextField
            label="Cód. do Centro de Custo"
            value={driverData.costCenter?.name}
            disabled
          />
        </Grid>
        <Grid item xs>
          <CustomTextField
            label="Vencimento de CDD"
            value={
              driverData.course?.expireDate &&
              new Date(driverData.course.expireDate).toLocaleDateString()
            }
            disabled
          />
        </Grid>
        <Grid item xs>
          <CustomTextField
            label="Vencimento da CNH"
            value={
              driverData.license?.expireDate &&
              new Date(driverData.license.expireDate).toLocaleDateString()
            }
            disabled
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={2}></Grid>
    </Grid>
  );
};

const YaraIdMask = MaskedInputFactory({
  id: "yaraIdMask",
  mask: [/[acAC]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
});

export default UserSegment;
