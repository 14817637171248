import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { ChevronRight, Calendar, Clock, User } from "react-feather";
import { Grid, makeStyles, Typography, Divider } from "@material-ui/core";
import { SECONDARY_COLOR, GRAY, THIN_BLUE2, DARK_GRAY } from "../../../theme";
import { bookingStatusStyle, carTypes } from "../../../shared/utils/enum";
import * as actions from "../../../store/BookingStore";
import { routes } from "../../../shared/utils/Constants";
import TooltipItem from "../../../shared/components/TooltipItem";

const BookingCard = props => {
  const { booking, activeTab, updateBooking, startDate, finishTime, startTime } = props;
  const [redirect, setRedirect] = useState(null);
  const carInfo = `${booking?.carLicensePlate} | ${booking?.carModel}`;
  const lastHistoryStatusId = booking.historyStatus[booking.historyStatus.length - 1].status.id;
  const {
    icon,
    text: statusText,
    color: statusColor
  } = bookingStatusStyle.find(item => item.statusId === lastHistoryStatusId);

  const classes = useStyles({
    colorStatus: statusColor
  });

  const onSelectBooking = () => {
    const bookingId = booking.id;
    const bookingDetailsPath = (
      <Redirect to={`${routes.bookingDetails}/${bookingId}?tab=${activeTab}`} />
    );

    updateBooking({
      id: booking.id,
      carId: booking.carId
    });

    setRedirect(bookingDetailsPath);
  };

  return (
    <Grid>
      <Grid
        container
        className={`mb-3 hover-icon  pl-10 pr-12 pt-4 pb-4 ${classes.card}`}
        onClick={onSelectBooking}
      >
        <Grid
          item
          container
          xs={12}
          md={5}
          alignItems="center"
          className={classes.carInfoContainer}
        >
          <img
            src={carTypes.find(type => type.id === booking.carTypeId).src}
            alt={carTypes.find(type => type.id === booking.carTypeId).alt}
            className={classes.carIcon}
          />
          <Grid xs item className={classes.carInfoSection}>
            <Typography variant="h6" className={classes.statusIcon} color="inherit">
              {icon} {statusText}
            </Typography>

            <TooltipItem onlyWhenOverflown tooltip={carInfo}>
              <Typography variant="h6" className={classes.carInfo}>
                {carInfo}
              </Typography>
            </TooltipItem>
          </Grid>
        </Grid>
        <Grid item className="mr-8 ml-6">
          <Divider orientation="vertical" />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md
          alignItems="center"
          className={classes.bookingInfoContainer}
        >
          <Grid item container xs>
            <Grid
              item
              container
              xs
              spacing={2}
              alignItems="center"
              className={classes.bookingInfoCard}
            >
              <Grid item>
                <Calendar size={24} color={SECONDARY_COLOR} className={classes.bookingInfoIcon} />
              </Grid>
              <Grid item xs container direction="column" className={classes.bookingInfoSection}>
                <Grid item>
                  <Typography variant="subtitle1" className={classes.bookingInfo}>
                    {startDate}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="caption" className={classes.label}>
                    Data
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs
              spacing={2}
              alignItems="center"
              className={classes.bookingInfoCard}
            >
              <Grid item>
                <Clock size={24} color={SECONDARY_COLOR} className={classes.bookingInfoIcon} />
              </Grid>
              <Grid item>
                <Grid container direction="column" className={classes.bookingInfoSection}>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      className={classes.bookingInfo}
                    >{`${startTime} às ${finishTime}`}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" className={classes.label}>
                      Hora
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs
              spacing={2}
              alignItems="center"
              className={classes.bookingInfoCard}
            >
              <Grid item>
                <User size={24} color={SECONDARY_COLOR} className={classes.bookingInfoIcon} />
              </Grid>
              <Grid item>
                <Grid container direction="column" className={classes.bookingInfoSection}>
                  <Grid item>
                    <Typography variant="subtitle1" className={classes.bookingInfo}>
                      {booking.driverName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" className={classes.label}>
                      Condutor
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" className={classes.redirectIcon}>
            <ChevronRight size={24} color={SECONDARY_COLOR} />
          </Grid>
        </Grid>
      </Grid>
      {redirect}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  carInfo: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    overflow: "inherit",
    [theme.breakpoints.down(600)]: {
      fontSize: 14
    }
  },
  carInfoContainer: {
    flexWrap: "nowrap"
  },
  bookingInfoCard: {
    minWidth: 176
  },
  bookingInfoContainer: {
    [theme.breakpoints.down(960)]: {
      marginTop: 26
    }
  },
  bookingInfoSection: {
    [theme.breakpoints.down(644)]: {
      flexDirection: "column-reverse"
    }
  },
  bookingInfo: {
    color: DARK_GRAY
  },
  bookingInfoIcon: {
    [theme.breakpoints.down(600)]: {
      width: 16
    }
  },
  carInfoSection: {
    overflow: "hidden",
    marginLeft: 40,
    [theme.breakpoints.down(600)]: {
      marginLeft: 16
    }
  },
  label: {
    color: GRAY
  },
  card: ({ colorStatus }) => ({
    border: `1px solid ${colorStatus}`,
    borderRadius: 8,
    backgroundColor: THIN_BLUE2,
    color: colorStatus,
    position: "relative",
    [theme.breakpoints.down(600)]: {
      paddingRight: 24,
      paddingTop: 24,
      paddingBottom: 24,
      paddingLeft: 24
    }
  }),
  redirectIcon: {
    height: "100%",
    [theme.breakpoints.down(960)]: {
      height: "initial",
      width: "fit-content",
      position: "absolute",
      top: 19
    },
    [theme.breakpoints.down(600)]: {
      right: 14
    }
  },
  statusIcon: {
    display: "flex",
    alignItems: "center",
    "& > svg": {
      marginRight: "8px"
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 14
    }
  },
  carIcon: {
    maxWidth: 120,
    [theme.breakpoints.down(600)]: {
      minWidth: 48,
      maxWidth: 48
    }
  }
}));

const mapDispatchToProps = dispatch => {
  return {
    updateBooking: data => dispatch(actions.updateBooking(data))
  };
};

export default connect(null, mapDispatchToProps)(BookingCard);
