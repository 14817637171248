import { GRAY, SECONDARY_COLOR } from "../../../theme";
import Loading from "../../../shared/components/Loading";
import { formatStringField } from "../../../shared/utils/Utils";
import { HistoryCheckIcon } from "../../../shared/components/HistoryPage";
import VerticalChevron from "../../../shared/components/VerticalChevron";
import { Grid, makeStyles, Divider, Typography } from "@material-ui/core";

const HistorySection = props => {
  const { isExpanded, setIsExpanded, isNewData, sectionLabel, data, isCreation, isLoadingData } =
    props;

  const sectionColor = isNewData ? SECONDARY_COLOR : GRAY;

  const classes = useStyles({ sectionColor });

  const createFieldStyle = _isEdited => {
    return { fontWeight: !isCreation && _isEdited && "bold" };
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      <Grid
        item
        container
        className={classes.clickableRow}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Grid item className="mr-1">
          <VerticalChevron
            size="small"
            color={sectionColor}
            value={isExpanded}
            setValue={setIsExpanded}
          />
        </Grid>
        <Grid item>
          <Typography variant="h6" color="inherit">
            {sectionLabel}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="mt-2">
        {isExpanded &&
          (!isLoadingData ? (
            Object.values(data).map(({ label, value, isEdited }) => (
              <Grid container key={label}>
                <Grid item container xs={12} className="ml-2">
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" color="inherit" className="regular">
                      {label}
                    </Typography>
                  </Grid>
                  <Grid container item xs={9} alignItems="center">
                    <Typography style={createFieldStyle(isEdited)} variant="body1" color="inherit">
                      {formatStringField(value)}
                    </Typography>
                    {isNewData && (
                      <HistoryCheckIcon isEdited={isEdited} value={value} isCreation={isCreation} />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} className="mt-2 mb-2">
                  <Divider className="divider" />
                </Grid>
              </Grid>
            ))
          ) : (
            <Loading loading size={16} />
          ))}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  section: ({ sectionColor }) => ({
    color: sectionColor
  }),
  clickableRow: {
    cursor: "pointer"
  }
}));

export default HistorySection;
