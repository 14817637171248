import React from "react";
import { Grid, makeStyles, TextField } from "@material-ui/core";
import { RED, SECONDARY_COLOR, THIN_BLUE2 } from "../../theme";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ChevronDown, X } from "react-feather";

const SearchText = props => {
  const {
    value,
    optionsList,
    handleChange,
    placeholder,
    hasError,
    highlightBackground = false
  } = props;

  const classes = useStyles({
    color: hasError ? RED : SECONDARY_COLOR,
    value,
    highlightBackground
  });
  return (
    <Grid container>
      <Autocomplete
        options={optionsList}
        value={value}
        key={`value-${value?.id}`}
        fullWidth
        getOptionLabel={option => option.name}
        className={classes.autoComplete}
        onChange={handleChange}
        closeIcon={<X size={28} />}
        popupIcon={<ChevronDown size={32} />}
        renderInput={params => (
          <TextField
            {...params}
            error={hasError}
            placeholder={placeholder}
            variant="outlined"
            InputProps={{ ...params.InputProps, className: classes.textFieldInput }}
          />
        )}
      />
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  autoComplete: ({ color, value, highlightBackground }) => ({
    border: `1px solid ${color}`,
    borderRadius: "8px",
    color: color,
    backgroundColor: highlightBackground && value?.id && THIN_BLUE2
  }),
  textFieldInput: ({ color }) => ({
    height: "78px",
    color: color,
    paddingLeft: "10px"
  })
}));

export default SearchText;
