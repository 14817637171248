import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ChecklistManager from "../ChecklistManager";
import useChecklist from "../customHooks/useChecklist";
import * as actions from "../../../store/ChecklistStore";
import { routes } from "../../../shared/utils/Constants";
import { createFormData } from "../../../shared/utils/Utils";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import ChecklistQuestion from "../../../shared/components/ChecklistQuestion";
import ImageDropzone from "../../../shared/components/ImageDropzone/ImageDropzone";
import { GRAY } from "../../../theme";
import { bookingStatus, checklistStatus, accessType } from "../../../shared/utils/enum";
import CustomTextField from "../../../shared/components/CustomTextField";
import NewChecklist from "./NewChecklist";

const CheckMandatoryEquipment = props => {
  const { checklist, updateChecklist, user, booking } = props;

  const isBooking = !!booking.id;
  const classes = useStyles();
  const [allItemsSetStatus, setAllItemsSetStatus] = useState(false);
  const [redirect, setRedirect] = useState();
  const [loading, setLoading] = useState(false);

  const {
    handleSaveChecklistQuestionResponse,
    getInitialChecklistQuestionResponse,
    groupedStepQuestions,
    stepGroups,
    handleChangeChecklistObservations
  } = useChecklist(checklist, updateChecklist, setAllItemsSetStatus);

  const handleSaveChecklist = () => {
    const saveOnDatabase = async newChecklistResponse => {
      setLoading(true);
      const imageFormData = createFormData(checklist.checklistImages, "checklistFiles");
      const data = isBooking
        ? await ChecklistManager.saveBookingChecklist(newChecklistResponse, booking.id)
        : await ChecklistManager.saveRecurringChecklist(newChecklistResponse);

      if (data) {
        if (data.checklistId && imageFormData.has("checklistFiles"))
          ChecklistManager.uploadChecklistImages(data.checklistId, imageFormData);

        const isInSecurityRisk = [
          bookingStatus.SECURITY_RISK,
          checklistStatus.SECURITY_RISK
        ].includes(data.statusId);

        setRedirect(
          <Redirect
            to={isInSecurityRisk ? routes.checklistSecurityRisk : routes.confirmChecklist}
          />
        );
      }
      setLoading(false);
    };

    const isCommercialOrExecutive = [accessType.commercial, accessType.executive].includes(
      user.profile
    );
    const isBackoffice = user.profile === accessType.backoffice;
    saveOnDatabase({
      user: { id: user.id },
      car: { id: isCommercialOrExecutive || isBackoffice ? user.privateCar.id : booking.carId },
      mileage: checklist.mileage,
      fuelLevel: checklist.fuelLevel,
      checklistModelId: checklist.checklistModelId,
      checklistObservations: checklist.checklistObservations,
      checklistQuestionResponses: checklist.questionsResponses
    });
  };

  const handleChangeChecklistImages = value => {
    updateChecklist({
      ...checklist,
      checklistImages: value
    });
  };

  useEffect(() => {
    const isCommercialOrExecutive = [accessType.commercial, accessType.executive].includes(
      user.profile
    );
    const isBackOffice = user.profile === accessType.backoffice;

    const redirectTo = () => {
      if (isBackOffice) return routes.reportsManagement;
      return isCommercialOrExecutive ? routes.listChecklists : routes.listBookings;
    };

    !checklist.mileage && setRedirect(<Redirect to={redirectTo()} />);
  }, [checklist, user]);

  return (
    <NewChecklist
      stepIndex={3}
      disableButton={!allItemsSetStatus}
      previousStep={routes.checklistSteps.CHECK_CAR_EXTERIOR.route}
      loading={loading}
      saveChecklist={handleSaveChecklist}
    >
      <Grid item xs={12}>
        {stepGroups &&
          stepGroups.map((stepGroup, groupIndex) => {
            return (
              <Grid key={groupIndex}>
                <Grid item xs={12} className="mb-12">
                  <Typography variant="h5" className="pb-4">
                    {stepGroup}
                  </Typography>
                  <Typography variant="subtitle1">
                    Responda as perguntas a seguir selecionando as reações positivas ou negativas e
                    se necessário acrescente um comentário
                  </Typography>
                </Grid>
                {groupedStepQuestions[stepGroup] &&
                  groupedStepQuestions[stepGroup].map((question, questionIndex) => {
                    return (
                      <Grid key={questionIndex} className="pb-4">
                        <ChecklistQuestion
                          padding
                          step={4}
                          checklistQuestion={question}
                          critical={question.critical}
                          initialObservations={getInitialChecklistQuestionResponse(
                            question,
                            "observations"
                          )}
                          initialSelectedStatus={getInitialChecklistQuestionResponse(
                            question,
                            "checklistTypeAnswerId"
                          )}
                          updateChecklistQuestionResponses={handleSaveChecklistQuestionResponse}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            );
          })}
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12} className="mb-2">
          <Typography variant="subtitle1" className={classes.subGroupItemsTitle}>
            Observações gerais do checklist
          </Typography>
        </Grid>
        <Grid item xs={12} align="left">
          <Grid item container xs={12}>
            <CustomTextField
              placeholder="Descreva aqui"
              rows={5}
              multiline
              onChange={handleChangeChecklistObservations}
              value={checklist.checklistObservations}
              maxLength={500}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid className="mb-12">
          <Typography variant="h5" className="pb-4">
            Anexos
          </Typography>
          <Typography variant="subtitle1">
            Você pode incluir até 5 imagens relacionadas ao checklist, tamanho máximo permitido de
            5MB:
          </Typography>
        </Grid>
        <ImageDropzone
          imagesState={checklist.checklistImages}
          maxFileSize={5}
          maxFilesToAttach={5}
          acceptedTypes="image/jpg, image/jpeg, image/png"
          onUploadSuccess={handleChangeChecklistImages}
        />
      </Grid>
      {redirect}
    </NewChecklist>
  );
};

const useStyles = makeStyles(() => ({
  subGroupItemsTitle: {
    color: GRAY
  }
}));

const mapStateToProps = state => {
  return {
    checklist: state.checklist,
    booking: state.booking,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateChecklist: data => dispatch(actions.updateChecklist(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckMandatoryEquipment);
