import React from "react";
import Logo from "../../../shared/img/Logo.svg";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import { WHITE } from "../../../theme";
import { profileStyle } from "../../../shared/utils/enum";

const UserInfo = props => {
  const classes = useStyles();
  const { user } = props;

  return (
    <Grid container item className="pl-4">
      <Grid item xs={4} md={2}>
        <img className={`${classes.img} pr-1`} src={Logo} alt="Logo Yara" />
      </Grid>
      <Grid container item xs={8} md={10} className="pl-2">
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={`${classes.userName} pt-2`}>
            {user.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.userProfile}>
            {profileStyle.find(item => item.profile === user.profile).label}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  userProfile: {
    flexGrow: 1,
    color: WHITE
  },
  img: {
    width: 50,
    height: 50
  },
  userName: {
    flexGrow: 1,
    color: WHITE,
    lineHeight: 1
  }
}));

export default UserInfo;
