import React, { useState } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import { Grid, Typography, Tabs, Tab, makeStyles } from "@material-ui/core";
import useListBookingsByUserId from "../customHooks/useListBookingsByUserId";
import Loading from "../../../shared/components/Loading";
import BookingCard from "./BookingCard";
import { Layout } from "../../layout/";
import { tabsMyBookings } from "../../../shared/utils/enum";
import { messages } from "../../../shared/utils/Constants";
import { LIGHT_GRAY, PRIMARY_COLOR, SECONDARY_COLOR } from "../../../theme";
import useQuery from "../../../shared/customHooks/useQuery";
import ptBR from "date-fns/locale/pt-BR";
import { ReactComponent as DoingAnChecklitsIcon } from "../../../shared/img/DoingAnChecklitsIcon.svg";

const ListBookings = props => {
  const { userId } = props;
  const { tab } = useQuery("tab");
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(parseInt(tab) || 0);
  const { filteredBookings, loading, hasBookings } = useListBookingsByUserId(userId, activeTab);
  const hasFilteredBookings = filteredBookings.length > 0;

  const handleChangeTab = (_event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDates = (startJourney, finishJourney) => {
    const startDate = format(new Date(startJourney), "dd/MM/yyyy", { locale: ptBR });
    const finishTime = format(new Date(finishJourney), "HH:mm", { locale: ptBR });
    const startTime = format(new Date(startJourney), "HH:mm", { locale: ptBR });

    return { startDate, finishTime, startTime };
  };

  const tabValues = Object.values(tabsMyBookings);
  const selectedTab = tabValues.find(_tab => _tab.value === activeTab);
  const noBookingsMessage = messages.NO_SELECTED_BOOKINGS.replace("{0}", selectedTab.label);
  const noBookingDescription = [
    "Quando você tiver uma reserva ela aparecerá aqui",
    "Suas reservas finalizadas aparecerão aqui",
    "Quando uma reserva for cancelada ela aparecerá aqui"
  ];

  window.onbeforeunload = null;

  return (
    <Layout showHeader>
      <Grid container justify="center">
        <Grid
          container
          item
          xs={12}
          spacing={3}
          alignItems="center"
          direction="column"
          className="mt-10"
        >
          <Grid item className="mb-6">
            <Typography variant="h4" component="h2">
              Minhas Reservas
            </Typography>
          </Grid>
          {hasBookings && (
            <Grid item>
              <Tabs
                className={classes.tabs}
                value={activeTab}
                onChange={handleChangeTab}
                centered
                TabIndicatorProps={{ className: classes.indicator }}
              >
                {tabValues.map(_tab => (
                  <Tab
                    className={classes.tab}
                    key={_tab.value}
                    label={_tab.label}
                    value={_tab.value}
                  />
                ))}
              </Tabs>
            </Grid>
          )}
        </Grid>

        {filteredBookings && !loading ? (
          <Grid item xs={12} xl={10} className={`mt-12 pl-10 pr-10 ${classes.cards}`}>
            {hasFilteredBookings ? (
              filteredBookings.map(booking => {
                const dates = handleDates(booking.startJourney, booking.finishJourney);

                return (
                  <BookingCard
                    key={booking.id}
                    booking={booking}
                    activeTab={activeTab}
                    {...dates}
                  />
                );
              })
            ) : (
              <Grid container direction="column" align="center" spacing={5} className="mt-2">
                <Grid item>
                  <DoingAnChecklitsIcon />
                </Grid>
                <Grid item>
                  <Typography variant="h5" component="p" className="bold">
                    {noBookingsMessage}
                  </Typography>
                  <Typography variant="h6" className="regular">
                    {noBookingDescription[activeTab]}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid item xs={12} className="mt-12">
            <Loading loading />
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};

const useStyles = makeStyles(theme => ({
  tab: {
    "&.MuiTab-root": {
      fontWeight: "600",
      padding: "6px 16px",
      minWidth: "fit-content",
      "&.MuiTab-textColorInherit": {
        color: PRIMARY_COLOR,
        opacity: 1
      },
      "&.Mui-selected": {
        color: SECONDARY_COLOR
      }
    }
  },
  tabs: {
    "&.MuiTabs-root": {
      borderBottom: `1px solid ${LIGHT_GRAY}`
    }
  },
  indicator: {
    height: "4px"
  },
  cards: {
    [theme.breakpoints.down(600)]: {
      paddingLeft: 20,
      paddingRight: 20
    }
  }
}));

const mapStateToProps = state => {
  return {
    userId: state.user.id
  };
};

export default connect(mapStateToProps, null)(ListBookings);
