import React, { useLayoutEffect, useRef, useState } from "react";
import { Paper, Divider, Grid, makeStyles } from "@material-ui/core";
import { useOutsideEvent } from "../customHooks/useOutsideEvent";

const FloatingDropdown = props => {
  const { children, disabled, enableDividers, parentRef, className, onOutsideClick, justifyStart } =
    props;

  const dropdownRef = useRef(null);
  const [rect, setRect] = useState({});

  const classes = useStyles({ rect });

  useLayoutEffect(() => {
    if (parentRef && dropdownRef.current && justifyStart) {
      const parentRect = parentRef.getBoundingClientRect();
      const ownRect = dropdownRef.current.getBoundingClientRect();
      const marginRight = getComputedStyle(dropdownRef.current).marginRight;

      let x = parentRect.x - ownRect.width + parentRect.width;
      //margin is suffixed with 'px'
      x -= marginRight.substring(0, marginRight.length - 2);

      setRect({ x });
    }
  }, [parentRef, justifyStart, disabled]);

  useOutsideEvent(dropdownRef, "mousedown", onOutsideClick);

  return (
    !disabled && (
      <Paper
        elevation={3}
        className={`${className} ${classes.dropdown}`}
        style={{ left: rect.x }}
        ref={dropdownRef}
      >
        {Array.isArray(children) ? (
          children.map((child, index) => {
            return (
              <Grid key={index}>
                {!!child && index > 0 && enableDividers && <Divider className="divider" />}
                {child}
              </Grid>
            );
          })
        ) : (
          <Grid>{children}</Grid>
        )}
      </Paper>
    )
  );
};

const useStyles = makeStyles(() => ({
  dropdown: {
    position: "absolute",
    zIndex: 1
  }
}));

export default FloatingDropdown;
