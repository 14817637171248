import CarService from "../../services/CarService";
import UserService from "../../services/UserService";

class VehicleDetailsManager {
  async getVehicleById(vehicleId) {
    if (!vehicleId) return;

    const response = await CarService.getVehicleById(vehicleId);

    return response && response.data;
  }

  async getUserById(userId, date) {
    if (!userId) return;

    const response = await UserService.getUserById(userId, date);

    return response && response.data;
  }
}

export default new VehicleDetailsManager();
