import { useState } from "react";
import CalendarManager from "../CalendarManager";
import moment from "moment";
import { bookingStatus } from "../../../shared/utils/enum";
import { messages } from "../../../shared/utils/Constants";
import { getNextQuarterDateTime } from "../../../shared/utils/Utils";

const useCarActivation = () => {
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [openedModalActivation, setOpenedModalActivation] = useState(false);
  const [titleModalActivation, setTitleModalActivation] = useState("");
  const [subtitleModalActivation, setSubtitleModalActivation] = useState("");
  const [selectedCarId, setSelectedCarId] = useState(null);
  const [inactivityStart, setInactivityStart] = useState(null);
  const [inactivityFinish, setInactivityFinish] = useState(null);
  const [isReactivation, setIsReactivation] = useState(false);

  const handleInactivation = async (carId, selectedDate) => {
    const actualDate = new Date();

    const start = new Date(selectedDate);
    const finish = new Date(selectedDate);

    if (start > actualDate) start.setHours(0, 0, 0);
    else {
      const actualDateOnQuarter = getNextQuarterDateTime(actualDate);
      start.setHours(actualDateOnQuarter.getHours(), actualDateOnQuarter.getMinutes(), actualDateOnQuarter.getSeconds());
    }

    finish.setHours(23, 59, 0, 0);

    const listFilteredBookings = await CalendarManager.listBookingsFiltered(
      carId,
      start,
      finish,
      null
    );

    const bookingInProgress = listFilteredBookings.find(
      booking =>
        booking.historyStatus[booking.historyStatus.length - 1].status.id ===
        bookingStatus.IN_PROGRESS
    );

    if (bookingInProgress) {
      setSubtitleModalActivation(messages.INACTIVATE_CAR_WITH_BOOKING_IN_PROGRESS);
      setInactivityStart(bookingInProgress.finishJourney);
    } else {
      setSubtitleModalActivation(messages.INACTIVATE_CAR);
      setInactivityStart(start);
    }

    setTitleModalActivation(messages.INACTIVATE_CAR_TITLE);
    setInactivityFinish(finish);
  }

  const handleReactivation = (selectedDate) => {
    let finish = getNextQuarterDateTime(new Date());

    if (moment(selectedDate).isAfter(finish, "day")) {
      setSubtitleModalActivation(messages.REACTIVATE_CAR_FUTURE_DAY);
      finish = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        finish.getHours(),
        finish.getMinutes(),
        finish.getSeconds()
      );
    }
    else {
      setSubtitleModalActivation((
        <>
          {messages.REACTIVATE_CAR_TODAY} <br /><br />
          <b>{messages.REACTIVATE_CAR_DATETIME.replace("{0}", finish.toTimeString().substring(0, 5))}</b>
        </>
      ));
    }

    setTitleModalActivation(messages.REACTIVATE_CAR_TITLE);
    setInactivityFinish(finish);
  }

  const handleChangeActivation = async (isInactive, carId, selectedDate) => {
    setLoadingContent(true);
    setOpenedModalActivation(true);

    if (isInactive) {
      setIsReactivation(true);
      handleReactivation(selectedDate);
    }
    else {
      setIsReactivation(false);
      await handleInactivation(carId, selectedDate);
    }

    setSelectedCarId(carId);
    setLoadingContent(false);
  };

  const handleCloseModalActivation = () => {
    setOpenedModalActivation(false);
    setSubtitleModalActivation("");
    setSelectedCarId(null);
    setInactivityStart(null);
    setInactivityFinish(null);
  };

  const handleConfirmInactivateCar = async callbackReloadData => {
    setLoadingConfirmation(true);

    const data = await CalendarManager.inactivateCar(
      selectedCarId,
      inactivityStart,
      inactivityFinish
    );

    if (data) callbackReloadData();

    handleCloseModalActivation();
    setLoadingConfirmation(false);
  };

  const handleConfirmReactivateCar = async callbackReloadData => {
    setLoadingConfirmation(true);

    const data = await CalendarManager.reactivateCar(selectedCarId, inactivityFinish);

    if (data) callbackReloadData();

    handleCloseModalActivation();
    setLoadingConfirmation(false);
  };

  const confirmActivationHandler = callbackReloadData => {
    if (isReactivation) {
      handleConfirmReactivateCar(callbackReloadData);
    }
    else {
      handleConfirmInactivateCar(callbackReloadData);
    }
  }

  return {
    handleChangeActivation,
    confirmActivationHandler,
    handleCloseModalActivation,
    openedModalActivation,
    subtitleModalActivation,
    titleModalActivation,
    loadingConfirmation,
    loadingContent
  };
};

export default useCarActivation;
