import axios from "../axiosInstance";

class FleetService {
  async listFleetByPoleId(poleId) {
    return axios.get(`fleets`, { params: { poleId } });
  }

  async listFleetTypes() {
    return axios.get(`fleets/types`);
  }
}

export default new FleetService();
