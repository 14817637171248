import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow, TableCell, Typography } from "@material-ui/core";
import CardEventsCalendar from "./CardEventsCalendar";
import { formatarHorarios, obterColSpanCelulaVazia } from "../../utils/AppointmentsCalendarUtils";
import { GRAY } from "../../../theme";

const CalendarEvents = props => {
  const classes = useStyles();
  const { horarioReferencia, agenda, handleExcluir, handleAddSchedule } = props;

  return (
    <TableRow>
      {(horarioReferencia.minutes() > 0 || horarioReferencia.minutes() < 60) && (
        <TableCell
          rowSpan={1}
          align="center"
          style={{ padding: "5px 3px" }}
          className={classes.horarios}
        >
          <Typography variant="overline" className={classes.hora}>
            {horarioReferencia.format("HH:mm")}
          </Typography>
        </TableCell>
      )}
      {agenda.map(a => {
        let colunas = a.colunas.filter(
          i => i.horarioReferencia === horarioReferencia.format("HH:mm")
        );
        if (colunas && colunas.length > 0) {
          colunas = formatarHorarios(colunas, a);

          return colunas.map((c, i) => (
            <React.Fragment key={i}>
              <TableCell
                key={i}
                rowSpan={c.rowSpan}
                colSpan={c.colSpan}
                align="center"
                style={{
                  padding: "3px 3px",
                  wordBreak: "break-all"
                }}
              >
                <CardEventsCalendar
                  evento={c}
                  handleExcluir={() => handleExcluir(c.idBloqueio)}
                  exclusaoPermitida={c.evento.exclusaoPermitida}
                />
              </TableCell>
              {obterColSpanCelulaVazia(horarioReferencia, a.colunas) > 0 && (
                <TableCell colSpan="1" />
              )}
            </React.Fragment>
          ));
        }
        return (
          <TableCell
            key={`emptycell-${horarioReferencia}`}
            onClick={() => handleAddSchedule(horarioReferencia)}
          ></TableCell>
        );
      })}
    </TableRow>
  );
};

const useStyles = makeStyles({
  horarios: {
    verticalAlign: "baseline",
    height: "37px"
  },
  hora: {
    color: GRAY
  }
});

export default CalendarEvents;
