import React from "react";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Divider,
  Box,
  makeStyles
} from "@material-ui/core";
import { WHITE, BRIGHT_BLUE } from "../../../theme";

const SideBarItem = props => {
  const { handleClick, icon, name, divider, isSubMenuItem } = props;

  const classes = useStyles();

  return (
    <Box className={isSubMenuItem && classes.submenuItem}>
      <ListItem button onClick={() => handleClick()} className="pl-6">
        <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="subtitle1" component="div" className={classes.typographyColorWhite}>
              {name}
            </Typography>
          }
        />
      </ListItem>
      {divider && <Divider className={`${classes.divider} ${isSubMenuItem && "ml-5 mr-5"}`} />}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: WHITE
  },
  typographyColorWhite: {
    color: WHITE
  },
  listItemIcon: {
    minWidth: theme.spacing(5)
  },
  submenuItem: {
    backgroundColor: BRIGHT_BLUE
  }
}));

export default SideBarItem;
