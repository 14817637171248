import React from "react";
import { hideNotification } from "../../../store/NotificationStore";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import ToastContent from "./ToastContent";
import { toastTypes } from "../../../shared/utils/enum";

const AlertMessage = props => {
  const {
    hide,
    notification: { type, message, showNotification }
  } = props;

  const classes = useStyles({
    primaryColor: toastTypes[type].primaryColor,
    secondaryColor: toastTypes[type].secondaryColor
  });

  const handleClose = () => {
    hide();
  };

  return (
    <Snackbar
      className={classes.toast}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={showNotification}
      message={<ToastContent message={message} icon={toastTypes[type].icon} />}
      autoHideDuration={7000}
      onClose={handleClose}
      ClickAwayListenerProps={{
        mouseEvent: false
      }}
      action={[
        <IconButton key="close" aria-label="Close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      ]}
    />
  );
};

const useStyles = makeStyles(() => ({
  toast: ({ primaryColor, secondaryColor }) => ({
    "& .MuiSnackbarContent-root": {
      backgroundColor: primaryColor,
      borderRadius: 8,
      border: "1px solid" + secondaryColor
    }
  })
}));

const mapStateToProps = state => {
  return {
    notification: state.notification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hide: () => dispatch(hideNotification())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage);
