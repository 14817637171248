import React, { useEffect, useState, useCallback } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  DialogActions
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import createValidationSchema from "./createValidationSchema";
import { GRAY, LIGHTER_GRAY, LIGHT_GRAY, PRIMARY_COLOR, WHITE } from "../../../../theme";
import ModalContent from "./ModalContent";
import ActionSegment from "./ActionSegment";
import useCreateOrUpdateVehicle, { vehicleModal } from "../../customHooks/useCreateOrUpdateVehicle";
import Loading from "../../../../shared/components/Loading";
import { adUserSize, carLicensePlateSize, messages } from "../../../../shared/utils/Constants";
import ConfirmationModal from "../../../../shared/components/ConfirmationModal";
import { carShouldHaveDriver } from "../../../../shared/utils/Utils";
import CloseIcon from "../../../../shared/components/CloseIcon";

const VehicleRegistrationModal = props => {
  const {
    isOpen,
    handleClose,
    editableVehicleId,
    setEditableVehicleId,
    additionalOptionsData,
    isAdmin
  } = props;

  const classes = useStyles();
  const defaultValues = vehicleModal.defaultValues;
  const isEdition = !!editableVehicleId;

  const [openedCancelModal, setOpenedCancelModal] = useState(false);
  const [openedConfirmationModal, setOpenedConfirmationModal] = useState(false);
  const [confirmationModalTitle, setConfirmationModalTitle] = useState(
    messages.SUCCESS_CREATED_VEHICLE
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
    setValue,
    setError,
    trigger
  } = useForm({
    resolver: yupResolver(createValidationSchema(isEdition)),
    mode: "onChange",
    defaultValues: defaultValues
  });

  const {
    optionsData,
    loadInitialData,
    listCarModels,
    listStatusDetails,
    loadingInitialData,
    carModelIsLoading,
    statusDetailsIsLoading,
    driverData,
    driverDataIsLoading,
    loadDriverData,
    resetDriverData,
    createOrUpdateVehicle,
    registrationIsLoading,
    getVehicleDataById,
    setSchemaValues,
    isDriver,
    setIsDriver,
    getVehicleByLicensePlate,
    licensePlateIsLoading
  } = useCreateOrUpdateVehicle(setValue, editableVehicleId, setError);

  const [values, setValues] = useState(defaultValues);

  useEffect(() => {
    async function getOldVehicleData() {
      const callback = (vechicle, driver) => {
        setSchemaValues(vechicle, driver);
      };

      await getVehicleDataById(callback);
    }

    if (isOpen) {
      loadInitialData();
      setConfirmationModalTitle(messages.SUCCESS_CREATED_VEHICLE);

      if (isEdition) {
        getOldVehicleData();
        setConfirmationModalTitle(messages.SUCCESS_UPDATED_VEHICLE);
      }
    } else {
      reset();
      resetDriverData();
      setEditableVehicleId(null);
    }
  }, [
    isOpen,
    isEdition,
    loadInitialData,
    reset,
    resetDriverData,
    getVehicleDataById,
    setSchemaValues,
    setEditableVehicleId
  ]);

  const handleChangeDriverAdUser = useCallback(
    async driverAdUser => {
      if (!driverAdUser.length) resetDriverData();

      if (driverAdUser.length === adUserSize) {
        await loadDriverData(driverAdUser);
      }
    },
    [loadDriverData, resetDriverData]
  );

  const handleChangeCarLicensePlate = useCallback(
    async carLicensePlate => {
      if (
        carLicensePlate.length === carLicensePlateSize &&
        values.oldLicensePlate !== carLicensePlate
      ) {
        await getVehicleByLicensePlate(carLicensePlate);
      }
    },
    [getVehicleByLicensePlate, values.oldLicensePlate]
  );

  useEffect(() => {
    const subscription = watch(async (updatedValues, { name, type }) => {
      setValues(updatedValues);

      if (type !== "change") return;

      if (name === "brandId") {
        updatedValues.brandId && listCarModels(updatedValues.brandId);
        setValue("carModelId", defaultValues.carModelId);
      } else if (name === "statusId") {
        updatedValues.statusId && listStatusDetails(updatedValues.statusId);
        setValue("statusCost", defaultValues.statusCost);
        setValue("statusDetailsId", defaultValues.statusDetailsId);
        setValue("statusReason", defaultValues.statusReason);
        setValue("statusStartDate", defaultValues.statusStartDate);
      } else if (name === "driverAdUser") {
        handleChangeDriverAdUser(updatedValues.driverAdUser);
      } else if (name === "fleetTypeId") {
        setValue("carTypeId", defaultValues.carTypeId);
        setValue("siteId", defaultValues.siteId);
        setIsDriver(carShouldHaveDriver(updatedValues.fleetTypeId));
      } else if (name === "licensePlate") {
        handleChangeCarLicensePlate(updatedValues.licensePlate);
      }
    });

    return () => subscription.unsubscribe();
  }, [
    watch,
    setValue,
    defaultValues,
    listCarModels,
    listStatusDetails,
    handleChangeDriverAdUser,
    handleChangeCarLicensePlate,
    setIsDriver
  ]);

  const submitForm = data => {
    const callback = () => {
      reset();
      resetDriverData();
      handleClose();
      setOpenedConfirmationModal(true);
    };
    createOrUpdateVehicle(data, driverData.id, callback);
  };

  const handleCancelRegistration = () => {
    handleClose();
    setOpenedCancelModal(false);
  };

  const handleContinue = () => {
    setValue("isFinalStep", true);

    trigger();
  };

  const handleReturn = () => {
    setValue("isFinalStep", false);

    trigger();
  };

  const hasLicensePlateError = !!errors?.licensePlate;

  const disableContinueButton = () => {
    if (isEdition && !isDirty) return false;
    else return !isValid;
  };

  return (
    <>
      <form aria-label="Formulário cadastro veículo" noValidate onSubmit={handleSubmit(submitForm)}>
        <Dialog
          fullWidth
          open={isOpen}
          onClose={handleClose}
          className={classes.modal}
          classes={{
            paper: classes.paperModal
          }}
        >
          <CloseIcon color={WHITE} onClick={handleClose} />

          <DialogTitle
            disableTypography
            onClose={() => handleClose()}
            className={`${classes.titleContainer} pb-6 pt-6`}
          >
            <Typography className={classes.modalTitle} align="center" variant="h6">
              {isEdition ? messages.UPDATE_VEHICLE : messages.REGISTER_VEHICLE}
            </Typography>
          </DialogTitle>

          {!loadingInitialData ? (
            <>
              <DialogContent className="pt-6">
                <ModalContent
                  control={control}
                  errors={errors}
                  values={values}
                  {...optionsData}
                  {...additionalOptionsData}
                  carModelIsLoading={carModelIsLoading}
                  statusDetailsIsLoading={statusDetailsIsLoading}
                  licensePlateIsLoading={licensePlateIsLoading}
                  isEdition={isEdition}
                  driverData={driverData}
                  driverDataIsLoading={driverDataIsLoading}
                  isDriver={isDriver}
                  isAdmin={isAdmin}
                />
              </DialogContent>
              <DialogActions>
                <ActionSegment
                  handleCancel={() => setOpenedCancelModal(true)}
                  handleContinue={handleContinue}
                  handleReturn={handleReturn}
                  handleSubmit={handleSubmit(submitForm)}
                  loadingSubmit={registrationIsLoading}
                  disableContinue={disableContinueButton()}
                  disableSubmit={!isValid || hasLicensePlateError || licensePlateIsLoading}
                  isFinalStep={values.isFinalStep}
                  isEdition={isEdition}
                />
              </DialogActions>
            </>
          ) : (
            <Loading loading />
          )}
        </Dialog>
      </form>

      <ConfirmationModal
        shouldCancel
        openedModal={openedCancelModal}
        title={
          isEdition
            ? messages.CONFIRMATION_CANCEL_EDIT_TITLE
            : messages.CONFIRMATION_CANCEL_CREATE_TITLE
        }
        subtitle={
          isEdition
            ? messages.CONFIRMATION_CANCEL_EDIT_SUBTITLE
            : messages.CONFIRMATION_CANCEL_CREATE_SUBTITLE
        }
        handleOpenClose={() => setOpenedCancelModal(false)}
        secondaryAction={() => setOpenedCancelModal(false)}
        primaryAction={handleCancelRegistration}
        secondaryButtonText={messages.NO_BUTTON_MESSAGE}
        primaryButtonText={messages.YES_BUTTON_MESSAGE}
      />

      <ConfirmationModal
        success
        openedModal={openedConfirmationModal}
        title={confirmationModalTitle}
        handleOpenClose={() => setOpenedConfirmationModal(false)}
        primaryAction={() => setOpenedConfirmationModal(false)}
        primaryButtonText={messages.OK_BUTTON_MESSAGE}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    overflow: "hidden",
    width: "100%",
    maxHeight: "100%",

    "& .MuiDialogActions-root": {
      boxShadow: `0px -1px 8px ${LIGHT_GRAY}`
    },

    "& .MuiDialogContent-root": {
      backgroundColor: LIGHTER_GRAY
    },

    "& .MuiInputBase-root": {
      backgroundColor: WHITE
    },

    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: LIGHTER_GRAY
    },

    "& .MuiInputBase-input.Mui-disabled": {
      color: GRAY
    }
  },
  paperModal: {
    backgroundColor: WHITE,
    maxWidth: "75%"
  },
  titleContainer: {
    backgroundColor: PRIMARY_COLOR,
    textAlign: "center"
  },
  modalTitle: {
    color: WHITE
  },
  caption: {
    color: GRAY
  }
}));

export default VehicleRegistrationModal;
