import React from "react";
import { Grid, Button, makeStyles } from "@material-ui/core";
import { DARK_GREEN, WHITE } from "../../../theme";
import Loading from "../../../shared/components/Loading";

const StepButtons = props => {
  const { loading, onPreviousStep, onNextStep, disabled, isLastStep, confirmText } = props;

  const confirmButtonText = confirmText ? confirmText.toUpperCase() : "CONFIRMAR";

  const classes = UseStyles();
  return (
    <Grid container className="mb-2 mt-6" justify="center" spacing={2}>
      <Grid item xs={6}>
        <Button variant="outlined" color="secondary" onClick={onPreviousStep} fullWidth>
          VOLTAR
        </Button>
      </Grid>
      <Grid item xs={6}>
        {isLastStep && (
          <Button
            variant="contained"
            color="primary"
            onClick={onNextStep}
            fullWidth
            disabled={disabled}
          >
            {loading ? (
              <Loading loading size={20} className={classes.loadingPosition} />
            ) : (
              confirmButtonText
            )}
          </Button>
        )}
        {!isLastStep && (
          <Button
            variant="contained"
            color="primary"
            onClick={onNextStep}
            disabled={disabled}
            fullWidth
          >
            {loading ? (
              <Loading loading size={20} className={classes.loadingPosition} />
            ) : (
              "CONTINUAR"
            )}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const UseStyles = makeStyles(theme => ({
  buttonConfirm: {
    backgroundColor: DARK_GREEN,
    color: WHITE
  },
  loadingPosition: {
    margin: theme.spacing(2),
    position: "relative"
  }
}));

export default StepButtons;
