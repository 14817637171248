import JwtDecode from 'jwt-decode';
import AuthenticationService from "../../services/AuthenticationService";
import { 
  storageKeyToken,
  storageKeyExpiresOn,
  storageKeyBffToken } from "../../shared/utils/Constants"

class LoginManager {
  async authenticate() {
    const token = sessionStorage.getItem(storageKeyToken);
    return AuthenticationService.getAuthenticate(token).then(response => {
      if(response && response.data && !response.data.requestError){
        const { exp, id, ad, name, email, idProfile, profile, idPole } = JwtDecode(response.data);

        const user = {
          id: parseInt(id),
          adUser: ad,
          name: name,
          email: email,
          profile: {
            id: parseInt(idProfile),
            profileName: profile
          },
          pole: {
            id: parseInt(idPole)
          },
          requestError: false
        };
        
        sessionStorage.setItem(storageKeyExpiresOn, new Date(exp * 1000));
        sessionStorage.setItem(storageKeyBffToken, response.data);
  
        return user;
      }
      return response?.data;
    })
  }
}

export default new LoginManager();
