import React, { useEffect, useState, useCallback } from "react";
import { WHITE, GRAY, LIGHT_GRAY, MID_GREEN, RED } from "../../theme";
import { Grid, makeStyles, Typography, Divider } from "@material-ui/core";
import { Frown, Smile } from "react-feather";
import { checklistTypeAnswer } from "../utils/enum";
import CustomTextField from "./CustomTextField";

const ChecklistQuestion = props => {
  const {
    updateChecklistQuestionResponses,
    initialSelectedStatus,
    initialObservations,
    checklistQuestion,
    fullWidth,
    critical,
    checkout,
    padding,
    divide,
    step
  } = props;

  const [selectedStatus, setSelectedStatus] = useState(initialSelectedStatus);
  const [observations, setObservations] = useState(initialObservations);

  const isCommentMandatory = !!critical && selectedStatus === checklistTypeAnswer.notOk;

  const selectSmile = () => {
    if (!selectedStatus || selectedStatus === checklistTypeAnswer.notOk)
      setSelectedStatus(checklistTypeAnswer.ok);
    else setSelectedStatus();
  };

  const selectFrown = () => {
    if (!selectedStatus || selectedStatus === checklistTypeAnswer.ok)
      setSelectedStatus(checklistTypeAnswer.notOk);
    else setSelectedStatus();
  };

  const updateQuestionResponse = useCallback(
    newValue => {
      if (step === checklistQuestion.step) {
        const newChecklistQuestionResponse = {
          questionId: checklistQuestion.questionId,
          checklistTypeAnswerId: newValue.selectedStatus,
          observations: newValue.observations
        };

        updateChecklistQuestionResponses(newChecklistQuestionResponse);
      }
    },
    [updateChecklistQuestionResponses, checklistQuestion, step]
  );

  useEffect(() => {
    updateQuestionResponse({ selectedStatus: selectedStatus, observations: observations });
  }, [selectedStatus, observations, updateQuestionResponse]);

  const classes = useStyles({
    highlight: isCommentMandatory && !observations,
    iconsDisplay: checkout ? "left" : "right"
  });

  return (
    <Grid container>
      <Grid item xs={12} className="mb-2">
        <Typography className={classes.text} variant="subtitle1">
          {checklistQuestion.questionLabel}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={`${!checkout && classes.backgroundItem} ${padding && "pt-2 pb-2 pl-4 pr-4"}`}
      >
        {!checkout && (
          <Grid item xs={fullWidth ? 12 : 10} align="left">
            <CustomTextField
              variant="standard"
              value={observations}
              error={isCommentMandatory && !observations}
              onChange={e => setObservations(e.target.value)}
              placeholder={
                isCommentMandatory
                  ? "É obrigatório o preenchimento deste campo."
                  : "Incluir comentários"
              }
              inputProps={
                isCommentMandatory
                  ? {
                      classes: {
                        input: classes.error
                      }
                    }
                  : {}
              }
              InputProps={{
                className: classes.inputDisplay
              }}
            />
          </Grid>
        )}
        <Grid
          item
          xs={fullWidth ? 12 : 2}
          className={`${classes.iconsDisplay} ${fullWidth ? "pt-2" : ""}`}
        >
          <Frown
            className={`mr-4 ${
              selectedStatus === checklistTypeAnswer.notOk ? classes.selectedFrown : classes.icon
            }`}
            onClick={selectFrown}
            aria-label="Triste"
            role="button"
          />
          <Smile
            className={
              selectedStatus === checklistTypeAnswer.ok ? classes.selectedSmile : classes.icon
            }
            onClick={selectSmile}
            aria-label="Feliz"
            role="button"
          />
        </Grid>
        {divide && (
          <Grid item xs={12}>
            <Divider className="divider-2" />
          </Grid>
        )}
      </Grid>

      {checkout && (
        <Grid item container xs={12} align="left" className="mt-4">
          <CustomTextField
            rows={6}
            multiline
            maxLength={255}
            value={observations}
            error={isCommentMandatory && !observations}
            placeholder={
              isCommentMandatory
                ? "É obrigatório o preenchimento deste campo."
                : "Incluir comentários"
            }
            inputProps={
              isCommentMandatory
                ? {
                    classes: {
                      input: classes.error
                    }
                  }
                : {}
            }
            InputProps={{
              className: classes.inputDisplay
            }}
            onChange={e => setObservations(e.target.value)}
          />
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  backgroundItem: ({ highlight }) => ({
    alignItems: "center",
    border: "1px solid",
    borderRadius: "4px",
    borderColor: highlight ? RED : LIGHT_GRAY,
    "&.MuiGrid-container": {
      backgroundColor: WHITE
    }
  }),
  text: {
    color: GRAY
  },
  icon: {
    color: GRAY,
    "&:hover": {
      cursor: "pointer"
    }
  },
  selectedFrown: {
    color: RED,
    "&:hover": {
      cursor: "pointer"
    }
  },
  selectedSmile: {
    color: MID_GREEN,
    "&:hover": {
      cursor: "pointer"
    }
  },
  iconsDisplay: ({ iconsDisplay }) => ({
    display: "flex",
    justifyContent: iconsDisplay
  }),
  error: {
    "&::placeholder": {
      color: RED
    }
  },
  inputDisplay: {
    "&.MuiInput-underline:after": {
      display: "none"
    },
    "&.MuiInput-underline:before": {
      display: "none"
    },
    fontSize: "16px"
  }
}));

export default ChecklistQuestion;
