import axios from "../axiosInstance";

class SecurityReportService {
  async listPrincipalInvolved() {
    return axios.get("securityreports/principalinvolved");
  }

  async listLocal() {
    return axios.get("securityreports/locals");
  }

  async listOccurrence(localId) {
    return axios.get(`securityreports/locals/${localId}/occurrences`);
  }

  async listSubject(localId, occurrenceId) {
    return axios.get(`securityreports/locals/${localId}/occurrences/${occurrenceId}/subjects`);
  }

  async createSecurityReport(securityReport) {
    return axios.post("securityreports", securityReport);
  }

  async getReportById(reportId) {
    return axios.get(`securityreports/${reportId}`);
  }

  async uploadReportImages(reportId, reportImages) {
    return axios.post(`securityreports/${reportId}/files`, reportImages);
  }
}

export default new SecurityReportService();
