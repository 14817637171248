import React from "react";
import ClearCache from "react-clear-cache";

function UpdateVersion() {
  const executeAction = emptyCacheStorage => {
    return emptyCacheStorage();
  };

  return (
    <ClearCache auto={true}>
      {({ isLatestVersion, emptyCacheStorage }) => (
        <div style={{ display: "none" }}>
          {!isLatestVersion && executeAction(emptyCacheStorage)}
        </div>
      )}
    </ClearCache>
  );
}

export default UpdateVersion;
