// Types
const UPDATE_NAVIGATION_ROUTE = "router/UPDATE_NAVIGATION_ROUTE";
const DELETE_NAVIGATION_ROUTE = "router/DELETE_NAVIGATION_ROUTE";

// Reducer
export const INITIAL_STATE = {
  route: null
};

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_NAVIGATION_ROUTE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case DELETE_NAVIGATION_ROUTE: {
      return { ...INITIAL_STATE };
    }
    default: {
      return state;
    }
  }
}

// Action creators

export const updateNavigationRoute = route => {
  return {
    type: UPDATE_NAVIGATION_ROUTE,
    payload: route
  };
};

export const deleteNavigationRoute = () => {
  return {
    type: DELETE_NAVIGATION_ROUTE
  };
};
