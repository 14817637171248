import React from "react";
import { connect } from "react-redux";
import {
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  InputAdornment,
  CircularProgress
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import CustomTextField from "../../../../shared/components/CustomTextField";
import { adUserExample, adUserSize } from "../../../../shared/utils/Constants";
import { userManagementProfiles, accessType, selectMenuProps } from "../../../../shared/utils/enum";
import { MaskedInputFactory } from "../../../../shared/components/MaskedInputFactory";

const GeneralSegment = props => {
  const { control, errors, values, isEdition, userData, userDataIsLoading, userProfile } = props;

  const listEnabledProfiles = () => {
    if (userProfile === accessType.siteFacilities)
        return [userManagementProfiles.driver ];
    if (userProfile === accessType.teamChecklist)
        return [userManagementProfiles.teamCList];
    else if (userProfile === accessType.centralFacilities)
      return Object.values(userManagementProfiles).filter(
        p => p.label !== userManagementProfiles.adminFac.label
      );
    else if (userProfile === accessType.adminFacilities)
      return Object.values(userManagementProfiles);
    else return [];
  };

  return (
    <Grid item container>
      <Grid item container xs={12} className="pb-8">
        <Grid item xs={6} className="pr-5">
          <Controller
            name="adUser"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  autoComplete="off"
                  fullWidth
                  {...field}
                  required
                  disabled={isEdition}
                  label="ID Yara"
                  error={errors && !!errors[field.name]}
                  helperText={errors && errors[field.name] ? errors[field.name].message : null}
                  placeholder={adUserExample}
                  type="text"
                  variant="outlined"
                  inputProps={{
                    "aria-label": "Digite o ID Yara do usuário",
                    maxLength: adUserSize
                  }}
                  InputProps={{
                    inputComponent: YaraIdMask,
                    endAdornment: userDataIsLoading && (
                      <InputAdornment position="end">
                        <CircularProgress size={20} className="mr-3" />
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={!!field.value ? { shrink: true } : null}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField label="Nome" value={userData?.name} disabled />
        </Grid>
      </Grid>
      <Grid item container xs={12} className="pb-8">
        <Grid item xs={6} className="pr-5">
          <CustomTextField label="Cargo" value={userData?.role?.name} disabled />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField label="E-mail" value={userData?.email} disabled />
        </Grid>
      </Grid>
      <Grid item container xs={12} className="pb-8">
        <Grid item xs={6} className="pr-5">
          <Controller
            name="profileId"
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth variant="outlined">
                  <InputLabel shrink={!!field.value}>Perfil *</InputLabel>
                  <Select
                    inputProps={{
                      "aria-label": "Selecione o perfil do usuário"
                    }}
                    label={values.profileId ? "Perfil *" : undefined}
                    {...field}
                    variant="outlined"
                    MenuProps={selectMenuProps}
                  >
                    {listEnabledProfiles().map(profile => (
                      <MenuItem key={profile.value} value={profile.value}>
                        {profile.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField label="Polo" value={userData?.pole?.name} disabled />
        </Grid>
      </Grid>
      <Grid item container xs={12} className="pb-8">
        <Grid item xs={4} className="pr-5">
          <CustomTextField label="Centro de Custo" value={userData?.costCenter?.name} disabled />
        </Grid>
        <Grid item xs={4} className="pr-5">
          <CustomTextField
            label="Região/Diretoria"
            value={userData?.management?.regional?.name}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField label="Gerência" value={userData?.management?.name} disabled />
        </Grid>
      </Grid>
    </Grid>
  );
};

const YaraIdMask = MaskedInputFactory({
  id: "yaraIdMask",
  mask: [/[acAC]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
});

const mapStateToProps = state => {
  return {
    userProfile: state.user.profile
  };
};

export default connect(mapStateToProps, null)(GeneralSegment);
