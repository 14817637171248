import React from "react";
import { CheckCircle } from "react-feather";
import { SECONDARY_COLOR } from "../../../theme";

const HistoryCheckIcon = ({ isEdited, value, isCreation }) => {
  if (!isEdited || value == null || isCreation) return null;

  return (
    <CheckCircle
      size={16}
      color={SECONDARY_COLOR}
      className="ml-2"
      aria-label="Campo editado"
    />
  );
};

export default HistoryCheckIcon;