import React, { useCallback, useEffect, useState } from "react";
import { Layout } from "../../layout/";
import VehicleDetailsManager from "../VehicleDetailsManager";
import Loading from "../../../shared/components/Loading";
import { Grid, Typography, IconButton, Paper, makeStyles, Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ChevronLeft } from "react-feather";
import { PRIMARY_COLOR } from "../../../theme";
import { carShouldHaveDriver } from "../../../shared/utils/Utils";
import VehicleMainDetails from "./VehicleMainDetails";
import VehicleContractDetails from "./VehicleContractDetails";
import UserDetailsContent from "../../../shared/components/Details/UserDetailsContent";
import VehicleDetailsHeader from "../../../shared/components/Details/VehicleDetailsHeader";

const VehicleDetails = props => {
  const {
    location: {
      state: { vehicleId }
    }
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const [vehicleData, setVehicleData] = useState();
  const [driverData, setDriverData] = useState();
  const [vehicleDataIsLoading, setVehicleDataIsLoading] = useState(false);

  const getVehicleDetails = useCallback(async id => {
    setVehicleDataIsLoading(true);
    const vehicle = await VehicleDetailsManager.getVehicleById(id);
    if (vehicle) {
      const driver = await VehicleDetailsManager.getUserById(
        vehicle.privateCar?.user?.id,
        vehicle.privateCar?.endValidity
      );
      setDriverData(driver);
    }

    setVehicleData(vehicle);
    setVehicleDataIsLoading(false);
  }, []);

  useEffect(() => {
    getVehicleDetails(vehicleId);
  }, [vehicleId, getVehicleDetails]);

  return (
    <Layout showHeader>
      <Grid item container className="mt-5 mr-8" alignItems="center">
        <IconButton onClick={() => history.goBack()} className="ml-2 mr-2 mt-4" aria-label="Voltar">
          <ChevronLeft size={32} color={PRIMARY_COLOR} />
        </IconButton>
        <Typography variant="h4" component="h2" className="mt-3">
          Detalhes do Veículo
        </Typography>
      </Grid>
      <Grid item container xs={12} className="mt-5 mb-5">
        {vehicleDataIsLoading ? (
          <Loading loading />
        ) : (
          <Paper elevation={3} className={`mr-8 ml-8 ${classes.paper}`}>
            {vehicleData && (
              <Grid item container xs={12} className="mt-8 mb-8">
                <Grid container className="ml-8 mr-8">
                  <VehicleDetailsHeader vehicleData={vehicleData} />
                </Grid>
                <Grid item container xs={12} className="mt-6 mb-6 ml-8 mr-8">
                  <UserDetailsContent
                    userData={driverData}
                    isActiveDriver={
                      !vehicleData.privateCar?.endValidity && vehicleData.privateCar?.startValidity
                    }
                    startValidity={vehicleData.privateCar?.startValidity}
                    isDriver={carShouldHaveDriver(vehicleData.carInformation.fleet?.fleetType?.id)}
                  />
                  <Grid item xs={12} className="mt-6">
                    <Divider className="divider" />
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <VehicleMainDetails mainData={vehicleData} />
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                  <VehicleContractDetails contractData={vehicleData.carContract} />
                </Grid>
                <Grid item xs={12} className="mt-6 ml-8 mr-8">
                  <Divider className="divider" />
                </Grid>
              </Grid>
            )}
          </Paper>
        )}
      </Grid>
    </Layout>
  );
};

const useStyles = makeStyles(() => ({
  paper: {
    width: "100%"
  }
}));

export default VehicleDetails;
