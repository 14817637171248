import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Layout } from "../../layout/";
import { Grid, Typography, Button, makeStyles } from "@material-ui/core";
import { Download } from "react-feather";
import CustomTable from "../../../shared/components/CustomTable/CustomTable";
import FleetContentTableRow from "./FleetContentTableRow";
import FleetManagementManager from "../FleetManagementManager";
import SharedManager from "../../../shared/methods/SharedManager";
import { messages } from "../../../shared/utils/Constants";
import { userManagementProfiles } from "../../../shared/utils/enum";
import VehicleRegistrationModal from "./vehicleRegistration/VehicleRegistrationModal";
import Loading from "../../../shared/components/Loading";
import { useHistory } from "react-router";
import CarFilters from "./CarFilters";
import SearchTextField from "../../../shared/components/SearchTextField";
import { updateManagementPage } from "../../../store/ManagementPageStore";
import usePageState from "../../../shared/customHooks/usePageState";
import TooltipItem from "../../../shared/components/TooltipItem";
import { LIGHT_GRAY } from "../../../theme";
import ImportFileModal from "../../../shared/components/ImportFile/ImportFileModal";

const FleetManagement = props => {
  const {
    location: { pathname },
    initialPageState: {
      pathname: storedPathname,
      initialSearch,
      filters: { initialFleetTypeList, initialProviderList, initialStatusList }
    },
    updatePageState,
    userProfile
  } = props;

  const history = useHistory();
  const classes = useStyles();

  const shouldLoadState = storedPathname === pathname;

  const [fleets, setFleets] = useState([]);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [hasLoadedFilters, setHasLoadedFilters] = useState(false);
  const [registrationModalVisibility, setRegistrationModalVisibility] = useState(false);
  const [importContractModalVisibility, setImportContractModalVisibility] = useState(false);
  const [editableVehicleId, setEditableVehicleId] = useState(null);
  const [carsReportIsLoading, setCarsReportIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(shouldLoadState ? initialSearch : "");
  const [filtersData, setFiltersData] = useState({
    fleetTypeList: shouldLoadState ? initialFleetTypeList : [],
    providerList: shouldLoadState ? initialProviderList : [],
    statusList: shouldLoadState ? initialStatusList : []
  });

  const listFleets = useCallback(async () => {
    setLoadingCounter(counter => counter + 1);
    const result = await SharedManager.listFleets(searchValue, filtersData);

    if (result) setFleets(result);

    updatePageState({
      initialSearch: searchValue,
      filters: {
        initialFleetTypeList: filtersData.fleetTypeList,
        initialProviderList: filtersData.providerList,
        initialStatusList: filtersData.statusList
      }
    });
    setLoadingCounter(counter => counter - 1);
  }, [searchValue, filtersData, updatePageState]);

  const loadFilters = useCallback(async () => {
    if (!initialFleetTypeList && !initialProviderList && !initialStatusList) {
      const fleetTypeList = await SharedManager.listFleetTypes();
      const providerList = await SharedManager.listProviders();
      const statusList = await FleetManagementManager.listStatus();

      const filters = {
        fleetTypeList: fleetTypeList?.map(item => ({ ...item, isSelected: false })),
        providerList: providerList?.map(item => ({ ...item, isSelected: false })),
        statusList: statusList?.map(item => ({ ...item, isSelected: false }))
      };

      setFiltersData(filters);
    }
    setHasLoadedFilters(true);
  }, [initialFleetTypeList, initialProviderList, initialStatusList]);

  usePageState({ pathname });

  useEffect(() => {
    if (hasLoadedFilters) listFleets();
  }, [listFleets, hasLoadedFilters]);

  useEffect(() => {
    loadFilters();
  }, [loadFilters]);

  const exportCarsReport = async () => {
    setCarsReportIsLoading(true);
    await FleetManagementManager.exportCarsReport(searchValue, filtersData);
    setCarsReportIsLoading(false);
  };

  const handleEditVehicle = async vehicleId => {
    setEditableVehicleId(vehicleId);
    setRegistrationModalVisibility(true);
  };

  const handleCloseRegistrationModal = () => {
    setRegistrationModalVisibility(false);
    listFleets();
  };

  const handleHistoryRedirect = vehicleId => {
    history.push("/vehicleHistory", { vehicleId });
  };

  const handleApplyFilters = useCallback(value => {
    setFiltersData(value);
  }, []);

  const handleDetailsRedirect = vehicleId => {
    history.push("/vehicleDetails", { vehicleId });
  };

  const handleCloseModal = () => {
    setImportContractModalVisibility(false);
  };

  const handleUploadContractValues = async file =>
    FleetManagementManager.uploadMonthlyRateSheet(file);

  const fleetHeaderTable = [
    { title: "Nome do condutor", width: "10%" },
    { title: "Placa / Fornecedor", width: "5%" },
    { title: "Tipo de Frota", width: "9%" },
    { title: "E-mail / ID", width: "9%" },
    { title: "Centro de Custo", width: "5%" },
    { title: "Status", width: "8%" },
    { title: "Ações", width: "3%" }
  ];

  return (
    <Layout showHeader>
      <Grid item container md={12} className="pl-8 pt-8 pr-8">
        <Grid item className={classes.headerRow}>
          <Typography variant="h4" className="mt-3">
            Gestão de Frotas
          </Typography>
        </Grid>
        <Grid item container className="pt-4">
          <Grid item container xs={6}>
            <Grid item xs={8} xl={6}>
              <SearchTextField
                label="Pesquise aqui"
                initialValue={searchValue}
                onChange={value => setSearchValue(value)}
              />
            </Grid>
            <Grid item className="ml-4" md={2}>
              <CarFilters filtersData={filtersData} applyFilters={handleApplyFilters} />
            </Grid>
          </Grid>
          <Grid container item xs={6} justify="flex-end">
            <Grid container item xs={12} justify="flex-end" spacing={2}>
              <Grid item xs={2} xl={2} className={`${classes.headerRow} pr-2`}>
                <TooltipItem tooltip="Relatório de carros">
                  <Button
                    fullWidth
                    color="primary"
                    onClick={exportCarsReport}
                    className={classes.exportButton}
                    disabled={carsReportIsLoading}
                  >
                    {carsReportIsLoading ? (
                      <Loading loading size={20} centered={false} color="inherit" />
                    ) : (
                      <Download size={20} />
                    )}
                  </Button>
                </TooltipItem>
              </Grid>
              <Grid item xs={5} xl={4} className={classes.headerRow}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => setImportContractModalVisibility(true)}
                >
                  IMPORTAR CONTRATO
                </Button>
              </Grid>
              <Grid item xs={5} xl={4} className={classes.headerRow}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => setRegistrationModalVisibility(!registrationModalVisibility)}
                >
                  CADASTRAR VEÍCULO
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="pl-8 pb-8 pt-8 pr-8">
        <CustomTable
          data={fleets}
          loading={loadingCounter > 0 || !hasLoadedFilters}
          noDataMessage={messages.NO_CARS}
          headerData={fleetHeaderTable}
          RowComponent={({ item }) => (
            <FleetContentTableRow
              item={item}
              handleEditData={handleEditVehicle}
              handleHistoryRedirect={handleHistoryRedirect}
              handleDetailsRedirect={handleDetailsRedirect}
            />
          )}
          optionsRowsPerPage={[5, 10, 20, 30]}
        />
      </Grid>
      <ImportFileModal
        isOpen={importContractModalVisibility}
        title="Importe a planilha do valor de contrato"
        handleClose={handleCloseModal}
        handleUploadFile={handleUploadContractValues}
      />
      <VehicleRegistrationModal
        isOpen={registrationModalVisibility}
        handleClose={handleCloseRegistrationModal}
        editableVehicleId={editableVehicleId}
        additionalOptionsData={filtersData}
        setEditableVehicleId={setEditableVehicleId}
        isAdmin={userProfile === userManagementProfiles.adminFac.name}
      />
    </Layout>
  );
};

const useStyles = makeStyles(theme => ({
  headerRow: {
    height: theme.dimensions.rowHeight
  },
  exportButton: {
    border: "solid",
    borderWidth: "1px",
    width: "56px",
    display: "flex",
    marginLeft: "auto",
    "&.Mui-disabled": {
      borderColor: LIGHT_GRAY,
      backgroundColor: "transparent"
    }
  }
}));

const mapStateToProps = state => {
  return {
    initialPageState: state.managementPage,
    userProfile: state.user.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePageState: data => dispatch(updateManagementPage(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetManagement);
