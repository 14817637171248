import axios from "../axiosInstance";

class ExportService {
  async exportChecklistsAndReports(startDate, endDate) {
    return axios.get(`exports/reportsAndChecklists`, {
      responseType: "blob",
      params: { startDate, endDate }
    });
  }
}

export default new ExportService();
