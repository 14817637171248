import { onlyNumbers, convertCurrencyStringToNumber } from "../../shared/utils/Utils";

export const formatVehicleData = (data, driverId) => {
  return {
    carInformation: {
      carModel: data.carModelId ? { id: data.carModelId } : null,
      site: data.siteId ? { id: data.siteId } : null,
      fleet: {
        fleetType: data.fleetTypeId ? { id: data.fleetTypeId } : null,
        carType: data.carTypeId ? { id: data.carTypeId } : null
      },
      year: data.year,
      chassisNumber: data.chassisNumber,
      color: data.colorId ? { id: data.colorId } : null,
      odometer: Number(onlyNumbers(data.odometer)) || null,
      fuel: data.fuelId ? { id: data.fuelId } : null,
      immobilized: Number(onlyNumbers(data.immobilized)) || null
    },
    carLicensing: {
      carLicensePlate: data.licensePlate,
      carDocument: Number(onlyNumbers(data.carDocument)) || null,
      hasFuelCard: data.hasFuelCard,
      hasTollTag: data.hasTollTag,
      isBorder: data.isBorder,
      has35PercentDiscount: data.has35PercentDiscount,
      isBulletProof: data.isBulletProof,
      carLicensingStatus: data.licenseStatusId ? { id: data.licenseStatusId } : null
    },
    carContract: {
      contractNumber: data.contractNumber,
      carProvider: data.providerId ? { id: data.providerId } : null,
      carMaintenanceProvider: data.maintenanceProviderId
        ? { id: data.maintenanceProviderId }
        : null,
      monthlyRate: convertCurrencyStringToNumber(data.contractMonthlyRate),
      startDate: data.contractStartDate,
      validity: data.contractValidity
    },
    historyCarStatus: {
      status: data.statusId ? { id: data.statusId } : null,
      statusDetails: data.statusDetailsId ? { id: data.statusDetailsId } : null,
      reason: data.statusReason,
      startDate: data.statusStartDate,
      cost: convertCurrencyStringToNumber(data.statusCost)
    },
    privateCar: driverId ? { user: { id: driverId } } : null
  };
};
