import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, Box, Grid } from "@material-ui/core";
import { ChevronLeft, Menu } from "react-feather";
import { GRAY, WHITE } from "../../../theme";
import { configureEnvironment } from "../../../config/env";
import {
  deliveryVersion,
  environment,
  formType as formTypeEnum,
  accessType
} from "../../../shared/utils/enum";
import * as bookingActions from "../../../store/BookingStore";
import * as checklistActions from "../../../store/ChecklistStore";
import SafeByChoiceSmallIcon from "../../../shared/img/SafeByChoiceSmall.png";
import { FLEET_TITLE, SAFETY_TITLE } from "../../../shared/utils/Constants";

const Header = props => {
  const {
    deleteChecklist,
    deleteBooking,
    formType,
    pathRedirect,
    openDrawer,
    setOpenDrawer,
    keepData,
    profile
  } = props;
  const classes = useStyles();

  const [redirect, setRedirect] = useState(null);

  const isChecklistForm = formType === formTypeEnum.checklist;
  const isCommercialOrExecutive = [accessType.commercial, accessType.executive].includes(profile);
  const showSafeByChoice = isChecklistForm || isCommercialOrExecutive;
  const showSafety = isCommercialOrExecutive || profile === accessType.backoffice;

  const onClickBackButton = () => {
    setRedirect(<Redirect to={pathRedirect} />);
    if (!keepData) {
      deleteBooking();
      deleteChecklist();
    }
  };

  const UATEnvironment =
    configureEnvironment().REACT_APP_ENVIRONMENT_NAME === environment.homologation;
  const DEVEnvironment =
    configureEnvironment().REACT_APP_ENVIRONMENT_NAME === environment.development;

  const getIconButton = () => {
    if (pathRedirect)
      return (
        <ChevronLeft
          size={20}
          className={`hover-icon ${classes.icons}`}
          onClick={() => onClickBackButton()}
        />
      );

    return (
      <Menu
        size={20}
        className={`hover-icon ${classes.icons}`}
        onClick={() => setOpenDrawer(!openDrawer)}
        role="button"
        aria-label="Menu hambúrguer"
      />
    );
  };

  return (
    <AppBar>
      <Toolbar>
        <Grid container item xs={12} className="pt-3">
          {getIconButton()}
          <Typography
            variant="h6"
            component="h1"
            align="center"
            className={`${classes.title} ${!isChecklistForm && "pr-6"}`}
          >
            {showSafety ? SAFETY_TITLE.pt : FLEET_TITLE.pt}
          </Typography>
        </Grid>

        <Typography
          variant="caption"
          className={`${classes.title} ${showSafeByChoice && "mr-2"} mt-3`}
        >
          {deliveryVersion[deliveryVersion.length - 1].version}
        </Typography>

        {showSafeByChoice && (
          <img src={SafeByChoiceSmallIcon} alt="Ícone SafeByChoice" className="mb-2" />
        )}
      </Toolbar>
      {(UATEnvironment || DEVEnvironment) && (
        <Box className={classes.envBar}>
          <Typography align="center" className={classes.envTitle}>
            {DEVEnvironment && "Ambiente DEV"}
            {UATEnvironment && "Ambiente UAT"}
          </Typography>
        </Box>
      )}

      {redirect}
    </AppBar>
  );
};

const useStyles = makeStyles(theme => ({
  icons: {
    display: "flex",
    margin: "auto"
  },
  title: {
    flexGrow: 1,
    color: WHITE
  },
  envBar: {
    backgroundColor: GRAY
  },
  envTitle: {
    color: WHITE,
    fontSize: theme.spacing(1.5)
  }
}));

const mapDispatchToProps = dispatch => {
  return {
    deleteChecklist: () => dispatch(checklistActions.deleteChecklist()),
    deleteBooking: () => dispatch(bookingActions.deleteBooking())
  };
};

export default connect(null, mapDispatchToProps)(Header);
