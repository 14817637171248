import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import * as actions from "../../../store/BookingStore";
import { Layout } from "../../layout/";
import { Redirect } from "react-router-dom";
import StepButtons from "./StepButtons";
import { formType } from "../../../shared/utils/enum";
import { routes } from "../../../shared/utils/Constants";
import ShortTrip from "./ShortTrip";

const SelectDate = props => {
  const { booking, updateBooking } = props;

  const [startDateTime, setStartDateTime] = useState(booking.startDateTime || null);
  const [endDateTime, setEndDateTime] = useState(booking.endDateTime || null);
  const [redirect, setRedirect] = useState();

  const handleSubmit = () => {
    updateBooking({
      ...booking,
      startDateTime,
      endDateTime,
      nextStep: routes.bookingSteps.SELECT_DETAILS.id
    });

    setRedirect(<Redirect to={routes.bookingSteps.SELECT_DETAILS.route} />);
  };

  const handlePreviousStep = () => {
    setRedirect(<Redirect to={routes.bookingSteps.SELECT_CAR_AND_TRIP.route} />);
  };

  useEffect(() => {
    !booking.nextStep && setRedirect(<Redirect to={routes.bookingSteps.SELECT_DRIVER.route} />);
  }, [booking]);

  return (
    <Layout showHeader formType={formType.booking}>
      <Grid container className="mt-8" justify="center">
        <Grid item xs={12}>
          <Grid item xs={12} className={"pl-4"}>
            <Typography variant="body1">Selecione uma data e horário</Typography>
          </Grid>
          <ShortTrip
            siteId={booking.siteId}
            carModelId={booking.carModelId}
            handleStartDateTime={setStartDateTime}
            handleEndDateTime={setEndDateTime}
            booking={booking}
          />
        </Grid>
        <Grid container item xs={12} md={5} spacing={3}>
          <Grid item xs={12}>
            <StepButtons
              onPreviousStep={handlePreviousStep}
              onNextStep={handleSubmit}
              disabled={!startDateTime || !endDateTime}
            />
          </Grid>
        </Grid>
      </Grid>
      {redirect}
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    booking: state.booking
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateBooking: data => dispatch(actions.updateBooking(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectDate);
