import { Typography, Grid, makeStyles } from "@material-ui/core";
import { DARK_GRAY, GRAY } from "../../../theme";
import {
  formatNumberToCurrencyString,
  parseDateWithoutTimezone
} from "../../../shared/utils/Utils";
import { format } from "date-fns";

const VehicleContractDetails = props => {
  const { contractData } = props;

  const classes = useStyles();
  return (
    <Grid item xs={4} className="mr-8 ml-8">
      <Typography variant="h6" component="h4" className={classes.header}>
        Dados do Contrato
      </Typography>
      <Grid item container xs={12} className="mt-6">
        <Grid className="mr-6">
          <Grid item xs={12} className="mb-4">
            <Typography variant="body1" className={classes.header}>
              {contractData?.carProvider?.name || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Fornecedor
            </Typography>
          </Grid>
        </Grid>
        <Grid className="mr-6">
          <Grid item xs={12} className="mb-4">
            <Typography variant="body1" className={classes.header}>
              {contractData?.carMaintenanceProvider?.name || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Manutenção
            </Typography>
          </Grid>
        </Grid>
        <Grid className="mr-6">
          <Grid item xs={12} className="mb-4">
            <Typography variant="body1" className={classes.header}>
              {contractData?.contractNumber || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Nº do Contrato
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} className="mt-6">
        <Grid className="mr-6">
          <Grid item xs={12} className="mb-4">
            <Typography variant="body1" className={classes.header}>
              {contractData?.monthlyRate
                ? `R$${formatNumberToCurrencyString(contractData?.monthlyRate)}`
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Valor Mensal
            </Typography>
          </Grid>
        </Grid>
        <Grid className="mr-6">
          <Grid item xs={12} className="mb-4">
            <Typography variant="body1" className={classes.header}>
              {contractData?.startDate
                ? format(parseDateWithoutTimezone(contractData?.startDate), "dd/MM/yyyy")
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Data de Aquisição
            </Typography>
          </Grid>
        </Grid>
        <Grid className="mr-6">
          <Grid item xs={12} className="mb-4">
            <Typography variant="body1" className={classes.header}>
              {contractData?.validity
                ? format(parseDateWithoutTimezone(contractData?.validity), "dd/MM/yyyy")
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="h5" className={classes.subtitle}>
              Vigência
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  header: {
    color: DARK_GRAY
  },
  subtitle: {
    color: GRAY
  }
}));

export default VehicleContractDetails;
