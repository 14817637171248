import qs from "qs";
import axios from "../axiosInstance";

class UserService {
  async listUsersFiltered(searchParam, filters) {
    if (this.listUsersController) this.listUsersController.abort();

    this.listUsersController = new AbortController();

    return axios.get(`users`, {
      params: { searchParam, ...filters },
      paramsSerializer: params => qs.stringify(params),
      signal: this.listUsersController.signal
    });
  }

  async createNewUser(user) {
    return axios.post("users", user);
  }

  async updateUser(user, userId, reasonForEdit) {
    return axios.put(`users/${userId}`, user, {
      params: { reasonForEdit }
    });
  }

  async getUserById(userId, date = null) {
    return axios.get(`users/${userId}/informations`, { params: { date } });
  }

  async getUserByAdUser(adUser, isDriver, isFromAd) {
    return axios.get(`users/${adUser}`, { params: { isDriver, isFromAd } });
  }

  async listRecurringChecklistsByUserId(userId) {
    return axios.get(`users/${userId}/checklists`);
  }

  async listReportsByUserId(userId) {
    return axios.get(`users/${userId}/securityreports`);
  }

  async getUsersReport(searchParam, filters) {
    return axios.get(`users/report`, {
      params: { searchParam, ...filters },
      paramsSerializer: params => qs.stringify(params),
      responseType: "blob"
    });
  }

  async getUserHistory(userId) {
    return axios.get(`users/${userId}/history`);
  }
}

export default new UserService();
