// Types
const UPDATE_BOOKING = "booking/UPDATE_BOOKING";
const DELETE_BOOKING = "booking/DELETE_BOOKING";

// Reducer
export const INITIAL_STATE = {
  id: null,
  nextStep: null,
  approved: null,
  bookingTypeId: null,
  carDocument: null,
  carId: null,
  carLicensePlate: null,
  carModelId: null,
  carModel: null,
  carType: null,
  destination: null,
  deleted: null,
  deletedByUser: null,
  driver: null,
  driverId: null,
  driverType: null,
  startDateTime: null,
  endDateTime: null,
  siteId: null,
  siteName: null,
  tripType: null,
  reason: null,
  schedulerId: null
};

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_BOOKING: {
      return {
        ...state,
        ...action.payload
      };
    }
    case DELETE_BOOKING: {
      return { ...INITIAL_STATE };
    }
    default: {
      return state;
    }
  }
}

// Action creators

export const updateBooking = booking => {
  return {
    type: UPDATE_BOOKING,
    payload: booking
  };
};

export const deleteBooking = () => {
  return {
    type: DELETE_BOOKING
  };
};
