import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { ChevronRight } from "react-feather";
import { Grid, makeStyles, Card, Typography, Paper } from "@material-ui/core";
import { DARK_GRAY, SECONDARY_COLOR } from "../../../theme";
import { routes } from "../../../shared/utils/Constants";
import { format } from "date-fns";

const ReportCard = props => {
  const { report } = props;

  const [redirect, setRedirect] = useState(null);
  const classes = useStyles();

  const onSelectReport = () => {
    const reportDetailsPath = <Redirect to={`${routes.reportDetails}/${report.id}`} />;
    setRedirect(reportDetailsPath);
  };

  return (
    <Card className="mb-3 hover-icon" onClick={onSelectReport}>
      <Paper className={classes.paper}>
        <Grid container className="pl-6 pr-6 pt-4 pb-4">
          <Grid item container xs={12} className="mb-1">
            <Grid item container xs={10} alignItems="center">
              <Typography variant="body2" className={classes.colorDetails}>
                Relato #<span className={`${classes.detailsInformation} body2`}>{report.id}</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <ChevronRight
                className={`${classes.icon} hover-icon`}
                role="button"
                aria-label="Detalhes"
              />
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Typography variant="caption" className={classes.colorDetails}>
              Data e horário da ocorrência:{" "}
              <span className={classes.detailsInformation}>
                {format(new Date(report.occurrenceDateTime), "dd/MM/yy | HH:mm")}
              </span>
            </Typography>
          </Grid>

          <Grid item container xs={12}>
            <Typography variant="caption" className={classes.colorDetails}>
              Local: <span className={classes.detailsInformation}>{report.local.name}</span>
            </Typography>
          </Grid>

          <Grid item container xs={12}>
            <Typography variant="caption" className={classes.colorDetails}>
              Ocorrência:{" "}
              <span className={classes.detailsInformation}>{report.occurrence.name}</span>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      {redirect}
    </Card>
  );
};

const useStyles = makeStyles(() => ({
  colorDetails: {
    color: DARK_GRAY
  },
  detailsInformation: {
    fontWeight: 600,
    fontSize: "14px"
  },
  icon: {
    width: "18px",
    height: "24px",
    display: "flex",
    marginLeft: "auto"
  },
  paper: {
    border: "solid",
    borderWidth: "1px",
    borderColor: SECONDARY_COLOR
  }
}));

export default ReportCard;
