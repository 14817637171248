import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import MaintenanceLogo from "../../../shared/img/Maintenance.png";
import { Layout } from "../../layout/";

const Maintenance = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Grid container className={`${classes.container} pt-6`}>
        <Grid item xs={12} className="pl-6 pr-6 pt-6 pb-6">
          <img
            src={MaintenanceLogo}
            alt="Logo Página Manutenção"
            align="center"
            className={classes.logo}
          />
        </Grid>
        <Grid item xs={12} className="pl-6 pr-6 pb-6">
          <Typography variant="h4" align="center">
            Sistema em manutenção!
          </Typography>
        </Grid>
        <Grid item xs={12} className="pl-6 pr-6 pb-6">
          <Typography variant="subtitle2" color="secondary" align="center">
            Desculpe-nos pelo transtorno. <br /> Estamos trabalhando para atendê-los melhor e em
            breve o sistema estará funcionando novamente.
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    margin: "auto",
    alignItems: "center"
  },
  logo: {
    margin: "auto",
    display: "flex"
  }
});

export default Maintenance;
