import qs from "qs";
import axios from "../axiosInstance";

class CarService {
  async inactivateCar(carId, inactivityStart, inactivityFinish) {
    return axios.patch(`cars/${carId}/inactivate`, null, {
      params: { inactivityStart, inactivityFinish }
    });
  }

  async reactivateCar(carId, inactivityFinish) {
    return axios.patch(`cars/${carId}/reactivate`, null, {
      params: { inactivityFinish }
    });
  }

  async saveRecurringChecklist(recurringChecklistResponse) {
    return axios.post(`cars/recurringChecklists`, recurringChecklistResponse);
  }

  async listFleets(searchParam, filters) {
    if (this.listFleetsController) this.listFleetsController.abort();

    this.listFleetsController = new AbortController();

    return axios.get(`cars`, {
      params: { searchParam, ...filters },
      paramsSerializer: params => qs.stringify(params),
      signal: this.listFleetsController.signal
    });
  }

  async listProviders() {
    return axios.get(`cars/providers`);
  }

  async listBrands() {
    return axios.get(`cars/brands`);
  }

  async listModelsByBrandId(brandId) {
    return axios.get(`cars/brands/${brandId}/models`);
  }

  async listColors() {
    return axios.get(`cars/colors`);
  }

  async listFuels() {
    return axios.get(`cars/fuels`);
  }

  async listLicenseStatus() {
    return axios.get(`cars/licensing/status`);
  }

  async listStatus() {
    return axios.get(`cars/status`);
  }

  async listStatusDetailsByStatusId(statusId) {
    return axios.get(`cars/status/${statusId}/details`);
  }

  async createVehicle(data) {
    return axios.post(`cars`, data);
  }

  async getVehicleById(carId) {
    return axios.get(`cars/${carId}`);
  }

  async getVehicleByLicensePlate(carLicensePlate) {
    return axios.get(`cars/licensing/${carLicensePlate}`);
  }

  async updateVehicle(carId, data, reasonForEdit) {
    return axios.put(`cars/${carId}`, data, {
      params: { reasonForEdit }
    });
  }

  async getCarsReport(searchParam, filters) {
    return axios.get(`cars/report`, {
      params: { searchParam, ...filters },
      paramsSerializer: params => qs.stringify(params),
      responseType: "blob"
    });
  }

  async getCarHistory(carId) {
    return axios.get(`cars/${carId}/history`);
  }

  async handleSheetUpload(file, route) {
    const formData = new FormData();
    formData.append("file", file);

    try {
      return await axios.post(route, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        handleError: false
      });
    } catch (error) {
      const message = error.response?.data?.message;
      const status = error.response?.status;
      const data = error.response?.data;

      return { hasError: true, message, status, data };
    }
  }

  async uploadReviewsSheet(file) {
    return this.handleSheetUpload(file, "cars/reviews");
  }

  async uploadOdometrySheet(file) {
    return this.handleSheetUpload(file, "cars/odometry");
  }

  async uploadMonthlyRateSheet(file) {
    return this.handleSheetUpload(file, "cars/contracts/update");
  }

  async getCarReviewsReport() {
    return axios.get(`cars/reviews/report`, {
      responseType: "blob"
    });
  }
}

export default new CarService();
