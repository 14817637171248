import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { connect } from "react-redux";
import { Grid, makeStyles, Typography, Divider, IconButton, TextField } from "@material-ui/core";
import { Smile, Frown, ChevronLeft, Calendar, Clock } from "react-feather";
import { marksFuelLevel, checklistTypeAnswer } from "../../../shared/utils/enum";
import { DARK_GRAY, GRAY, RED, PRIMARY_COLOR, LIGHT_GREEN, SECONDARY_COLOR } from "../../../theme";
import Loading from "../../../shared/components/Loading";
import { Layout } from "../../layout";
import { Redirect } from "react-router-dom";
import { groupBy } from "../../../shared/utils/Utils";
import ChecklistDetailsManager from "../ChecklistDetailsManager";
import {
  messages,
  MOBILE_WINDOW_WIDTH,
  routes,
  SMALL_WINDOW_WIDTH
} from "../../../shared/utils/Constants";
import CarInformation from "../../../shared/components/CarInformation";
import LevelSlider from "../../../shared/components/LevelSlider";

const ChecklistDetails = props => {
  const {
    match: { params: routeParams },
    windowWidth,
    user
  } = props;
  const { checklistId } = routeParams;
  const classes = useStyles();
  const [redirect, setRedirect] = useState(null);
  const [checklist, setChecklist] = useState();
  const [loading, setLoading] = useState(false);

  window.onbeforeunload = null;

  useEffect(() => {
    const getChecklistById = async () => {
      setLoading(true);
      const data = await ChecklistDetailsManager.getChecklistById(checklistId);
      if (data && user) {
        const isOwnChecklist = user.id === data.checklistResponse.user.id;
        isOwnChecklist ? setChecklist(data) : setRedirect(<Redirect to={routes.deniedAccess} />);
      } else setRedirect(<Redirect to={routes.notFound} />);
      setLoading(false);
    };

    if (checklistId) getChecklistById();
    else setRedirect(<Redirect to={routes.listChecklists} />);
  }, [checklistId, user]);

  const fields = [
    "Nome do condutor",
    "Modelo/fabricante",
    "Placa",
    "Diretoria/Regional",
    "Gerência",
    "Documento"
  ];

  const generalInformationData = checklist &&
    user && [
      user.name,
      checklist.checklistResponse.car.carInformation?.carModel?.model,
      checklist.checklistResponse.car.carLicensing.carLicensePlate,
      checklist.checklistResponse.user.management.regional.name,
      checklist.checklistResponse.user.management.name,
      checklist.checklistResponse.car.carLicensing?.carDocument
    ];

  const groupedQuestions =
    checklist && groupBy(checklist.checklistQuestionResponses, "questionGroupName");
  const keysGroups = checklist && Object.keys(groupedQuestions);

  const getTitleVariant = () => (windowWidth.width < MOBILE_WINDOW_WIDTH ? "h6" : "h4");
  const getSubtitleVariant = () => (windowWidth.width < MOBILE_WINDOW_WIDTH ? "subtitle1" : "h5");
  const getDateTimeVariant = () => (windowWidth.width < SMALL_WINDOW_WIDTH ? "body2" : "subtitle1");
  const getIconSize = () => (windowWidth.width < SMALL_WINDOW_WIDTH ? 18 : 22);
  const getChevronSize = () => (windowWidth.width < SMALL_WINDOW_WIDTH ? 25 : 32);
  const getXsGrid = () => (windowWidth.width < MOBILE_WINDOW_WIDTH ? 12 : false);
  const getMarginLeft = () => (windowWidth.width < MOBILE_WINDOW_WIDTH ? "ml-0" : "ml-4");

  return checklist ? (
    <Layout showHeader>
      <Grid container className="mt-8 mb-8 pl-4 pr-4">
        <Grid item container alignItems="center" className="mt-4 mb-12">
          <IconButton
            onClick={() => setRedirect(<Redirect to={routes.listChecklists} />)}
            className="ml-2"
            aria-label="Voltar"
          >
            <ChevronLeft size={getChevronSize()} color={PRIMARY_COLOR} />
          </IconButton>
          <Typography variant={getTitleVariant()} component="h2" className={classes.pageTitle}>
            {`Detalhes do Checklist #${checklist.checklistResponse.id}`}
          </Typography>
        </Grid>
        <Grid container justify="center">
          <Grid item container xs={12} sm={10} md={8} lg={6} xl={5}>
            <Grid item container className={`${classes.dateTime} mb-4`}>
              <Grid item xs={getXsGrid()} className={classes.mobileView}>
                <Typography variant={getSubtitleVariant()}>Data e Horário da Realização</Typography>
              </Grid>
              <Grid item xs={getXsGrid()}>
                <Grid container className={classes.mobileView}>
                  <Calendar
                    size={getIconSize()}
                    className={`${classes.iconsDetails} ${
                      classes.dateTimeDetailsDisplay
                    } ${getMarginLeft()} mr-4`}
                  />
                  <Typography
                    variant={getDateTimeVariant()}
                    className={`${classes.detailsDateTime} ${classes.dateTimeDetailsDisplay}`}
                  >
                    {checklist.checklistResponse.responseDate &&
                      format(new Date(checklist.checklistResponse.responseDate), "dd/MM/yyyy")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={getXsGrid()}>
                <Grid container className={classes.mobileView}>
                  <Clock
                    size={getIconSize()}
                    className={`${classes.iconsDetails} ${
                      classes.dateTimeDetailsDisplay
                    } ${getMarginLeft()} mr-4 `}
                  />
                  <Typography
                    variant={getDateTimeVariant()}
                    className={`${classes.detailsDateTime} ${classes.dateTimeDetailsDisplay}`}
                  >
                    {checklist.checklistResponse.responseDate &&
                      format(new Date(checklist.checklistResponse.responseDate), "HH:mm")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CarInformation
                window={window}
                fields={fields}
                data={generalInformationData}
                warningMessage={messages.WARNING_COMMERCIAL_CHECKLIST_INFO}
                carInfo={`${user?.privateCar?.licensePlate ?? "--"} | ${
                  user?.privateCar?.model ?? "-"
                }`}
              />
            </Grid>

            <Grid item xs={12} className="mb-4 mt-4">
              <Divider className="divider" />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" className={classes.questionsText}>
                Quilometragem no checklist:
              </Typography>
            </Grid>
            <Grid item xs={12} className="mt-2">
              <TextField
                fullWidth
                disabled
                variant="outlined"
                value={
                  checklist.checklistResponse && `${checklist.checklistResponse.mileage} KM rodados`
                }
                className={classes.mileageText}
              ></TextField>
            </Grid>
            <Grid item xs={12} className="mt-6">
              <Typography variant="subtitle1" className={classes.questionsText}>
                Combustível na saída:
              </Typography>
            </Grid>
            <Grid item xs={12} className="mt-2">
              <Grid item xs={12} align="center" className="pl-4 pr-4">
                {checklist.checklistResponse && (
                  <LevelSlider
                    value={checklist.checklistResponse.fuelLevel}
                    marks={marksFuelLevel}
                    step={25}
                    min={25}
                    className={classes.slider}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} className="pt-4 pb-4">
              <Divider className="divider" />
            </Grid>

            <Grid item container xs={12}>
              {keysGroups &&
                keysGroups.map((groupName, groupIndex) => {
                  return (
                    <Grid container className="pb-8" key={groupName + groupIndex}>
                      <Grid item xs={12} className="pb-4">
                        <Typography variant="h5" className="mb-10 ">
                          {`${(groupIndex + 1).toLocaleString("pt-BR", {
                            minimumIntegerDigits: 2
                          })}. ${groupName}`}
                        </Typography>
                      </Grid>
                      {groupedQuestions &&
                        groupedQuestions[groupName].map((question, questionIndex) => {
                          const isOk = question.checklistTypeAnswerId === checklistTypeAnswer.ok;
                          const labelClass = isOk
                            ? classes.dynamicOkQuestionsLabel
                            : classes.dynamicNotOkQuestionsLabel;
                          const dividerClass = isOk
                            ? classes.dynamicOkDivider
                            : classes.dynamicNotOkDivider;
                          return (
                            <Grid
                              container
                              className="mb-6"
                              direction="row"
                              alignItems="center"
                              key={question.questionLabel + questionIndex}
                            >
                              <Grid item xs>
                                <Typography variant="body1" className={labelClass}>
                                  {question.questionLabel}
                                </Typography>
                              </Grid>
                              <Grid item xs={1} align="right">
                                {isOk ? (
                                  <Smile size={28} color={LIGHT_GREEN} />
                                ) : (
                                  <Frown size={28} color={RED} />
                                )}
                              </Grid>
                              <Grid item xs={12} className="mt-2">
                                <Divider className={dividerClass} />
                              </Grid>
                              {question.observations && (
                                <Grid item xs={12}>
                                  <Typography
                                    variant="subtitle1"
                                    className={`${classes.observationText} italic`}
                                  >
                                    {question.observations}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          );
                        })}
                    </Grid>
                  );
                })}
            </Grid>
            {checklist.checklistResponse.checklistObservations && (
              <Grid container className="mt-10 mb-6">
                <Grid item xs={12}>
                  <Typography variant="h5" className="mb-10">
                    Observações gerais
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    className={`${classes.observationText} ${classes.overflow}`}
                  >
                    {checklist.checklistResponse.checklistObservations}
                  </Typography>
                </Grid>
                <Grid item xs={12} className="mt-2">
                  <Divider className={classes.endDivider} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {redirect}
    </Layout>
  ) : (
    loading && (
      <Grid className="pt-8">
        <Loading loading />
        {redirect}
      </Grid>
    )
  );
};

const useStyles = makeStyles(theme => ({
  overflow: {
    overflowWrap: "break-word"
  },
  pageTitle: {
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      margin: "auto"
    }
  },
  questionsText: {
    color: GRAY
  },
  mileageText: {
    "& .MuiInputBase-input": {
      color: DARK_GRAY
    }
  },
  dynamicOkQuestionsLabel: {
    color: LIGHT_GREEN
  },
  dynamicNotOkQuestionsLabel: {
    color: RED
  },
  dynamicOkDivider: {
    backgroundColor: LIGHT_GREEN
  },
  dynamicNotOkDivider: {
    backgroundColor: RED
  },
  observationText: {
    color: GRAY
  },
  detailsDateTime: {
    color: DARK_GRAY
  },
  iconsDetails: {
    color: SECONDARY_COLOR
  },
  dateTimeDetailsDisplay: {
    display: "flex",
    marginBottom: "auto",
    marginTop: "auto"
  },
  dateTime: {
    alignItems: "center"
  },
  slider: {
    "&.MuiSlider-root": {
      cursor: "unset"
    }
  },
  endDivider: {
    backgroundColor: PRIMARY_COLOR
  },
  mobileView: {
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      justifyContent: "center",
      textAlign: "center"
    }
  }
}));

const mapStateToProps = state => {
  return {
    user: state.user,
    windowWidth: state.window
  };
};

export default connect(mapStateToProps, null)(ChecklistDetails);
