import { environment } from "../../shared/utils/enum";

export const envConfig = {
  PUBLIC_URL: "http://localhost:3000/",
  REACT_APP_ENVIRONMENT_NAME: environment.development,
  REACT_APP_NAME: "$npm_package_name",
  REACT_APP_VERSION: "$npm_package_version",
  REACT_APP_API_URL: "http://localhost:53066/api/v1/",
  REACT_APP_CLARITY_KEY: ""
};
