import React, { useEffect, useState, useCallback } from "react";
import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  DialogActions
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GRAY, LIGHTER_GRAY, LIGHT_GRAY, PRIMARY_COLOR, WHITE } from "../../../../theme";
import createValidationSchema from "./createValidationSchema";
import GeneralSegment from "./GeneralSegment";
import LicenseSegment from "./LicenseSegment";
import CourseSegment from "./CourseSegment";
import ActionSegment from "./ActionSegment";
import ConfirmationModal from "../../../../shared/components/ConfirmationModal";
import { messages, adUserSize } from "../../../../shared/utils/Constants";
import useCreateOrUpdateUser, { userModal } from "../../customHooks/useCreateOrUpdateUser";
import { cnhIsRequired } from "../../../../shared/utils/Utils";
import { driverProfiles } from "../../../../shared/utils/enum";
import EditionSegment from "../../../../shared/components/ManagementPage/EditionSegment";
import CloseIcon from "../../../../shared/components/CloseIcon";

const RegistrationModal = props => {
  const { isOpen, handleClose, listUsersFiltered, editableUserId, setEditableUserId } = props;
  const classes = useStyles();
  const defaultValues = userModal.defaultValues;
  const isEdition = !!editableUserId;

  const [openedCancelModal, setOpenedCancelModal] = useState(false);
  const [openedDropModal, setOpenedDropModal] = useState(false);
  const [openedConfirmationModal, setOpenedConfirmationModal] = useState(false);
  const [confirmationModalTitle, setConfirmationModalTitle] = useState(
    messages.SUCCESS_CREATED_USER
  );
  const [oldUserData, setOldUserData] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(createValidationSchema()),
    mode: "onChange",
    defaultValues: defaultValues
  });

  const {
    getUserById,
    createOrUpdate,
    loadingCreateOrUpdate,
    loadingUser,
    setSchemaValues,
    checkIfDeletedCourseOrLicense,
    userData,
    loadUserData,
    resetUserData
  } = useCreateOrUpdateUser(setValue, editableUserId);

  const [values, setValues] = useState(defaultValues);

  const handleChangeAdUser = useCallback(
    async adUser => {
      if (!adUser.length) resetUserData();

      if (adUser.length === adUserSize) {
        await loadUserData(adUser);
      }
    },
    [loadUserData, resetUserData]
  );

  useEffect(() => {
    const subscription = watch(async (updatedValues, { name, type }) => {
      setValues(updatedValues);

      if (type !== "change") return;

      if (name === "adUser") {
        handleChangeAdUser(updatedValues.adUser);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, defaultValues, handleChangeAdUser]);

  useEffect(() => {
    async function getOldUserData() {
      const callback = data => {
        setSchemaValues(data);
        setOldUserData(data);
      };

      await getUserById(callback);
    }

    if (isOpen) {
      setConfirmationModalTitle(messages.SUCCESS_CREATED_USER);
      if (isEdition) {
        setConfirmationModalTitle(messages.SUCCESS_UPDATED_USER);
        getOldUserData();
      }
    } else {
      reset();
      resetUserData();
      setEditableUserId(null);
      setOldUserData(null);
    }
  }, [isOpen, isEdition, getUserById, reset, resetUserData, setEditableUserId, setSchemaValues]);

  const submitForm = async data => {
    const callback = () => {
      reset();
      handleClose();
      setOpenedConfirmationModal(true);
      listUsersFiltered();
    };

    const isDeletedLicenseOrCourse =
      !!editableUserId && checkIfDeletedCourseOrLicense(data, oldUserData);

    isDeletedLicenseOrCourse ? setOpenedDropModal(true) : await createOrUpdate(data, callback);
  };

  const handleCancelUserRegistration = () => {
    handleClose();
    setOpenedCancelModal(false);
  };

  return (
    <>
      <form aria-label="Formulário cadastro usuário" noValidate onSubmit={handleSubmit(submitForm)}>
        <Dialog
          fullWidth
          open={isOpen}
          onClose={handleClose}
          className={classes.modal}
          classes={{
            paper: classes.paperModal
          }}
        >
          <CloseIcon color={WHITE} onClick={handleClose} />

          <DialogTitle
            disableTypography
            onClose={() => handleClose()}
            className={classes.titleContainer}
          >
            <Typography className={classes.modalTitle} align="center" variant="h6">
              {!!editableUserId ? messages.UPDATE_USER : messages.REGISTER_USER}
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Grid item xs={12} className="mt-1">
              <GeneralSegment
                control={control}
                errors={errors}
                values={values}
                isEdition={isEdition}
                userData={userData}
                userDataIsLoading={loadingUser}
                isEditableUser={!!editableUserId}
              />
            </Grid>
            <Grid item container xs={12} className="mb-6">
              <LicenseSegment
                control={control}
                errors={!isValid && errors}
                values={values}
                licenseTypeIsRequired={cnhIsRequired(
                  values.profileId,
                  null,
                  values.licenseExpireDate
                )}
                expireDateIsRequired={cnhIsRequired(
                  values.profileId,
                  values.licenseTypeId,
                  values.licenseExpireDate
                )}
              />
              <CourseSegment
                control={control}
                errors={!isValid && errors}
                required={driverProfiles.includes(values.profileId)}
              />
            </Grid>
            {isEdition && (
              <Grid item container xs={12}>
                <EditionSegment control={control} errors={errors} />
              </Grid>
            )}
            <Typography variant="caption" className={classes.caption}>
              *campos obrigatórios
            </Typography>
          </DialogContent>

          <DialogActions>
            <ActionSegment
              classes={classes}
              secondaryAction={() => setOpenedCancelModal(true)}
              loading={loadingCreateOrUpdate}
              handleSubmit={handleSubmit(submitForm)}
              disableSubmit={!isDirty || !isValid || !userData}
              isEditableUser={!!editableUserId}
            />
          </DialogActions>
        </Dialog>
      </form>

      <ConfirmationModal
        shouldCancel
        openedModal={openedCancelModal}
        title={
          !!editableUserId
            ? messages.CONFIRMATION_CANCEL_EDIT_TITLE
            : messages.CONFIRMATION_CANCEL_CREATE_TITLE
        }
        subtitle={
          !!editableUserId
            ? messages.CONFIRMATION_CANCEL_EDIT_SUBTITLE
            : messages.CONFIRMATION_CANCEL_CREATE_SUBTITLE
        }
        handleOpenClose={() => setOpenedCancelModal(false)}
        secondaryAction={() => setOpenedCancelModal(false)}
        primaryAction={handleCancelUserRegistration}
        secondaryButtonText={messages.NO_BUTTON_MESSAGE}
        primaryButtonText={messages.YES_BUTTON_MESSAGE}
      />

      <ConfirmationModal
        shouldCancel
        openedModal={openedDropModal}
        title={messages.ALERT_TITLE}
        subtitle={messages.ALERT_DROP_SUBTITLE}
        handleOpenClose={() => setOpenedDropModal(false)}
        primaryAction={() => setOpenedDropModal(false)}
        primaryButtonText={messages.OK_BUTTON_MESSAGE}
      />

      <ConfirmationModal
        success
        openedModal={openedConfirmationModal}
        title={confirmationModalTitle}
        handleOpenClose={() => setOpenedConfirmationModal(false)}
        primaryAction={() => setOpenedConfirmationModal(false)}
        primaryButtonText={messages.OK_BUTTON_MESSAGE}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    overflow: "hidden",
    width: "100%",
    maxHeight: "100%",

    "& .MuiDialogActions-root": {
      boxShadow: `0px -1px 8px ${LIGHT_GRAY}`
    },

    "& .MuiDialogContent-root": {
      backgroundColor: LIGHTER_GRAY
    },

    "& .MuiInputBase-root": {
      backgroundColor: WHITE
    },

    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: LIGHTER_GRAY
    },

    "& .MuiInputBase-input.Mui-disabled": {
      color: GRAY
    }
  },
  caption: {
    color: GRAY
  },
  paperModal: {
    backgroundColor: WHITE,
    maxWidth: "50%"
  },
  titleContainer: {
    backgroundColor: PRIMARY_COLOR,
    textAlign: "center"
  },
  modalTitle: {
    color: WHITE
  }
}));

export default RegistrationModal;
