import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import Logo from "../../../shared/img/Logo.svg";
import { routes } from "../../../shared/utils/Constants";
import { Redirect } from "react-router-dom";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "../../../authProvider";
import store from "../../../store";
import { Layout } from "../../layout/";
import { verifyTokenIsValid } from "../../../shared/utils/RequestUtil";
import { accessType } from "../../../shared/utils/enum";

const Login = props => {
  const { user, screenDimensions, isSafety, location } = props;

  const previousPath = location?.state?.from?.pathname;

  const classes = useStyles({ screenDimensions: screenDimensions });

  useEffect(() => {
    if (previousPath && previousPath !== routes.root)
      sessionStorage.setItem("redirect", previousPath);
  }, [previousPath]);

  verifyTokenIsValid();

  const isFacilities = user.profile === accessType.siteFacilities;
  const isTeamChecklist = user.profile === accessType.teamChecklist;
  const isCommercialOrExecutive = [accessType.commercial, accessType.executive].includes(
    user.profile
  );

  const redirectTo = () => {
    if (previousPath) return previousPath;

    if (isFacilities) return routes.calendar;

    if (isTeamChecklist) return routes.teamChecklist;

    return isCommercialOrExecutive ? routes.listChecklists : routes.listBookings;
  };

  return user.isAuthenticated ? (
    <Redirect to={redirectTo()} />
  ) : (
    <AzureAD provider={authProvider} reduxStore={store}>
      {({ login, _logout, _authenticationState, _error, _accountInfo }) => {
        return (
          <Layout>
            <Grid item xs={12} container className={classes.container}>
              <Grid item xs={12} className={classes.content}>
                <Grid item xs={12} container lg={12}>
                  <Grid item xs={12} className="pl-6 pr-6">
                    <img src={Logo} alt="Logo Natal Yara" align="center" className={classes.logo} />
                  </Grid>
                  <Grid item xs={12} className="pl-6 pr-6 pb-6">
                    {isSafety ? (
                      <Typography variant="h3" align="center">
                        Segurança
                        <br />
                        Comercial
                      </Typography>
                    ) : (
                      <Typography variant="h3" align="center">
                        Gestão de
                        <br />
                        Frotas
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} className="pl-6 pr-6 pb-8">
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={login}
                    >
                      ENTRAR
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Layout>
        );
      }}
    </AzureAD>
  );
};

const useStyles = makeStyles({
  container: props => ({
    height: `${props.screenDimensions.height}px`,
    width: `${props.screenDimensions.width}px`
  }),
  content: {
    display: "flex",
    margin: "auto",
    alignItems: "center"
  },
  button: {
    width: "180px",
    margin: "auto",
    display: "flex"
  },
  logo: {
    margin: "auto",
    display: "flex",
    width: 110
  }
});

const mapStateToProps = state => {
  return {
    user: state.user,
    screenDimensions: {
      height: state.window.height,
      width: state.window.width
    }
  };
};

export default connect(mapStateToProps, null)(Login);
