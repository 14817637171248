import { useState, useCallback } from "react";
import UserManagementManager from "../UserManagementManager";

export const userModal = {
  defaultValues: {
    adUser: "",
    profileId: "",
    licenseTypeId: "",
    licenseExpireDate: null,
    courseExpireDate: null
  }
};

const useCreateOrUpdateUser = (setValue, editableUserId) => {
  const [loadingCreateOrUpdate, setLoadingCreateOrUpdate] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [userData, setUserData] = useState({});

  const getUserById = useCallback(
    async callback => {
      setLoadingUser(true);
      const data = await UserManagementManager.getUserById(editableUserId);
      if (data) callback(data);

      setLoadingUser(false);
    },
    [editableUserId]
  );

  const setSchemaValues = useCallback(
    async editableUser => {
      setValue("isEditable", true);
      setValue("adUser", editableUser.adUser);
      setValue("profileId", editableUser.profile?.profileId);
      setValue("licenseTypeId", editableUser.license?.licenseTypeId);
      setValue("licenseExpireDate", editableUser.license?.expireDate);
      setValue("courseExpireDate", editableUser.course?.expireDate);

      setUserData(editableUser);
    },
    [setValue]
  );

  const formatDataToSubmit = data => ({
    adUser: data.adUser,
    profile: {
      profileId: data.profileId
    },
    license: {
      licenseTypeId: data.licenseTypeId > 0 ? data.licenseTypeId : null,
      expireDate: data.licenseExpireDate
    },
    course: {
      expireDate: data.courseExpireDate
    }
  });

  const checkIfDeletedCourseOrLicense = (data, oldData) => {
    const isDeletedLicense =
      (!!oldData.license.licenseTypeId && !data.licenseTypeId) ||
      (!!oldData.license.expireDate && !data.licenseExpireDate);
    const isDeletedCourse = !!oldData.course.expireDate && !data.courseExpireDate;
    return isDeletedLicense || isDeletedCourse;
  };

  const createOrUpdate = async (data, callBack) => {
    setLoadingCreateOrUpdate(true);

    const user = formatDataToSubmit(data);

    const response = !!editableUserId
      ? await UserManagementManager.updateUser(user, editableUserId, data.reasonForEdit)
      : await UserManagementManager.createNewUser(user);

    if (response) callBack();

    setLoadingCreateOrUpdate(false);
  };

  const loadUserData = useCallback(async adUser => {
    setLoadingUser(true);

    const data = await UserManagementManager.getUserDataFromAd(adUser);

    if (data) setUserData(data);
    else setUserData();

    setLoadingUser(false);
    return !!data;
  }, []);

  const resetUserData = useCallback(() => {
    setUserData();
  }, []);

  return {
    getUserById,
    createOrUpdate,
    loadingCreateOrUpdate,
    loadingUser,
    setSchemaValues,
    checkIfDeletedCourseOrLicense,
    userData,
    loadUserData,
    resetUserData
  };
};

export default useCreateOrUpdateUser;
