import UserService from "../../services/UserService";
import { downloadResponseFile } from "../../shared/utils/RequestUtil";
import { formatFilters } from "./dataFormatting";

class UserManagementManager {
  async listUsersFiltered(searchParam, filtersData) {
    const filters = formatFilters(filtersData);
    const response = await UserService.listUsersFiltered(searchParam, filters);

    return response && response.data;
  }

  async createNewUser(user) {
    const response = await UserService.createNewUser(user);

    return response && response.data;
  }

  async updateUser(user, userId, reasonForEdit) {
    const response = await UserService.updateUser(user, userId, reasonForEdit);

    return response && response.data;
  }

  async getUserById(userId) {
    const response = await UserService.getUserById(userId);

    return response && response.data;
  }

  async getUserDataFromAd(adUser) {
    const response = await UserService.getUserByAdUser(adUser, false, true);

    return response && response.data;
  }

  async exportUsersReport(searchParam, filtersData) {
    const filters = formatFilters(filtersData);
    const response = await UserService.getUsersReport(searchParam, filters);

    downloadResponseFile(response, "Relatório de Usuários.xlsx");
  }
}

export default new UserManagementManager();
