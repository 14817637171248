import { useState, useEffect, useRef } from "react";

export const useDebounce = ({
  value,
  callback,
  delay,
  triggerOnEnter,
  textFieldRef
}) => {
  const [debouncedValue, setDebouncedValue] = useState(value || "");

  const hasSetUp = useRef(false);
  const timeoutIdRef = useRef(0);

  useEffect(() => {
    const textField = textFieldRef?.current;

    const handleEnterPressed = event => {
      if (event.key === "Enter") {
        if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);

        setDebouncedValue(textField.value);
      }
    }

    if (triggerOnEnter && textField) {
      textField.addEventListener("keypress", handleEnterPressed);
    }

    return () => {
      if (textField) textField.removeEventListener("keypress", handleEnterPressed);
    }
  }, [triggerOnEnter, textFieldRef]);

  useEffect(() => {
    hasSetUp.current && callback && callback(debouncedValue);

    if (!hasSetUp.current) hasSetUp.current = true;
  }, [debouncedValue, callback]);

  useEffect(() => {
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);

    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    timeoutIdRef.current = timeoutId;
  }, [value, delay]);

  return {
    debouncedValue
  };
};
