import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { routes } from "../utils/Constants";
import Loading from "./Loading";
import { Box } from "@material-ui/core";
import { accessType } from "../utils/enum";

const DefaultRoute = props => {
  const { userProfile } = props;

  const redirectTo = () => {
    let defaultRoute;
    let previousPath = sessionStorage.getItem("redirect");

    if (previousPath) defaultRoute = previousPath;
    else
      switch (userProfile) {
        case accessType.driver:
          defaultRoute = routes.listBookings;
          break;
        case accessType.siteFacilities:
          defaultRoute = routes.calendar;
          break;
        case accessType.backoffice:
          defaultRoute = routes.reportsManagement;
          break;
        case accessType.centralFacilities:
          defaultRoute = routes.fleetManagement;
          break;
        case accessType.adminFacilities:
          defaultRoute = routes.fleetManagement;
              break;
          case accessType.teamChecklist:
              defaultRoute = routes.reportChecklist;
              break;
        default:
          defaultRoute = routes.listChecklists;
          break;
      }
    return defaultRoute;
  };

  return !userProfile ? (
    <Box className="pt-4">
      <Loading loading size={50} />
    </Box>
  ) : (
    <Redirect to={redirectTo()} />
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.user.profile
  };
};

export default connect(mapStateToProps, null)(DefaultRoute);
