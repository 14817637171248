import { MOBILE_WINDOW_WIDTH, SMALL_WINDOW_WIDTH } from "../../shared/utils/Constants";
import { marksFuelLevel } from "../../shared/utils/enum";

export const getTitleVariant = window => (window.width < MOBILE_WINDOW_WIDTH ? "h6" : "h4");
export const getSubtitleVariant = window =>
  window.width < MOBILE_WINDOW_WIDTH ? "subtitle1" : "h5";
export const getDateTimeVariant = window =>
  window.width < SMALL_WINDOW_WIDTH ? "body2" : "subtitle1";
export const getIconSize = window => (window.width < SMALL_WINDOW_WIDTH ? 18 : 22);
export const getXsGrid = window => (window.width < MOBILE_WINDOW_WIDTH ? 12 : false);
export const getMarginLeft = window => (window.width < MOBILE_WINDOW_WIDTH ? "ml-0" : "ml-4");
export const getFuelNumber = label => marksFuelLevel.find(e => e.label === label).value ?? 25;
export const getChevronSize = window => (window.width < SMALL_WINDOW_WIDTH ? 25 : 32);
