import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { WHITE, PRIMARY_COLOR, SECONDARY_COLOR } from "../../../theme";
import { routes, MOBILE_WINDOW_WIDTH } from "../../../shared/utils/Constants";
import { Layout } from "../../layout";
import * as actions from "../../../store/ChecklistStore";
import SafeByChoiceLargeIcon from "../../../shared/img/SafeByChoiceLarge.png";
import { accessType } from "../../../shared/utils/enum";
import { ReactComponent as ChecklistIcon } from "../../../shared/img/ConfirmedChecklistIcon.svg";

const ConfirmChecklist = props => {
  const { screenDimensions, deleteChecklist, profile } = props;
  const classes = useStyles({ screenDimensions: screenDimensions });
  const isFacilities = profile === accessType.siteFacilities;
  const isBackOffice = profile === accessType.backoffice;
  const isCommercialOrExecutive = [accessType.commercial, accessType.executive].includes(profile);

  const [redirect, setRedirect] = useState(null);

  window.onbeforeunload = null;

  const primaryButtonText = () => {
    if (isBackOffice) return "GESTÃO DE CHECKLISTS E RELATOS";
    return isCommercialOrExecutive
      ? "ACESSAR MEUS CHECKLISTS E RELATOS"
      : "ACESSAR MINHAS RESERVAS";
  };

  const redirectTo = () => {
    if (isBackOffice) return routes.reportsManagement;
    return isCommercialOrExecutive ? routes.listChecklists : routes.listBookings;
  };

  return (
    <Layout>
      <Grid container className={classes.container}>
        <Grid item xs={12} align="right" className="pr-8">
          <img src={SafeByChoiceLargeIcon} alt="Ícone SafeByChoice" />
        </Grid>
        <Grid item container justify="center">
          <Grid item container align="center" xs={12} md={7} direction="column" className="mb-10">
            <Grid item className="mb-10">
              <ChecklistIcon />
            </Grid>
            <Grid item className="mt-2">
              <Typography variant="h6" color="inherit" className={classes.description}>
                Checklist realizado com sucesso.
                <br />
                Tenha uma boa viagem!
              </Typography>
            </Grid>
            <Grid container justify="center" className="mt-12">
              <Grid item className={classes.button}>
                {isFacilities && (
                  <Button
                    className={classes.calendarButton}
                    color="secondary"
                    onClick={() => {
                      deleteChecklist();
                      setRedirect(<Redirect to={routes.calendar} />);
                    }}
                  >
                    <Typography variant="subtitle2" color="secondary">
                      VOLTAR PARA O CALENDÁRIO
                    </Typography>
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    deleteChecklist();
                    setRedirect(<Redirect to={redirectTo()} />);
                  }}
                >
                  <Typography variant="subtitle2" color="inherit">
                    {primaryButtonText()}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {redirect}
    </Layout>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: PRIMARY_COLOR,
    height: "100vh",
    color: WHITE,
    paddingBottom: "14px",
    paddingRight: "14px",
    paddingLeft: "14px"
  },
  description: {
    fontWeight: 400
  },
  calendarButton: {
    border: "solid 1px",
    color: SECONDARY_COLOR,
    paddingRight: "13px",
    paddingLeft: "13px"
  },
  button: {
    [theme.breakpoints.up(MOBILE_WINDOW_WIDTH)]: {
      marginRight: "16px"
    },
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      marginBottom: "16px"
    }
  }
}));

const mapStateToProps = state => {
  return {
    screenDimensions: {
      height: state.window.height,
      width: state.window.width
    },
    profile: state.user.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteChecklist: () => dispatch(actions.deleteChecklist())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmChecklist);
