import SecurityReportService from "../../services/SecurityReportService";
import SiteService from "../../services/SiteService";

class SecurityReportManager {
  async listPrincipalInvolved() {
    return SecurityReportService.listPrincipalInvolved().then(answer => answer && answer.data);
  }

  async listLocal() {
    return SecurityReportService.listLocal().then(answer => answer && answer.data);
  }

  async listSite() {
    return SiteService.listSite().then(answer => answer && answer.data);
  }

  async listOccurrence(localId) {
    return SecurityReportService.listOccurrence(localId).then(answer => answer && answer.data);
  }

  async listSubject(localId, occurrenceId) {
    return SecurityReportService.listSubject(localId, occurrenceId).then(
      answer => answer && answer.data
    );
  }

  async createSecurityReport(securityReport) {
    return SecurityReportService.createSecurityReport(securityReport).then(
      answer => answer && answer.data
    );
  }

  async uploadReportImages(reportId, reportImages) {
    return SecurityReportService.uploadReportImages(reportId, reportImages).then(
      answer => answer && answer.data
    );
  }
}

export default new SecurityReportManager();
