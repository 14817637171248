import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { RED, SECONDARY_COLOR, THIN_RED, LIGHT_GREEN, THIN_GREEN, THIN_BLUE2 } from "../../../theme";
import { ReactComponent as FileIcon } from "../../img/FileIconSmallHover.svg";
import { ReactComponent as FileIconError } from "../../img/FileIconSmallError.svg";
import { CheckCircle, Download, Trash2, X } from "react-feather";
import TooltipItem from "../TooltipItem";

const ImportedFileCard = props => {
  const {
    fileName,
    hasImportError,
    hasValidationError,
    handleDeleteCard,
    handleDownloadFile
  } = props;

  const hasError = hasImportError || hasValidationError;
  const classes = useStyles({ hasError });

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item>
        {hasError ? (
          <FileIconError className="m-4" />
        ) : (
          <FileIcon className="m-4" />
        )}
      </Grid>
      <Grid item className={classes.fileNameItem}>
        <TooltipItem onlyWhenOverflown tooltip={fileName} placement="top">
          <Typography variant="body2" className={`text-overflow-ellipsis ${classes.fileNameText}`}>
            {fileName}
          </Typography>
        </TooltipItem>
      </Grid>
      <Grid container item xs={3} justify="flex-end" alignItems="center">
        {hasValidationError && (
          <IconButton className="p-1" onClick={handleDownloadFile}>
            <Download size={24} className={classes.deleteIcon} />
          </IconButton>
        )}
        <IconButton className="mr-2 p-1" onClick={handleDeleteCard}>
          {hasError ? (
            <X size={20} className={classes.deleteIcon} />
          ) : (
            <Trash2 size={24} className={classes.deleteIcon} />
          )}
        </IconButton>
        {!hasError && (
          <CheckCircle size={24} className={`mr-2 p-1 ${classes.checkIcon}`} />
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: ({ hasError }) => ({
    width: "100%",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "8px",
    borderColor: hasError ? RED : SECONDARY_COLOR,
    backgroundColor: hasError ? THIN_RED : THIN_BLUE2,
  }),
  fileNameItem: {
    flexGrow: 1,
    maxWidth: "64%"
  },
  fileNameText: ({ hasError }) => ({
    color: hasError ? RED : SECONDARY_COLOR
  }),
  deleteIcon: ({ hasError }) => ({
    color: hasError ? RED : SECONDARY_COLOR
  }),
  checkIcon: {
    borderRadius: "16px",
    color: LIGHT_GREEN,
    backgroundColor: THIN_GREEN
  }
}));

export default ImportedFileCard;