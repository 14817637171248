import axios from "../axiosInstance";

class ChecklistService {
  async listChecklistModelQuestionsByCarId(carId, checklistTypeId) {
    return axios.get("checklistQuestions", { params: { carId, checklistTypeId } });
  }

  async getChecklistById(checklistId) {
    return axios.get(`checklists/${checklistId}`);
  }

  async uploadChecklistImages(checklistId, checklistFiles) {
    return axios.post(`checklists/${checklistId}/files`, checklistFiles);
  }
}

export default new ChecklistService();
