import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Layout } from "../../layout/";
import Stepper from "../../../shared/components/Stepper";
import FirstStep from "./firstStep";
import SecondStep from "./secondStep";
import ThirdStep from "./thirdStep";
import { messages, routes } from "../../../shared/utils/Constants";
import ActionButtons from "./actionButtons";
import { Redirect } from "react-router-dom";
import ConfirmationModal from "../../../shared/components/ConfirmationModal";
import useSecurityReport from "../customHooks/useSecurityReport";
import { formType } from "../../../shared/utils/enum";
import { createFormData } from "../../../shared/utils/Utils";

const SecurityReport = () => {
  const [securityReport, setSecurityReport] = useState({});
  const [disabledNextButton, setDisabledNextButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [redirect, setRedirect] = useState(null);
  const [openedSuccessModal, setOpenedSuccessModal] = useState(false);

  const steps = { firstStep: 1, secondStep: 2, thirdStep: 3 };

  window.onbeforeunload = function () {
    return messages.ALERT_RELOAD_PAGE;
  };

  const { createSecurityReport } = useSecurityReport();

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSaveSecurityReport = async () => {
    setLoading(true);
    setDisabledNextButton(true);

    function callback() {
      setOpenedSuccessModal(true);
    }
    const imageFormData = createFormData(securityReport.reportImages, "reportFiles");
    await createSecurityReport(securityReport, imageFormData, callback);
    setLoading(false);
    setDisabledNextButton(false);
  };

  const handleCancelSecurityReport = () => {
    setSecurityReport({});
    setRedirect(<Redirect to={routes.listChecklists} />);
  };

  const handleCloseSuccessModal = () => {
    setSecurityReport({});
    setOpenedSuccessModal(false);
    setRedirect(<Redirect to={routes.listChecklists} />);
  };

  return (
    <Layout formType={formType.securityReport} showHeader>
      <Grid item xs={12} container className={`pt-8`}>
        <Grid item xs={12} className={`pb-5 pt-4`}>
          <Stepper step={step - 1} size={3} />
        </Grid>
        <Grid item xs={12} className="pl-4 pr-4">
          {step === steps.firstStep && (
            <FirstStep
              setDisabledNextButton={setDisabledNextButton}
              securityReport={securityReport}
              setSecurityReport={setSecurityReport}
            />
          )}

          {step === steps.secondStep && (
            <SecondStep
              setDisabledNextButton={setDisabledNextButton}
              securityReport={securityReport}
              setSecurityReport={setSecurityReport}
            />
          )}

          {step === steps.thirdStep && (
            <ThirdStep securityReport={securityReport} setSecurityReport={setSecurityReport} />
          )}

          <Grid item className="mt-8" xs={12}>
            <ActionButtons
              primaryText={step === steps.thirdStep ? "Enviar relato" : "Continuar"}
              disabled={disabledNextButton}
              loading={loading}
              secondaryAction={
                step > steps.firstStep ? handlePreviousStep : handleCancelSecurityReport
              }
              primaryAction={step < steps.thirdStep ? handleNextStep : handleSaveSecurityReport}
            />
          </Grid>
        </Grid>
      </Grid>
      {redirect}
      <ConfirmationModal
        success
        openedModal={openedSuccessModal}
        handleOpenClose={() => handleCloseSuccessModal()}
        secondaryAction={() => handleCloseSuccessModal()}
        title="Relato enviado"
        secondaryButtonText="VOLTAR PARA TELA INICIAL"
        subtitle="Seu relato foi enviado com sucesso!"
      />
    </Layout>
  );
};

export default SecurityReport;
