import React from "react";
import { Grid, makeStyles, Slider } from "@material-ui/core";
import { WHITE } from "../../theme";

const LevelSlider = ({ handleChangeLevel, value, defaultValue, ...rest }) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} className={classes.backgroundItem}>
      <Slider onChange={handleChangeLevel} value={defaultValue || value} {...rest} />
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  backgroundItem: {
    width: "100%",
    height: "80px",
    border: "0",
    "&.MuiGrid-container": {
      backgroundColor: WHITE
    }
  }
}));

export default LevelSlider;
