import { useEffect } from "react";

export const useOutsideEvent = (ref, eventName, callback) => {
  useEffect(() => {
    const handleOutsideEvent = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener(eventName, handleOutsideEvent);

    return () => {
      document.removeEventListener(eventName, handleOutsideEvent);
    };
  }, [ref, eventName, callback]);
};
