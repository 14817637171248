import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { DARK_GRAY, GRAY } from "../../theme";

const GeneralInformation = props => {
  const { fields, data, breakLine } = props;

  const classes = useStyles({ breakLine });

  return (
    <>
      {data &&
        fields &&
        fields.map((field, index) => {
          return (
            <Grid container item xs={12} className="pb-3" key={index}>
              <Grid item xs={breakLine ? 12 : 5}>
                <Typography variant="body2" className={classes.infoTitleColor}>
                  {field}
                </Typography>
              </Grid>
              <Grid item xs={breakLine ? 12 : 7} className={breakLine && "mt-1"}>
                <Typography
                  variant="body2"
                  align={breakLine ? "left" : "right"}
                  className={classes.infoColor}
                >
                  {data[index] || "-"}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
    </>
  );
};

const useStyles = makeStyles(() => ({
  infoColor: {
    color: DARK_GRAY,
    fontWeight: 400
  },
  infoTitleColor: {
    color: GRAY
  }
}));

export default GeneralInformation;
