import axios from "../axiosInstance";

class BookingService {
  async listBookingsByUserId(userId, limit) {
    return axios.get("bookings", { params: { userId, limit } });
  }

  async saveBooking(data) {
    return axios.post(`bookings`, data);
  }

  async getBoookingById(bookingId) {
    return axios.get(`bookings/${bookingId}`);
  }

  async cancelBookingById(bookingId, userId) {
    return axios.patch(`bookings/${bookingId}/cancel`, null, { params: { userId } });
  }

  async finalizeBookingById(bookingId, checklist) {
    return axios.patch(`bookings/${bookingId}/finalize`, {
      checklistResponse: checklist
    });
  }

  async listBookingsFiltered(carId, startJourney, finishJourney, poleId) {
    return axios.get(`bookings`, {
      params: { carId, startJourney, finishJourney, poleId }
    });
  }

  async startBookingChecklist(bookingId, userId) {
    return axios.patch(`bookings/${bookingId}/startChecklist`, null, {
      params: { userId }
    });
  }

  async getChecklistResponseByBookingId(bookingId, checklistTypeId) {
    return axios.get(`bookings/${bookingId}/checklists`, {
      params: { checklistTypeId }
    });
  }

  async saveBookingChecklist(checklist, bookingId) {
    return axios.post(`bookings/${bookingId}/checkin`, checklist);
  }

  async getUserAvailability(userId) {
    return axios.get(`bookings/availability`, {
      params: { userId }
    });
  }
}

export default new BookingService();
