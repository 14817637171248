import CarService from "../../services/CarService";
import { downloadResponseFile, handleImportResponse } from "../../shared/utils/RequestUtil";

class CarReviewManagementManager {
  

  async uploadCarReviewsSheet(file) {
    const response = await CarService.uploadReviewsSheet(file);

    return handleImportResponse(file, response);
  }

  async uploadCarOdometrySheet(file) {
    const response = await CarService.uploadOdometrySheet(file);

    return handleImportResponse(file, response);
  }

  async exportCarReviewsReport() {
    const response = await CarService.getCarReviewsReport();
    downloadResponseFile(response, "Relatório de Revisões.xlsx");
  }
}

export default new CarReviewManagementManager();
