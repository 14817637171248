import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Layout } from "../../layout/";
import { Grid, Typography, Button, makeStyles } from "@material-ui/core";
import { Download } from "react-feather";
import Loading from "../../../shared/components/Loading";
import CustomTable from "../../../shared/components/CustomTable/CustomTable";
import UserContentTableRow from "./UserContentTableRow";
import UserManagementManager from "../UserManagementManager";
import UserRegistrationModal from "./UserRegistration/RegistrationModal";
import { messages } from "../../../shared/utils/Constants";
import { useHistory } from "react-router";
import { accessType, profileStyle, userManagementProfiles } from "../../../shared/utils/enum";
import SearchTextField from "../../../shared/components/SearchTextField";
import { updateManagementPage } from "../../../store/ManagementPageStore";
import usePageState from "../../../shared/customHooks/usePageState";
import UserFilters from "./UserFilters";

const UserManagement = props => {
  const {
    profile,
    initialPageState: {
      pathname: storedPathname,
      initialSearch,
      filters: {
        initialProfileList,
        initialLicenseStatus,
        initialCourseStatus,
        initialHasCar,
        initialUserSituation
      }
    },
    updatePageState,
    location: { pathname }
  } = props;

  const classes = useStyles();
  const history = useHistory();

  const shouldLoadState = storedPathname === pathname;
  const isCentralFacOrAdminFac = (profile === accessType.centralFacilities || profile === accessType.adminFacilities);
  const shouldLoadFilters =
    !initialProfileList &&
    !initialLicenseStatus &&
    !initialCourseStatus &&
    !initialHasCar &&
    !initialUserSituation;

  const [users, setUsers] = useState([]);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [hasLoadedFilters, setHasLoadedFilters] = useState(false);
  const [usersReportIsLoading, setUsersReportIsLoading] = useState(false);
  const [registrationModalVisibility, setRegistrationModalVisibility] = useState(false);
  const [searchValue, setSearchValue] = useState(shouldLoadState ? initialSearch : "");
  const [editableUserId, setEditableUserId] = useState(null);
  const [filtersData, setFiltersData] = useState({
    profileList: shouldLoadState ? initialProfileList : [],
    licenseStatus: shouldLoadState ? initialLicenseStatus : [],
    courseStatus: shouldLoadState ? initialCourseStatus : [],
    hasCar: shouldLoadState ? initialHasCar : [],
    userSituation: shouldLoadState ? initialUserSituation : []
  });

  const listUsersFiltered = useCallback(async () => {
    setLoadingCounter(counter => counter + 1);
    const result = await UserManagementManager.listUsersFiltered(searchValue, filtersData);

    if (result) setUsers(result);

    updatePageState({
      initialSearch: searchValue,
      filters: {
        initialProfileList: filtersData.profileList,
        initialLicenseStatus: filtersData.licenseStatus,
        initialCourseStatus: filtersData.courseStatus,
        initialHasCar: filtersData.hasCar,
        initialUserSituation: filtersData.userSituation
      }
    });

    setLoadingCounter(counter => counter - 1);
  }, [searchValue, filtersData, updatePageState]);

  const loadFilters = useCallback(async () => {
    if (shouldLoadFilters) {
      const allowedProfiles = isCentralFacOrAdminFac
        ? [...profileStyle].sort((a, b) => a.label.localeCompare(b.label))
        : [
          { id: userManagementProfiles.driver.value, ...userManagementProfiles.driver },
          { id: userManagementProfiles.facilities.value, ...userManagementProfiles.facilities }
        ];

      const filters = {
        profileList: allowedProfiles.map(item => ({ ...item, isSelected: false })),
        licenseStatus: [
          { id: 1, label: "Válido", isSelected: false, value: true },
          { id: 2, label: "Vencido", isSelected: false, value: false }
        ],
        courseStatus: [
          { id: 1, label: "Válido", isSelected: false, value: true },
          { id: 2, label: "Vencido", isSelected: false, value: false }
        ],
        hasCar: [
          { id: 1, label: "Possui vínculo", isSelected: false, value: true },
          { id: 2, label: "Não possui vínculo", isSelected: false, value: false }
        ],
        userSituation: [
          { id: 1, label: "Usuário Ativo", isSelected: false, value: true },
          { id: 2, label: "Usuário Inativo", isSelected: false, value: false }
        ]
      };

      setFiltersData(filters);
    }
    setHasLoadedFilters(true);
  }, [shouldLoadFilters, isCentralFacOrAdminFac]);

  usePageState({ pathname });

  useEffect(() => {
    if (hasLoadedFilters) listUsersFiltered();
  }, [listUsersFiltered, hasLoadedFilters]);

  useEffect(() => {
    loadFilters();
  }, [loadFilters]);

  const exportUsersReport = async () => {
    setUsersReportIsLoading(true);
    await UserManagementManager.exportUsersReport(searchValue, filtersData);
    setUsersReportIsLoading(false);
  };

  const handleEditUser = async userId => {
    setEditableUserId(userId);
    setRegistrationModalVisibility(true);
  };

  const handleApplyFilters = useCallback(value => {
    setFiltersData(value);
  }, []);

  const handleDetailsRedirect = userId => {
    history.push("/userDetails", { userId });
  };

  const handleHistoryRedirect = userId => {
    history.push("/userHistory", { userId });
  };

  const userHeaderTable = [
    { title: "Nome / Cargo", width: "10%" },
    { title: "E-mail / ID", width: "10%" },
    isCentralFacOrAdminFac && { title: "Vínculo", width: "3%" },
    { title: "Centro de Custo", width: "6%" },
    { title: "Vencimento e Status CNH", width: "11%", align: "center" },
    { title: "Vencimento e Status CDD", width: "11%", align: "center" },
    { title: "Perfil", width: "5%" },
    { title: "Ações", width: "3%" }
  ];

  return (
    <Layout showHeader>
      <Grid item container xs={12} className="pl-8 pt-8 pr-8">
        <Grid item className={classes.headerRow}>
          <Typography variant="h4" className="mt-3">
            Gestão de Usuários
          </Typography>
        </Grid>
        <Grid item container className="pt-4">
          <Grid item container xs={6}>
            <Grid item xs={8} xl={6}>
              <SearchTextField
                label="Pesquise aqui"
                initialValue={searchValue}
                onChange={setSearchValue}
              />
            </Grid>
            <Grid item className="ml-4" md={2}>
              <UserFilters
                filtersData={filtersData}
                applyFilters={handleApplyFilters}
                isCentralFac={isCentralFacOrAdminFac}
              />
            </Grid>
          </Grid>
          <Grid container item xs={6} justify="flex-end">
            <Grid item xs={5} xl={4} className={`${classes.headerRow} pr-2`}>
              <Button
                fullWidth
                color="primary"
                onClick={exportUsersReport}
                startIcon={
                  usersReportIsLoading ? (
                    <Loading loading size={20} centered={false} color="inherit" />
                  ) : (
                    <Download size={20} />
                  )
                }
              >
                EXPORTAR PLANILHA
              </Button>
            </Grid>
            <Grid item xs={5} xl={4} className={classes.headerRow}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => setRegistrationModalVisibility(!registrationModalVisibility)}
              >
                CADASTRAR USUÁRIO
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="pl-8 pb-8 pt-8 pr-8">
        <CustomTable
          data={users}
          loading={loadingCounter > 0}
          noDataMessage={messages.NO_USERS}
          headerData={userHeaderTable}
          RowComponent={({ item }) => (
            <UserContentTableRow
              item={item}
              handleEditData={handleEditUser}
              handleDetailsRedirect={handleDetailsRedirect}
              isCentralFac={isCentralFacOrAdminFac}
              handleHistoryRedirect={handleHistoryRedirect}
            />
          )}
          optionsRowsPerPage={[5, 10, 20, 30]}
        />
      </Grid>
      <UserRegistrationModal
        isOpen={registrationModalVisibility}
        handleClose={() => setRegistrationModalVisibility(false)}
        listUsersFiltered={listUsersFiltered}
        editableUserId={editableUserId}
        setEditableUserId={setEditableUserId}
      />
    </Layout>
  );
};

const useStyles = makeStyles(theme => ({
  headerRow: {
    height: theme.dimensions.rowHeight
  }
}));

const mapStateToProps = state => {
  return {
    profile: state.user.profile,
    initialPageState: state.managementPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePageState: data => dispatch(updateManagementPage(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
