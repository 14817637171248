import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import Modal from "./Modal";
import { GRAY } from "../../theme";

const ConfirmationModal = props => {
  const {
    secondaryButtonColor,
    secondaryButtonText,
    primaryButtonText,
    handleOpenClose,
    secondaryAction,
    loadingContent,
    primaryAction,
    shouldCancel,
    openedModal,
    shouldExit,
    titleIcon,
    subtitle,
    loading,
    success,
    title
  } = props;
  const classes = useStyles({ secondaryButtonColor });

  return (
    openedModal && (
      <Modal
        primaryButton={
          primaryButtonText
            ? {
              action: () => primaryAction(),
              text: primaryButtonText,
              variant: "contained",
              color: "primary"
            }
            : null
        }
        secondaryButton={
          secondaryButtonText
            ? {
              action: () => secondaryAction(),
              text: secondaryButtonText,
              variant: "outlined",
              color: secondaryButtonColor,
              className: classes.secondaryButton
            }
            : null
        }
        openedModal={openedModal}
        closeModal={() => handleOpenClose()}
        title={title}
        titleIcon={titleIcon}
        closeIcon
        shouldCancel={shouldCancel}
        shouldExit={shouldExit}
        success={success}
        loadingPrimaryButton={loading}
        loadingContent={loadingContent}
      >
        <Typography variant="body1" align="center" className={classes.colorSubtitle}>
          {subtitle}
        </Typography>
      </Modal>
    )
  );
};

const useStyles = makeStyles({
  colorSubtitle: {
    color: GRAY
  },
  secondaryButton: ({ secondaryButtonColor }) => ({
    color: secondaryButtonColor,
    borderColor: secondaryButtonColor
  })
});

export default ConfirmationModal;
