import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const FilterMenuButtons = props => {
  const { handleClear, handleApply } = props;
  const classes = useStyles();

  return (
    <>
      <Button color="primary" className={`${classes.button} mr-4`} onClick={handleClear}>
        Limpar filtros
      </Button>
      <Button color="primary" variant="contained" className={classes.button} onClick={handleApply}>
        Aplicar
      </Button>
    </>
  );
};

const useStyles = makeStyles(() => ({
  button: {
    fontWeight: 400,
    maxHeight: 24
  }
}));

export default FilterMenuButtons;
