import { Grid, Typography, Checkbox, makeStyles, IconButton, Divider } from "@material-ui/core";
import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { DEEP_GRAY, LIGHT_GRAY, PRIMARY_COLOR, SECONDARY_COLOR, WHITE } from "../../../theme";

const FilterOption = props => {
  const {
    label,
    expandible,
    checked,
    handleChange,
    children,
    inderteminate,
    enableDividers,
    className,
    disabled
  } = props;

  const [chevronIsExpanded, setChevronIsExpanded] = useState(false);

  const classes = useStyles({ checked, disabled });

  const Chevron = ({ isExpanded }) => {
    return isExpanded ? (
      <ChevronUp size={20} aria-label="Colapsar" color={SECONDARY_COLOR} />
    ) : (
      <ChevronDown size={20} aria-label="Expandir" color={SECONDARY_COLOR} />
    );
  };

  return (
    <Grid container>
      <Grid
        container
        alignItems="center"
        item
        xs={12}
        className={`${classes.carFilterOption} ${className} pr-1`}
      >
        <Checkbox
          className={`${classes.checkBox} pl-4`}
          checked={checked}
          onChange={handleChange}
          indeterminate={inderteminate}
          disabled={disabled}
        />
        <Typography className={classes.label}>{label}</Typography>
        {!!expandible && (
          <IconButton
            className={classes.chevronIcon}
            onClick={() => setChevronIsExpanded(!chevronIsExpanded)}
          >
            <Chevron isExpanded={chevronIsExpanded} />
          </IconButton>
        )}
      </Grid>
      {chevronIsExpanded && (
        <Grid container item xs={12} className={classes.childrenList}>
          {enableDividers && <Divider className="divider" />}
          {children}
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  chevronIcon: {
    marginLeft: "auto"
  },
  carFilterOption: ({ checked }) => ({
    backgroundColor: checked ? "#00205b0d" : WHITE,
    height: 48
  }),
  checkBox: ({ disabled }) => ({ color: disabled ? LIGHT_GRAY : SECONDARY_COLOR }),
  childrenList: {
    maxHeight: "250px",
    overflow: "auto"
  },
  label: ({ disabled }) => ({ color: disabled ? DEEP_GRAY : PRIMARY_COLOR })
}));

export default FilterOption;
