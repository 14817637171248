import React, { useState, useRef } from "react";
import { TableCell, TableRow, makeStyles, Typography, Grid, IconButton } from "@material-ui/core";
import { MoreHorizontal } from "react-feather";
import { DARK_GRAY, WHITE, LIGHT_GRAY, LIGHT_GRAY2, SECONDARY_COLOR } from "../../../theme";
import { profileStyle, expireStatus } from "../../../shared/utils/enum";
import { Link, LinkOff } from "@material-ui/icons";
import StatusBox from "../../../shared/components/StatusBox";
import TooltipItem from "../../../shared/components/TooltipItem";
import FloatingDropdown from "../../../shared/components/FloatingDropdown";
import { formatStringField } from "../../../shared/utils/Utils";

const UserContentTableRow = props => {
  const { item, handleEditData, isCentralFac, isAdminFac, handleDetailsRedirect, handleHistoryRedirect } = props;

  const classes = useStyles({ disabled: !item.active });

  const actionsMenuRef = useRef();
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  const currentDate = new Date().setHours(0, 0, 0, 0);
  const cnhExpireDate = item.license.expireDate && new Date(item.license.expireDate);
  const courseExpireDate = item.course.expireDate && new Date(item.course.expireDate);
  const profileEnum = profileStyle.find(profile => profile.id === item.profile.profileId);
  let cnhStatusEnum = currentDate <= cnhExpireDate ? expireStatus.valid : expireStatus.invalid;
  let courseStatusEnum =
    currentDate <= courseExpireDate ? expireStatus.valid : expireStatus.invalid;
  const iconColor = item.active ? "secondary" : "inherit";
  const disabledStatusEnumStyle = {
    backgroundColor: LIGHT_GRAY,
    borderColor: LIGHT_GRAY,
    textColor: WHITE
  };

  if (!item.active) {
    cnhStatusEnum = {
      ...cnhStatusEnum,
      style: disabledStatusEnumStyle
    };
    courseStatusEnum = {
      ...courseStatusEnum,
      style: disabledStatusEnumStyle
    };
  }

  return (
    <TooltipItem tooltip="Usuário inativo" disabled={item.active} followCursor>
      <TableRow hover className={classes.row} onClick={() => handleDetailsRedirect(item.id)}>
        <TableCell className={classes.bodyCell} align="left">
          <Grid item xs={12}>
            <TooltipItem
              tooltip={item.name}
              disabled={!item.active}
              onlyWhenOverflown
              className={classes.cellTooltip}
            >
              <Typography className={`${classes.typography} ${classes.overflow}`} variant="subtitle2">
                {item.name}
              </Typography>
            </TooltipItem>
          </Grid>
          <Grid item xs={12}>
            <TooltipItem
              tooltip={item.role?.name}
              disabled={!item.active}
              onlyWhenOverflown
              className={classes.cellTooltip}
            >
              <Typography className={`${classes.typography} ${classes.overflow}`} variant="body2">
                {formatStringField(item.role?.name)}
              </Typography>
            </TooltipItem>
          </Grid>
        </TableCell>
        <TableCell className={classes.bodyCell} align="left">
          <Grid item xs={12}>
            <TooltipItem
              tooltip={item.email}
              disabled={!item.active}
              onlyWhenOverflown
              className={classes.cellTooltip}
            >
              <Typography
                className={`${classes.typography} ${classes.overflow}`}
                variant="subtitle2"
              >
                {item.email}
              </Typography>
            </TooltipItem>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.typography} variant="body2">
              {formatStringField(item.adUser)}
            </Typography>
          </Grid>
        </TableCell>
        {(isCentralFac || isAdminFac) && (
          <TableCell className={classes.bodyCell} align="left">
            {item.hasCar ? (
              <TooltipItem tooltip="Veículo vinculado" disabled={!item.active}>
                <Link color={iconColor} />
              </TooltipItem>
            ) : (
              <TooltipItem tooltip="Veículo não vinculado" disabled={!item.active}>
                <LinkOff color={iconColor} />
              </TooltipItem>
            )}
          </TableCell>
        )}
        <TableCell className={classes.bodyCell} align="left">
          {formatStringField(item.costCenter.name)}
        </TableCell>
        <TableCell className={classes.bodyCell} align="left">
          <Grid container item xs={12} justify="center">
            <Typography className={classes.statusColumn}>
              {item.license.expireDate ? cnhExpireDate.toLocaleDateString("pt-BR") : "--"}
            </Typography>
            {item.license.expireDate && (
              <StatusBox
                boxStyle={cnhStatusEnum.style}
                text={cnhStatusEnum.label}
                textColor={cnhStatusEnum.style.textColor}
                className="ml-2"
              />
            )}
          </Grid>
        </TableCell>
        <TableCell className={classes.bodyCell} align="left">
          <Grid container item xs={12} justify="center">
            <Typography className={classes.statusColumn}>
              {item.course.expireDate ? courseExpireDate.toLocaleDateString("pt-BR") : "--"}
            </Typography>
            {item.course.expireDate && (
              <StatusBox
                boxStyle={courseStatusEnum.style}
                text={courseStatusEnum.label}
                textColor={courseStatusEnum.style.textColor}
                className="ml-2"
              />
            )}
          </Grid>
        </TableCell>
        <TableCell className={classes.bodyCell} align="left">
          <Typography className={`${classes.typography} ${classes.labelTexts}`}>
            {profileEnum.label}
          </Typography>
        </TableCell>
        <TableCell className={classes.bodyCell} align="left">
          <IconButton
            id="menu"
            aria-label="Exibir ações"
            ref={actionsMenuRef}
            onClick={e => {
              e.stopPropagation();
              setShowDropdownMenu(true)
            }}
          >
            <MoreHorizontal size={24} color={SECONDARY_COLOR} />
          </IconButton>
          <FloatingDropdown
            disabled={!showDropdownMenu}
            parentRef={actionsMenuRef.current}
            justifyStart
            className="mr-3"
            onOutsideClick={() => setShowDropdownMenu(false)}
          >
            <Typography
              className={`p-4 ${classes.dropdownItem}`}
              onClick={e => {
                e.stopPropagation();
                handleDetailsRedirect(item.id);
              }}
            >
              Visualizar
            </Typography>
            {item.active && (
              <Typography
                className={`p-4 ${classes.dropdownItem}`}
                onClick={e => {
                  e.stopPropagation();
                  handleEditData(item.id);
                }}
              >
                Editar
              </Typography>
            )}
            <Typography
              className={`p-4 ${classes.dropdownItem}`}
              onClick={e => {
                e.stopPropagation();
                handleHistoryRedirect(item.id);
              }}
            >
              Histórico
            </Typography>
          </FloatingDropdown>
        </TableCell>
      </TableRow>
    </TooltipItem>
  );
};

const useStyles = makeStyles(() => ({
  row: ({ disabled }) => ({
    color: disabled ? LIGHT_GRAY : undefined,
    "&:hover": {
      cursor: "pointer"
    }
  }),
  typography: ({ disabled }) => ({
    color: disabled ? "inherit" : undefined
  }),
  bodyCell: ({ disabled }) => ({
    "&.MuiTableCell-root": {
      padding: "10px"
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "inherit"
    },
    fontSize: 16,
    color: disabled ? "inherit" : DARK_GRAY
  }),
  boxLabels: {
    border: "1px solid",
    borderRadius: 4,
    padding: 5
  },
  labelTexts: {
    fontSize: 12,
    fontWeight: 700
  },
  overflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  cellTooltip: {
    fontSize: 14
  },
  statusColumn: ({ disabled }) => ({
    width: "110px",
    textAlign: "center",
    color: disabled ? "inherit" : "black"
  }),
  dropdownItem: {
    "&:hover": {
      backgroundColor: LIGHT_GRAY2,
      cursor: "pointer"
    }
  }
}));

export default UserContentTableRow;
