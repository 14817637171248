import ExportService from "../../services/ExportService";
import { downloadResponseFile } from "../../shared/utils/RequestUtil";

class ReportsManagementManager {
  async exportChecklistsAndReports(startDate, endDate) {
    const response = await ExportService.exportChecklistsAndReports(startDate, endDate);
    downloadResponseFile(response, "Relatorio checklists e relatos.xlsx");
  }
}

export default new ReportsManagementManager();
