import React from "react";
import { Grid, IconButton, makeStyles, Paper } from "@material-ui/core";
import VehicleDetailsHeader from "../../../shared/components/Details/VehicleDetailsHeader";
import { Link, ChevronRight } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const AssociatedVehicleCard = props => {
  const { vehicleData } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleRedirect = () => {
    history.push("/vehicleDetails", { vehicleId: vehicleData.id });
  };

  return (
    <Paper className={classes.paper} onClick={handleRedirect}>
      <Grid container alignItems="center">
        <Grid item className="m-6 mr-0">
          <Link color="secondary" />
        </Grid>
        <Grid item className={`m-6 ${classes.vehicleDetailsItem}`}>
          <VehicleDetailsHeader
            vehicleData={vehicleData}
            startValidity={vehicleData.privateCar?.startValidity}
            statusBoxProps={{ justifyEnd: true }}
          />
        </Grid>
        <Grid item className={classes.chevronIcon}>
          <IconButton>
            <ChevronRight color="primary" />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles(() => ({
  paper: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  chevronIcon: {
    marginLeft: "auto",
    marginRight: "8px"
  },
  vehicleDetailsItem: {
    flex: 1
  }
}));

export default AssociatedVehicleCard;
