import axios from "../axiosInstance";

class AuthenticationService {
  async getAuthenticate(adToken) {
    try {
      return await axios.get("authentications/login", {
        handleError: false,
        headers: {
          Authorization: `Bearer ${adToken}`
        }
      });
    } catch (error) {
      return { data: { requestError: true } };
    }
  }
}

export default new AuthenticationService();
