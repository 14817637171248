import React, { useCallback, useEffect, useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { SECONDARY_COLOR } from "../../../theme";
import HistoryCardContentFields from "./HistoryCardContentFields";
import {
  formatDateTimeToLiteral,
  formatNumberToCurrencyString,
  formatOdometer
} from "../../../shared/utils/Utils";
import { carType, driverLicenseTypes } from "../../../shared/utils/enum";

const HistoryCardContent = props => {
  const { oldData, newData, isLoadingAdditionalData, isCreation } = props;

  const classes = useStyles();

  const [formatedOldData, setFormatedOldData] = useState({});
  const [formatedNewData, setFormatedNewData] = useState({});
  const [isDriverExpanded, setIsDriverExpanded] = useState(false);
  const [isCarInformationExpanded, setIsCarInformationExpanded] = useState(false);
  const [isCarContractExpanded, setIsCarContractExpanded] = useState(false);

  const compareAndFormatData = useCallback((data1, data2) => {
    const driverIsEdited = data1?.driver?.id !== data2?.driver?.id;

    return (
      data1 && {
        vehicleData: {
          carLicensePlate: {
            value: data1?.car?.carLicensing?.carLicensePlate,
            isEdited:
              data1?.car?.carLicensing?.carLicensePlate !==
              data2?.car?.carLicensing?.carLicensePlate,
            label: "Placa"
          },
          fleet: {
            value: data1?.car?.carInformation?.fleet?.fleetType?.name,
            isEdited:
              data1?.car?.carInformation?.fleet?.id !== data2?.car?.carInformation?.fleet?.id,
            label: "Tipo da frota "
          },
          carType: {
            value: Object.values(carType).find(
              _carType => _carType.id === data1?.car?.carInformation?.fleet?.carType?.id
            )?.text,
            isEdited:
              data1?.car?.carInformation?.fleet?.carType?.id !==
              data2?.car?.carInformation?.fleet?.carType?.id,
            label: "Tipo do veículo"
          },
          site: {
            value: data1?.car?.carInformation?.site?.name,
            isEdited: data1?.car?.carInformation?.site?.id !== data2?.car?.carInformation?.site?.id,
            label: "Unidade do veículo"
          },
          year: {
            value: data1?.car?.carInformation?.year,
            isEdited:
              (data1?.car?.carInformation?.year || null) !==
              (data2?.car?.carInformation?.year || null),
            label: "Ano modelo"
          },
          carBrand: {
            value: data1?.car?.carInformation?.carModel?.carBrand?.name,
            isEdited:
              data1?.car?.carInformation?.carModel?.carBrand?.id !==
              data2?.car?.carInformation?.carModel?.carBrand?.id,
            label: "Marca"
          },
          carModel: {
            value: data1?.car?.carInformation?.carModel?.model,
            isEdited:
              data1?.car?.carInformation?.carModel?.id !== data2?.car?.carInformation?.carModel?.id,
            label: "Modelo"
          },
          chassisNumber: {
            value: data1?.car?.carInformation?.chassisNumber,
            isEdited:
              data1?.car?.carInformation?.chassisNumber !==
              data2?.car?.carInformation?.chassisNumber,
            label: "Número do chassi"
          },
          odometer: {
            value: formatOdometer(data1?.car?.carInformation?.odometer),
            isEdited:
              (data1?.car?.carInformation?.odometer || null) !==
              (data2?.car?.carInformation?.odometer || null),
            label: "Odômetro"
          },
          fuel: {
            value: data1?.car?.carInformation?.fuel?.name,
            isEdited: data1?.car?.carInformation?.fuel?.id !== data2?.car?.carInformation?.fuel?.id,
            label: "Combustível"
          },
          color: {
            value: data1?.car?.carInformation?.color?.name,
            isEdited:
              data1?.car?.carInformation?.color?.id !== data2?.car?.carInformation?.color?.id,
            label: "Cor"
          },
          immobilized: {
            value: data1?.car?.carInformation?.immobilized,
            isEdited:
              data1?.car?.carInformation?.immobilized !== data2?.car?.carInformation?.immobilized,
            label: "Imobilizado"
          },
          carDocument: {
            value: data1?.car?.carLicensing?.carDocument,
            isEdited:
              data1?.car?.carLicensing?.carDocument !== data2?.car?.carLicensing?.carDocument,
            label: "Renavan"
          },
          carLicensingStatus: {
            value: data1?.car?.carLicensing?.carLicensingStatus?.name,
            isEdited:
              data1?.car?.carLicensing?.carLicensingStatus?.id !==
              data2?.car?.carLicensing?.carLicensingStatus?.id,
            label: "Licenciamento"
          },
          hasFuelCard: {
            value: data1?.car?.carLicensing?.hasFuelCard ? "Sim" : "Não",
            isEdited:
              data1?.car?.carLicensing?.hasFuelCard !== data2?.car?.carLicensing?.hasFuelCard,
            label: "Cartão combustível"
          },
          hasTollTag: {
            value: data1?.car?.carLicensing?.hasTollTag ? "Sim" : "Não",
            isEdited: data1?.car?.carLicensing?.hasTollTag !== data2?.car?.carLicensing?.hasTollTag,
            label: "TAG pedágio"
          },
          isBorder: {
            value: data1?.car?.carLicensing?.isBorder ? "Sim" : "Não",
            isEdited: data1?.car?.carLicensing?.isBorder !== data2?.car?.carLicensing?.isBorder,
            label: "Fronteira"
          },
          has35PercentDiscount: {
            value: data1?.car?.carLicensing?.has35PercentDiscount ? "Sim" : "Não",
            isEdited:
              data1?.car?.carLicensing?.has35PercentDiscount !==
              data2?.car?.carLicensing?.has35PercentDiscount,
            label: "35% Desconto"
          },
          isBulletProof: {
            value: data1?.car?.carLicensing?.isBulletProof ? "Sim" : "Não",
            isEdited:
              data1?.car?.carLicensing?.isBulletProof !== data2?.car?.carLicensing?.isBulletProof,
            label: "Blindado"
          },
          historyCarStatus: {
            value: data1?.car?.historyCarStatus?.status?.name,
            isEdited: data1?.car?.historyCarStatus?.id !== data2?.car?.historyCarStatus?.id,
            label: "Status"
          },
          carStatusDetails: {
            value: data1?.car?.historyCarStatus?.statusDetails?.name,
            isEdited:
              data1?.car?.historyCarStatus?.statusDetails?.id !==
              data2?.car?.historyCarStatus?.statusDetails?.id,
            label: "Destinação"
          },
          reason: {
            value: data1?.car?.historyCarStatus?.reason,
            isEdited: data1?.car?.historyCarStatus?.reason !== data2?.car?.historyCarStatus?.reason,
            label: "Motivo da baixa"
          },
          carStatusStartDate: {
            value: formatDateTimeToLiteral(data1?.car?.historyCarStatus?.startDate, "pt-br", false),
            isEdited:
              data1?.car?.historyCarStatus?.startDate !== data2?.car?.historyCarStatus?.startDate,
            label: "Data da baixa"
          },
          carStatusCost: {
            value: `R$ ${formatNumberToCurrencyString(data1?.car?.historyCarStatus?.cost)}`,
            isEdited: data1?.car?.historyCarStatus?.cost !== data2?.car?.historyCarStatus?.cost,
            label: "Valor da baixa"
          }
        },
        carContractData: {
          provider: {
            value: data1?.car?.carContract?.carProvider?.name,
            isEdited:
              data1?.car?.carContract?.carProvider?.Id !== data2?.car?.carContract?.carProvider?.Id,
            label: "Fornecedor"
          },
          maintenanceProvider: {
            value: data1?.car?.carContract?.carMaintenanceProvider?.name,
            isEdited:
              data1?.car?.carContract?.carMaintenanceProvider?.Id !==
              data2?.car?.carContract?.carMaintenanceProvider?.Id,
            label: "Fornecedor manutenção"
          },
          contractNumber: {
            value: data1?.car?.carContract?.contractNumber,
            isEdited:
              data1?.car?.carContract?.contractNumber !== data2?.car?.carContract?.contractNumber,
            label: "Nº do contrato"
          },
          monthlyRate: {
            value: `R$ ${formatNumberToCurrencyString(data1?.car?.carContract?.monthlyRate)}`,
            isEdited: data1?.car?.carContract?.monthlyRate !== data2?.car?.carContract?.monthlyRate,
            label: "Valor mensal"
          },
          startDate: {
            value: formatDateTimeToLiteral(data1?.car?.carContract?.startDate, "pt-BR", false),
            isEdited: data1?.car?.carContract?.startDate !== data2?.car?.carContract?.startDate,
            label: "Data de aquisição"
          },
          contractValidity: {
            value: formatDateTimeToLiteral(data1?.car?.carContract?.validity, "pt-BR", false),
            isEdited: data1?.car?.carContract?.validity !== data2?.car?.carContract?.validity,
            label: "Vigência"
          }
        },
        driver: {
          name: {
            value: data1?.driver?.name,
            isEdited: driverIsEdited,
            label: "Nome"
          },
          role: {
            value: data1?.driver?.role?.name,
            isEdited: driverIsEdited,
            label: "Cargo"
          },
          email: {
            value: data1?.driver?.email,
            isEdited: driverIsEdited,
            label: "Email"
          },
          adUser: {
            value: data1?.driver?.adUser,
            isEdited: driverIsEdited,
            label: "ID"
          },
          profile: {
            value: data1?.driver?.profile?.profileName,
            isEdited: false,
            label: "Perfil"
          },
          costCenter: {
            value: data1?.driver?.costCenter?.name,
            isEdited: false,
            label: "Cód. Centro de Custo"
          },
          regional: {
            value: data1?.driver?.management?.regional?.name,
            isEdited: false,
            label: "Região/Diretória"
          },
          management: {
            value: data1?.driver?.management?.name,
            isEdited: false,
            label: "Gerência"
          },
          expLicense: {
            value: formatDateTimeToLiteral(data1?.driver?.license?.expireDate, "pt-BR", false),
            isEdited: false,
            label: "Exp. da CNH"
          },
          licenseType: {
            value: driverLicenseTypes.find(x => x.value === data1?.driver?.license?.licenseTypeId)
              ?.label,
            isEdited: false,
            label: "Tipo CNH"
          },
          expCourse: {
            value: formatDateTimeToLiteral(data1?.driver?.course?.expireDate, "pt-BR", false),
            isEdited: false,
            label: "Exp. do CDD"
          }
        }
      }
    );
  }, []);

  useEffect(() => {
    setFormatedOldData(compareAndFormatData(oldData, newData));
    setFormatedNewData(compareAndFormatData(newData, oldData));
  }, [oldData, newData, compareAndFormatData]);

  return (
    <Grid container>
      <Grid item xs={6} className={classes.newData}>
        <Grid container>
          <Grid item xs={12} className="ml-2">
            <Typography variant="overline" component="h3" color="primary">
              {oldData ? "Dados Atualizados" : "Dados Cadastrais"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <HistoryCardContentFields
              data={formatedNewData}
              isNewData
              isCreation={isCreation}
              isLoadingDriver={isLoadingAdditionalData}
              isDriverExpanded={isDriverExpanded}
              setIsDriverExpanded={setIsDriverExpanded}
              isCarInformationExpanded={isCarInformationExpanded}
              setIsCarInformationExpanded={setIsCarInformationExpanded}
              isCarContractExpanded={isCarContractExpanded}
              setIsCarContractExpanded={setIsCarContractExpanded}
            />
          </Grid>
        </Grid>
      </Grid>
      {formatedOldData && (
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12} className="ml-2">
              <Typography variant="overline" component="h3">
                Dados Antigos
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.oldData}>
              <HistoryCardContentFields
                data={formatedOldData}
                isLoadingDriver={isLoadingAdditionalData}
                isDriverExpanded={isDriverExpanded}
                setIsDriverExpanded={setIsDriverExpanded}
                isCarInformationExpanded={isCarInformationExpanded}
                setIsCarInformationExpanded={setIsCarInformationExpanded}
                isCarContractExpanded={isCarContractExpanded}
                setIsCarContractExpanded={setIsCarContractExpanded}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  newData: {
    color: SECONDARY_COLOR
  },
  oldData: {
    height: "100%"
  }
}));

export default HistoryCardContent;
