import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import RadioSelection from "../../../shared/components/RadioSelection";
import SearchText from "../../../shared/components/SearchText";
import { ReactComponent as SiteIcon } from "../../../shared/img/SiteIcon.svg";
import { connect } from "react-redux";
import * as actions from "../../../store/BookingStore";
import BookingManager from "../BookingManager";
import { messages, routes } from "../../../shared/utils/Constants";
import { Redirect } from "react-router-dom";
import NewBooking from "./NewBooking";

const SelectSite = props => {
  const { booking, updateBooking, userPoleId } = props;

  const [loading, setLoading] = useState(false);
  const [listSite, setListSite] = useState([]);
  const [selectedSite, setSelectedSite] = useState(booking.siteId);
  const [selectedSiteName, setSelectedSiteName] = useState(booking.siteName);
  const [redirect, setRedirect] = useState();

  window.onbeforeunload = function () {
    return messages.ALERT_RELOAD_PAGE;
  };

  const refSelectedSite = useRef(selectedSite);

  const userSites = listSite && listSite.filter(e => e.pole.id === userPoleId);
  const otherSites = listSite && listSite.filter(e => e.pole.id !== userPoleId);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const result = await BookingManager.listSite(true);

      if (result) {
        result.forEach(item => (item.icon = SiteIcon));
        setListSite(result);
        if (!refSelectedSite.current) {
          const defaultSelected = result.find(e => e.pole.id === userPoleId);

          setSelectedSite(defaultSelected?.id);
          setSelectedSiteName(defaultSelected?.name);
        }
      }
      setLoading(false);
    };

    getData();
  }, [userPoleId]);

  useEffect(() => {
    !booking.nextStep && setRedirect(<Redirect to={routes.bookingSteps.SELECT_DRIVER.route} />);
  }, [booking]);

  const handleSubmit = () => {
    updateBooking({
      ...booking,
      siteId: selectedSite,
      siteName: selectedSiteName,
      nextStep: routes.bookingSteps.SELECT_CAR_AND_TRIP.id
    });

    setRedirect(<Redirect to={routes.bookingSteps.SELECT_CAR_AND_TRIP.route} />);
  };

  const handlePrevious = () => {
    setRedirect(<Redirect to={routes.bookingSteps.SELECT_DRIVER.route} />);
  };

  useEffect(() => {
    if (listSite.length > 0 && selectedSite) {
      const siteName = listSite.find(site => site.id === selectedSite).name;
      setSelectedSiteName(siteName);
    }
  }, [selectedSite, listSite]);

  const handleOnChange = e => {
    const selectedId = parseInt(e.target.value);
    setSelectedSite(selectedId);
  };

  return (
    <NewBooking
      loadingContent={loading}
      disabled={!selectedSite}
      redirect={redirect}
      subtitle="Selecione a unidade:"
      handleNext={handleSubmit}
      handlePrevious={handlePrevious}
    >
      <Grid item xs={12}>
        <RadioSelection
          optionsList={userSites}
          handleChange={e => handleOnChange(e)}
          value={selectedSite}
          emptyListMessage={messages.NO_AVAILABLE_SITES}
        />
      </Grid>
      <Grid item xs={12}>
        <SearchText
          placeholder="Selecione outra unidade"
          value={otherSites.find(e => e.id === selectedSite) || null}
          optionsList={otherSites}
          handleChange={(_, site) => setSelectedSite(site?.id)}
          highlightBackground
        />
      </Grid>
    </NewBooking>
  );
};

const mapStateToProps = state => {
  return {
    booking: state.booking,
    screenDimensions: {
      height: state.window.height,
      width: state.window.width
    },
    userPoleId: state.user.pole.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateBooking: data => dispatch(actions.updateBooking(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectSite);
