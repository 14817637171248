// Types
const UPDATE_MANAGEMENT_PAGE = "managementPage/UPDATE_MANAGEMENT_PAGE";
const RESET_MANAGEMENT_PAGE = "managementPage/RESET_MANAGEMENT_PAGE";

// Reducer
export const INITIAL_STATE = {
  pathname: "",
  pagination: {},
  initialSearch: "",
  filters: {}
};

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_MANAGEMENT_PAGE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case RESET_MANAGEMENT_PAGE: {
      return { ...INITIAL_STATE, ...action.payload };
    }
    default: {
      return state;
    }
  }
}

// Action creators

export const updateManagementPage = state => {
  return {
    type: UPDATE_MANAGEMENT_PAGE,
    payload: state
  };
};

export const resetManagementPage = state => {
  return {
    type: RESET_MANAGEMENT_PAGE,
    payload: state
  };
};
