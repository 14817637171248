import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  showNotification,
  NOTIFY_ERROR,
  NOTIFY_WARNING,
  NOTIFY_INFORMATION,
  NOTIFY_SUCCESS
} from "../../store/NotificationStore";

const useToaster = () => {
  const dispatch = useDispatch();

  const showErrorToast = useCallback(
    message => dispatch(showNotification(NOTIFY_ERROR, message)),
    [dispatch]
  );
  const showWarningToast = useCallback(
    message => dispatch(showNotification(NOTIFY_WARNING, message)),
    [dispatch]
  );
  const showSuccessToast = useCallback(
    message => dispatch(showNotification(NOTIFY_SUCCESS, message)),
    [dispatch]
  );
  const showInfoToast = useCallback(
    message => dispatch(showNotification(NOTIFY_INFORMATION, message)),
    [dispatch]
  );

  return { showErrorToast, showWarningToast, showInfoToast, showSuccessToast };
};

export default useToaster;
