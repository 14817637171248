import axios from "../axiosInstance";

class SiteService {
  async listSite(isRollouted = null) {
    const parameters = isRollouted ? `?isRollouted=${isRollouted}` : "";
    return axios.get(`sites${parameters}`);
  }

  async getCarModelList(siteId) {
    return axios.get(`sites/${siteId}/carmodels`);
  }

  async listUnavailableSchedules(startDate, timezone, siteId, carModelId) {
    return axios.get(`sites/${siteId}/carmodels/${carModelId}/unavailable/schedules`, {
      params: { startDate, timezone }
    });
  }
}

export default new SiteService();
