import {
  format,
  addDays,
  startOfWeek,
  endOfWeek,
  addMinutes,
  startOfDay,
  endOfDay,
  subSeconds,
  millisecondsToHours
} from "date-fns";
import {
  dateTimeViews,
  checklistTypeAnswer,
  tripType,
  carStatus,
  noStatusStyle,
  driverProfiles
} from "./enum";
import {
  DARK_GREEN,
  DARK_YELLOW,
  LIGHT_BLUE,
  LIGHT_YELLOW,
  RED,
  SECONDARY_COLOR,
  THIN_BLUE
} from "../../theme";
import {
  messages,
  EXCLUSIVE_FLEET_TYPE_ID,
  SHARED_FLEET_TYPE_ID,
  MEDIUM_WINDOW_WIDTH,
  checklistTolerance
} from "./Constants";

export const onlyNumbers = text => {
  return text && text.replace(/\D+/g, "");
};

export const getCurrentWeek = currentDate => {
  const { ptBR } = require("date-fns/locale");
  return (
    format(startOfWeek(currentDate), "dd - ") +
    format(endOfWeek(currentDate), "dd 'de' ") +
    format(currentDate, "MMMM ", { locale: ptBR }) +
    format(currentDate, "yyyy")
  );
};

export const checkDateTimeInBetween = (
  weekStart,
  weekEnd,
  bookingDay,
  bookingMonth,
  bookingYear
) => {
  let bookingDate = new Date(
    parseInt(bookingYear),
    parseInt(bookingMonth) - 1,
    parseInt(bookingDay)
  );

  return bookingDate >= weekStart && bookingDate <= weekEnd;
};

export const formatStringField = value => value || "--";

export const formatCurrentDate = (currentDate, typeId) => {
  const { ptBR } = require("date-fns/locale");
  const formattedCurrentWeek = getCurrentWeek(currentDate);

  return (
    (typeId === dateTimeViews.dayView.id &&
      format(currentDate, "dd 'de' MMMM, eeee", { locale: ptBR })) ||
    (typeId === dateTimeViews.weekView.id && formattedCurrentWeek)
  );
};

export const formatDateTime = (currentDate, typeId, index) => {
  const { ptBR } = require("date-fns/locale");

  const newDay = addDays(currentDate, index);
  const formattedDate =
    (typeId === dateTimeViews.dayView.id &&
      format(newDay, "dd 'de' MMMM, eeee", { locale: ptBR })) ||
    (typeId === dateTimeViews.weekView.id && getCurrentWeek(newDay));

  const newDate =
    (typeId === dateTimeViews.dayView.id && newDay) ||
    (typeId === dateTimeViews.weekView.id && newDay);

  return { formattedDate: formattedDate, newDate: newDate };
};

export const getYear = dateString => {
  return dateString.split("-")[0];
};

export const getMonth = dateString => {
  return dateString.split("-")[1];
};

export const getDay = dateString => {
  return dateString.split("-")[2].split("T")[0];
};

export const makeBookings = (bookings, fleet, viewType) => {
  let formattedBookings = [];
  let carPriorityId;
  let carInfo;
  let bookingTitle;
  let shouldGroup = viewType === dateTimeViews.dayView.en_type;

  const createTooltipObject = booking => ({
    id: booking.id,
    title: carInfo,
    startDate: booking.startJourney,
    endDate: booking.finishedDateTime || booking.finishJourney,
    bookingInfo: bookingTitle
  });

  const mountBookingTitle = booking => {
    return `Reserva ${booking?.schedulerId !== booking?.driverId ? "para" : "pessoal para"} 
        ${booking?.driverName}`;
  };

  const mountBookingObject = props => {
    return {
      title: props?.title,
      priorityId: props?.priorityId,
      startDate: props?.startDate,
      endDate: props?.endDate,
      bookingInfo: props?.bookingInfo,
      bookingTooltip: props?.bookingTooltip,
      backgroundColor: props?.backgroundColor
    };
  };

  bookings?.forEach((booking, index) => {
    bookingTitle = mountBookingTitle(booking);
    fleet?.forEach(car => {
      if (car.id === booking.carId) {
        carPriorityId = `${car.carLicensePlate}-${car.carSiteName}-${car.id}`;
        carInfo = `${car.carSiteName}: ${car.carModel} ${car.carLicensePlate}`;
      }
    });

    let bookingProps = {};

    if (shouldGroup) {
      bookingProps = {
        title:
          booking.bookingTypeId === tripType.maintenance
            ? messages.MAINTENANCE_TITLE
            : bookingTitle,
        priorityId: carPriorityId,
        startDate: booking.startJourney,
        endDate: booking.finishedDateTime || booking.finishJourney,
        bookingInfo: bookingTitle,
        bookingTooltip: createTooltipObject(booking),
        backgroundColor: booking.bookingTypeId === tripType.maintenance ? LIGHT_YELLOW : THIN_BLUE
      };
    } else {
      bookingProps = {
        title:
          booking.bookingTypeId === tripType.maintenance ? messages.MAINTENANCE_TITLE : carInfo,
        startDate: booking.startJourney,
        endDate: booking.finishedDateTime || booking.finishJourney,
        bookingInfo: bookingTitle,
        bookingTooltip: createTooltipObject(booking),
        backgroundColor: booking.bookingTypeId === tripType.maintenance ? LIGHT_YELLOW : THIN_BLUE
      };
    }

    formattedBookings[index] = mountBookingObject(bookingProps);
  });
  return formattedBookings;
};

export const makeFleetList = fleet => {
  if (!fleet) return;

  let formattedFleetList = [];

  fleet.forEach((car, index) => {
    formattedFleetList[index] = {
      id: `${car.carLicensePlate}-${car.carSiteName}-${car.id}`,
      text: `${car.carModel} - ${car.carLicensePlate}`,
      color: LIGHT_BLUE
    };
  });

  return formattedFleetList;
};

export const makeResources = priorities => {
  return [
    {
      fieldName: "priorityId",
      title: "Fleet",
      instances: priorities
    }
  ];
};

export const getSiteTagColor = (fleet, carSiteName) => {
  const possibleSiteTagColors = [DARK_GREEN, SECONDARY_COLOR, DARK_YELLOW, RED];
  const fleetSites = [...new Set(fleet.map(car => car.carSiteName))];
  const siteIndex = fleetSites.indexOf(carSiteName);

  return possibleSiteTagColors[siteIndex % possibleSiteTagColors.length];
};

export const obterIntervalosDePeriodo = (horarioInicio, horarioFim, tamanhoIntervalo) => {
  let listaHorarios = [];
  let contador = 0;
  while (horarioFim.isSameOrAfter(horarioInicio)) {
    listaHorarios[contador] = horarioInicio;
    horarioInicio = horarioInicio.clone().add(tamanhoIntervalo, "minutes");
    contador++;
  }

  return listaHorarios;
};

export const checkAnswerSet = (answer, isCritical) => {
  let verified;

  if (isCritical)
    if (answer.checklistTypeAnswerId === checklistTypeAnswer.notOk)
      verified = !!answer.observations;
    else verified = answer.checklistTypeAnswerId === checklistTypeAnswer.ok;
  else
    verified =
      answer.checklistTypeAnswerId === checklistTypeAnswer.notOk ||
      answer.checklistTypeAnswerId === checklistTypeAnswer.ok;

  return verified;
};

export const getNextQuarterDateTime = time => {
  let hour = time.getHours();
  let minutes = time.getMinutes();

  if (minutes < 15) {
    minutes = 15;
  } else if (minutes < 30) {
    minutes = 30;
  } else if (minutes < 45) {
    minutes = 45;
  } else {
    hour = hour + 1;
    minutes = 0;
  }

  return new Date(time.getFullYear(), time.getMonth(), time.getDate(), hour, minutes, 0);
};

export const convertToFullHours = (dateTime, roundUp = false) => {
  let formatedDate = new Date(dateTime);
  const minutes = formatedDate.getMinutes();
  let increment = 0;

  if (minutes === 0 || minutes === 15 || minutes === 30 || minutes === 45)
    return addMinutes(formatedDate, increment);

  switch (minutes) {
    case minutes > 45:
      increment = roundUp ? 60 - minutes : 45 - minutes;
      break;
    case minutes > 30:
      increment = roundUp ? 45 - minutes : 30 - minutes;
      break;
    case minutes > 15:
      increment = roundUp ? 30 - minutes : 15 - minutes;
      break;
    case minutes > 0:
      increment = roundUp ? 15 - minutes : 0 - minutes;
      break;
    default:
      break;
  }

  return addMinutes(formatedDate, increment);
};

export const dateAndTimeToISOFormat = (date, timeString) => {
  const [hours, minutes] = timeString.split(":");
  const datetimeString = date.setHours(hours, minutes, "00");

  return new Date(datetimeString).toISOString();
};

export const formatDateTimeToLiteral = (date, locale, includeTime = true) => {
  if (!date || isNaN(new Date(date))) return "--";

  date = new Date(date);
  const timeOptions = { hour: "2-digit", minute: "2-digit" };

  let dateString = `${date.toLocaleDateString(locale)}`;

  if (includeTime) dateString += ` às ${date.toLocaleTimeString(locale, timeOptions)}`;

  return dateString;
};

export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const addThousandSeparator = number => new Intl.NumberFormat("pt-br").format(number);

export const formatOdometer = number => {
  if(number != null)
    return addThousandSeparator(number).concat(" Km");
  
  return "--";
};

export const convertCurrencyStringToNumber = (string, decimalSeparator = ",") => {
  if (!string || typeof string !== "string") return;

  const firstDecimalIndex = string.indexOf(decimalSeparator) + 1;
  const numberOfDecimals = firstDecimalIndex > 0 ? string.length - firstDecimalIndex : 0;

  return Number(onlyNumbers(string)) / 10 ** numberOfDecimals;
};

export const formatNumberToCurrencyString = (number, localeCurrency = "pt-BR") => {
  if (typeof number !== "number" || isNaN(Number(number))) return "--";

  return Number(number).toLocaleString(localeCurrency, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const checkIfDateIsContainedInListSchedules = (listSchedules, startDateTime, endDateTime) =>
  listSchedules &&
  listSchedules.some(schedule => {
    const newDateStartJourney = new Date(schedule.startJourney);
    const newDateFinishJourney = new Date(schedule.finishJourney);

    return (
      (startDateTime >= newDateStartJourney && startDateTime < newDateFinishJourney) ||
      (endDateTime > newDateStartJourney && endDateTime < newDateFinishJourney) ||
      (startDateTime < newDateStartJourney && endDateTime >= newDateFinishJourney)
    );
  });

export const groupDates = arrayData => {
  const arrayStartJourney = arrayData
    .map(item => convertToFullHours(item.startJourney))
    .sort((a, b) => b - a);
  const arrayFinishJourney = arrayData
    .map(item => convertToFullHours(item.finishJourney, true))
    .sort((a, b) => b - a);

  const newArray = [];

  arrayData.forEach(() => {
    const newObject = {};

    newObject.startJourney = arrayStartJourney.find(
      startJourney =>
        !arrayFinishJourney.includes(startJourney) &&
        !newArray.find(item => item.startJourney === startJourney)
    );

    newObject.finishJourney = arrayFinishJourney.find(
      finishJourney =>
        !arrayStartJourney.includes(finishJourney) &&
        !newArray.find(item => item.finishJourney === finishJourney)
    );

    if (newObject.startJourney) newArray.push(newObject);
  });

  return newArray;
};

export const roundToStartOrEndOfDay = (dateTime, isEndOfDay, selectedDate) => {
  const startOfDayDateTime = startOfDay(selectedDate);
  const endOfDayDateTime = endOfDay(selectedDate);

  if (isEndOfDay) {
    dateTime = roundTimesWithEndAtMidnight(dateTime);
    dateTime = dateTime > endOfDayDateTime ? endOfDayDateTime : dateTime;
    return dateTime;
  }

  return dateTime < startOfDayDateTime ? startOfDayDateTime : dateTime;
};

const roundTimesWithEndAtMidnight = dateTime => {
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  dateTime.setSeconds(0);

  dateTime = hours === 0 && minutes === 0 ? subSeconds(dateTime, 1) : dateTime;

  return dateTime;
};

export const moveOtherOptionToTheEnd = list => {
  const index = list.findIndex(e => e.name.includes("Outros"));

  if (index) {
    const otherElement = list.splice(index, 1)[0];
    list.push(otherElement);
  }

  return list;
};

export const createFormData = (files, appendName = "file") => {
  const formData = new FormData();

  files &&
    files.forEach(file => {
      formData.append(appendName, file);
    });

  return formData;
};

export const getCarStatusStyle = statusId => {
  const carStatusEnum = carStatus.find(status => status.id === statusId);
  return carStatusEnum || noStatusStyle;
};

export const carShouldHaveResponsible = fleetTypeId =>
  fleetTypeId === SHARED_FLEET_TYPE_ID || fleetTypeId === EXCLUSIVE_FLEET_TYPE_ID;

export const carShouldHaveDriver = fleetTypeId => !carShouldHaveResponsible(fleetTypeId);

export const cnhIsRequired = (profileId, licenseTypeId, licenseExpireDate) =>
  driverProfiles.includes(profileId) || !!licenseExpireDate || licenseTypeId > 0;

export const isValidDate = date => date instanceof Date && !isNaN(date);

export const formatFleetFilters = filtersData => ({
  fleetTypes: filtersData?.fleetTypeList?.filter(item => item.isSelected).map(item => item.id),
  providers: filtersData?.providerList?.filter(item => item.isSelected).map(item => item.id),
  statuses: filtersData?.statusList?.filter(item => item.isSelected).map(item => item.id),
  reviewStatuses: filtersData?.reviewStatusList
    ?.filter(item => item.isSelected)
    .map(item => item.id)
});

export const intervalInDays = (dateEnd, datePart = new Date()) =>
  Math.round(millisecondsToHours(dateEnd.getTime() - datePart.getTime()) / 24);

export const checkUndersizedWidth = width => width < MEDIUM_WINDOW_WIDTH;

export const parseDateWithoutTimezone = dateString =>
  new Date(dateString.substring(0, dateString.indexOf("+")));

export const checkIfCheckListEnabled = startJourney => {
  const startChecklistTime = addMinutes(new Date(startJourney), -checklistTolerance);
  const checklistTolerationTime = addMinutes(new Date(startJourney), checklistTolerance);
  const currentTime = new Date();

  return currentTime >= startChecklistTime && currentTime <= checklistTolerationTime;
};
