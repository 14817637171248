import React, { useCallback, useEffect, useState } from "react";
import { Layout } from "../../layout/";
import { Grid } from "@material-ui/core";
import VehicleHistoryManager from "../VehicleHistoryManager";
import { HistoryCard, HistoryPageHeader } from "../../../shared/components/HistoryPage";
import Loading from "../../../shared/components/Loading";
import HistoryCardContent from "./HistoryCardContent";
import { messages } from "../../../shared/utils/Constants";
import { ReactComponent as CarIcon } from "../../../shared/img/CustomCarIcon.svg";

const VehicleHistory = props => {
  const {
    location: {
      state: { vehicleId }
    }
  } = props;

  const [vehicleHistory, setVehicleHistory] = useState([]);
  const [vehicleHistoryIsLoading, setVehicleHistoryIsLoading] = useState(true);
  const [cardExpansionStates, setCardExpansionStates] = useState([]);
  const [allCardsExpanded, setAllCardsExpanded] = useState(false);

  const getVehicleHistory = useCallback(async id => {
    setVehicleHistoryIsLoading(true);
    const response = await VehicleHistoryManager.getVehicleHistory(id);

    setVehicleHistory(response);
    setCardExpansionStates(response.map(_ => (response.length > 3 ? false : true)));

    setVehicleHistoryIsLoading(false);
  }, []);

  useEffect(() => {
    getVehicleHistory(vehicleId);
  }, [vehicleId, getVehicleHistory]);

  useEffect(() => {
    setAllCardsExpanded(cardExpansionStates.every(state => state === true));
  }, [cardExpansionStates]);

  const handleExpandAllClick = () => {
    if (allCardsExpanded) {
      setCardExpansionStates(cardExpansionStates.map(_ => false));
    } else {
      setCardExpansionStates(cardExpansionStates.map(_ => true));
    }
  };

  const getOldData = index => {
    return index !== vehicleHistory.length - 1 && vehicleHistory[index + 1];
  };

  const getAdditionalCardData = async (oldData, newData) => {
    const oldDriverId = oldData?.car?.privateCar?.user?.id;
    const newDriverId = newData.car.privateCar?.user?.id;

    const newDriverData = await VehicleHistoryManager.getUserById(newDriverId);
    let oldDriverData = newDriverData;

    if (newDriverData?.id !== oldDriverId) {
      oldDriverData = await VehicleHistoryManager.getUserById(oldDriverId);
    }

    return {
      newAdditionalDataToLoad: {
        driver: newDriverData
      },
      oldAdditionalDataToLoad: {
        driver: oldDriverData
      }
    };
  };

  return (
    <Layout showHeader>
      <Grid item container xs={12} className="pt-8 pr-8">
        <HistoryPageHeader
          allCardsExpanded={allCardsExpanded}
          handleExpandAllClick={handleExpandAllClick}
        />
      </Grid>
      <Grid item xs={12} className="pl-8 pb-8 pt-8 pr-8">
        {vehicleHistoryIsLoading ? (
          <Loading loading />
        ) : (
          <>
            {vehicleHistory.map((data, index) => {
              return (
                <HistoryCard
                  key={index}
                  oldData={getOldData(index)}
                  newData={data}
                  isExpanded={cardExpansionStates[index]}
                  setIsExpanded={state =>
                    setCardExpansionStates(
                      cardExpansionStates.map((value, i) => (i === index ? state : value))
                    )
                  }
                  automaticEditionReason={messages.AD_EDITION_REASON_INACTIVATION}
                  HistoryCardContent={HistoryCardContent}
                  hasAdditionalData
                  getAdditionalData={getAdditionalCardData}
                  TagProps={{
                    contentLabel: "Veículo",
                    DefaultIcon: outerProps => (
                      <CarIcon
                        fill={outerProps.color}
                        stroke={outerProps.color}
                        style={{ width: outerProps.size, height: outerProps.size }}
                        alt={outerProps["aria-label"]}
                        {...outerProps}
                      />
                    )
                  }}
                />
              );
            })}
          </>
        )}
      </Grid>
    </Layout>
  );
};

export default VehicleHistory;
