import React from "react";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { connect } from "react-redux";
import AlertMessage from "./features/alertMessage";
import Clarity from "./integrations/clarity";
import Routes from "./Routes";
import styles from "./theme";
import "./App.css";
import * as actions from "./store/WindowSizeStore";
import useWindowDimensions from "./shared/utils/useWindowDimensions";
import UpdateVersion from "./shared/utils/UpdateVersion";

const projectTheme = createMuiTheme(styles);

function App({ authenticated, updateWindowDimensions }) {
  useStyles();

  const { height, width } = useWindowDimensions();

  updateWindowDimensions({ height: height, width: width });

  return (
    <>
      <Clarity />
      <ThemeProvider theme={projectTheme}>
        <CssBaseline />
        <Routes authenticated={authenticated} />
        <AlertMessage />
      </ThemeProvider>
      <UpdateVersion />
    </>
  );
}

const useStyles = makeStyles(() => ({
  "@global": styles.global
}));

const mapStateToProps = state => {
  return {
    authenticated: state.user.isAuthenticated
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateWindowDimensions: data => dispatch(actions.updateWindowDimensions(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
