import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { ChevronRight } from "react-feather";
import { Grid, makeStyles, Card, Typography, Paper } from "@material-ui/core";
import { DARK_GRAY, SECONDARY_COLOR, GRAY } from "../../../theme";
import { routes } from "../../../shared/utils/Constants";

const ChecklistCard = props => {
  const {
    checklistStatusIcon,
    checklistStatusText,
    responseTime,
    responseDate,
    checklistId,
    carModel,
    carIcon
  } = props;

  const [redirect, setRedirect] = useState(null);
  const classes = useStyles();

  const onSelectChecklist = () => {
    const checklistDetailsPath = <Redirect to={`${routes.checklistDetails}/${checklistId}`} />;
    setRedirect(checklistDetailsPath);
  };

  return (
    <Card className="mb-3 hover-icon" onClick={onSelectChecklist}>
      <Paper className={classes.paper}>
        <Grid container className="pl-6 pr-6 pt-4 pb-4">
          <Grid item container xs={12}>
            <Grid item container xs={10}>
              <Typography variant="caption" className={classes.colorDetails}>
                Data: <span className={classes.detailsInformation}>{responseDate}</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <ChevronRight
                className={`${classes.icon} hover-icon`}
                role="button"
                aria-label="Detalhes"
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item container xs={12}>
              <Typography variant="caption" className={classes.colorDetails}>
                Horário: <span className={classes.detailsInformation}>{responseTime}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item container xs={12} className="pb-4">
              <Typography variant="caption" className={classes.colorDetails}>
                Fabricante/Modelo: <span className={classes.detailsInformation}>{carModel}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item container xs={12} className="pb-4">
              <Typography variant="body2" className={classes.colorDetails}>
                Checklist #
                <span className={`${classes.detailsInformation} body2`}>{checklistId}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={1}>
              {checklistStatusIcon}
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body2" className={`${classes.checklistStatus} pt-1 pl-2`}>
                {checklistStatusText}
              </Typography>
            </Grid>
            <Grid item xs={1} className="pt-1">
              {carIcon}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {redirect}
    </Card>
  );
};

const useStyles = makeStyles(() => ({
  colorDetails: {
    color: DARK_GRAY
  },
  detailsInformation: {
    fontWeight: 600,
    fontSize: "14px"
  },
  icon: {
    width: "18px",
    height: "24px",
    display: "flex",
    marginLeft: "auto"
  },
  paper: {
    border: "solid",
    borderWidth: "1px",
    borderColor: SECONDARY_COLOR
  },
  checklistStatus: {
    color: GRAY
  }
}));

export default ChecklistCard;
