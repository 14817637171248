import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid, makeStyles, Button } from "@material-ui/core";
import { PRIMARY_COLOR, RED, SECONDARY_COLOR } from "../../../theme";
import { Redirect } from "react-router-dom";
import ConfirmationModal from "../../../shared/components/ConfirmationModal";
import CheckoutModal from "../../checklist/views/CheckoutModal";
import BookingDetailsManager from "../BookingDetailsManager";
import { MOBILE_WINDOW_WIDTH, routes } from "../../../shared/utils/Constants";
import Loading from "../../../shared/components/Loading";
import { groupBy } from "../../../shared/utils/Utils";
import { checklistTypes, accessType } from "../../../shared/utils/enum";
import * as actions from "../../../store/ChecklistStore";

const DetailsButtons = props => {
  const {
    carId,
    userId,
    window,
    profile,
    isActive,
    checklist,
    bookingId,
    isCanceled,
    isFinalized,
    setRedirect,
    startJourney,
    deleteChecklist,
    updateChecklist,
    checklistIsEnabled,
    loadingPrimaryButton,
    hasCheckInResponseId,
    refreshBookingDetails,
    hasCheckOutResponseId,
    handleGetCheckInInfos,
    handleGetCheckOutInfos,
    loadingSecondaryButton,
    setLoadingPrimaryButton,
    setLoadingSecondaryButton
  } = props;

  const isFacilities = profile === accessType.siteFacilities;
  const isLoading = loadingPrimaryButton || loadingSecondaryButton;

  const [openModal, setOpenModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openCheckOutModal, setOpenCheckOutModal] = useState(false);
  const [loadingModalButton, setLoadingModalButton] = useState(false);

  const redirectToHomePage = <Redirect to={isFacilities ? routes.calendar : routes.listBookings} />;
  const redirectToOnCancel = <Redirect to={routes.listBookings} />;
  const redirectToChecklistStart = (
    <Redirect to={routes.checklistSteps.CHECK_CAR_INFORMATION.route} />
  );

  const confirmCancelBookingById = async () => {
    if (bookingId && userId) {
      const data = await BookingDetailsManager.cancelBookingById(bookingId, userId);

      if (data) setRedirect(redirectToOnCancel);

      setLoadingModalButton(false);
      setOpenModal(!openModal);
    }
  };

  const confirmFinalizeBookingById = async () => {
    if (bookingId && carId && userId && checklist) {
      const data = await BookingDetailsManager.finalizeBookingById(bookingId, {
        user: { id: userId },
        car: { id: carId },
        mileage: checklist.mileage,
        fuelLevel: checklist.fuelLevel,
        checklistModelId: checklist.checklistModelId,
        checklistObservations: checklist.checklistObservations,
        checklistQuestionResponses: checklist.questionsResponses
      });
      setLoadingModalButton(false);
      if (data) {
        handleCloseCheckOutModal();
        setOpenSuccessModal(true);
      }
    }
  };

  const listChecklistModelQuestionsByCarId = async checklistTypeId => {
    setLoadingSecondaryButton(checklistTypeId === checklistTypes.CHECKOUT);

    if (carId) {
      const data = await BookingDetailsManager.listChecklistModelQuestionsByCarId(
        carId,
        checklistTypeId
      );

      setLoadingPrimaryButton(false);
      setLoadingSecondaryButton(false);

      if (data) {
        const checklistQuestions = groupBy(data, "step");
        const checklistModelId = data.shift()?.checklistModelId;

        updateChecklist({
          ...checklist,
          checklistModelId: checklistModelId,
          stepQuestions: checklistQuestions
        });
        hasCheckInResponseId ? setOpenCheckOutModal(true) : setRedirect(redirectToChecklistStart);
      }
    }
  };

  const startBookingChecklist = async (startBookingId, startUserId) => {
    setLoadingSecondaryButton(true);
    if (startBookingId && startUserId) {
      const data = await BookingDetailsManager.startBookingChecklist(startBookingId, startUserId);
      if (!data) setLoadingSecondaryButton(false);
      else listChecklistModelQuestionsByCarId(checklistTypes.CHECKIN);
    }
  };

  const canViewCheckin = hasCheckInResponseId && !isCanceled;
  const firstButtonText = canViewCheckin ? "VISUALIZAR CHECK-IN" : "CANCELAR RESERVA";
  const secondButtonText = hasCheckInResponseId ? "REALIZAR O CHECK-OUT" : "REALIZAR O CHECK-IN";

  const activeWithoutCheckout = isActive && !hasCheckOutResponseId;
  const isDisabled = isLoading || openCheckOutModal;

  const getSecondButtonDisabled = () => {
    if (isFinalized) return false;
    if (hasCheckInResponseId) return new Date() < new Date(startJourney);
    else return !checklistIsEnabled;
  };

  const getSecondText = () => {
    if (activeWithoutCheckout) return secondButtonText;
    if (hasCheckOutResponseId) return "VISUALIZAR CHECK-OUT";
  };
  const getSecondButtonColor = () => {
    return isFinalized ? "secondary" : "primary";
  };
  const handlePrimaryActionConfirmationModal = () => {
    setLoadingModalButton(true);
    hasCheckInResponseId ? confirmFinalizeBookingById() : confirmCancelBookingById();
  };

  const handleSecondAction = () => {
    hasCheckInResponseId
      ? listChecklistModelQuestionsByCarId(checklistTypes.CHECKOUT)
      : startBookingChecklist(bookingId, userId);
  };
  const handleFirstAction = () => {
    hasCheckInResponseId ? handleGetCheckInInfos() : setOpenModal(!openModal);
  };
  const handleOnClick = isFirstButton => {
    if (isFinalized) isFirstButton ? handleGetCheckInInfos() : handleGetCheckOutInfos();
    else isFirstButton ? handleFirstAction() : handleSecondAction();
  };

  const handleOnClose = () => {
    setOpenSuccessModal(false);
    refreshBookingDetails();
  };
  const handleActionSuccessModal = () => {
    setOpenModal(!openSuccessModal);
    setRedirect(redirectToHomePage);
  };
  const handleCloseCheckOutModal = () => {
    setOpenCheckOutModal(false);
    deleteChecklist();
  };

  const getBorderColor = isFirstButton => {
    if (isLoading) return;
    if (isFinalized) return SECONDARY_COLOR;
    else if (isActive) {
      if (isFirstButton && !isDisabled) return hasCheckInResponseId ? SECONDARY_COLOR : RED;
      else return hasCheckInResponseId && hasCheckOutResponseId && SECONDARY_COLOR;
    }
  };

  const classes = useStyles({
    firstBorderColor: getBorderColor(true),
    secondBorderColor: getBorderColor(),
    firstColor: !isFinalized && !hasCheckInResponseId && RED,
    secondColor: isFinalized && SECONDARY_COLOR,
    backgroundColor: !isFinalized && PRIMARY_COLOR
  });

  const getProperPaddingOrientation = isFirst => {
    const isMobile = window.width < MOBILE_WINDOW_WIDTH;
    if (isFirst) return isMobile ? "b" : "r";
    else return isMobile ? "t" : "l";
  };

  return (
    !isCanceled && (
      <Grid container className={classes.buttons}>
        <Grid item className={`p${getProperPaddingOrientation(true)}-2`}>
          <Button
            color="secondary"
            disabled={isDisabled}
            className={classes.firstButton}
            onClick={() => handleOnClick(true)}
          >
            {loadingPrimaryButton ? (
              <Loading loading className={classes.loading} size={25} />
            ) : (
              firstButtonText
            )}
          </Button>
        </Grid>
        <Grid item className={`p${getProperPaddingOrientation()}-2`}>
          <Button
            color={getSecondButtonColor()}
            variant={!isFinalized ? "contained" : null}
            disabled={isDisabled || getSecondButtonDisabled()}
            className={classes.secondButton}
            onClick={() => handleOnClick()}
          >
            {loadingSecondaryButton ? (
              <Loading loading className={classes.loading} size={25} />
            ) : (
              getSecondText()
            )}
          </Button>
        </Grid>
        <ConfirmationModal
          shouldCancel
          loading={loadingModalButton}
          primaryButtonText="SIM"
          secondaryButtonText="NÃO"
          openedModal={openModal}
          title="Cancelamento"
          subtitle="Tem certeza que deseja cancelar essa reserva?"
          secondaryAction={() => handleOnClick(true)}
          handleOpenClose={() => handleOnClick(true)}
          secondaryButtonColor={RED}
          primaryAction={() => handlePrimaryActionConfirmationModal()}
        />
        <CheckoutModal
          loading={loadingModalButton}
          primaryButtonText="FINALIZAR"
          secondaryButtonText="CANCELAR"
          openedModal={openCheckOutModal}
          title="Finalizar reserva"
          questions={checklist?.stepQuestions}
          secondaryAction={() => handleCloseCheckOutModal()}
          handleOpenClose={() => handleCloseCheckOutModal()}
          primaryAction={() => handlePrimaryActionConfirmationModal()}
        />
        <ConfirmationModal
          success
          loading={isLoading}
          openedModal={openSuccessModal}
          handleOpenClose={() => handleOnClose()}
          secondaryAction={() => handleActionSuccessModal()}
          title="Reserva finalizada"
          secondaryButtonText={isFacilities ? "VOLTAR PARA O CALENDÁRIO" : "VOLTAR À TELA INICIAL"}
          subtitle="Seu check-out foi feito com sucesso!"
        />
      </Grid>
    )
  );
};

const useStyles = makeStyles(theme => ({
  buttons: {
    [theme.breakpoints.up(MOBILE_WINDOW_WIDTH)]: {
      justifyContent: "end"
    },
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      justifyContent: "center"
    }
  },
  firstButton: props => ({
    border: props.firstBorderColor && "solid",
    borderWidth: props.firstBorderColor && "1px",
    borderColor: props.firstBorderColor,
    color: props.firstColor,
    width: "236px"
  }),
  secondButton: props => ({
    border: props.secondBorderColor && "solid",
    borderWidth: props.secondBorderColor && "1px",
    backgroundColor: props.backgroundColor,
    borderColor: props.secondBorderColor,
    color: props.secondColor,
    width: "236px"
  }),
  loading: {
    display: "flex",
    margin: "auto"
  }
}));

const mapStateToProps = state => {
  return {
    userId: state.user.id,
    bookingId: state.booking.id,
    carId: state.booking.carId,
    checklist: state.checklist,
    profile: state.user.profile,
    window: state.window
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateChecklist: data => dispatch(actions.updateChecklist(data)),
    deleteChecklist: () => dispatch(actions.deleteChecklist())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsButtons);
