import { useLocation } from "react-router-dom";

const useQuery = (...params) => {
  const query = new URLSearchParams(useLocation().search);
  const gettedParams = {};

  params.forEach(param => (gettedParams[param] = query.get(param)));

  return gettedParams;
};

export default useQuery;
