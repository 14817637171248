import { Button, Dialog, DialogContent, DialogTitle, Grid, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "../CloseIcon";
import Dropzone from "../Dropzone";
import { GRAY, LIGHT_GREEN, RED, SECONDARY_COLOR, THIN_GREEN } from "../../../theme";
import { ReactComponent as FileIcon } from "../../../shared/img/FileIconLarge.svg";
import { ReactComponent as FileIconHover } from "../../../shared/img/FileIconLargeHover.svg";
import ImportedFileCard from "./ImportedFileCard";
import { messages } from "../../utils/Constants";
import { CheckCircle } from "react-feather";
import { downloadFile } from "../../utils/RequestUtil";

const ImportFileModal = props => {
  const {
    isOpen,
    title,
    handleClose: parentsHandleClose,
    handleUploadFile,
    maxFileSize = 1.5,
    acceptedExtensions = [".xlsx"]
  } = props;

  const [dropzoneIsHovered, setDropzoneIsHovered] = useState(false);
  const [importedFile, setImportedFile] = useState(null);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const dropzoneDisabled = importedFile && !importedFile?.hasImportError;
  const formattedExtensions = acceptedExtensions.map(ext => ext.substring(1).toUpperCase()).join(",");

  const classes = useStyles({ dropzoneDisabled });

  useEffect(() => {
    setImportedFile(null);
    setUploadSuccess(false);
  }, [isOpen]);

  const handleUploadClick = async () => {
    setUploadIsLoading(true);
    setImportedFile(null);

    const result = await handleUploadFile(importedFile.content);

    if (result.hasValidationError) {
      setImportedFile({
        name: result.file.name,
        type: result.file.type,
        content: result.file.content,
        hasImportError: false,
        hasValidationError: true,
        validationError: result.validationError
      });
    } else {
      setUploadSuccess(true);
    }

    setUploadIsLoading(false);
  }

  const handleDownloadFile = () =>
    downloadFile(importedFile.content, importedFile.type, importedFile.name);

  const handleClose = () => {
    if (!uploadIsLoading) parentsHandleClose();
  }

  const renderDialogContent = () => {
    if (uploadIsLoading) {
      return (
        <DialogContent className="mb-8 mt-8">
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} className={`m-2 mb-0 ${classes.loadingText}`}>
              <Typography variant="h5" component="p">
                Enviando arquivo...
              </Typography>
            </Grid>
            <Grid item xs={12} className="m-2 mt-0">
              <LinearProgress color="secondary" />
            </Grid>
          </Grid>
        </DialogContent>
      );
    }

    if (uploadSuccess) {
      return (
        <DialogContent className="mb-8 mt-8">
          <Grid container spacing={2}>
            <Grid container item xs={12} justify="center" alignItems="center" className="mt-8">
              <CheckCircle size={64} className={`p-1 ${classes.checkIcon}`} />
            </Grid>
            <Grid container item xs={12} justify="center" alignItems="center" className="mb-8">
              <Typography variant="body1" align="center" className={classes.successText}>
                Arquivo enviado com sucesso!
              </Typography>
            </Grid>
            <Grid item xs={12} className="mt-8">
              <Button variant="contained" color="primary" fullWidth onClick={handleClose}>
                FECHAR
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      );
    }

    return (
      <>
        <DialogTitle disableTypography>
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent className="mb-2">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Dropzone
                handleDrop={files => setImportedFile(files[0])}
                handleMouseEnter={() => setDropzoneIsHovered(true)}
                handleMouseLeave={() => setDropzoneIsHovered(false)}
                acceptedExtensions={acceptedExtensions}
                maxFileSize={maxFileSize}
                disabled={dropzoneDisabled}
                className={`p-8 ${classes.dropzone}`}
              >
                <Grid container>
                  <Grid item xs={12}>
                    {dropzoneIsHovered && !dropzoneDisabled ? (
                      <FileIconHover className="m-2" />
                    ) : (
                      <FileIcon className="m-2" />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" display="inline" className={classes.mainText}>
                      {"Arraste e solte o arquivo ou "}
                    </Typography>
                    <Typography variant="body1" display="inline" className={classes.clickHereText}>
                      clique aqui
                    </Typography>
                    <Typography variant="caption" display="block" className={classes.secondaryText}>
                      {`Apenas arquivos ${formattedExtensions} de no máximo ${maxFileSize}MB`}
                    </Typography>
                  </Grid>
                </Grid>
              </Dropzone>
            </Grid>
            {importedFile && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid container item xs={12}>
                    <ImportedFileCard
                      fileName={importedFile.name}
                      hasImportError={importedFile.hasImportError}
                      hasValidationError={importedFile.hasValidationError}
                      handleDeleteCard={() => setImportedFile(null)}
                      handleDownloadFile={handleDownloadFile}
                    />
                    {importedFile.hasImportError && (
                      <Grid item xs={12} className="mb-2 mt-2">
                        {importedFile.errors.map(error => (
                          <Typography variant="body2" display="inline" className={classes.errorText}>
                            {`${error} `}
                          </Typography>
                        ))}
                        <Typography variant="body2" display="inline" className={classes.errorText}>
                          Tente novamente.
                        </Typography>
                      </Grid>
                    )}
                    {importedFile.hasValidationError && (
                      <Grid item xs={12} className="mb-2 mt-2">
                        <Typography variant="body2" className={classes.errorText}>
                          {importedFile.validationError || messages.FILE_UPLOAD_ERROR}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  {(!importedFile.hasImportError && !importedFile.hasValidationError) && (
                    <Grid item xs={12} className="mt-8 mb-6">
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={handleUploadClick}
                      >
                        ENVIAR ARQUIVO
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </>
    );
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={handleClose}>
      {!uploadIsLoading && <CloseIcon onClick={handleClose} />}
      {renderDialogContent()}
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  dropzone: {
    textAlign: "center"
  },
  mainText: {
    color: "black",
    fontWeight: "bold"
  },
  clickHereText: ({ dropzoneDisabled }) => ({
    color: !dropzoneDisabled ? SECONDARY_COLOR : GRAY,
    fontWeight: "bold"
  }),
  secondaryText: {
    color: GRAY
  },
  errorText: {
    color: RED
  },
  checkIcon: {
    borderRadius: "50%",
    color: LIGHT_GREEN,
    backgroundColor: THIN_GREEN
  },
  successText: {
    fontSize: "2rem",
    maxWidth: "50%"
  },
  loadingText: {
    textAlign: "center"
  }
}));

export default ImportFileModal;