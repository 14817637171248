import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Grid,
  makeStyles,
  Typography,
  IconButton,
  Step,
  Stepper,
  StepLabel
} from "@material-ui/core";
import { ChevronLeft, ChevronRight, Info } from "react-feather";
import { PRIMARY_COLOR, SECONDARY_COLOR, MID_GREEN, BRIGHT_BLUE } from "../../../theme";
import { Layout } from "../../layout";
import * as actions from "../../../store/ChecklistStore";
import { routes, MOBILE_WINDOW_WIDTH } from "../../../shared/utils/Constants";
import StepButtons from "../../../shared/components/StepButtons";
import { formType } from "../../../shared/utils/enum";
import CarInformation from "../../../shared/components/CarInformation";
import Loading from "../../../shared/components/Loading";
import CustomStepper from "../../../shared/components/Stepper";

const NewChecklist = props => {
  const {
    window,
    loading,
    carInfo,
    children,
    nextStep,
    stepIndex,
    customRoute,
    previousStep,
    saveChecklist,
    disableButton,
    bookingDetails,
    warningMessage,
    deleteChecklist,
    isBookingChecklist,
    carInformationData,
    carInformationFields
  } = props;

  const classes = useStyles();

  const [redirect, setRedirect] = useState();

  const handleRedirect = () => {
    deleteChecklist();

    const redirect = bookingDetails.id ? `${routes.bookingDetails}/${bookingDetails.id}` : "/";
    setRedirect(<Redirect to={redirect} />);
  };
  const getTitleVariant = () => (window.width < MOBILE_WINDOW_WIDTH ? "h5" : "h4");
  const getSubtitleVariant = () => (window.width < MOBILE_WINDOW_WIDTH ? "subtitle1" : "h5");
  const isMobile = window.width < MOBILE_WINDOW_WIDTH;

  const stepsLabels = [
    "Documentação em Geral",
    "Parte Interna",
    "Parte Externa",
    "Equipamentos Obrigatórios"
  ];
  const renderStep = (index, label) => {
    let isActive = index === stepIndex;
    let isCompleted = stepIndex > index;

    return (
      <Step key={index} active={isActive} completed={isCompleted}>
        <StepLabel className={classes.elementStyle}>{label}</StepLabel>
      </Step>
    );
  };
  const isRecChecklistFirstStep = stepIndex === 0 && !isBookingChecklist;
  const pageTitle = isRecChecklistFirstStep ? "Conferência dos dados" : "Checklist de Segurança";

  return (
    <Layout showHeader formType={formType.checklist}>
      <Grid container className="mt-8 mb-8">
        <Grid item container alignItems="center" className="mt-4">
          <IconButton onClick={() => handleRedirect()} className="ml-2" aria-label="Voltar">
            <ChevronLeft size={32} color={PRIMARY_COLOR} />
          </IconButton>
          <Typography variant={getTitleVariant()} component="h2" className={classes.pageTitle}>
            {pageTitle}
          </Typography>
        </Grid>
        {isRecChecklistFirstStep && (
          <Grid item container alignItems="center" className="mb-4">
            <Grid item xs={12} className="mb-10">
              <Typography
                variant={getSubtitleVariant()}
                component="h3"
                className={`mt-2 ${classes.subtitle}`}
              >
                Antes de iniciar o check-in, você precisa conferir os itens abaixo:
              </Typography>
            </Grid>
            <Grid item xs={12} className="pr-8 pl-8">
              <CarInformation
                window={window}
                fields={carInformationFields}
                data={carInformationData}
                warningMessage={warningMessage}
                carInfo={carInfo}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant={getTitleVariant()}
                component="h2"
                className={`mt-10 ${classes.subtitle}  ${classes.pageTitle}`}
              >
                Checklist de Segurança
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container justify="center">
          {!isMobile && (
            <Grid item xs={12} lg={10} xl={8} className="mt-4 mb-6 mr-6 ml-6">
              <Stepper
                activeStep={stepIndex}
                connector={<ChevronRight size={18} />}
                className={classes.stepper}
              >
                {stepsLabels.map((label, index) => {
                  return renderStep(index, label);
                })}
              </Stepper>
            </Grid>
          )}

          {isMobile && (
            <Grid item xs={12} lg={10} xl={8} className="mt-4 mb-6 mr-6 ml-6">
              <CustomStepper step={stepIndex} />
            </Grid>
          )}

          <Grid item container xs={12} sm={7} md={6} lg={5} spacing={6}>
            {warningMessage && isBookingChecklist && (
              <Grid item container xs={12}>
                <Grid item className={classes.iconWidth}>
                  <Info size={15} className={classes.iconContent} />
                </Grid>
                <Grid item xs className="ml-4">
                  <Typography variant="body2" align="inherit" className={classes.infoContent}>
                    {warningMessage}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {loading ? <Loading loading /> : children}

            {!loading && (
              <Grid item xs={12} align="center" className="mb-6">
                <StepButtons
                  stepType="Checklist"
                  customRoute={customRoute}
                  disabled={disableButton}
                  nextStep={nextStep}
                  previousStep={previousStep}
                  saveChecklist={saveChecklist}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {redirect}
    </Layout>
  );
};
const useStyles = makeStyles(theme => ({
  pageTitle: {
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      margin: "auto"
    }
  },
  stepper: {
    justifyContent: "space-around"
  },
  elementStyle: {
    "& .MuiStepLabel-label": {
      fontWeight: 600,
      fontSize: "20px"
    },
    "& .MuiStepIcon-root": {
      fontSize: "32px"
    },
    "& .MuiStepLabel-iconContainer": {
      paddingRight: "16px"
    },
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: BRIGHT_BLUE
    },
    "& .MuiStepLabel-label.MuiStepLabel-completed": {
      color: MID_GREEN
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: BRIGHT_BLUE
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: MID_GREEN
    }
  },
  infoContent: {
    color: SECONDARY_COLOR
  },
  iconWidth: {
    width: "25px",
    display: "contents"
  },
  iconContent: {
    color: SECONDARY_COLOR,
    margin: "auto"
  },
  subtitle: {
    marginLeft: "64px",
    marginRight: "64px",
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      textAlign: "center"
    }
  }
}));

const mapStateToProps = state => {
  return {
    bookingDetails: state.booking,
    window: state.window
  };
};
const mapDispatchToProps = dispatch => {
  return {
    deleteChecklist: () => dispatch(actions.deleteChecklist())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewChecklist);
