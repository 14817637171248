import axios from "axios";
import { configureEnvironment } from "./config/env";
import AxiosWrapper from "./shared/utils/AxiosWrapper";
import { handleRequestError } from "./shared/utils/RequestUtil";
import { storageKeyBffToken } from "./shared/utils/Constants";

const header = { Pragma: "no-cache" };

const axiosInstance = axios.create({
  headers: header,
  baseURL: configureEnvironment().REACT_APP_API_URL
});

axiosInstance.interceptors.request.use(async config => {
  const token = sessionStorage.getItem(storageKeyBffToken);
  
  if (token && !config.headers.Authorization) 
    config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default new AxiosWrapper(axiosInstance, { defaultErrorHandler: handleRequestError });
