import CarService from "../../services/CarService";
import UserService from "../../services/UserService";

class VehicleHistoryManager {
  async getVehicleHistory(vehicleId) {
    if (!vehicleId) return;

    const response = await CarService.getCarHistory(vehicleId);

    if (Array.isArray(response.data)) return response.data;

    return [];
  }

  async getUserById(userId) {
    if (!userId) return;

    const response = await UserService.getUserById(userId);

    return response && response.data;
  }
}

export default new VehicleHistoryManager();
