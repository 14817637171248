import CarService from "../../services/CarService";
import UserService from "../../services/UserService";

class UserDetailsManager {
  async getUserById(userId) {
    if (!userId) return;

    const response = await UserService.getUserById(userId);

    return response && response.data;
  }

  async getVehiclesByUserAd(userAd) {
    if (!userAd) return;

    const response = await CarService.listFleets(userAd);

    return response && response.data;
  }
}

export default new UserDetailsManager();
