import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import RadioSelection from "../../../shared/components/RadioSelection";
import SearchText from "../../../shared/components/SearchText";
import { connect } from "react-redux";
import * as actions from "../../../store/BookingStore";
import { accessType, driverType } from "../../../shared/utils/enum";
import BookingManager from "../BookingManager";
import { routes } from "../../../shared/utils/Constants";
import { Redirect } from "react-router-dom";
import MessageBox from "../../../shared/components/MessageBox";
import NewBooking from "./NewBooking";
import { RED } from "../../../theme";

const SelectDriver = props => {
  const { booking, updateBooking, deleteBooking, user, profile } = props;
  const classes = useStyles();
  const isFacilities = profile === accessType.siteFacilities;
  const isDriver = profile === accessType.driver;

  const [drivers, setDrivers] = useState([]);
  const [driverTypes, setDriverTypes] = useState([]);
  const [selectedDriverType, setSelectedDriverType] = useState(booking.driverType);
  const [driverId, setDriverId] = useState(null);
  const [driverName, setDriverName] = useState(booking.driverName);
  const [redirect, setRedirect] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingAvailability, setLoadingAvailability] = useState(true);
  const [isAvailable, setIsAvailable] = useState(false);
  const [radioDisabled, setRadioDisabled] = useState(true);

  const isSelfDriver = selectedDriverType === driverType.SELF_DRIVER.id;
  const isOtherDriver = selectedDriverType === driverType.OTHER_DRIVER.id;
  const hasError = !isAvailable && !loadingAvailability;

  useEffect(() => {
    const getDriverList = async () => {
      const result = await BookingManager.getDriverListByName();
      if (result) setDrivers(result);
    };
    getDriverList();
  }, []);

  useEffect(() => {
    setDriverId(booking.driverId);
  }, [booking.driverId]);

  useEffect(() => {
    if (drivers.length <= 0) return;

    const driverTypeOptions = [driverType.OTHER_DRIVER];
    const userIsDriver = !!drivers.find(driver => driver.id === user.id);

    if (userIsDriver) {
      if (!driverId && selectedDriverType === driverType.SELF_DRIVER.id) {
        setDriverId(user.id);
        setDriverName(user.name);
      }

      driverTypeOptions.unshift(driverType.SELF_DRIVER);
    }

    setDriverTypes(driverTypeOptions);
    if (!selectedDriverType) setSelectedDriverType(driverTypeOptions[0].id);
    setLoading(false);
  }, [drivers, user, driverId, selectedDriverType]);

  useEffect(() => {
    const verifyUserAvailability = async () => {
      setLoadingAvailability(true);
      const result = await BookingManager.getUserAvailability(driverId);
      setIsAvailable(result);
      setLoadingAvailability(false);
    };

    if (driverId !== user.id) verifyUserAvailability();
  }, [driverId, user]);

  useEffect(() => {
    if (isAvailable) setRadioDisabled(!isAvailable);
  }, [isAvailable]);

  const handleChangeSelectedDriverType = newDriverType => {
    const parsedNewDriverType = parseInt(newDriverType);

    setSelectedDriverType(parsedNewDriverType);
    setIsAvailable(true);

    if (parsedNewDriverType === driverType.OTHER_DRIVER.id) {
      setDriverId(null);
      setDriverName(null);
    } else {
      setDriverId(user.id);
      setDriverName(user.name);
    }
  };

  const handleSubmit = () => {
    updateBooking({
      ...booking,
      schedulerId: user.id,
      driverId: driverId,
      driverName: driverName,
      driverType: selectedDriverType,
      nextStep: routes.bookingSteps.SELECT_SITE.id
    });

    setRedirect(<Redirect to={routes.bookingSteps.SELECT_SITE.route} />);
  };

  const redirectTo = () => {
    if (isFacilities) return routes.calendar;
    return isDriver ? routes.listBookings : routes.securityReport;
  };

  const handleCancel = () => {
    deleteBooking();
    setRedirect(<Redirect to={redirectTo()} />);
  };

  const handleClickHere = () => {
    setRedirect(<Redirect to={routes.listBookings} />);
  };

  return (
    <NewBooking
      loadingContent={loading}
      loadingNextStep={loadingAvailability}
      disabled={!isAvailable || !driverId}
      redirect={redirect}
      subtitle="Selecione o condutor da reserva:"
      handleNext={handleSubmit}
      handlePrevious={handleCancel}
    >
      {isSelfDriver && hasError && (
        <Grid container item xs={12}>
          <MessageBox>
            Atualmente você não está apto para realizar uma reserva, pois existem pendências de
            checkout em seu nome,{" "}
            <span onClick={handleClickHere} className={classes.linkText}>
              clique aqui
            </span>{" "}
            para regularizar.
          </MessageBox>
        </Grid>
      )}
      <Grid item xs={12}>
        <RadioSelection
          disabled={radioDisabled}
          optionsList={driverTypes}
          handleChange={e => handleChangeSelectedDriverType(e.target.value)}
          value={selectedDriverType}
          step={booking.nextStep}
        />
      </Grid>
      {drivers.length > 0 && isOtherDriver && (
        <Grid item xs={12}>
          <SearchText
            placeholder="Pesquise o nome do condutor"
            value={drivers.find(driver => driver.id === driverId)}
            optionsList={drivers}
            handleChange={(_, driver) => {
              setDriverId(driver?.id);
              setDriverName(driver?.name);
            }}
            hasError={driverId && hasError}
          />
        </Grid>
      )}
      {isOtherDriver && hasError && (
        <Grid item xs={12}>
          <MessageBox>
            O condutor selecionado não está apto para dirigir, pois existem reservas sem checkout em
            seu nome, peça para o condutor regularizar a sua situação e tente novamente.
          </MessageBox>
        </Grid>
      )}
    </NewBooking>
  );
};

const useStyles = makeStyles(() => ({
  linkText: {
    fontWeight: 700,
    cursor: "pointer",
    color: RED
  }
}));

const mapStateToProps = state => {
  return {
    booking: state.booking,
    user: state.user,
    profile: state.user.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateBooking: data => dispatch(actions.updateBooking(data)),
    deleteBooking: () => dispatch(actions.deleteBooking())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectDriver);
