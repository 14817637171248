import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ReportDetailsManager from "../ReportDetailsManager";
import { routes } from "../../../shared/utils/Constants";
import { Layout } from "../../layout";
import ReportContent from "./reportContent";
import Loading from "../../../shared/components/Loading";

const ReportDetails = props => {
  const {
    match: { params: routeParams },
    user
  } = props;
  const { reportId } = routeParams;

  const [redirect, setRedirect] = useState(null);
  const [report, setReport] = useState();
  const [loading, setLoading] = useState(false);

  window.onbeforeunload = null;

  useEffect(() => {
    const getReportById = async () => {
      setLoading(true);

      const data = await ReportDetailsManager.getReportById(reportId);

      if (!data) return setRedirect(<Redirect to={routes.notFound} />);
      if (user.id !== data.user.id) return setRedirect(<Redirect to={routes.deniedAccess} />);

      setReport(data);
      setLoading(false);
    };

    if (!parseInt(reportId)) return setRedirect(<Redirect to={routes.listChecklists} />);
    getReportById();
  }, [reportId, user]);

  return (
    <Layout showHeader pathRedirect={routes.listReports}>
      {!loading && report && <ReportContent report={report} user={user} />}

      {loading && (
        <Grid className="pt-8">
          <Loading loading />
        </Grid>
      )}

      {redirect}
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(ReportDetails);
