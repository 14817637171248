import React from "react";
import { TableCell, TableRow, makeStyles, Typography, Grid } from "@material-ui/core";
import { Check, Calendar } from "react-feather";
import { ReactComponent as OdometerIcon } from "../../../shared/img/Odometer.svg";
import { format, addMonths } from "date-fns";
import { DARK_GRAY, GRAY, SECONDARY_COLOR } from "../../../theme";
import {
  intervalInDays,
  addThousandSeparator,
  formatStringField,
  parseDateWithoutTimezone
} from "../../../shared/utils/Utils";
import TooltipItem from "../../../shared/components/TooltipItem";
import CarStatusItem from "./CarStatusItem";

const CarReviewTableRow = props => {
  const { item, wrap } = props;

  const classes = useStyles();

  const nextReviewDate =
    (item.carContract?.startDate || item.carReview?.reviewDate) &&
    format(
      addMonths(
        parseDateWithoutTimezone(item.carReview?.reviewDate || item.carContract?.startDate),
        item.carInformation.carModel?.monthsReviewRecurrence
      ),
      "dd/MM/yyyy"
    );

  const contractTime = item.carContract?.startDate
    ? intervalInDays(new Date(), new Date(item.carContract.startDate))?.toString().concat(" dia(s)")
    : "-";

  const nextOdometryReview =
    item.carInformation.carModel?.odometerReviewRecurrence + item.carReview?.odometry;

  return (
    <TableRow>
      <TableCell className={classes.bodyCell} align="left">
        <Grid item xs={12}>
          <TooltipItem
            tooltip={formatStringField(item.carLicensing?.carLicensePlate)}
            onlyWhenOverflown
          >
            <Typography variant="subtitle2" className={classes.overflow}>
              {formatStringField(item.carLicensing?.carLicensePlate)}
            </Typography>
          </TooltipItem>
        </Grid>
        <Grid item xs={12}>
          <TooltipItem
            tooltip={formatStringField(item.carContract?.carProvider?.name)}
            onlyWhenOverflown
          >
            <Typography variant="body2" className={classes.overflow}>
              {formatStringField(item.carContract?.carProvider?.name)}
            </Typography>
          </TooltipItem>
        </Grid>
      </TableCell>
      <TableCell className={classes.bodyCell} align="left">
        <Grid item xs={12}>
          <TooltipItem tooltip={formatStringField(item.privateCar?.user?.name)} onlyWhenOverflown>
            <Typography variant="subtitle2" className={classes.overflow}>
              {formatStringField(item.privateCar?.user?.name)}
            </Typography>
          </TooltipItem>
        </Grid>
        <Grid item xs={12}>
          <TooltipItem tooltip={formatStringField(item.privateCar?.user?.adUser)} onlyWhenOverflown>
            <Typography variant="body2" className={classes.overflow}>
              {formatStringField(item.privateCar?.user?.adUser)}
            </Typography>
          </TooltipItem>
        </Grid>
      </TableCell>
      <TableCell className={classes.bodyCell} align="left">
        <TooltipItem tooltip={formatStringField(item.fleet?.fleetType?.name)} onlyWhenOverflown>
          <Typography variant="body2" className={classes.overflow}>
            {formatStringField(item.carInformation.fleet?.fleetType?.name)}
          </Typography>
        </TooltipItem>
      </TableCell>
      <TableCell className={classes.bodyCellBreakLine} align="left">
        <Grid item xs={12}>
          <TooltipItem
            tooltip={formatStringField(item.privateCar?.user?.pole?.name)}
            onlyWhenOverflown
          >
            <Typography variant="body2" className={`${classes.userInfo} ${classes.overflow}`}>
              {formatStringField(item.privateCar?.user?.pole?.name)}
            </Typography>
          </TooltipItem>
        </Grid>
        <Grid item xs={12}>
          <TooltipItem
            tooltip={formatStringField(item.privateCar?.user?.costCenter?.name)}
            onlyWhenOverflown
          >
            <Typography variant="body2" className={`${classes.userInfo} ${classes.overflow}`}>
              {formatStringField(item.privateCar?.user?.costCenter?.name)}
            </Typography>
          </TooltipItem>
        </Grid>
      </TableCell>
      <TableCell className={classes.bodyCellBreakLine} align="left">
        <Grid item xs={12}>
          <TooltipItem
            tooltip={formatStringField(item.privateCar?.user?.management?.name)}
            onlyWhenOverflown
          >
            <Typography variant="body2" className={`${classes.userInfo} ${classes.overflow}`}>
              {formatStringField(item.privateCar?.user?.management?.name)}
            </Typography>
          </TooltipItem>
        </Grid>
        <Grid item xs={12}>
          <TooltipItem
            tooltip={formatStringField(item.privateCar?.user?.management?.regional?.name)}
            onlyWhenOverflown
          >
            <Typography variant="body2" className={`${classes.userInfo} ${classes.overflow}`}>
              {formatStringField(item.privateCar?.user?.management?.regional?.name)}
            </Typography>
          </TooltipItem>
        </Grid>
      </TableCell>
      <TableCell className={classes.bodyCellBreakLine} align="left">
        <Grid item container xs={12}>
          {item.carReview?.reviewDate ? (
            <>
              {!wrap && <Check size={14} className={classes.lastReviewIcon} />}
              <TooltipItem
                tooltip={format(parseDateWithoutTimezone(item.carReview?.reviewDate), "dd/MM/yyyy")}
                onlyWhenOverflown
              >
                <Typography
                  variant="body2"
                  className={`${classes.lastReviewInfo} ${classes.overflow} pl-2`}
                >
                  {format(parseDateWithoutTimezone(item.carReview?.reviewDate), "dd/MM/yyyy")}
                </Typography>
              </TooltipItem>
            </>
          ) : (
            <Typography className={classes.lastReviewInfo}>{"-"}</Typography>
          )}
        </Grid>
        <Grid item container xs={12}>
          {item.carReview?.odometry ? (
            <>
              {!wrap && <Check size={14} className={classes.lastReviewIcon} />}
              <TooltipItem tooltip={item.carReview?.odometry} onlyWhenOverflown>
                <Typography
                  variant="body2"
                  className={`${classes.lastReviewInfo} ${classes.overflow} pl-2`}
                >
                  {addThousandSeparator(item.carReview?.odometry).concat(" Km")}
                </Typography>
              </TooltipItem>
            </>
          ) : (
            <Typography className={classes.lastReviewInfo}>{"-"}</Typography>
          )}
        </Grid>
      </TableCell>
      <TableCell className={classes.bodyCellBreakLine} align="left">
        <Grid item container xs={12}>
          {nextReviewDate ? (
            <>
              {!wrap && <Calendar size={14} className={classes.reviewIcon} />}
              <TooltipItem tooltip={nextReviewDate} onlyWhenOverflown>
                <Typography
                  variant="body2"
                  className={`${classes.reviewInfo} ${classes.overflow} pl-2`}
                >
                  {nextReviewDate}
                </Typography>
              </TooltipItem>
            </>
          ) : (
            <Typography variant="body2" className={classes.overflow}>
              {"-"}
            </Typography>
          )}
        </Grid>
        <Grid item container xs={12}>
          {nextOdometryReview ? (
            <>
              {!wrap && (
                <OdometerIcon style={{ width: 14, height: 14 }} className={classes.reviewIcon} />
              )}
              <TooltipItem
                tooltip={addThousandSeparator(nextOdometryReview).concat(" Km")}
                onlyWhenOverflown
              >
                <Typography
                  variant="body2"
                  className={`${classes.reviewInfo} ${classes.overflow} pl-2`}
                >
                  {addThousandSeparator(nextOdometryReview).concat(" Km")}
                </Typography>
              </TooltipItem>
            </>
          ) : (
            <Typography variant="body2" className={classes.reviewInfo}>
              {"-"}
            </Typography>
          )}
        </Grid>
      </TableCell>
      <TableCell className={classes.bodyCellBreakLine} align="left">
        <Grid item xs={12}>
          <TooltipItem
            tooltip={formatStringField(
              item.carInformation.odometer &&
                addThousandSeparator(item.carInformation.odometer).concat(" Km")
            )}
            onlyWhenOverflown
          >
            <Typography variant="body2" className={`${classes.reviewInfo} ${classes.overflow}`}>
              {formatStringField(
                item.carInformation.odometer &&
                  addThousandSeparator(item.carInformation.odometer).concat(" Km")
              )}
            </Typography>
          </TooltipItem>
        </Grid>
        <Grid item xs={12}>
          <TooltipItem tooltip={contractTime} onlyWhenOverflown>
            <Typography variant="body2" className={`${classes.reviewInfo} ${classes.overflow}`}>
              {contractTime}
            </Typography>
          </TooltipItem>
        </Grid>
      </TableCell>
      <TableCell>
        <CarStatusItem
          unit={item.carReview.status?.unit}
          value={item.carReview.status?.value}
          label={item.carReview.status?.label}
          percentage={item.carReview.status?.percentage}
          wrap={wrap}
        />
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles(() => ({
  bodyCell: {
    "&.MuiTableCell-root": {
      padding: "10px"
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "inherit"
    },
    fontSize: 16,
    color: DARK_GRAY
  },
  bodyCellBreakLine: {
    "&.MuiTableCell-root": {
      paddingLeft: "8px"
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "inherit"
    },
    fontSize: 16,
    color: DARK_GRAY
  },
  userInfo: {
    color: DARK_GRAY
  },
  lastReviewIcon: {
    color: SECONDARY_COLOR,
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto"
  },
  lastReviewInfo: {
    color: SECONDARY_COLOR
  },
  reviewInfo: {
    color: GRAY
  },
  reviewIcon: {
    color: GRAY,
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto"
  },
  overflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
}));

export default CarReviewTableRow;
