import React from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import AssociatedVehicleCard from './AssociatedVehicleCard';
import { ReactComponent as CarIconLarge } from "../../../shared/img/CarIconLarge.svg";

const AssociatedVehicles = props => {
  const { vehiclesData } = props;
  const classes = useStyles();

  return (
    !!vehiclesData.length ? (
      <>
        {vehiclesData.map(vehicle => (
          <Grid item xs={12} className="mb-8" key={vehicle.id}>
            <AssociatedVehicleCard vehicleData={vehicle} />
          </Grid>
        ))}
      </>
    ) : (
      <Paper className={`${classes.noCarsPaper} p-8`}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <CarIconLarge style={{ width: "100%", height: 200 }} />
          </Grid>
          <Grid item xs={12} className={classes.noCarsText}>
            <Typography variant="h6" component="h4">
              Não existe nenhum veículo!
            </Typography>
            <Typography variant="body">
              Atualmente este usuário não possui veículo vinculado.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    )
  );
}

const useStyles = makeStyles(() => ({
  noCarsPaper: {
    width: "100%"
  },
  noCarsText: {
    textAlign: "center"
  }
}));

export default AssociatedVehicles;