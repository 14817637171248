import React, { useState } from "react";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  Appointments,
  AppointmentTooltip
} from "@devexpress/dx-react-scheduler-material-ui";
import { User } from "react-feather";
import { Paper, Typography, Grid, withStyles, makeStyles, Button } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { makeBookings } from "../../../shared/utils/Utils";
import { routes } from "../../../shared/utils/Constants";
import { calendarView, calendarLanguage } from "../../../shared/utils/enum";
import Loading from "../../../shared/components/Loading";
import { SECONDARY_COLOR, GRAY_2 } from "../../../theme";
import { connect } from "react-redux";

const WeekDateViewType = props => {
  const [redirect, setRedirect] = useState();
  const { height, bookings, currentDate, currentViewName, fleet } = props;
  const classes = useStyles();

  const bookingsList = makeBookings(bookings, fleet, currentViewName);

  const AppointmentContent = withStyles({
    name: "AppointmentContent"
  })(({ appointmentData, ...restProps }) => {
    return (
      <AppointmentTooltip.Content appointmentData={appointmentData.bookingTooltip} {...restProps}>
        <Grid container className="pl-6">
          <User className={classes.iconStatus} />
          <Typography className="pl-6" variant="body2">
            {appointmentData.bookingInfo}
          </Typography>
          <Grid item xs={12} className="pl-2 pt-4 pr-6">
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={() =>
                setRedirect(
                  <Redirect
                    to={`${routes.bookingDetailsByFac}/${appointmentData.bookingTooltip.id}`}
                  />
                )
              }
            >
              VER DETALHES DA RESERVA
            </Button>
          </Grid>
        </Grid>
      </AppointmentTooltip.Content>
    );
  });

  const Appointment = ({ children, style, data, ...restProps }) => (
    <Appointments.Appointment
      {...restProps}
      data={data}
      style={{
        ...style,
        backgroundColor: data.backgroundColor
      }}
      className={classes.appointment}
    >
      {children}
    </Appointments.Appointment>
  );

  return bookingsList && currentViewName ? (
    <Paper square elevation={3}>
      <Scheduler locale={calendarLanguage.ptBR} data={bookingsList} height={height - 165}>
        <ViewState currentDate={currentDate} currentViewName={currentViewName} />
        <WeekView
          startDayHour={calendarView.startDayHour}
          endDayHour={calendarView.endDayHour}
          cellDuration={calendarView.cellDuration}
        />
        <Appointments appointmentComponent={Appointment} />
        <AppointmentTooltip showCloseButton contentComponent={AppointmentContent} />
      </Scheduler>
      {redirect}
    </Paper>
  ) : (
    <Loading loading />
  );
};

const useStyles = makeStyles(() => ({
  appointment: {
    "& > div": {
      color: GRAY_2
    }
  },
  iconStatus: {
    width: "16px",
    height: "17px",
    color: SECONDARY_COLOR
  }
}));

const mapStateToProps = state => {
  return {
    height: state.window.height
  };
};

export default connect(mapStateToProps, null)(WeekDateViewType);
