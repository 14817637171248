import CarService from "../../services/CarService";
import FleetService from "../../services/FleetService";
import { formatFleetFilters } from "../../shared/utils/Utils";

class SharedManager {
  async listFleets(searchParam, filtersData) {
    const filters = formatFleetFilters(filtersData);

    const response = await CarService.listFleets(searchParam, filters);

    return response && response.data;
  }

  async listFleetTypes() {
    const response = await FleetService.listFleetTypes();

    return response && response.data;
  }

  async listProviders() {
    const response = await CarService.listProviders();

    return response && response.data;
  }
}

export default new SharedManager();
