import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { DARK_GRAY } from "../../../theme";
const ToastContent = ({ message, icon }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Grid item className={"mr-4"}>
        {icon}
      </Grid>
      <Grid item className={classes.messageGrid}>
        <Typography className={classes.message}>{message}</Typography>
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles(() => ({
  messageGrid: {
    maxWidth: 450
  },
  message: {
    color: DARK_GRAY
  }
}));

export default ToastContent;
