import React from "react";
import { Tooltip } from "@material-ui/core";
import { SECONDARY_COLOR } from "../../theme";
import { makeStyles } from "@material-ui/core/styles";

const CustomizedTooltip = props => {
  const classes = useStyles(props);
  const { children, titulo, placement, disabled } = props;

  return (
    <Tooltip
      title={titulo ? titulo : null}
      placement={placement ? placement : "top-start"}
      aria-label="justificativa"
      disableHoverListener={disabled}
      disableFocusListener={disabled}
      disableTouchListener={disabled}
      classes={{ tooltip: classes.tooltip }}
    >
      {children}
    </Tooltip>
  );
};

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: props => (props.backgroundColor ? props.backgroundColor : "#F7F6F9"),
    boxShadow: "0 5px 15px 0 #B39DAF",
    border: `1px solid ${SECONDARY_COLOR}`,
    borderRadius: "3px",
    color: "black",
    fontSize: "12px",
    maxWidth: props => (props.maxWidth ? props.maxWidth : "600px"),
    padding: "12px 16px 10px 16px"
  }
});

export default CustomizedTooltip;
