import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Grid, Typography, Button, Tabs, Tab, makeStyles } from "@material-ui/core";
import { Download } from "react-feather";
import { Layout } from "../../layout";
import Loading from "../../../shared/components/Loading";
import ImportFileModal from "../../../shared/components/ImportFile/ImportFileModal";
import CarReviewManagementManager from "../CarReviewManagementManager";
import { checkUndersizedWidth } from "../../../shared/utils/Utils";
import { carStatus, reviewStatus } from "../../../shared/utils/enum";
import { messages } from "../../../shared/utils/Constants";
import CarReviewTableRow from "./CarReviewTableRow";
import SharedManager from "../../../shared/methods/SharedManager";
import CustomTable from "../../../shared/components/CustomTable/CustomTable";
import CarReviewFilters from "./CarReviewFilters";
import SearchTextField from "../../../shared/components/SearchTextField";
import { PRIMARY_COLOR, SECONDARY_COLOR, LIGHT_GRAY } from "../../../theme";
import TooltipItem from "../../../shared/components/TooltipItem";

const CarReviewManagement = props => {
  const { windowWidth } = props;
  const classes = useStyles();

  const pendingReviewsTab = 0;
  const nextReviewsTab = 1;

  const reviewTabs = [
    { id: pendingReviewsTab, label: "REVISÕES PENDENTES" },
    { id: nextReviewsTab, label: "PRÓXIMAS REVISÕES" }
  ];

  const fleetHeaderTable = [
    { title: "Placa / Fornecedor", width: "9%" },
    { title: "Nome / ID", width: "12%" },
    { title: "Tipo de Frota", width: "10%" },
    { title: "Polo / CC", width: "10%" },
    {
      title: "DIR / GER",
      width: "10%",
      tooltip: { always: true, text: "Diretoria / Gerência" }
    },
    { title: "Última revisão", width: "12%" },
    { title: "Próxima revisão", width: "12%" },
    { title: "Km / Tempo de Contrato", width: "10%" },
    { title: "Status", width: "20%", align: "right" }
  ];

  const [importReviewsModalVisibility, setImportReviewsModalVisibility] = useState(false);
  const [importOdometryModalVisibility, setImportOdometryModalVisibility] = useState(false);
  const [activeTab, setActiveTab] = useState(pendingReviewsTab);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [activeFleets, setActiveFleets] = useState([]);
  const [isUndersized, setIsUndersized] = useState();
  const [carsReportIsLoading, setCarsReportIsLoading] = useState(false);
  const [hasLoadedFilters, setHasLoadedFilters] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [filtersData, setFiltersData] = useState({
    fleetTypeList: [],
    providerList: [],
    reviewStatusList: []
  });

  const handleUploadReviews = async file => CarReviewManagementManager.uploadCarReviewsSheet(file);
  const handleUploadOdometry = async file =>
    CarReviewManagementManager.uploadCarOdometrySheet(file);

  useEffect(() => {
    setIsUndersized(checkUndersizedWidth(windowWidth));
  }, [windowWidth]);

  const listFleets = useCallback(async () => {
    setLoadingCounter(counter => counter + 1);

    let handledFilters = { ...filtersData };
    if (filtersData.reviewStatusList.every(status => !status.isSelected)) {
      handledFilters = {
        ...filtersData,
        reviewStatusList: filtersData.reviewStatusList.map(item => ({
          ...item,
          isSelected: item.id !== reviewStatus.upToDate.id
        }))
      };
    }

    const result = await SharedManager.listFleets(searchValue, {
      ...handledFilters,
      statusList: statusList
    });

    if (result) setActiveFleets(result);
    setLoadingCounter(counter => counter - 1);
  }, [searchValue, filtersData]);

  useEffect(() => {
    listFleets();
  }, [listFleets]);

  const handleChange = (_event, newValue) => {
    setActiveTab(newValue);
  };

  const getLabelTabColor = index =>
    activeTab === index ? classes.typographyLabelSelected : classes.typographyLabel;

  const getLabelTab = (label, index) => {
    return (
      <Typography variant="subtitle2" className={getLabelTabColor(index)}>
        {label}
      </Typography>
    );
  };

  const handleCloseModal = () => {
    listFleets();
    setImportReviewsModalVisibility(false);
    setImportOdometryModalVisibility(false);
  };

  const exportCarReviewsReport = async () => {
    setCarsReportIsLoading(true);
    await CarReviewManagementManager.exportCarReviewsReport();
    setCarsReportIsLoading(false);
  };

  const loadFilters = useCallback(async () => {
    if (!hasLoadedFilters) {
      const fleetTypeList = await SharedManager.listFleetTypes();
      const providerList = await SharedManager.listProviders();

      const filters = {
        fleetTypeList: fleetTypeList?.map(item => ({ ...item, isSelected: false })),
        providerList: providerList?.map(item => ({ ...item, isSelected: false })),
        reviewStatusList: reviewsStatusList
      };

      setFiltersData(filters);
      setHasLoadedFilters(true);
    }
  }, [hasLoadedFilters]);

  const checkDisabledItem = useCallback(
    itemId => {
      if (activeTab === pendingReviewsTab) {
        return !(
          itemId === reviewStatus.expired.id ||
          itemId === reviewStatus.toExpire.id
        );
      }
      if (activeTab === nextReviewsTab) return true;
    },
    [activeTab]
  );

  const setFilters = useCallback(() => {
    if (hasLoadedFilters) {
      setFiltersData(filters => ({
        ...filters,
        reviewStatusList: reviewsStatusList.map(item => ({
          ...item,
          isSelected: activeTab === nextReviewsTab && item.id === reviewStatus.upToDate.id,
          disabled: checkDisabledItem(item.id)
        }))
      }));
    }
  }, [hasLoadedFilters, activeTab, checkDisabledItem]);

  useEffect(() => {
    loadFilters();
  }, [loadFilters]);

  useEffect(() => {
    setFilters();
  }, [setFilters, activeTab]);

  const handleApplyFilters = useCallback(value => {
    setFiltersData(value);
  }, []);

  return (
    <Layout showHeader>
      <Grid item container md={12} className="pl-8 pt-8 pr-8">
        <Grid item className={classes.headerRow}>
          <Typography variant="h4" className="mt-3">
            Controle de Revisões
          </Typography>
        </Grid>
        <Grid item container className="pt-4">
          <Grid item container xs={6}>
            <Grid item xs={8} xl={6}>
              <SearchTextField
                label="Pesquise aqui"
                initialValue={searchValue}
                onChange={value => setSearchValue(value)}
              />
            </Grid>
            <Grid item className="ml-4" md={2}>
              <CarReviewFilters filtersData={filtersData} applyFilters={handleApplyFilters} />
            </Grid>
          </Grid>
          <Grid item container xs={6} justify="flex-end">
            <Grid container item xs={12} justify="flex-end" spacing={2}>
              <Grid item xs={2} xl={2} className={classes.headerRow}>
                <TooltipItem tooltip="Histórico de revisões">
                  <Button
                    fullWidth
                    color="primary"
                    onClick={exportCarReviewsReport}
                    className={classes.exportButton}
                    disabled={carsReportIsLoading}
                  >
                    {carsReportIsLoading ? (
                      <Loading loading size={20} centered={false} color="inherit" />
                    ) : (
                      <Download size={20} />
                    )}
                  </Button>
                </TooltipItem>
              </Grid>
              <Grid item xs={5} xl={4} className={classes.headerRow}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => setImportOdometryModalVisibility(true)}
                >
                  IMPORTAR KM
                </Button>
              </Grid>
              <Grid item xs={5} xl={4} className={classes.headerRow}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => setImportReviewsModalVisibility(true)}
                >
                  IMPORTAR REVISÕES
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container className="pt-4">
          <Tabs value={activeTab} onChange={handleChange} centered>
            {reviewTabs.map((tab, index) => (
              <Tab key={tab.id} label={getLabelTab(tab.label, index)} value={tab.id} />
            ))}
          </Tabs>
        </Grid>
      </Grid>
      <Grid className="pl-8 pb-8 pt-8 pr-8">
        <CustomTable
          data={activeFleets}
          loading={loadingCounter > 0 || !hasLoadedFilters}
          noDataMessage={messages.NO_CARS}
          headerData={fleetHeaderTable}
          RowComponent={({ item }) => <CarReviewTableRow item={item} wrap={isUndersized} />}
          optionsRowsPerPage={[5, 10, 20, 30]}
        />
      </Grid>
      <ImportFileModal
        isOpen={importReviewsModalVisibility}
        title="Importe a planilha de revisões"
        handleClose={handleCloseModal}
        handleUploadFile={handleUploadReviews}
      />
      <ImportFileModal
        isOpen={importOdometryModalVisibility}
        title="Importe a planilha de KM"
        handleClose={handleCloseModal}
        handleUploadFile={handleUploadOdometry}
      />
    </Layout>
  );
};

const statusList = [
  { id: carStatus[0].id, isSelected: true },
  { id: carStatus[1].id, isSelected: true },
  { id: carStatus[4].id, isSelected: true }
];

const reviewsStatusList = [
  {
    id: reviewStatus.upToDate.id,
    name: reviewStatus.upToDate.label,
    isSelected: false,
    disabled: false
  },
  {
    id: reviewStatus.expired.id,
    name: reviewStatus.expired.label,
    isSelected: false,
    disabled: false
  },
  {
    id: reviewStatus.toExpire.id,
    name: reviewStatus.toExpire.label,
    isSelected: false,
    disabled: false
  }
];

const useStyles = makeStyles(theme => ({
  headerRow: {
    height: theme.dimensions.rowHeight
  },
  typographyLabelSelected: {
    color: SECONDARY_COLOR
  },
  typographyLabel: {
    color: PRIMARY_COLOR,
    opacity: 1
  },
  exportButton: {
    border: "solid",
    borderWidth: "1px",
    width: "56px",
    display: "flex",
    marginLeft: "auto",
    "&.Mui-disabled": {
      borderColor: LIGHT_GRAY,
      backgroundColor: "transparent"
    }
  }
}));

const mapStateToProps = state => {
  return { windowWidth: state.window.width };
};

export default connect(mapStateToProps, null)(CarReviewManagement);
