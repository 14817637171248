import React from "react";
import { Grid, Divider, Typography } from "@material-ui/core";
import { HistoryCheckIcon } from "../../../shared/components/HistoryPage";
import { formatDateTimeToLiteral, formatStringField } from "../../../shared/utils/Utils";
import { driverLicenseTypes, profileStyle } from "../../../shared/utils/enum";

const HistoryCardContentFields = props => {
  const { data, isNewData, isCreation } = props;

  const createFieldStyle = fieldName => {
    return { fontWeight: !isCreation && data?.[fieldName]?.isEdited && "bold" };
  };

  return (
    <Grid container spacing={1}>
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Typography variant="body1" color="inherit">
            Nome
          </Typography>
        </Grid>
        <Grid container item xs={9} alignItems="center">
          <Typography variant="body1" color="inherit">
            {formatStringField(data?.name?.value)}
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} className="divider" />
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Typography style={createFieldStyle("role")} variant="body1" color="inherit">
            Cargo
          </Typography>
        </Grid>
        <Grid container item xs={9} alignItems="center">
          <Typography style={createFieldStyle("role")} variant="body1" color="inherit">
            {formatStringField(data?.role?.value)}
          </Typography>
          {isNewData &&
            <HistoryCheckIcon
              isEdited={data?.role?.isEdited}
              value={data?.role?.value}
              isCreation={isCreation}
            />
          }
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} className="divider" />
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Typography variant="body1" color="inherit">
            Email
          </Typography>
        </Grid>
        <Grid container item xs={9} alignItems="center">
          <Typography variant="body1" color="inherit">
            {formatStringField(data?.email?.value)}
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} className="divider" />
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Typography variant="body1" color="inherit">
            ID Yara
          </Typography>
        </Grid>
        <Grid container item xs={9} alignItems="center">
          <Typography variant="body1" color="inherit">
            {formatStringField(data?.adUser?.value)}
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} className="divider" />
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Typography style={createFieldStyle("profile")} variant="body1" color="inherit">
            Perfil
          </Typography>
        </Grid>
        <Grid container item xs={9} alignItems="center">
          <Typography style={createFieldStyle("profile")} variant="body1" color="inherit">
            {formatStringField(profileStyle.find(e => e.profile === data?.profile?.value)?.label)}
          </Typography>
          {isNewData &&
            <HistoryCheckIcon
              isEdited={data?.profile?.isEdited}
              value={data?.profile?.value}
              isCreation={isCreation}
            />
          }
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} className="divider" />
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Typography style={createFieldStyle("pole")} variant="body1" color="inherit">
            Polo
          </Typography>
        </Grid>
        <Grid container item xs={9} alignItems="center">
          <Typography style={createFieldStyle("pole")} variant="body1" color="inherit">
            {formatStringField(data?.pole?.value)}
          </Typography>
          {isNewData &&
            <HistoryCheckIcon
              isEdited={data?.pole?.isEdited}
              value={data?.pole?.value}
              isCreation={isCreation}
            />
          }
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} className="divider" />
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Typography style={createFieldStyle("costCenter")} variant="body1" color="inherit">
            Cód. Centro de Custo
          </Typography>
        </Grid>
        <Grid container item xs={9} alignItems="center">
          <Typography style={createFieldStyle("costCenter")} variant="body1" color="inherit">
            {formatStringField(data?.costCenter?.value)}
          </Typography>
          {isNewData &&
            <HistoryCheckIcon
              isEdited={data?.costCenter?.isEdited}
              value={data?.costCenter?.value}
              isCreation={isCreation}
            />
          }
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} className="divider" />
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Typography style={createFieldStyle("regional")} variant="body1" color="inherit">
            Região/Diretoria
          </Typography>
        </Grid>
        <Grid container item xs={9} alignItems="center">
          <Typography style={createFieldStyle("regional")} variant="body1" color="inherit">
            {formatStringField(data?.regional?.value)}
          </Typography>
          {isNewData &&
            <HistoryCheckIcon
              isEdited={data?.regional?.isEdited}
              value={data?.regional?.value}
              isCreation={isCreation}
            />
          }
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} className="divider" />
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Typography style={createFieldStyle("management")} variant="body1" color="inherit">
            Gerência
          </Typography>
        </Grid>
        <Grid container item xs={9} alignItems="center">
          <Typography style={createFieldStyle("management")} variant="body1" color="inherit">
            {formatStringField(data?.management?.value)}
          </Typography>
          {isNewData &&
            <HistoryCheckIcon
              isEdited={data?.management?.isEdited}
              value={data?.management?.value}
              isCreation={isCreation}
            />
          }
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} className="divider" />
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Typography style={createFieldStyle("licenseExpireDate")} variant="body1" color="inherit">
            Vencimento CNH
          </Typography>
        </Grid>
        <Grid container item xs={9} alignItems="center">
          <Typography style={createFieldStyle("licenseExpireDate")} variant="body1" color="inherit">
            {formatDateTimeToLiteral(data?.licenseExpireDate?.value, "pt-BR", false)}
          </Typography>
          {isNewData &&
            <HistoryCheckIcon
              isEdited={data?.licenseExpireDate?.isEdited}
              value={data?.licenseExpireDate?.value}
              isCreation={isCreation}
            />
          }
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} className="divider" />
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Typography style={createFieldStyle("licenseType")} variant="body1" color="inherit">
            Tipo CNH
          </Typography>
        </Grid>
        <Grid container item xs={9} alignItems="center">
          <Typography style={createFieldStyle("licenseType")} variant="body1" color="inherit">
            {formatStringField(driverLicenseTypes.find(e => e.value === data?.licenseType?.value)?.label)}
          </Typography>
          {isNewData &&
            <HistoryCheckIcon
              isEdited={data?.licenseType?.isEdited}
              value={data?.licenseType?.value}
              isCreation={isCreation}
            />
          }
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} className="divider" />
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Typography style={createFieldStyle("courseExpireDate")} variant="body1" color="inherit">
            Vencimento CDD
          </Typography>
        </Grid>
        <Grid container item xs={9} alignItems="center">
          <Typography style={createFieldStyle("courseExpireDate")} variant="body1" color="inherit">
            {formatDateTimeToLiteral(data?.courseExpireDate?.value, "pt-BR", false)}
          </Typography>
          {isNewData &&
            <HistoryCheckIcon
              isEdited={data?.courseExpireDate?.isEdited}
              value={data?.courseExpireDate?.value}
              isCreation={isCreation}
            />
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HistoryCardContentFields;