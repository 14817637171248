import React from "react";
import { Grid, FormControl, InputLabel, FormHelperText, MenuItem } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { driverLicenseTypes, selectMenuProps } from "../../../../shared/utils/enum";
import { ptBR } from "date-fns/locale";
import { CustomSelect } from "../../../../shared/components/CustomSelect";

const LicenseSegment = props => {
  const { control, errors, values, licenseTypeIsRequired, expireDateIsRequired } = props;
  const licenseTypeLabelText = "Tipo da CNH".concat(licenseTypeIsRequired ? " *" : "");
  const selectLicenseText = "Selecione o tipo da CNH".concat(licenseTypeIsRequired ? " *" : "");

  return (
    <>
      <Grid item container xs={8}>
        <Grid item xs={6} className="pr-5">
          <Controller
            name="licenseExpireDate"
            control={control}
            render={({ field: { ref, ...fieldProps } }) => {
              return (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <KeyboardDatePicker
                    autoComplete="off"
                    inputRef={ref}
                    {...fieldProps}
                    fullWidth
                    label={"Vencimento CNH".concat(expireDateIsRequired ? " *" : "")}
                    disablePast
                    variant="outlined"
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    cancelLabel="CANCELAR"
                    KeyboardButtonProps={{
                      "aria-label": "Selecione uma data"
                    }}
                    inputProps={{
                      "aria-label": "Selecione a data de vencimento da CNH".concat(
                        expireDateIsRequired ? " *" : ""
                      )
                    }}
                    InputLabelProps={{ shrink: true }}
                    error={errors && !!errors[fieldProps.name]}
                    helperText={
                      errors && errors[fieldProps.name]
                        ? errors[fieldProps.name].message
                        : null
                    }
                  />
                </MuiPickersUtilsProvider>
              );
            }}
          />
        </Grid>
        <Grid item xs={6} className="pr-5">
          <Controller
            name="licenseTypeId"
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth variant="outlined">
                  <InputLabel shrink={!!field.value}>
                    {field.value === 0 ? "" : licenseTypeLabelText}
                  </InputLabel>
                  <CustomSelect
                    required={licenseTypeIsRequired}
                    handleClearField={() => field.onChange("")}
                    label={values.licenseTypeId ? licenseTypeLabelText : undefined}
                    {...field}
                    variant="outlined"
                    inputProps={{
                      "aria-label": selectLicenseText
                    }}
                    MenuProps={selectMenuProps}
                    error={errors && !!errors[field.name]}
                  >
                    {driverLicenseTypes.map(type => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  <FormHelperText>
                    {errors && errors[field.name] ? errors[field.name].message : null}
                  </FormHelperText>
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LicenseSegment;
