import React from "react";
import StatusBox from "../../../shared/components/StatusBox";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { getCarStatusStyle } from "../../../shared/utils/Utils";
import { format } from "date-fns";
import { DARK_GRAY } from "../../../theme";

const VehicleDetailsHeader = props => {
  const { vehicleData, startValidity, statusBoxProps: { justifyEnd } = {} } = props;
  const classes = useStyles({ justifyEnd });

  if (!vehicleData) return null;

  const carStatusStyle = vehicleData && getCarStatusStyle(vehicleData.historyCarStatus?.status?.id);
  const licensePlate = vehicleData.carLicensing?.carLicensePlate || "-";
  const model = vehicleData.carInformation.carModel?.model || "";
  const provider = vehicleData.carContract?.carProvider?.name || "-";
  let fleetType = vehicleData.carInformation.fleet?.fleetType?.name || "-";
  if (fleetType.length > 1) fleetType = fleetType.replace("-", "");

  return (
    <Grid container>
      <Grid container item xs className={classes.header} alignItems="baseline">
        <Typography variant="h5" component="h3" className={classes.headerMain}>
          {licensePlate + " | " + model}
        </Typography>
        <Typography variant="h6" component="h3" className={`ml-2 mr-8 ${classes.headerInfo}`}>
          {provider + " - " + fleetType}
        </Typography>
        {startValidity && (
          <Grid item xs={12} className="mt-1">
            <Typography variant="body1" className={classes.subtitle}>
              Data da Vinculação:{" "}
              {startValidity ? format(new Date(startValidity), "dd/MM/yyyy") : "-"}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item className={classes.statusBoxItem}>
        <StatusBox
          boxStyle={carStatusStyle?.style}
          text={carStatusStyle?.name.toUpperCase()}
          textColor={carStatusStyle?.style.textColor}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  header: ({ justifyEnd }) => ({
    maxWidth: justifyEnd ? undefined : "fit-content"
  }),
  subtitle: {
    color: DARK_GRAY
  },
  headerMain: {
    fontWeight: "bold"
  },
  headerInfo: {
    fontWeight: "normal"
  },
  statusBoxItem: {
    alignSelf: "center"
  }
}));

export default VehicleDetailsHeader;
