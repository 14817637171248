import React from "react";
import { Grid, TextField, Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { selectMenuProps } from "../../../../shared/utils/enum";
import {
  MaskedInputFactory,
  createFormattedNumberMask
} from "../../../../shared/components/MaskedInputFactory";
import { CustomSelect } from "../../../../shared/components/CustomSelect";
import { CAR_PROVIDER_YARA_ID } from "../../../../shared/utils/Constants";

const mapProviders = (providerList, isMaintenance) => {
  return providerList
    .filter(
      provider =>
        provider.doesMaintenance === isMaintenance || provider.doesSalesOrRental === !isMaintenance
    )
    .map(provider => (
      <MenuItem key={provider.id} value={provider.id}>
        {provider.name}
      </MenuItem>
    ));
};

const ContractSegment = props => {
  const { control, errors, values, providerList, shouldDisable } = props;

  return (
    <Grid item container spacing={2}>
      <Grid item xs>
        <Controller
          name="providerId"
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth variant="outlined" disabled={shouldDisable}>
                <InputLabel shrink={!!field.value}>Fornecedor *</InputLabel>
                <Select
                  inputProps={{
                    "aria-label": "Selecione o fornecedor do veículo"
                  }}
                  label={values.providerId ? "Fornecedor *" : undefined}
                  {...field}
                  MenuProps={selectMenuProps}
                  variant="outlined"
                >
                  {mapProviders(providerList, false)}
                </Select>
              </FormControl>
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="maintenanceProviderId"
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth variant="outlined" disabled={shouldDisable}>
                <InputLabel shrink={!!field.value}>Manutenção</InputLabel>
                <CustomSelect
                  clear={!shouldDisable}
                  handleClearField={() => field.onChange(0)}
                  inputProps={{
                    "aria-label": "Selecione o fornecedor de manutenção do veículo"
                  }}
                  label={values.maintenanceProviderId ? "Manutenção" : undefined}
                  error={errors && !!errors[field.name]}
                  helperText={errors && errors[field.name] ? errors[field.name].message : null}
                  {...field}
                  MenuProps={selectMenuProps}
                  variant="outlined"
                >
                  {mapProviders(providerList, true)}
                </CustomSelect>
              </FormControl>
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="contractNumber"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                fullWidth
                {...field}
                label="Nº do Contrato"
                disabled={shouldDisable}
                error={errors && !!errors[field.name]}
                helperText={errors && errors[field.name] ? errors[field.name].message : null}
                type="text"
                variant="outlined"
                inputProps={{
                  "aria-label": "Digite o número do contrato",
                  maxLength: 100
                }}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="contractMonthlyRate"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                fullWidth
                {...field}
                required={values.providerId !== CAR_PROVIDER_YARA_ID}
                label="Valor Mensal"
                error={errors && !!errors[field.name]}
                helperText={errors && errors[field.name] ? errors[field.name].message : null}
                placeholder="R$1.000,00"
                type="text"
                variant="outlined"
                inputProps={{
                  "aria-label": "Digite o valor mensal do contrato"
                }}
                InputProps={{
                  inputComponent: ContractMonthlyRateMask
                }}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

const ContractMonthlyRateMask = MaskedInputFactory({
  id: "contractMonthlyRateMask",
  mask: createFormattedNumberMask({
    decimalPlaces: 2,
    prefix: "R$"
  })
});

export default ContractSegment;
