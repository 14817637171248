import React, { useState } from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { Layout } from "../../layout";
import { sidebarItemsNames, messages } from "../../../shared/utils/Constants";
import { DARK_GRAY, RED, WHITE } from "../../../theme";
import { ptBR } from "date-fns/locale";
import ReportsManagementManager from "../ReportsManagementManager";
import Loading from "../../../shared/components/Loading";
import { startOfDay, endOfDay, isToday } from "date-fns";

const ChecklistsAndReportsManagement = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState();

  const exportChecklistsAndReports = async () => {
    setLoading(true);

    await ReportsManagementManager.exportChecklistsAndReports(
      startOfDay(startDate),
      isToday(endDate) ? new Date() : endOfDay(endDate)
    );

    setLoading(false);
  };

  window.onbeforeunload = null;

  return (
    <Layout showHeader>
      <Grid item container xs={12} className="pt-8 pb-8 pl-6 pr-6">
        <Grid item xs={12} className="pb-6 pt-4">
          <Typography variant="h4" align="center">
            {sidebarItemsNames.reportsManagement}
          </Typography>
        </Grid>
        <Grid item xs={12} className="pb-8">
          <Typography variant="subtitle1" align="center" className={classes.subtitle}>
            Selecione a data de início e a data de fim <br />
            para exportar os dados de checklists e relatos
          </Typography>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={12} className={`${classes.components} pb-6 pt-4`}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <KeyboardDatePicker
                autoComplete="off"
                value={startDate || null}
                onChange={value => setStartDate(value)}
                label="Data de início"
                disableFuture
                variant="outlined"
                format="dd/MM/yyyy"
                placeholder="dd/mm/aaaa"
                inputVariant="outlined"
                cancelLabel="CANCELAR"
                ampm={false}
                KeyboardButtonProps={{
                  "aria-label": "Data de início"
                }}
                inputProps={{
                  "aria-label": "Informe a data de início"
                }}
                invalidDateMessage="Informe a data no formato correto"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} className={`${classes.components} pb-8 pt-4`}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <KeyboardDatePicker
                autoComplete="off"
                value={endDate || null}
                onChange={value => setEndDate(value)}
                label="Data de fim"
                disableFuture
                variant="outlined"
                format="dd/MM/yyyy"
                placeholder="dd/mm/aaaa"
                inputVariant="outlined"
                cancelLabel="CANCELAR"
                ampm={false}
                KeyboardButtonProps={{
                  "aria-label": "Data de fim"
                }}
                inputProps={{
                  "aria-label": "Informe a data de fim"
                }}
                invalidDateMessage="Informe a data no formato correto"
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.components}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => exportChecklistsAndReports()}
            className={classes.exportButton}
            disabled={!startDate || !endDate || startOfDay(startDate) > endDate || loading}
          >
            {loading ? <Loading loading className={classes.loading} /> : "EXPORTAR"}
          </Button>
        </Grid>
        {startOfDay(startDate) > endDate && (
          <Grid item xs={12} className={`${classes.components} pb-6 pt-2`}>
            <Typography variant="caption" className={classes.alertMessage}>
              {messages.START_DATE_GREATHER_THAN_END_DATE}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};

const useStyles = makeStyles(() => ({
  subtitle: {
    color: DARK_GRAY
  },
  components: {
    textAlign: "center"
  },
  exportButton: {
    width: "263px"
  },
  alertMessage: {
    color: RED
  },
  loading: {
    display: "flex",
    margin: "auto",
    color: WHITE
  }
}));

export default ChecklistsAndReportsManagement;
