import React from "react";
import {
  Dialog,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  Box,
  Button
} from "@material-ui/core";
import { AlertTriangle, Home, CheckCircle } from "react-feather";
import { PRIMARY_COLOR, SECONDARY_COLOR, WHITE, LIGHT_GREEN } from "../../theme";
import Loading from "./Loading";
import CloseIcon from "./CloseIcon";

const Modal = props => {
  const classes = useStyles(props);
  const {
    disableClickOutsideModal,
    loadingPrimaryButton,
    disableCloseOnEsc,
    secondaryButton,
    loadingContent,
    primaryButton,
    shouldCancel,
    titleVariant,
    preTitleIcon,
    openedModal,
    shouldExit,
    closeModal,
    titleAlign,
    closeIcon,
    titleIcon,
    className,
    errorType,
    preTitle,
    maxWidth,
    success,
    title
  } = props;

  const isThereTwoButtons = Boolean(primaryButton && secondaryButton);
  const buttonSizes = isThereTwoButtons ? 6 : 12;
  return (
    <Dialog
      open={openedModal}
      onClose={closeModal}
      scroll="body"
      maxWidth={maxWidth}
      fullWidth
      className={`${classes.modal} ${className}`}
      classes={{
        paper: `${classes.paperModal} ${errorType && classes.errorModalBorder} `
      }}
      disableBackdropClick={disableClickOutsideModal}
      disableEscapeKeyDown={disableCloseOnEsc}
    >
      {loadingContent ? (
        <Loading loading />
      ) : (
        <>
          {closeIcon && <CloseIcon onClick={closeModal} />}
          {shouldCancel && (
            <Grid className={classes.iconPadding}>
              <AlertTriangle color={SECONDARY_COLOR} size={45} className={classes.iconPosition} />
            </Grid>
          )}
          {shouldExit && (
            <Grid className={classes.iconPadding}>
              <Home color={SECONDARY_COLOR} size={45} className={classes.iconPosition} />
            </Grid>
          )}
          {success && (
            <Grid className={classes.iconPadding}>
              <CheckCircle color={LIGHT_GREEN} size={110} className={classes.iconPosition} />
            </Grid>
          )}

          {preTitleIcon && <Box className="pl-8">{preTitleIcon}</Box>}

          {preTitle && <Box className={`${classes.preTitleContainer} pt-8`}>{preTitle}</Box>}

          {title && (
            <DialogTitle
              disableTypography
              onClose={closeModal}
              className={`${classes.titleContainer} pb-2`}
            >
              {titleIcon && <Box className="pb-2">{titleIcon}</Box>}
              <Typography
                className={`${errorType ? classes.errorTitle : classes.title}`}
                align={titleAlign || "center"}
                variant={titleVariant || "h4"}
              >
                {title}
              </Typography>
            </DialogTitle>
          )}
          <DialogContent>{props.children}</DialogContent>

          <DialogActions className="p-6">
            <Grid container spacing={3}>
              {secondaryButton && (
                <Grid item align={isThereTwoButtons ? "left" : "center"} xs={buttonSizes}>
                  <Button
                    onClick={secondaryButton.action}
                    href={secondaryButton.href}
                    target={secondaryButton.openNewTab ? "_blank" : "_self"}
                    className={secondaryButton.className}
                    color={secondaryButton.color || "secondary"}
                    variant={secondaryButton.variant || "text"}
                    disabled={secondaryButton.disabled}
                    fullWidth
                  >
                    {secondaryButton.text}
                  </Button>
                </Grid>
              )}
              {primaryButton && (
                <Grid item align={isThereTwoButtons ? "right" : "center"} xs={buttonSizes}>
                  <Button
                    onClick={primaryButton.action}
                    href={primaryButton.href}
                    target={primaryButton.openNewTab ? "_blank" : "_self"}
                    className={primaryButton.className}
                    color={primaryButton.color || "secondary"}
                    variant={primaryButton.variant || "outlined"}
                    disabled={primaryButton.disabled}
                    fullWidth
                  >
                    {loadingPrimaryButton ? (
                      <Loading loading size={20} className={classes.loading} />
                    ) : (
                      primaryButton.text
                    )}
                  </Button>
                </Grid>
              )}
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  modal: {
    overflow: "hidden",
    width: "100%",
    maxHeight: "calc(100% - 70px)"
  },
  paperModal: {
    backgroundColor: WHITE
  },
  errorModalBorder: {
    border: `2px solid ${SECONDARY_COLOR}`
  },
  preTitleContainer: {
    textAlign: "center"
  },
  title: {
    color: PRIMARY_COLOR
  },
  errorTitle: {
    color: SECONDARY_COLOR
  },
  closeIcon: {
    position: "absolute",
    right: 0
  },
  iconPosition: {
    display: "flex",
    margin: "auto"
  },
  iconPadding: {
    paddingTop: "64px"
  },
  titleContainer: props => ({
    textAlign: "center",
    paddingTop: props.shouldCancel || props.shouldExit ? "0px" : "40px"
  }),
  loading: {
    margin: theme.spacing(2),
    position: "relative",
    color: WHITE
  }
}));

export default Modal;
