import React from "react";
import { Typography, Grid, LinearProgress, makeStyles } from "@material-ui/core";
import { AlertTriangle, Circle } from "react-feather";
import { reviewStatus } from "../../../shared/utils/enum";
import { RED, ORANGE, LIGHT_GREEN, LIGHT_GRAY, GRAY } from "../../../theme";
import TooltipItem from "../../../shared/components/TooltipItem";

const CarStatusItem = props => {
  const { unit, value, label, percentage, wrap } = props;

  const isExpired = label === reviewStatus.expired.label;

  const getColor = () => {
    if (isExpired) return RED;
    if (label === reviewStatus.toExpire.label) return ORANGE;
    if (label === reviewStatus.upToDate.label) return LIGHT_GREEN;
    return GRAY;
  };

  const createLabelText = () => {
    const stringValue = `${Math.abs(value)} ${unit}`;
    return isExpired ? `Atrasada há ${stringValue}` : `Daqui ${stringValue}`;
  };

  const classes = useStyles({
    color: getColor(),
    labelColor: isExpired ? RED : GRAY
  });

  return (
    <Grid>
      <TooltipItem disabled={!wrap || isExpired} tooltip={label}>
        <Grid container className="pb-3">
          {isExpired && !wrap && (
            <AlertTriangle width={14} height={14} className={classes.alertIcon} />
          )}
          {(value || value === 0) && (
            <TooltipItem tooltip={createLabelText()} onlyWhenOverflown>
              <Typography
                variant={isExpired ? "subtitle2" : "body2"}
                align="left"
                className={`${classes.labelText} ${classes.overflow} ${isExpired && "pl-1"}`}
              >
                {createLabelText()}
              </Typography>
            </TooltipItem>
          )}
          {!isExpired && (
            <>
              <Circle width={8} height={8} className={classes.circleIcon} />
              {!wrap && (
                <Typography variant="subtitle2" className={`${classes.statusText} pl-2`}>
                  {label}
                </Typography>
              )}
            </>
          )}
        </Grid>
      </TooltipItem>
      <Grid>
        <LinearProgress variant="determinate" value={percentage} className={classes.progressBar} />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  progressBar: props => ({
    borderRadius: 8,
    height: 8,
    backgroundColor: LIGHT_GRAY,
    "& .MuiLinearProgress-bar": {
      borderRadius: 8,
      backgroundColor: props.color
    }
  }),
  labelText: props => ({
    color: props.labelColor
  }),
  statusText: props => ({
    color: props.color
  }),
  alertIcon: props => ({
    color: props.color,
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto"
  }),
  circleIcon: props => ({
    color: props.color,
    fill: props.color,
    maxWidth: 14,
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "auto"
  }),
  overflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
}));

export default CarStatusItem;
