import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { WHITE, PRIMARY_COLOR, DARK_GRAY, DARK_YELLOW } from "../../../theme";
import { Home, Calendar, AlertOctagon, FileText, Clipboard } from "react-feather";
import { routes, messages, sidebarItemsNames } from "../../../shared/utils/Constants";
import { Layout } from "../../layout";
import * as actions from "../../../store/ChecklistStore";
import SafeByChoiceLargeIcon from "../../../shared/img/SafeByChoiceLarge.png";
import { accessType } from "../../../shared/utils/enum";

const ChecklistSecurityRisk = props => {
  const { screenDimensions, deleteChecklist, profile } = props;
  const classes = useStyles({ screenDimensions: screenDimensions });
  const isFacilities = profile === accessType.siteFacilities;
  const isBackOffice = profile === accessType.backoffice;
  const isCommercialOrExecutive = [accessType.commercial, accessType.executive].includes(profile);

  const [redirect, setRedirect] = useState(null);

  window.onbeforeunload = null;

  const primaryButtonText = () => {
    if (isBackOffice) return sidebarItemsNames.reportsManagement;
    return isCommercialOrExecutive ? sidebarItemsNames.listChecklists : "Minhas reservas";
  };

  const primaryButtonIcon = () => {
    if (isBackOffice) return <Clipboard size={40} />;
    return isCommercialOrExecutive ? <FileText size={40} /> : <Calendar size={40} />;
  };

  const redirectTo = () => {
    if (isBackOffice) return routes.reportsManagement;
    return isCommercialOrExecutive ? routes.listChecklists : routes.listBookings;
  };

  const subtitleMessage = isCommercialOrExecutive
    ? messages.SECURITY_RISK_COMMERCIAL_MESSAGE
    : messages.SECURITY_RISK_DEFAULT_MESSAGE;

  return (
    <Layout>
      <Grid container className={classes.container}>
        <Grid item xs={12} align="Center" className={classes.content}>
          <Grid item xs={12} align="right" className="pr-8">
            <img src={SafeByChoiceLargeIcon} alt="Ícone SafeByChoice" />
          </Grid>
          <Grid className="mt-8">
            <AlertOctagon className={classes.titleIcon} />
            <Typography variant="h6" className={`pr-2 pl-2 ${classes.text}`}>
              Para sua segurança, você não <br />
              pode utilizar este carro!
            </Typography>
            <Grid item container xs={12} align="Center" className="pt-2 pb-6 pr-2 pl-2">
              <Grid item xs={12} className="pb-4">
                <Typography variant="body2" className={classes.text}>
                  Itens críticos foram marcados como não OK no seu checklist que impedem de iniciar
                  uma viagem!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.text}>
                  {subtitleMessage}
                </Typography>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.primmaryButton}
              onClick={() => {
                deleteChecklist();
                setRedirect(<Redirect to={redirectTo()} />);
              }}
            >
              <Grid container>
                <Grid item xs={12} align="Center">
                  {primaryButtonIcon()}
                </Grid>
                <Grid item xs={12} align="Center">
                  <Typography variant="button">{primaryButtonText()}</Typography>
                </Grid>
              </Grid>
            </Button>
            <Grid item xs={12} align="Center" className="pb-6" />
            {isFacilities && (
              <Button
                variant="contained"
                className={classes.buttonHome}
                onClick={() => {
                  deleteChecklist();
                  setRedirect(<Redirect to={routes.calendar} />);
                }}
              >
                <Grid container>
                  <Grid item xs={12} align="Center">
                    <Home size={40} className={classes.homeIconText} />
                  </Grid>
                  <Grid item xs={12} align="Center">
                    <Typography variant="button" className={classes.homeIconText}>
                      Voltar para o calendário
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            )}
          </Grid>
          {redirect}
        </Grid>
      </Grid>
    </Layout>
  );
};

const useStyles = makeStyles(() => ({
  container: props => ({
    display: "flex",
    backgroundColor: DARK_YELLOW,
    height: `${props.screenDimensions.height}px`,
    width: `${props.screenDimensions.width}px`
  }),
  text: {
    color: DARK_GRAY
  },
  titleIcon: {
    color: DARK_GRAY,
    height: "54px",
    width: "54px"
  },
  primmaryButton: {
    width: "300px",
    height: "124px",
    borderRadius: "4px"
  },
  buttonHome: {
    width: "300px",
    height: "124px",
    backgroundColor: WHITE,
    borderRadius: "4px"
  },
  homeIconText: {
    color: PRIMARY_COLOR
  }
}));

const mapStateToProps = state => {
  return {
    screenDimensions: {
      height: state.window.height,
      width: state.window.width
    },
    profile: state.user.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteChecklist: () => dispatch(actions.deleteChecklist())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistSecurityRisk);
