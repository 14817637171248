import React from "react";
import { Grid, Typography, Box, Radio, FormControlLabel, makeStyles } from "@material-ui/core";
import { LIGHT_GRAY, SECONDARY_COLOR, THIN_BLUE2 } from "../../theme";

const RadioBox = props => {
  const { id, Icon, name, value, disabled, className } = props;

  const isChecked = parseInt(value) === id;
  const color = disabled ? LIGHT_GRAY : SECONDARY_COLOR;
  const classes = useStyles({ color, isChecked });

  return (
    <Box key={id} className={className}>
      <Grid container item xs={12} className={classes.choiceBox}>
        <FormControlLabel
          disabled={disabled}
          value={id}
          labelPlacement="start"
          className={classes.formControlLabel}
          control={<Radio disabled={disabled} checked={isChecked} className={classes.radio} />}
          label={
            <Grid container alignItems="center">
              <Grid item>
                <Icon fill={color} width={32} height={32} className="mt-2" />
              </Grid>
              <Grid item xs={10}>
                <Typography
                  className={`pt-2 pb-2 pl-4 ${classes.text}`}
                  variant="body1"
                  align="left"
                >
                  {name}
                </Typography>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  formControlLabel: {
    width: "100%",
    marginRight: "24px",
    marginLeft: "24px",
    justifyContent: "space-between"
  },
  choiceBox: ({ color, isChecked }) => ({
    width: "100%",
    border: `1px solid ${color}`,
    borderRadius: "8px",
    minHeight: "80px",
    backgroundColor: isChecked ? THIN_BLUE2 : undefined
  }),
  text: ({ color }) => ({
    color: color
  }),
  radio: {
    color: SECONDARY_COLOR
  }
}));

export default RadioBox;
