import React, { useState } from "react";
import {
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  TableBody
} from "@material-ui/core";
import { GRAY, LIGHT_GRAY2 } from "../../../theme";
import Loading from "../Loading";
import TooltipItem from "../TooltipItem";
import CustomTableHasNoData from "./CustomTableHasNoData";
import TablePagination from "./TablePagination";

const CustomTable = props => {
  const { data, loading, noDataMessage, headerData, RowComponent, optionsRowsPerPage } = props;

  const [pageItems, setPageItems] = useState([]);

  const classes = useStyles();

  return !loading && data.length <= 0 ? (
    <Grid container alignItems="center" className={classes.notFoundGrid}>
      <CustomTableHasNoData message={noDataMessage} />
    </Grid>
  ) : (
    <Grid item xs={12}>
      <Table size="medium" className={classes.table}>
        <TableHead>
          <TableRow className={classes.headerRow}>
            {headerData.map((header, headerIndex) => {
              return (
                header && (
                  <TableCell
                    key={headerIndex}
                    className={classes.headerCell}
                    align={header.align || "left"}
                    width={header.width}
                  >
                    <TooltipItem
                      tooltip={header.tooltip?.text || header.title}
                      onlyWhenOverflown={header.tooltip?.always ? false : true}
                    >
                      <Typography className={`${classes.overflow} ${classes.headerStyle}`}>
                        {header.title}
                      </Typography>
                    </TooltipItem>
                  </TableCell>
                )
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && pageItems.map((item, index) => <RowComponent item={item} key={index} />)}
        </TableBody>
      </Table>

      {loading && (
        <Grid item xs={12}>
          <Loading loading />
        </Grid>
      )}

      <TablePagination
        allItems={data}
        setPageItems={setPageItems}
        optionsRowsPerPage={optionsRowsPerPage}
      />
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: "fixed",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
      overflowX: "auto"
    }
  },
  headerRow: {
    backgroundColor: LIGHT_GRAY2
  },
  headerCell: {
    "&.MuiTableCell-root": {
      borderBottom: "0",
      padding: "8px"
    }
  },
  headerStyle: {
    fontSize: 12,
    color: GRAY
  },
  notFoundGrid: {
    height: "100vh",
    maxHeight: 600
  },
  overflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
}));

export default CustomTable;
