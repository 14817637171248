import React, { useState } from "react";
import { ViewState, GroupingState, IntegratedGrouping } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  Appointments,
  GroupingPanel,
  Resources,
  AppointmentTooltip
} from "@devexpress/dx-react-scheduler-material-ui";
import { Button, Grid, makeStyles, withStyles, Typography, Paper } from "@material-ui/core";
import { User, AlertTriangle } from "react-feather";
import { getSiteTagColor, makeBookings, makeFleetList, makeResources } from "../../../shared/utils/Utils";
import {
  calendarView,
  grouping,
  dateTimeViews,
  calendarLanguage,
  tripType,
  bookingStatus
} from "../../../shared/utils/enum";
import { LIGHT_GRAY2, PALE_BLUE_GRAY, SECONDARY_COLOR, GRAY_2 } from "../../../theme";
import Loading from "../../../shared/components/Loading";
import { routes } from "../../../shared/utils/Constants";
import { Redirect } from "react-router-dom";
import HeaderCarCalendar from "./HeaderCarCalendar";
import { format } from "date-fns";
import { connect } from "react-redux";
import ConfirmationModal from "../../../shared/components/ConfirmationModal";
import useCarActivation from "../customHooks/userCarActivation";

const DayDateViewType = props => {
  const [redirect, setRedirect] = useState();

  const { reloadData, height, bookings, currentDate, currentViewName, fleet } = props;
  const classes = useStyles();

  const bookingsList = makeBookings(bookings, fleet, currentViewName);
  const fleetList = makeFleetList(fleet);
  const resources = makeResources(fleetList);

  const {
    handleChangeActivation,
    confirmActivationHandler,
    handleCloseModalActivation,
    openedModalActivation,
    subtitleModalActivation,
    titleModalActivation,
    loadingConfirmation,
    loadingContent
  } = useCarActivation();

  const AppointmentContent = withStyles(null)(({ appointmentData, ...restProps }) => {
    return (
      <AppointmentTooltip.Content appointmentData={appointmentData.bookingTooltip} {...restProps}>
        <Grid container item xs={12} className="pl-6">
          <User className={classes.iconStatus} />
          <Typography className="pl-6" variant="body2">
            {appointmentData.bookingInfo}
          </Typography>
          <Grid item xs={12} className="pl-2 pt-4 pr-6">
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={() =>
                setRedirect(
                  <Redirect
                    to={`${routes.bookingDetailsByFac}/${appointmentData.bookingTooltip.id}`}
                  />
                )
              }
            >
              VER DETALHES DA RESERVA
            </Button>
          </Grid>
        </Grid>
      </AppointmentTooltip.Content>
    );
  });

  const GroupingPanelCell = withStyles(null)(({ group, ...restProps }) => {
    const fleetSite = group.id && group.id.split("-")[1];
    const carId = parseInt(group.id && group.id.split("-")[2]);
    const car = fleet.find(_car => _car.id === carId);

    const siteTagColor = getSiteTagColor(fleet, car.carSiteName);

    const activeMaintenanceBooking = bookings.find(
      booking =>
        booking.bookingTypeId === tripType.maintenance &&
        booking.carId === carId &&
        !booking.historyStatus.find(
          historyStatus => historyStatus.status.id === bookingStatus.CANCELED
        ) &&
        new Date(booking.finishJourney) > new Date()
    );

    return (
      <GroupingPanel.Cell group={{}} className={classes.calendarHeader} {...restProps}>
        <HeaderCarCalendar
          maintenanceBooking={activeMaintenanceBooking}
          carId={carId}
          currentDate={currentDate}
          fleetSite={fleetSite}
          carLicensePlate={car.carLicensePlate}
          carTypeId={car.carTypeId}
          siteTagColor={siteTagColor}
          handleChangeActivation={handleChangeActivation}
        />
      </GroupingPanel.Cell>
    );
  });

  const Appointment = ({ children, style, data, ...restProps }) => (
    <Appointments.Appointment
      {...restProps}
      data={data}
      style={{
        ...style,
        backgroundColor: data.backgroundColor
      }}
      className={classes.appointment}
    >
      {children}
    </Appointments.Appointment>
  );

  return bookingsList && currentViewName ? (
    <Paper square elevation={3}>
      <Scheduler locale={calendarLanguage.ptBR} data={bookingsList} height={height - 165}>
        <ViewState
          currentDate={format(currentDate, "yyyy-MM-dd")}
          currentViewName={currentViewName}
        />
        <GroupingState grouping={grouping} />
        <DayView
          startDayHour={calendarView.startDayHour}
          endDayHour={calendarView.endDayHour}
          cellDuration={calendarView.cellDuration}
          intervalCount={1}
          dayScaleRowComponent={() => null}
          timeTableCellComponent={_props => (
            <DayView.TimeTableCell {..._props} className={classes.dayCell} />
          )}
        />
        <Appointments appointmentComponent={Appointment} />
        <AppointmentTooltip showCloseButton contentComponent={AppointmentContent} />
        <Resources data={resources} />
        <IntegratedGrouping />
        <Grid item container xs={12} className={classes.schedulerBorder}>
          <GroupingPanel
            displayName={dateTimeViews.dayView.en_type}
            cellComponent={GroupingPanelCell}
          />
        </Grid>
      </Scheduler>
      {redirect}

      <ConfirmationModal
        titleIcon={<AlertTriangle size={45} color={SECONDARY_COLOR} />}
        title={titleModalActivation}
        subtitle={subtitleModalActivation}
        openedModal={openedModalActivation}
        primaryButtonText="SIM"
        secondaryButtonText="NÃO"
        primaryAction={() => confirmActivationHandler(reloadData)}
        handleOpenClose={handleCloseModalActivation}
        secondaryAction={handleCloseModalActivation}
        loading={loadingConfirmation}
        loadingContent={loadingContent}
      />
    </Paper>
  ) : (
    <Loading loading />
  );
};

const useStyles = makeStyles(theme => ({
  appointment: {
    "& > div": {
      color: GRAY_2
    }
  },
  calendarHeader: {
    backgroundColor: LIGHT_GRAY2,
    border: `1px solid ${PALE_BLUE_GRAY}`,
    padding: "6px 4px 0 4px",
    minWidth: "200px",
    width: "200px",

    "& > div > div": {
      width: "100%",

      "& img": {
        height: "40px"
      },

      "& > div div:not(:first-child)": {
        display: "flex",
        justifyContent: "center"
      }
    }
  },
  dayCell: {
    minWidth: "200px",
    width: "200px"
  },
  schedulerBorder: {
    border: `1px solid ${PALE_BLUE_GRAY}`,
    borderRadius: theme.spacing(1)
  },
  iconStatus: {
    width: "16px",
    height: "17px",
    color: SECONDARY_COLOR
  }
}));

const mapStateToProps = state => {
  return {
    height: state.window.height
  };
};

export default connect(mapStateToProps, null)(DayDateViewType);
