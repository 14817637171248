import UserService from "../../services/UserService";

class ChecklistsAndReportManager {
  async listRecurringChecklistsByUserId(userId) {
    return UserService.listRecurringChecklistsByUserId(userId).then(
      answer => answer && answer.data
    );
  }

  async listReportsByUserId(userId) {
    return UserService.listReportsByUserId(userId).then(answer => answer && answer.data);
  }
}

export default new ChecklistsAndReportManager();
