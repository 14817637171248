import FleetService from "../../services/FleetService";
import BookingService from "../../services/BookingService";
import CarService from "../../services/CarService";

class CalendarManager {
  async listFleetByPoleId(poleId) {
    return FleetService.listFleetByPoleId(poleId).then(answer => answer && answer.data);
  }

  async listBookingsFiltered(carId, startJourney, finishJourney, poleId) {
    return BookingService.listBookingsFiltered(carId, startJourney, finishJourney, poleId).then(
      answer => answer && answer.data
    );
  }

  async inactivateCar(carId, inactivityStart, inactivityFinish) {
    return CarService.inactivateCar(carId, inactivityStart, inactivityFinish).then(
      answer => answer && answer.data
    );
  }

  async reactivateCar(carId, inactivityFinish) {
    return CarService.reactivateCar(carId, inactivityFinish).then(
      answer => answer && answer.data
    );
  }
}

export default new CalendarManager();
