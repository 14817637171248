import React from "react";
import { Grid, Typography, makeStyles, Divider } from "@material-ui/core";
import { FileText } from "react-feather";
import { format } from "date-fns";
import GeneralInformation from "../../../shared/components/GeneralInformation";
import { DARK_GRAY, GRAY } from "../../../theme";
import { securityReportOtherCases } from "../../../shared/utils/enum";

const ReportContent = props => {
  const { report, user } = props;
  const classes = useStyles();

  let localDescription = "Descrição local";
  let localValue = report.localDescription;

  if (report.local.id === securityReportOtherCases.LOCAL_OFFICE) {
    localDescription = "Escritório";
    localValue = report.office.name;
  }

  if (report.local.id === securityReportOtherCases.LOCAL_SITE) {
    localDescription = "Planta";
    localValue = report.site.name;
  }

  const fields = [
    "Nome",
    "Diretoria/Regional",
    "Gerência",
    "Principal envolvido",
    "Local",
    localDescription,
    "Ocorrência",
    "Data e horário da ocorrência",
    "Assunto"
  ];

  const generalInformationData = report && [
    user.name,
    user.management.regional.name,
    user.management.name,
    report.principalInvolved.name,
    report.local.name,
    localValue || "Não informado",
    report.occurrence.name,
    format(new Date(report.occurrenceDateTime), "dd/MM/yy - HH:mm"),
    report.subject.name
  ];

  return (
    <Grid container className="pl-6 pr-6 pt-4">
      <Grid item xs={12} className="pb-4">
        <Grid item xs={12} className="pt-8">
          <Typography variant="h6">Detalhes do relato</Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} className={`pb-4 ${classes.reportNumber}`}>
        <FileText size={24} />
        <Typography variant="body1" className="ml-3">{`Relato #${report.id}`}</Typography>
      </Grid>

      <Grid item xs={12} className="pb-4">
        <Divider />
      </Grid>

      <GeneralInformation data={generalInformationData} fields={fields} />

      <Grid item xs={12} className="pb-4 pt-1">
        <Divider />
      </Grid>

      <Grid item xs={12} className="mb-6">
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.infoTitleColor}>
            Descrição
          </Typography>
        </Grid>
        <Grid item xs={12} className="mt-1">
          <Typography variant="body1" className={classes.infoColor}>
            {report.description}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} className="pb-3">
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.infoTitleColor}>
            Sugestão de Melhoria
          </Typography>
        </Grid>
        <Grid item xs={12} className="mt-1">
          <Typography variant="body1" className={classes.infoColor}>
            {report.feedBack || "Não informado"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  reportNumber: {
    display: "flex",
    color: DARK_GRAY,
    "& > p": {
      color: DARK_GRAY
    }
  },
  infoColor: {
    color: DARK_GRAY,
    fontWeight: 400
  },
  infoTitleColor: {
    color: GRAY
  }
}));

export default ReportContent;
