import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import ReactDropzone from "react-dropzone";
import { LIGHT_GRAY, SECONDARY_COLOR, THIN_BLUE2 } from "../../theme";
import { messages } from "../utils/Constants";

const Dropzone = props => {
  const {
    className,
    disabled,
    handleDrop,
    handleMouseEnter,
    handleMouseLeave,
    acceptedExtensions = [".xlsx"],
    maxFiles = 1,
    maxFileSize = 1.5,
    minFileSize = 1,
    isMultiple = false,
    children
  } = props;

  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const classes = useStyles({ isDraggingOver, disabled });

  const handleDragEnter = () => {
    if (handleMouseEnter && !disabled) handleMouseEnter();

    setIsDraggingOver(true);
  }

  const handleDragLeave = () => {
    if (handleMouseLeave && !disabled) handleMouseLeave();

    setIsDraggingOver(false);
  }

  const handleDropAccepted = files => {
    const formattedFiles = files.map(file => ({
      name: file.name,
      hasImportError: false,
      content: file
    }));

    handleDrop(formattedFiles);
  }

  const handleDropRejected = files => {
    const formattedFiles = files.map(file => ({
      name: file.file.name,
      hasImportError: true,
      errors: file.errors.map(error => DropzoneErrors[error.code])
    }));

    handleDrop(formattedFiles);
  }

  return (
    <ReactDropzone
      accept={acceptedExtensions}
      maxFiles={maxFiles}
      minSize={minFileSize}
      maxSize={maxFileSize * 1000000}
      multiple={isMultiple}
      disabled={disabled}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDragLeave}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()} className={`${classes.root} ${className}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <input {...getInputProps()} />
          {children}
        </div>
      )}
    </ReactDropzone>
  );
};

const DropzoneErrors = {
  "file-invalid-type": messages.INVALID_FILE_TYPE,
  "file-too-large": messages.FILE_TOO_LARGE,
  "file-too-small": messages.FILE_TOO_SMALL
}

const useStyles = makeStyles(() => ({
  root: ({ isDraggingOver, disabled }) => ({
    borderWidth: "1px",
    borderRadius: "8px",
    borderStyle: isDraggingOver && !disabled ? "solid" : "dashed",
    borderColor: isDraggingOver && !disabled ? SECONDARY_COLOR : LIGHT_GRAY,
    backgroundColor: isDraggingOver && !disabled && THIN_BLUE2,
    cursor: !disabled && "pointer",
    "&:hover": {
      borderStyle: !disabled ? "solid" : "dashed",
      borderColor: !disabled ? SECONDARY_COLOR : LIGHT_GRAY,
      backgroundColor: !disabled ? THIN_BLUE2 : "inherit"
    },
    "&:focus": {
      outline: "none"
    }
  })
}));

export default Dropzone;