import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { UserCheck } from "react-feather";
import ChecklistCard from "./ChecklistCard";
import { format } from "date-fns";
import { tabsListChecklists } from "../../../shared/utils/enum";
import Loading from "../../../shared/components/Loading";
import { GRAY } from "../../../theme";
import CarIcon from "../../../shared/img/CarIcon.svg";
import { messages } from "../../../shared/utils/Constants";
import useChecklistAndReport from "../customHooks/useChecklistAndReport";
import ReportCard from "./ReportCard";

const ListChecklists = props => {
  const { userId, activeTab } = props;
  const classes = useStyles();

  window.onbeforeunload = null;

  const { checklists, reports, loading } = useChecklistAndReport(userId, activeTab);

  const data = activeTab === tabsListChecklists.checklists.value ? checklists : reports;
  const hasData = data && data.length > 0;

  return (
    <Fragment>
      {!loading && (
        <Grid item xs={12}>
          {hasData &&
            data.map((item, index) => {
              return (
                <Grid item xs={12} key={index}>
                  {activeTab === tabsListChecklists.checklists.value ? (
                    <ChecklistCard
                      checklistId={item.id}
                      responseDate={format(new Date(item.responseDate), "dd/MM/yy")}
                      responseTime={format(new Date(item.responseDate), "HH:mm")}
                      carModel={item.car.carInformation?.carModel?.model}
                      checklistStatusText={"Checklist realizado"}
                      checklistStatusIcon={<UserCheck className={classes.iconStatus} />}
                      carIcon={
                        <img src={CarIcon} alt="Ícone de carro" className={classes.carTypeIcon} />
                      }
                    />
                  ) : (
                    <ReportCard report={item} userId={userId} />
                  )}
                </Grid>
              );
            })}
        </Grid>
      )}

      {!loading && !hasData && (
        <Grid className={classes.informationPosition}>
          <Typography variant="h5" className={classes.informationText}>
            {activeTab === tabsListChecklists.checklists.value
              ? messages.NO_CHECKLISTS
              : messages.NO_REPORTS}
          </Typography>
        </Grid>
      )}

      {loading && <Loading loading></Loading>}
    </Fragment>
  );
};
const useStyles = makeStyles(() => ({
  iconStatus: {
    width: "18px",
    height: "24px",
    color: GRAY
  },
  carTypeIcon: {
    width: 23,
    height: 23,
    display: "flex",
    marginLeft: "auto"
  },
  informationText: {
    color: GRAY,
    textAlign: "center",
    margin: "auto"
  },
  informationPosition: {
    display: "flex",
    alignItems: "center",
    height: "50vh"
  }
}));

const mapStateToProps = state => {
  return {
    userId: state.user.id
  };
};

export default connect(mapStateToProps, null)(ListChecklists);
