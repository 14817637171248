import React, { useCallback, useEffect, useState } from "react";
import { Layout } from "../../layout";
import { Grid } from "@material-ui/core";
import { Users } from "react-feather";
import UserHistoryManager from "../UserHistoryManager";
import Loading from "../../../shared/components/Loading";
import { messages } from "../../../shared/utils/Constants";
import { HistoryCard, HistoryPageHeader } from "../../../shared/components/HistoryPage";
import HistoryCardContent from "./HistoryCardContent";

const UserHistory = props => {
  const {
    location: {
      state: { userId }
    }
  } = props;

  const [userHistory, setUserHistory] = useState([]);
  const [userHistoryIsLoading, setUserHistoryIsLoading] = useState(true);
  const [cardExpansionStates, setCardExpansionStates] = useState([]);
  const [allCardsExpanded, setAllCardsExpanded] = useState(false);

  const getUserHistory = useCallback(async id => {
    setUserHistoryIsLoading(true);
    const response = await UserHistoryManager.getUserHistory(id);

    setUserHistory(response);
    setCardExpansionStates(response.map(_ => (response.length > 3 ? false : true)));

    setUserHistoryIsLoading(false);
  }, []);

  useEffect(() => {
    getUserHistory(userId);
  }, [userId, getUserHistory]);

  useEffect(() => {
    setAllCardsExpanded(cardExpansionStates.every(state => state === true));
  }, [cardExpansionStates]);

  const handleExpandAllClick = () => {
    if (allCardsExpanded) {
      setCardExpansionStates(cardExpansionStates.map(_ => false));
    } else {
      setCardExpansionStates(cardExpansionStates.map(_ => true));
    }
  };

  const getOldData = index => {
    return index !== userHistory.length - 1 && userHistory[index + 1];
  };

  return (
    <Layout showHeader>
      <Grid item container xs={12} className="pt-8 pr-8">
        <HistoryPageHeader
          allCardsExpanded={allCardsExpanded}
          handleExpandAllClick={handleExpandAllClick}
        />
      </Grid>
      <Grid item xs={12} className="pl-8 pb-8 pt-8 pr-8">
        {userHistoryIsLoading ? (
          <Loading loading />
        ) : (
          <>
            {userHistory.map((data, index) => {
              return (
                <HistoryCard
                  key={index}
                  oldData={getOldData(index)}
                  newData={data}
                  isExpanded={cardExpansionStates[index]}
                  setIsExpanded={state =>
                    setCardExpansionStates(
                      cardExpansionStates.map((value, i) => (i === index ? state : value))
                    )
                  }
                  automaticEditionReason={
                    data.user.active ? (
                      messages.AD_EDITION_REASON
                    ) : (
                      messages.AD_EDITION_REASON_INACTIVATION
                    )}
                  HistoryCardContent={HistoryCardContent}
                  TagProps={{
                    contentLabel: "Usuário",
                    DefaultIcon: outerProps => <Users {...outerProps} size={20} />
                  }}
                />
              );
            })}
          </>
        )}
      </Grid>
    </Layout>
  );
}

export default UserHistory;