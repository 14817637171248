import { useState } from "react";
import SecurityReportManager from "../SecurityReportManager";
import { moveOtherOptionToTheEnd } from "../../../shared/utils/Utils";

const useSecurityReport = () => {
  const [principalInvolvedList, setPrincipalInvolvedList] = useState([]);
  const [localList, setLocalList] = useState([]);
  const [occurrenceList, setOccurrenceList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [siteList, setSiteList] = useState([]);

  const formatDataToSubmit = securityReport => ({
    localDescription: securityReport.localDescription,
    occurrenceDateTime: securityReport.occurrenceDateTime,
    principalInvolvedDescription: securityReport.principalInvolvedDescription,
    subjectDescription: securityReport.subjectDescription,
    description: securityReport.description,
    feedBack: securityReport.feedback,
    office: {
      id: securityReport.officeId
    },
    site: {
      id: securityReport.siteId
    },
    user: {
      id: securityReport.userId
    },
    principalInvolved: {
      id: securityReport.principalInvolvedId
    },
    local: {
      id: securityReport.localId
    },
    occurrence: {
      id: securityReport.occurrenceId
    },
    subject: {
      id: securityReport.subjectId
    },
    reportImagesFormData: securityReport.reportImagesFormData
  });

  async function listPrincipalInvolved() {
    const data = await SecurityReportManager.listPrincipalInvolved();
    if (data) {
      const newData = moveOtherOptionToTheEnd(data);
      setPrincipalInvolvedList(newData);
    }
  }

  async function listLocal() {
    const data = await SecurityReportManager.listLocal();
    if (data) {
      const newData = moveOtherOptionToTheEnd(data);
      setLocalList(newData);
    }
  }

  async function listSite() {
    const data = await SecurityReportManager.listSite();
    if (data) {
      const orderedData = data.sort((a, b) => {
        if (a.fullName > b.fullName) return 1;
        return b.fullName > a.fullName ? -1 : 0;
      });
      setSiteList(orderedData);
    }
  }

  async function listOccurrence(localId) {
    const data = await SecurityReportManager.listOccurrence(localId);
    if (data) setOccurrenceList(data);
  }

  async function listSubject(localId, occurrenceId) {
    const data = await SecurityReportManager.listSubject(localId, occurrenceId);
    if (data) {
      const newData = moveOtherOptionToTheEnd(data);
      setSubjectList(newData);
    }
  }

  async function createSecurityReport(securityReport, reportImagesFormData, callback) {
    const formatedData = formatDataToSubmit(securityReport);

    const isSubmited = async () => {
      const createdReportId = await SecurityReportManager.createSecurityReport(formatedData);

      if (createdReportId && reportImagesFormData.has("reportFiles")) {
        return SecurityReportManager.uploadReportImages(createdReportId, reportImagesFormData);
      }

      return createdReportId;
    };

    if (await isSubmited()) callback();
  }

  return {
    principalInvolvedList,
    listPrincipalInvolved,
    localList,
    listLocal,
    siteList,
    listSite,
    occurrenceList,
    listOccurrence,
    subjectList,
    listSubject,
    createSecurityReport
  };
};

export default useSecurityReport;
