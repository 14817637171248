import { useState, useEffect, useCallback } from "react";
import { bookingStatus, tabsMyBookings } from "../../../shared/utils/enum";
import ListBookingsManager from "../ListBookingsManager";

const useListBookingsByUserId = (userId, currentTab) => {
  const [loading, setLoading] = useState(true);
  const [bookings, setBookings] = useState([]);
  const [hasBookings, setHasBookings] = useState(false);
  const [filteredBookings, setfilteredBookings] = useState([]);

  const sortBookings = useCallback(
    (firstBooking, secondBooking) => {
      let sorted;

      const lastHistoryStatusFirstBooking =
        firstBooking.historyStatus[firstBooking.historyStatus.length - 1];
      const lastHistoryStatusSecondBooking =
        secondBooking.historyStatus[secondBooking.historyStatus.length - 1];

      switch (currentTab) {
        case tabsMyBookings.finished.value:
          sorted =
            new Date(secondBooking.startJourney).getTime() -
            new Date(firstBooking.startJourney).getTime();
          break;
        case tabsMyBookings.canceled.value:
          sorted =
            lastHistoryStatusFirstBooking.status.createdAt &&
            lastHistoryStatusSecondBooking.status.createdAt
              ? new Date(lastHistoryStatusSecondBooking.status.createdAt).getTime() -
                new Date(lastHistoryStatusFirstBooking.status.createdAt).getTime()
              : lastHistoryStatusFirstBooking.status.createdAt
              ? new Date(secondBooking.finishJourney).getTime() -
                new Date(lastHistoryStatusFirstBooking.status.createdAt).getTime()
              : lastHistoryStatusSecondBooking.status.createdAt
              ? new Date(lastHistoryStatusSecondBooking.status.createdAt).getTime() -
                new Date(firstBooking.finishJourney).getTime()
              : new Date(secondBooking.finishJourney).getTime() -
                new Date(firstBooking.finishJourney).getTime();
          break;
        default:
          sorted =
            new Date(firstBooking.startJourney).getTime() -
            new Date(secondBooking.startJourney).getTime();
          break;
      }

      return sorted;
    },
    [currentTab]
  );

  const bookingBelongsToTab = useCallback(
    booking => {
      const lastHistoryStatus = booking.historyStatus[booking.historyStatus.length - 1];

      const expired = lastHistoryStatus.status.id === bookingStatus.EXPIRED;
      const canceled = lastHistoryStatus.status.id === bookingStatus.CANCELED;
      const approved = lastHistoryStatus.status.id === bookingStatus.APPROVED;
      const finished = lastHistoryStatus.status.id === bookingStatus.FINALIZED;
      const inProgress = lastHistoryStatus.status.id === bookingStatus.IN_PROGRESS;
      const securityRisk = lastHistoryStatus.status.id === bookingStatus.SECURITY_RISK;
      const checklistStarted = lastHistoryStatus.status.id === bookingStatus.CHECKLIST_STARTED;

      return (
        (currentTab === tabsMyBookings.active.value &&
          !expired &&
          (approved || inProgress || checklistStarted)) ||
        (currentTab === tabsMyBookings.finished.value && finished) ||
        (currentTab === tabsMyBookings.canceled.value &&
          !finished &&
          (expired || canceled || securityRisk))
      );
    },
    [currentTab]
  );

  useEffect(() => {
    let filteredBookingsByTab = bookings.filter(bookingBelongsToTab);
    setfilteredBookings(filteredBookingsByTab.sort(sortBookings));
  }, [currentTab, bookings, sortBookings, bookingBelongsToTab]);

  useEffect(() => {
    const listBookingsByUserId = async () => {
      const booking = await ListBookingsManager.listBookingsByUserId(userId);
      if (booking) {
        setLoading(false);
        setHasBookings(true);
        setBookings(booking);
      }
    };

    if (userId) listBookingsByUserId();
  }, [userId]);

  return { filteredBookings, loading, hasBookings };
};

export default useListBookingsByUserId;
