import React, { useState, useRef } from "react";
import { TableCell, TableRow, makeStyles, Typography, Grid, IconButton } from "@material-ui/core";
import { MoreHorizontal } from "react-feather";
import { DARK_GRAY, SECONDARY_COLOR, LIGHT_GRAY2 } from "../../../theme";
import { getCarStatusStyle } from "../../../shared/utils/Utils";
import FloatingDropdown from "../../../shared/components/FloatingDropdown";
import StatusBox from "../../../shared/components/StatusBox";

const FleetContentTableRow = props => {
  const { item, handleEditData, handleHistoryRedirect, handleDetailsRedirect } = props;

  const classes = useStyles();

  const actionsMenuRef = useRef();
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  const carStatusStyle = getCarStatusStyle(item.historyCarStatus?.status?.id);

  return (
    <TableRow hover className="hover-icon" onClick={() => handleDetailsRedirect(item.id)}>
      <TableCell className={classes.bodyCell} align="left">
        <Grid item xs={12}>
          <Typography variant="subtitle2">{item.privateCar?.user?.name || "-"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{item.privateCar?.user?.role?.name || "-"}</Typography>
        </Grid>
      </TableCell>
      <TableCell className={classes.bodyCell} align="left">
        <Grid item xs={12}>
          <Typography variant="subtitle2">{item.carLicensing?.carLicensePlate}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{item.carContract?.carProvider?.name}</Typography>
        </Grid>
      </TableCell>
      <TableCell className={classes.bodyCell} align="left">
        <Typography variant="body1">{item.carInformation.fleet?.fleetType?.name}</Typography>
      </TableCell>
      <TableCell className={classes.bodyCellBreakLine} align="left">
        <Grid item xs={12}>
          <Typography variant="subtitle2">{item.privateCar?.user?.email || "-"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{item.privateCar?.user?.adUser || "-"}</Typography>
        </Grid>
      </TableCell>
      <TableCell className={classes.bodyCellBreakLine} align="left">
        <Grid item xs={12}>
          {item.privateCar?.user?.costCenter?.name || "-"}
        </Grid>
      </TableCell>
      <TableCell className={classes.bodyCell} align="left">
        <StatusBox
          boxStyle={carStatusStyle.style}
          text={carStatusStyle.name.toUpperCase()}
          textColor={carStatusStyle.style.textColor}
        />
      </TableCell>
      <TableCell className={classes.bodyCell} align="left">
        <IconButton
          id="menu"
          aria-label="Exibir ações"
          ref={actionsMenuRef}
          onClick={e => {
            e.stopPropagation();
            setShowDropdownMenu(true);
          }}
        >
          <MoreHorizontal size={24} color={SECONDARY_COLOR} />
        </IconButton>
        <FloatingDropdown
          enableDividers
          disabled={!showDropdownMenu}
          parentRef={actionsMenuRef.current}
          justifyStart
          className="mr-3"
          onOutsideClick={() => setShowDropdownMenu(false)}
        >
          <Typography
            className={`p-4 ${classes.dropdownItem}`}
            onClick={e => {
              e.stopPropagation();
              handleDetailsRedirect(item.id);
            }}
          >
            Visualizar
          </Typography>
          <Typography
            className={`p-4 ${classes.dropdownItem}`}
            onClick={e => {
              e.stopPropagation();
              handleEditData(item.id);
            }}
          >
            Editar
          </Typography>
          <Typography
            className={`p-4 ${classes.dropdownItem}`}
            onClick={e => {
              e.stopPropagation();
              handleHistoryRedirect(item.id);
            }}
          >
            Histórico
          </Typography>
        </FloatingDropdown>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles(() => ({
  bodyCell: {
    "&.MuiTableCell-root": {
      padding: "10px"
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "inherit"
    },
    fontSize: 16,
    color: DARK_GRAY
  },
  bodyCellBreakLine: {
    "&.MuiTableCell-root": {
      paddingLeft: "8px"
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "inherit"
    },
    fontSize: 16,
    color: DARK_GRAY
  },
  dropdownItem: {
    "&:hover": {
      backgroundColor: LIGHT_GRAY2,
      cursor: "pointer"
    }
  }
}));

export default FleetContentTableRow;
