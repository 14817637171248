import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Grid, makeStyles, Typography, TextField } from "@material-ui/core";
import { GRAY, LIGHT_GRAY } from "../../../theme";
import useChecklist from "../customHooks/useChecklist";
import * as actions from "../../../store/ChecklistStore";
import { routes, messages } from "../../../shared/utils/Constants";
import LevelSlider from "../../../shared/components/LevelSlider";
import { addThousandSeparator } from "../../../shared/utils/Utils";
import ChecklistQuestion from "../../../shared/components/ChecklistQuestion";
import {
  accessType,
  marksFuelLevel,
  bookingCarInformationFields,
  commercialCarInformationFields
} from "../../../shared/utils/enum";
import ChecklistManager from "../ChecklistManager";
import NewChecklist from "./NewChecklist";

const CheckCarInformation = props => {
  const { checklist, updateChecklist, bookingDetails, privateCar, user, window } = props;

  const classes = useStyles();

  const [allItemsSetStatus, setAllItemsSetStatus] = useState(false);
  const [redirect, setRedirect] = useState();
  const isBookingChecklist = useRef(bookingDetails && bookingDetails.id);
  const [carInformationFields, setCarInformationFields] = useState();
  const [carInformationData, setCarInformationData] = useState();
  const [warningMessage, setWarningMessage] = useState();

  const {
    handleSaveChecklistQuestionResponse,
    getInitialChecklistQuestionResponse,
    handleChangeFuelLevel,
    groupedStepQuestions,
    handleChangeMileage,
    stepGroups
  } = useChecklist(checklist, updateChecklist, setAllItemsSetStatus);

  window.onbeforeunload = function () {
    return messages.ALERT_RELOAD_PAGE;
  };

  useEffect(() => {
    const setCarInformation = async () => {
      const userData = await ChecklistManager.getUserById(
        isBookingChecklist.current ? bookingDetails.driverId : user.id
      );
      setCarInformationFields(
        isBookingChecklist.current ? bookingCarInformationFields : commercialCarInformationFields
      );
      setCarInformationData(
        isBookingChecklist.current
          ? [
              userData.name,
              new Date(userData.license.expireDate).toLocaleDateString(),
              new Date(userData.course.expireDate).toLocaleDateString(),
              bookingDetails.carLicensePlate,
              bookingDetails.carModel,
              bookingDetails.carDocument,
              bookingDetails.siteName
            ]
          : [
              user.name,
              user.management.regional.name,
              user.management.name,
              new Date(userData.license.expireDate).toLocaleDateString(),
              new Date(userData.course.expireDate).toLocaleDateString(),
              privateCar?.document
            ]
      );
    };
    const redirectTo = () => {
      const isCommercialOrExecutive = [accessType.commercial, accessType.executive].includes(
        user.profile
      );
      const isBackOffice = user.profile === accessType.backoffice;

      if (isBackOffice) return routes.reportsManagement;
      return isCommercialOrExecutive ? routes.listChecklists : routes.listBookings;
    };

    if (!checklist.checklistModelId && !bookingDetails.id)
      setRedirect(<Redirect to={redirectTo()} />);

    setCarInformation();
    setWarningMessage(
      isBookingChecklist.current
        ? messages.WARNING_CHECKLIST_INFO
        : messages.WARNING_COMMERCIAL_CHECKLIST_INFO
    );
  }, [bookingDetails, checklist, user, privateCar]);

  return (
    <NewChecklist
      stepIndex={0}
      customRoute={bookingDetails.id && `${routes.bookingDetails}/${bookingDetails.id}`}
      disableButton={!checklist.mileage || !allItemsSetStatus}
      nextStep={routes.checklistSteps.CHECK_CAR_INTERIOR.route}
      warningMessage={warningMessage}
      carInfo={`${privateCar?.licensePlate ?? "--"} | ${privateCar?.model ?? "-"}`}
      isBookingChecklist={isBookingChecklist.current}
      carInformationFields={carInformationFields}
      carInformationData={carInformationData}
    >
      <Grid container item>
        <Grid item xs={12}>
          {stepGroups &&
            stepGroups.map((stepGroup, groupIndex) => {
              return (
                <Grid key={groupIndex}>
                  <Grid item xs={12} className="mb-12">
                    <Typography variant="h5" className="pb-4">
                      {stepGroup}
                    </Typography>
                    <Typography variant="subtitle1">
                      Responda as perguntas a seguir selecionando as reações positivas ou negativas
                      e se necessário acrescente um comentário
                    </Typography>
                  </Grid>
                  {groupedStepQuestions[stepGroup] &&
                    groupedStepQuestions[stepGroup].map((question, questionIndex) => {
                      return (
                        question && (
                          <Grid key={questionIndex} className="mb-6">
                            <ChecklistQuestion
                              step={1}
                              padding
                              checklistQuestion={question}
                              critical={question.critical}
                              initialObservations={getInitialChecklistQuestionResponse(
                                question,
                                "observations"
                              )}
                              initialSelectedStatus={getInitialChecklistQuestionResponse(
                                question,
                                "checklistTypeAnswerId"
                              )}
                              updateChecklistQuestionResponses={handleSaveChecklistQuestionResponse}
                            />
                          </Grid>
                        )
                      );
                    })}
                </Grid>
              );
            })}
        </Grid>
        <Grid container>
          <Grid item xs={12} className="mb-2">
            <Typography variant="subtitle1" className={classes.infoTitleColor}>
              Qual a quilometragem atual do veículo?
            </Typography>
          </Grid>
          <Grid item xs={12} align="left" className="mb-6">
            <TextField
              fullWidth
              placeholder="Digite aqui*"
              variant="outlined"
              type="tel"
              inputProps={{ maxLength: 7 }}
              value={checklist.mileage ? addThousandSeparator(checklist.mileage) : ""}
              onChange={handleChangeMileage}
              InputProps={{ className: classes.inputDisplay }}
              className={classes.mileageInput}
            />
          </Grid>
          <Grid item xs={12} className="mb-2">
            <Typography variant="subtitle1" className={classes.infoTitleColor}>
              Informe o nível de combustível atual
            </Typography>
          </Grid>
          <Grid item xs={12} align="center" className="pl-4 pr-4">
            <LevelSlider
              value={checklist.fuelLevel}
              handleChangeLevel={handleChangeFuelLevel}
              marks={marksFuelLevel}
              step={25}
              min={25}
            />
          </Grid>
        </Grid>
      </Grid>
      {redirect}
    </NewChecklist>
  );
};
const useStyles = makeStyles(() => ({
  infoTitleColor: {
    color: GRAY
  },
  inputDisplay: {
    "&.MuiInput-underline:after": {
      display: "none"
    },
    "&.MuiInput-underline:before": {
      display: "none"
    },
    fontSize: "16px"
  },
  mileageInput: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "14px",
      paddingBottom: "15px",
      paddingLeft: "16px"
    },
    " & .MuiOutlinedInput-notchedOutline": {
      borderColor: LIGHT_GRAY
    }
  }
}));

const mapStateToProps = state => {
  return {
    checklist: state.checklist,
    bookingDetails: state.booking,
    privateCar: state.user.privateCar,
    user: state.user,
    window: state.window
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateChecklist: data => dispatch(actions.updateChecklist(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckCarInformation);
