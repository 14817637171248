import React from "react";
import {
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  InputAdornment,
  CircularProgress
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { selectMenuProps } from "../../../../shared/utils/enum";
import { CustomSelect } from "../../../../shared/components/CustomSelect";

const ModelSegment = props => {
  const {
    control,
    errors,
    values,
    brandList,
    carModelList,
    yearList,
    carModelIsLoading,
    shouldDisable
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Controller
          name="year"
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth variant="outlined" disabled={shouldDisable}>
                <InputLabel shrink={!!field.value}>Ano *</InputLabel>
                <Select
                  inputProps={{
                    "aria-label": "Selecione o ano do veículo"
                  }}
                  label={values.year ? "Ano *" : undefined}
                  {...field}
                  MenuProps={selectMenuProps}
                  variant="outlined"
                >
                  {yearList.map(yearOption => (
                    <MenuItem key={yearOption} value={yearOption}>
                      {yearOption}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="brandId"
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth variant="outlined" disabled={shouldDisable}>
                <InputLabel shrink={!!field.value}>Marca</InputLabel>
                <CustomSelect
                  clear={!shouldDisable}
                  handleClearField={() => {
                    field.onChange(0);
                    delete errors["carModelId"];
                  }}
                  inputProps={{
                    "aria-label": "Selecione a marca do veículo"
                  }}
                  label={values.brandId ? "Marca" : undefined}
                  error={errors && !!errors[field.name]}
                  helperText={errors && errors[field.name] ? errors[field.name].message : null}
                  {...field}
                  MenuProps={selectMenuProps}
                  variant="outlined"
                >
                  {brandList.map(brand => (
                    <MenuItem key={brand.id} value={brand.id}>
                      {brand.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="carModelId"
          control={control}
          render={({ field }) => {
            let modelLabel = `Modelo ${values.brandId > 0 ? "*" : ""}`;
            return (
              <FormControl disabled={!values.brandId || shouldDisable} fullWidth variant="outlined">
                <InputLabel shrink={!!field.value}>{modelLabel}</InputLabel>
                <CustomSelect
                  clear={!shouldDisable}
                  handleClearField={() => field.onChange(0)}
                  inputProps={{
                    "aria-label": "Selecione o modelo do veículo"
                  }}
                  label={values.carModelId ? modelLabel : undefined}
                  {...field}
                  error={errors && !!errors[field.name]}
                  helperText={errors && errors[field.name] ? errors[field.name].message : null}
                  MenuProps={selectMenuProps}
                  variant="outlined"
                  IconComponent={
                    carModelIsLoading
                      ? () => (
                          <InputAdornment position="end">
                            <CircularProgress size={20} className="mr-3" />
                          </InputAdornment>
                        )
                      : undefined
                  }
                >
                  {carModelList.map(carModel => (
                    <MenuItem key={carModel.id} value={carModel.id}>
                      {carModel.model}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ModelSegment;
