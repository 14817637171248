// Types
const UPDATE_WINDOW_DIMENSIONS = "window/UPDATE_WINDOW_DIMENSIONS";

// Reducer
export const INITIAL_STATE = {
  height: null,
  width: null
};

export function reducer(state = INITIAL_STATE, action) {
  if (action.type === UPDATE_WINDOW_DIMENSIONS)
    return {
      ...state,
      ...action.payload
    };
  return state;
}

// Action creators

export const updateWindowDimensions = windowSize => {
  return {
    type: UPDATE_WINDOW_DIMENSIONS,
    payload: windowSize
  };
};
