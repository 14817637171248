import React from "react";
import { Grid, Button, makeStyles } from "@material-ui/core";
import Loading from "../../../shared/components/Loading";

const ActionButtons = props => {
  const {
    loading,
    disabled,
    primaryText = "Continuar",
    secondaryText = "Voltar",
    primaryAction,
    secondaryAction
  } = props;

  const classes = UseStyles();
  return (
    <Grid className={`mb-8 ${classes.container}`}>
      <Button color="primary" onClick={secondaryAction} className={classes.button}>
        {secondaryText}
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={primaryAction}
        className={classes.button}
        disabled={disabled}
      >
        {loading ? <Loading loading size={20} className={classes.loading} /> : primaryText}
      </Button>
    </Grid>
  );
};

const UseStyles = makeStyles(theme => ({
  button: {
    width: "160px",
    textTransform: "uppercase"
  },
  container: {
    display: "flex",
    justifyContent: "space-evenly"
  },
  loading: {
    margin: theme.spacing(2),
    position: "relative"
  }
}));

export default ActionButtons;
