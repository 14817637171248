import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Grid, makeStyles, Typography, Box } from "@material-ui/core";
import {
  DARK_GRAY,
  GRAY,
  DARK_GREEN,
  RED,
  WHITE,
  SECONDARY_COLOR,
  LIGHTER_GRAY
} from "../../../theme";
import Loading from "../../../shared/components/Loading";
import { Layout } from "../../layout/";
import { routes } from "../../../shared/utils/Constants";
import { Redirect } from "react-router-dom";
import BookingDetailsManager from "../BookingDetailsManager";
import { format } from "date-fns";
import { bookingStatusStyle, tripType, carTypes, bookingStatus } from "../../../shared/utils/enum";
import ptBR from "date-fns/locale/pt-BR";
import DetailsButtonsByFac from "./DetailsButtonsByFac";
import useChecklistResponse from "../customHooks/useChecklistResponse";
import * as checklistActions from "../../../store/ChecklistStore";
import * as routerActions from "../../../store/RouterStore";

const BookingDetailsByFac = props => {
  const {
    match: { params: routeParams },
    checklist,
    updateChecklist,
    updateNavigationRoute
  } = props;
  const { bookingId } = routeParams;
  const [redirect, setRedirect] = useState(null);
  const [booking, setBooking] = useState();
  const history = useHistory();

  const lastStatus = booking?.historyStatus[booking.historyStatus.length - 1];
  const isFinalized = lastStatus?.status.id === bookingStatus.FINALIZED;
  const lastStatusStyle = bookingStatusStyle.find(style => style.statusId === lastStatus?.status.id);
  const bookingCarTypeId = booking?.carTypeId;
  const checkinDateTime = booking?.checkInResponseDate;
  const checkoutDateTime = lastStatus?.createdAt;

  const checkinDateHeader =
    booking?.checkInResponseId > 0 ? (
      <>
        <Typography variant="subtitle1">Realizado em</Typography>
        <Typography variant="h6">{format(new Date(checkinDateTime), "dd/MM/yyyy")}</Typography>
        <Typography variant="subtitle1">às</Typography>
        <Typography variant="h6">{format(new Date(checkinDateTime), "HH:mm")}</Typography>
      </>
    ) : (
      <Typography variant="h6">Não realizado</Typography>
    );
  const checkoutDateHeader = isFinalized ? (
    <>
      <Typography variant="subtitle1">Realizado em</Typography>
      <Typography variant="h6">{format(new Date(checkoutDateTime), "dd/MM/yyyy")}</Typography>
      <Typography variant="subtitle1">às</Typography>
      <Typography variant="h6">{format(new Date(checkoutDateTime), "HH:mm")}</Typography>
    </>
  ) : (
    <Typography variant="h6">Não realizado</Typography>
  );

  const getBookingType = () => {
    if (booking?.bookingTypeId === tripType.long) return "longa";
    if (booking?.bookingTypeId === tripType.maintenance) return "manutenção";

    return "curta";
  };

  const classes = useStyles({
    hasChecklist: booking?.checkInResponseId > 0,
    isFinalized: isFinalized,
    colorStatus: lastStatusStyle?.color
  });

  const {
    handleGetCheckInInfos,
    handleGetCheckOutInfos,
    loadingCheckInResponses,
    loadingCheckOutResponses
  } = useChecklistResponse(
    setRedirect,
    bookingId,
    checklist,
    updateChecklist,
    checkoutDateTime,
    checkinDateTime
  );

  useEffect(() => {
    const getBookingById = async () => {
      const data = await BookingDetailsManager.getBookingById(bookingId);

      if (data) setBooking(data);
      else setRedirect(<Redirect to="/notFound" />);
    };

    if (bookingId) getBookingById();
  }, [bookingId]);

  useEffect(() => {
    if (checklist.checkInResponses || checklist.checkOutResponses)
      updateNavigationRoute({ route: history.location.pathname });
  }, [checklist.checkInResponses, checklist.checkOutResponses, history, updateNavigationRoute]);

  return booking ? (
    <Layout showHeader pathRedirect={routes.calendar}>
      <Grid className={`pl-6 pr-6 pb-6 ${classes.background}`}>
        <Grid item xs={12} className={`pb-8 pt-8`}>
          <Typography variant="h6">Detalhes da reserva</Typography>
        </Grid>
        <Grid container className={`pb-8 pt-8 pl-8 pr-8 ${classes.BorderWhite}`}>
          <Grid container className="mb-8">
            <Grid item xs={12} sm={6} md={6} className={classes.alignItens}>
              <Typography variant="h5">Reserva viagem {getBookingType()}</Typography>
            </Grid>
            <Grid item md={1} />
            <Grid item xs={12} sm={6} md={5} className={classes.alignItens}>
              {bookingCarTypeId && (
                <img
                  src={carTypes.find(type => type.id === bookingCarTypeId).src}
                  className={`${classes.carIcon}`}
                  alt={carTypes.find(type => type.id === bookingCarTypeId).alt}
                />
              )}
              <Typography variant="subtitle1" className={`${classes.colorDetail} ml-3`}>
                {`${booking.carModel} ${booking.carLicensePlate}`}
              </Typography>
              <Typography variant="caption" className={`${classes.badgeSite} ml-3`}>
                {booking.site.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} align="Left">
            <Grid container item xs={12} className={`${classes.containerDetails} mb-7`}>
              <Typography variant="caption" className={classes.colorLabel}>
                Status da reserva
              </Typography>
              {lastStatusStyle && (
                <Typography variant="h6" align="right" className={classes.statusDetails}>
                  {lastStatusStyle.icon} {lastStatusStyle.text}
                </Typography>
              )}
            </Grid>
            <Grid container item xs={12} className={`${classes.containerDetails} mb-7`}>
              <Typography variant="caption" className={classes.colorLabel}>
                Solicitante
              </Typography>
              <Typography variant="h6" align="right" className={classes.colorDetail}>
                {booking.schedulerName}
              </Typography>
            </Grid>
            <Grid container item xs={12} className={`${classes.containerDetails} mb-7`}>
              <Typography variant="caption" className={classes.colorLabel}>
                Condutor
              </Typography>
              <Typography variant="h6" align="right" className={classes.colorDetail}>
                {booking.driverName}
              </Typography>
            </Grid>
            <Grid container item xs={12} className={`${classes.containerDetails} mb-7`}>
              <Typography variant="caption" className={classes.colorLabel}>
                Check-in
              </Typography>
              <Box align="right" className={`${classes.detailsChecklist} ${classes.spancingTime}`}>
                {checkinDateHeader}
              </Box>
            </Grid>
            <Grid container item xs={12} className={`${classes.containerDetails} mb-7`}>
              <Typography variant="caption" className={classes.colorLabel}>
                Check-out
              </Typography>
              <Box align="right" className={`${classes.detailsCheckOut} ${classes.spancingTime}`}>
                {checkoutDateHeader}
              </Box>
            </Grid>
            <Grid container item xs={12} className="mb-7">
              <Grid item xs={12} className="mb-3">
                <Grid item xs={6} align="left">
                  <Typography variant="caption" className={classes.colorLabel}>
                    Justificativa
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align="left">
                <Typography variant="subtitle1" className={classes.colorDetail}>
                  {booking.reason || "Não há justificativa para a reserva."}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} />
          <Grid item xs={12} md={5} align="left">
            <Grid item xs={12} className="mb-1">
              <Typography variant="caption" className={`${classes.colorLabel}`}>
                Retirada
              </Typography>
            </Grid>
            <Grid container item xs={12} className="mb-6">
              <Grid item xs={12} className={classes.containerDetails}>
                <Box className={classes.containerDetailsDay}>
                  <Typography variant="h6">
                    {format(new Date(booking.startJourney), "EEEE, ", { locale: ptBR })}
                  </Typography>
                  <Typography variant="h6">
                    {format(new Date(booking.startJourney), "dd 'de' MMM'. '", { locale: ptBR })}
                  </Typography>
                </Box>
                <Typography variant="h6">
                  {format(new Date(booking.startJourney), "HH:mm")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} className="mb-1">
              <Grid item>
                <Typography variant="caption" className={`${classes.colorLabel}`}>
                  Devolução
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} className="mb-8">
              <Grid item xs={12} className={classes.containerDetails}>
                <Box className={classes.containerDetailsDay}>
                  <Typography variant="h6">
                    {format(new Date(booking.finishJourney), "EEEE, ", { locale: ptBR })}
                  </Typography>
                  <Typography variant="h6">
                    {format(new Date(booking.finishJourney), "dd 'de' MMM'. '", { locale: ptBR })}
                  </Typography>
                </Box>
                <Typography variant="h6">
                  {format(new Date(booking.finishJourney), "HH:mm")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} className="mb-6">
              <Grid item xs={12} className="mb-1">
                <Typography variant="caption" className={classes.colorLabel}>
                  Local de saída
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" align="left" className={classes.colorDetail}>
                  {booking.site.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} className="mb-1">
                <Typography variant="caption" className={classes.colorLabel}>
                  Local de destino
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" align="left" className={classes.colorDetail}>
                  {booking.destination}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <DetailsButtonsByFac
            isCheckOutActive={booking.checkOutResponseId > 0}
            isCheckInActive={booking.checkInResponseId > 0}
            loadingCheckOutResponses={loadingCheckOutResponses}
            loadingCheckInResponses={loadingCheckInResponses}
            handleGetCheckOutInfos={handleGetCheckOutInfos}
            handleGetCheckInInfos={handleGetCheckInInfos}
          />
        </Grid>
        {redirect}
      </Grid>
    </Layout>
  ) : (
    <Grid className="pt-8">
      <Loading loading />
      {redirect}
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  colorDetail: {
    color: DARK_GRAY
  },
  colorLabel: {
    color: GRAY
  },
  spancingTime: {
    "& > h6:nth-child(3)": {
      marginLeft: "8px",
      marginRight: "8px"
    },
    "& > h6:nth-child(2)": {
      marginLeft: "8px"
    }
  },
  detailsChecklist: props => ({
    display: "flex",
    alignItems: "flex-end",
    color: `${props.hasChecklist ? DARK_GREEN : RED}`,
    "& > h6": {
      color: `${props.hasChecklist ? DARK_GREEN : RED}`
    }
  }),
  detailsCheckOut: props => ({
    display: "flex",
    alignItems: "flex-end",
    color: `${props.isFinalized ? DARK_GREEN : RED}`,
    "& > h6": {
      color: `${props.isFinalized ? DARK_GREEN : RED}`
    }
  }),
  statusDetails: props => ({
    color: `${props.colorStatus}`,
    display: "flex",
    alignItems: "center",
    "& > svg": {
      marginRight: "8px"
    }
  }),
  carIcon: {
    width: "80px"
  },
  alignItens: {
    display: "flex",
    alignItems: "center"
  },
  badgeSite: {
    textAlign: "center",
    color: SECONDARY_COLOR,
    border: "1px solid" + SECONDARY_COLOR,
    boxSizing: "border-box",
    borderRadius: "4px",
    height: "24px",
    width: "58px"
  },
  containerDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  containerDetailsDay: {
    display: "flex",
    "& > h6:first-child": {
      color: GRAY,
      fontWeight: 400,
      marginRight: "8px"
    }
  },
  BorderWhite: {
    background: WHITE,
    borderRadius: "20px"
  },
  background: {
    background: LIGHTER_GRAY,
    height: "calc(100vh - 64px)"
  }
}));

const mapStateToProps = state => {
  return {
    checklist: state.checklist
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateChecklist: data => dispatch(checklistActions.updateChecklist(data)),
    updateNavigationRoute: data => dispatch(routerActions.updateNavigationRoute(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailsByFac);
