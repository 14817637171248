import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  Grid,
  DialogActions,
  DialogTitle,
  makeStyles,
  Typography,
  Button,
  Divider,
  TextField
} from "@material-ui/core";
import useChecklist from "../customHooks/useChecklist";
import Loading from "../../../shared/components/Loading";
import * as actions from "../../../store/ChecklistStore";
import LevelSlider from "../../../shared/components/LevelSlider";
import { addThousandSeparator } from "../../../shared/utils/Utils";
import ChecklistQuestion from "../../../shared/components/ChecklistQuestion";
import CloseIcon from "../../../shared/components/CloseIcon";
import { marksFuelLevel } from "../../../shared/utils/enum";
import { PRIMARY_COLOR, WHITE, DARK_GRAY, RED } from "../../../theme";
import { Info } from "react-feather";

const CheckoutModal = props => {
  const classes = useStyles(props);
  const {
    secondaryButtonText,
    primaryButtonText,
    updateChecklist,
    handleOpenClose,
    secondaryAction,
    primaryAction,
    openedModal,
    checklist,
    loading,
    title
  } = props;

  const [allItemsSetStatus, setAllItemsSetStatus] = useState(false);

  const {
    handleSaveChecklistQuestionResponse,
    getInitialChecklistQuestionResponse,
    handleChangeFuelLevel,
    groupedStepQuestions,
    handleChangeMileage,
    stepGroups
  } = useChecklist(checklist, updateChecklist, setAllItemsSetStatus);

  const sendDisabled = !checklist.mileage || !allItemsSetStatus;

  return (
    <Dialog
      open={openedModal}
      onClose={() => handleOpenClose()}
      scroll="body"
      fullWidth
      className={classes.modal}
      classes={{
        paper: classes.paperModal
      }}
    >
      <CloseIcon color={WHITE} onClick={handleOpenClose} />

      {title && (
        <DialogTitle
          disableTypography
          onClose={() => handleOpenClose()}
          className={`${classes.titleContainer} pb-4 pt-4`}
        >
          <Typography className={classes.title} align="center" variant="h5">
            {title}
          </Typography>
        </DialogTitle>
      )}
      <Grid className="pl-4 pr-4">
        <Grid item xs={12} align="left" className="pt-4 pb-4">
          <TextField
            fullWidth
            placeholder="Digite a quilometragem atual*"
            variant="outlined"
            type="tel"
            inputProps={{ maxLength: 7 }}
            onChange={handleChangeMileage}
            value={checklist.mileage ? addThousandSeparator(checklist.mileage) : ""}
          />
        </Grid>
        <Grid item xs={12} className="pb-6">
          <Divider className="divider-2" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.infoTitleColor}>
            Informe o nível de combustível atual
          </Typography>
        </Grid>
        <Grid item xs={12} align="center" className="pl-4 pr-4">
          <LevelSlider
            handleChangeLevel={handleChangeFuelLevel}
            marks={marksFuelLevel}
            value={checklist.fuelLevel}
            step={25}
            min={25}
          />
        </Grid>
        <Grid item xs={12} className="pb-4">
          <Divider className="divider-2" />
        </Grid>
        <Grid item container>
          {stepGroups &&
            stepGroups.map(stepGroup => {
              return (
                groupedStepQuestions[stepGroup] &&
                groupedStepQuestions[stepGroup].map((question, questionIndex) => {
                  return (
                    question && (
                      <Grid item xs={12} key={questionIndex}>
                        <ChecklistQuestion
                          step={1}
                          critical={question.critical}
                          checkout
                          checklistQuestion={question}
                          rows={6}
                          multiline
                          maxLength={255}
                          initialObservations={getInitialChecklistQuestionResponse(
                            question,
                            "observations"
                          )}
                          initialSelectedStatus={getInitialChecklistQuestionResponse(
                            question,
                            "checklistTypeAnswerId"
                          )}
                          updateChecklistQuestionResponses={handleSaveChecklistQuestionResponse}
                        />
                      </Grid>
                    )
                  );
                })
              );
            })}
        </Grid>
      </Grid>
      <DialogActions className="p-6">
        <Grid container spacing={3}>
          <Grid item align="left" xs={6}>
            <Button
              onClick={secondaryAction}
              target="_self"
              fullWidth
              className={classes.secondaryButton}
              color="secondary"
            >
              {secondaryButtonText}
            </Button>
          </Grid>
          <Grid item align="right" xs={6}>
            <Button
              onClick={primaryAction}
              fullWidth
              variant="contained"
              color="primary"
              disabled={sendDisabled}
            >
              {loading ? (
                <Loading loading size={20} className={classes.loading} />
              ) : (
                primaryButtonText
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      {sendDisabled && (
        <Grid item container xs={12} className={`${classes.labelDisplay} mt-2 mb-6`}>
          <Grid item className={classes.iconDisplay}>
            <Info size={15} className={classes.labelText} />
          </Grid>
          <Grid item className="ml-4">
            <Typography variant="body2" className={classes.labelText}>
              Oops... ainda existem itens para preencher
            </Typography>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  secondaryButton: {
    color: RED
  },
  modal: {
    overflow: "hidden",
    width: "100%",
    maxHeight: "100%"
  },
  paperModal: {
    backgroundColor: WHITE
  },
  title: {
    color: WHITE
  },
  titleContainer: {
    backgroundColor: PRIMARY_COLOR,
    textAlign: "center"
  },
  loading: {
    margin: theme.spacing(2),
    position: "relative",
    color: WHITE
  },
  infoTitleColor: {
    color: DARK_GRAY
  },
  labelText: {
    color: RED
  },
  labelDisplay: {
    justifyContent: "center"
  },
  iconDisplay: {
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto"
  }
}));

const mapStateToProps = state => {
  return {
    checklist: state.checklist
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateChecklist: data => dispatch(actions.updateChecklist(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutModal);
