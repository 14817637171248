import React from "react";
import { Filter, X } from "react-feather";
import { Button, makeStyles, Typography, IconButton } from "@material-ui/core";
import { GRAY, SECONDARY_COLOR, WHITE } from "../../../theme";

const FiltersButton = ({ anyFilterAreSelected, onClick, handleClear }) => {
  const classes = useStyles();

  const ButtonAdornment = ({ size }) =>
    anyFilterAreSelected ? (
      <IconButton
        onClick={e => {
          e.stopPropagation();
          handleClear();
        }}
        className="p-2"
      >
        <X size={size} color={SECONDARY_COLOR} />
      </IconButton>
    ) : (
      <Filter color={GRAY} size={size} className="m-2" />
    );

  return (
    <Button
      fullWidth
      className={classes.filtersButton}
      color={anyFilterAreSelected ? "secondary" : "default"}
      variant="outlined"
      onClick={onClick}
      endIcon={<ButtonAdornment size={20} />}
    >
      <Typography color={anyFilterAreSelected ? "secondary" : "textSecondary"} variant="subtitle1">
        Filtros
      </Typography>
    </Button>
  );
};

const useStyles = makeStyles(() => ({
  filtersButton: {
    maxWidth: 118,
    borderRadius: 4,
    "&:hover": {
      backgroundColor: WHITE
    }
  }
}));

export default FiltersButton;
