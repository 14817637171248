import React, { useCallback, useEffect, useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { LIGHT_GRAY, SECONDARY_COLOR } from "../../../theme";
import HistoryCardContentFields from "./HistoryCardContentFields";

const HistoryCardContent = props => {
  const { oldData, newData, isCreation } = props;

  const isInactive = !newData?.user?.active;
  const classes = useStyles({ isInactive });

  const [formatedOldData, setFormatedOldData] = useState({});
  const [formatedNewData, setFormatedNewData] = useState({});

  const compareAndFormatData = useCallback((data1, data2) => {
    return (
      data1 && {
        name: { value: data1.user?.name },
        email: { value: data1.user?.email },
        adUser: { value: data1.user?.adUser },
        role: {
          value: data1.user?.role?.name,
          isEdited: data1.user?.role?.name !== data2?.user?.role?.name,
        },
        profile: {
          value: data1.user?.profile?.profileName,
          isEdited: data1.user?.profile?.profileName !== data2?.user?.profile?.profileName
        },
        pole: {
          value: data1.user?.pole?.name,
          isEdited: data1.user?.pole?.name !== data2?.user?.pole?.name
        },
        costCenter: {
          value: data1.user?.costCenter?.name,
          isEdited: data1.user?.costCenter?.name !== data2?.user?.costCenter?.name
        },
        regional: {
          value: data1.user?.management?.regional.name,
          isEdited: data1.user?.management?.regional.name !== data2?.user?.management?.regional.name
        },
        management: {
          value: data1.user?.managment?.name,
          isEdited: data1.user?.management?.name !== data2?.user?.management?.name
        },
        licenseExpireDate: {
          value: data1.user?.license?.expireDate,
          isEdited: data1.user?.license?.expireDate !== data2?.user?.license?.expireDate
        },
        licenseType: {
          value: data1.user?.license?.licenseTypeId,
          isEdited: data1.user?.license?.licenseTypeId !== data2?.user?.license?.licenseTypeId
        },
        courseExpireDate: {
          value: data1.user?.course?.expireDate,
          isEdited: data1.user?.course?.expireDate !== data2?.user?.course?.expireDate
        }
      }
    );
  }, []);

  useEffect(() => {
    setFormatedOldData(compareAndFormatData(oldData, newData));
    setFormatedNewData(compareAndFormatData(newData, oldData));
  }, [oldData, newData, compareAndFormatData]);

  const getNewDataTitle = () => {
    if (isInactive) return "Dados do Usuário";

    return oldData ? "Dados Atualizados" : "Dados Cadastrais";
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} className={classes.newData}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h3" variant="h5" color="inherit" className={classes.dataTitle}>
              {getNewDataTitle()}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.newDataFields}>
            <HistoryCardContentFields
              data={formatedNewData}
              isNewData
              isCreation={isCreation}
            />
          </Grid>
        </Grid>
      </Grid>
      {formatedOldData && !isInactive && (
        <Grid container item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="h3" variant="h5" className={classes.dataTitle}>
                Dados Antigos
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.oldData}>
              <HistoryCardContentFields data={formatedOldData} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  newData: {
    color: SECONDARY_COLOR
  },
  newDataFields: ({ isInactive }) => ({
    color: isInactive ? LIGHT_GRAY : "inherit"
  }),
  oldData: {
    height: "100%"
  },
  dataTitle: {
    fontWeight: "bold"
  }
}));

export default HistoryCardContent;