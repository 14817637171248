import { Typography, Grid, makeStyles } from "@material-ui/core";
import { DARK_GRAY, GRAY, SECONDARY_COLOR } from "../../../theme";
import { UserX } from "react-feather";
import { format } from "date-fns";
import { formatStringField } from "../../utils/Utils";
import { driverLicenseTypes, profileStyle } from "../../utils/enum";

const UserDetailsContent = props => {
  const {
    userData,
    isActiveDriver,
    startValidity,
    isDriver,
    hideTitle,
    hideNameAndRole
  } = props;

  const classes = useStyles();
  const userTerm = isDriver ? "Condutor" : "Responsável";

  return (
    <Grid item container xs={12}>
      {!hideTitle && (
        <Grid container className="mb-4">
          <Typography variant="h6" component="h4" className={classes.header}>
            {`Dados do ${userTerm}`}
          </Typography>
          {!isActiveDriver && (
            <Grid item container xs={12} className="mt-6">
              <UserX className={`mr-4 ${classes.noActiveDriver}`} />
              <Typography variant="body1" className={classes.noActiveDriver}>
                {startValidity
                  ? `Atualmente este veículo não possui nenhum ${userTerm.toLowerCase()} vinculado, os dados apresentados são do último ${userTerm.toLowerCase()}.`
                  : `Este veículo nunca possuiu nenhum ${userTerm.toLowerCase()} vinculado.`}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}

      {userData && (
        <Grid container>
          <Grid item container xs={12} className="mt-2">
            {!hideNameAndRole && (
              <Grid className="mr-6">
                <Grid item xs={12} className="mb-4">
                  <Typography variant="body1" className={classes.header}>
                    {formatStringField(userData.name)}
                    <br />
                    {userData.role?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" component="h5" className={classes.subtitle}>
                    Nome e Cargo
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid className="mr-6">
              <Grid item xs={12} className="mb-4">
                <Typography variant="body1" className={classes.header}>
                  {formatStringField(userData.email)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" component="h5" className={classes.subtitle}>
                  E-mail
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mr-6">
              <Grid item xs={12} className="mb-4">
                <Typography variant="body1" className={classes.header}>
                  {formatStringField(userData.adUser)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" component="h5" className={classes.subtitle}>
                  ID Yara
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mr-6">
              <Grid item xs={12} className="mb-4">
                <Typography variant="body1" className={classes.header}>
                  {formatStringField(userData.costCenter?.name)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" component="h5" className={classes.subtitle}>
                  Cód. Centro de Custo
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mr-6">
              <Grid item xs={12} className="mb-4">
                <Typography variant="body1" className={classes.header}>
                  {formatStringField(userData.pole?.name)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" component="h5" className={classes.subtitle}>
                  Polo
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mr-6">
              <Grid item xs={12} className="mb-4">
                <Typography variant="body1" className={classes.header}>
                  {formatStringField(userData.management?.regional?.name)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" component="h5" className={classes.subtitle}>
                  Região/Diretoria
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} className="mt-6">
            <Grid className="mr-6">
              <Grid item xs={12} className="mb-4">
                <Typography variant="body1" className={classes.header}>
                  {formatStringField(userData.management?.name)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" component="h5" className={classes.subtitle}>
                  Gerência
                </Typography>
              </Grid>
            </Grid>
            {startValidity && (
              <Grid className="mr-6">
                <Grid item xs={12} className="mb-4">
                  <Typography variant="body1" className={classes.header}>
                    {startValidity ? format(new Date(startValidity), "dd/MM/yyyy") : "--"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" component="h5" className={classes.subtitle}>
                    Data da Vinculação
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid className="mr-6">
              <Grid item xs={12} className="mb-4">
                <Typography variant="body1" className={classes.header}>
                  {userData.license?.expireDate
                    ? format(new Date(userData.license?.expireDate), "dd/MM/yyyy")
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" component="h5" className={classes.subtitle}>
                  Vencimento CNH
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mr-6">
              <Grid item xs={12} className="mb-4">
                <Typography variant="body1" className={classes.header}>
                  {formatStringField(driverLicenseTypes.find(e => e.value === userData.license?.licenseTypeId)?.label)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" component="h5" className={classes.subtitle}>
                  Tipo CNH
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mr-6">
              <Grid item xs={12} className="mb-4">
                <Typography variant="body1" className={classes.header}>
                  {userData.course?.expireDate
                    ? format(new Date(userData.course?.expireDate), "dd/MM/yyyy")
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" component="h5" className={classes.subtitle}>
                  Vencimento CDD
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mr-6">
              <Grid item xs={12} className="mb-4">
                <Typography variant="body1" className={classes.header}>
                  {formatStringField(profileStyle.find(e => e.profile === userData.profile?.profileName)?.label)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" component="h5" className={classes.subtitle}>
                  Perfil
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  header: {
    color: DARK_GRAY
  },
  subtitle: {
    color: GRAY
  },
  noActiveDriver: {
    color: SECONDARY_COLOR
  }
}));

export default UserDetailsContent;
