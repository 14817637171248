import React from "react";
import {
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  InputAdornment,
  CircularProgress
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { licensePlateExample } from "../../../../shared/utils/Constants";
import { carShouldHaveResponsible } from "../../../../shared/utils/Utils";
import { carType, selectMenuProps } from "../../../../shared/utils/enum";
import { MaskedInputFactory } from "../../../../shared/components/MaskedInputFactory";

const MainSegment = props => {
  const { control, errors, values, fleetTypeList, siteList, licensePlateIsLoading, shouldDisable } =
    props;

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Controller
          name="licensePlate"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                fullWidth
                {...field}
                required
                label="Placa"
                error={errors && !!errors[field.name]}
                helperText={errors && errors[field.name] ? errors[field.name].message : null}
                placeholder={licensePlateExample}
                type="text"
                autoComplete="off"
                variant="outlined"
                inputProps={{
                  "aria-label": "Digite a placa do veículo"
                }}
                InputProps={{
                  inputComponent: LicensePlateMask,
                  endAdornment: licensePlateIsLoading && (
                    <InputAdornment position="end">
                      <CircularProgress size={20} className="mr-3" />
                    </InputAdornment>
                  )
                }}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="fleetTypeId"
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth variant="outlined" disabled={shouldDisable}>
                <InputLabel shrink={!!field.value}>Tipo da Frota *</InputLabel>
                <Select
                  inputProps={{
                    "aria-label": "Selecione o tipo da frota"
                  }}
                  label={values.fleetTypeId ? "Tipo da Frota *" : undefined}
                  {...field}
                  MenuProps={selectMenuProps}
                  variant="outlined"
                >
                  {fleetTypeList.map(fleetType => (
                    <MenuItem key={fleetType.id} value={fleetType.id}>
                      {fleetType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="siteId"
          control={control}
          render={({ field }) => {
            let required = carShouldHaveResponsible(values.fleetTypeId);
            let siteLabel = `Unidade ${required ? "*" : ""}`;
            return (
              <FormControl fullWidth variant="outlined" disabled={shouldDisable || !required}>
                <InputLabel shrink={!!field.value}>{siteLabel}</InputLabel>
                <Select
                  inputProps={{
                    "aria-label": "Selecione a unidade do veículo"
                  }}
                  label={values.siteId ? siteLabel : undefined}
                  {...field}
                  MenuProps={selectMenuProps}
                  variant="outlined"
                >
                  {siteList.map(site => (
                    <MenuItem key={site.id} value={site.id}>
                      {site.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="carTypeId"
          control={control}
          render={({ field }) => {
            let required = carShouldHaveResponsible(values.fleetTypeId);
            let carTypeLabel = `Tipo do Veículo ${required ? "*" : ""}`;
            return (
              <FormControl fullWidth variant="outlined" disabled={shouldDisable || !required}>
                <InputLabel shrink={!!field.value}>{carTypeLabel}</InputLabel>
                <Select
                  inputProps={{
                    "aria-label": "Selecione o tipo do veículo"
                  }}
                  label={values.carTypeId ? carTypeLabel : undefined}
                  {...field}
                  MenuProps={selectMenuProps}
                  variant="outlined"
                >
                  {Object.values(carType).map(carTypeOption => (
                    <MenuItem key={carTypeOption.id} value={carTypeOption.id}>
                      {carTypeOption.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

const LicensePlateMask = MaskedInputFactory({
  id: "licensePlateMask",
  mask: [/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /\d/, /\d/]
});

export default MainSegment;
