import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import StepButtons from "./StepButtons";
import { formType } from "../../../shared/utils/enum";
import { Layout } from "../../layout";
import Loading from "../../../shared/components/Loading";

const NewBooking = props => {
  const {
    loadingContent,
    loadingNextStep,
    disabled,
    redirect,
    subtitle,
    handleNext,
    handlePrevious,
    children
  } = props;
  const classes = useStyles();

  return (
    <Layout showHeader formType={formType.booking}>
      <Grid container justify="center">
        <Grid container item xs={12} sm={8} md={7} lg={6} xl={5} spacing={3} className="mt-10">
          <Grid container item xs={12} className={`${classes.titles} mb-6`}>
            <Grid item xs={12}>
              <Typography variant="h4">Solicitar Reserva</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">{subtitle}</Typography>
            </Grid>
          </Grid>
          {loadingContent ? <Loading loading /> : children}
          <Grid item xs={12}>
            <StepButtons
              loading={loadingNextStep}
              onPreviousStep={handlePrevious}
              onNextStep={handleNext}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
      {redirect}
    </Layout>
  );
};

const useStyles = makeStyles(() => ({
  titles: {
    textAlign: "center"
  }
}));

export default NewBooking;
