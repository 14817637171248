import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import ImageDropzone from "../../../shared/components/ImageDropzone/ImageDropzone";
import { GRAY } from "../../../theme";
import CustomTextField from "../../../shared/components/CustomTextField";

const ThirdStep = props => {
  const { securityReport, setSecurityReport } = props;
  const classes = useStyles();

  const handleChangeFeedback = value => {
    value = value === "" ? null : value;
    setSecurityReport({ ...securityReport, feedback: value });
  };

  const handleChangeReportImages = value => {
    setSecurityReport({ ...securityReport, reportImages: value });
  };

  return (
    <>
      <CustomTextField
        label="Sugestão de melhoria / Aprendizado"
        placeholder="Descreva sua sugestão de melhoria para o relato/ocorrência"
        multiline
        rows={4}
        value={securityReport.feedback || ""}
        onChange={e => handleChangeFeedback(e.target.value)}
        maxLength={255}
      />
      <Grid className="mt-8">
        <Typography variant="body1" className={classes.dropZoneLabel}>
          Você pode incluir até 5 imagens relacionadas ao relato/ocorrência:
        </Typography>
        <ImageDropzone
          imagesState={securityReport.reportImages}
          maxFileSize={5}
          maxFilesToAttach={5}
          acceptedTypes="image/jpg, image/jpeg, image/png"
          onUploadSuccess={handleChangeReportImages}
        />
      </Grid>
    </>
  );
};

const useStyles = makeStyles(() => ({
  dropZoneLabel: {
    color: GRAY
  }
}));

export default ThirdStep;
