import React from "react";
import { Grid, RadioGroup, FormControl, makeStyles } from "@material-ui/core";
import RadioBox from "./RadioBox";
import MessageBox from "./MessageBox";
import { toastTypes } from "../utils/enum";

const RadioSelection = props => {
  const { optionsList, handleChange, value = null, step, disabled, emptyListMessage } = props;

  const classes = useStyles();

  return (
    <Grid container>
      {optionsList && optionsList.length > 0 ? (
        <Grid container item xs={12}>
          <FormControl component="fieldset" className={classes.choicesContainer}>
            <RadioGroup value={value} onChange={handleChange}>
              {optionsList.map((opt, index) => (
                <RadioBox
                  disabled={disabled}
                  key={index}
                  id={opt.id}
                  Icon={opt.icon}
                  name={opt.name}
                  value={value}
                  step={step}
                  className={index > 0 ? "mt-6" : undefined}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      ) : (
        <Grid item container xs={12}>
          <MessageBox type={toastTypes.warning}>{emptyListMessage}</MessageBox>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(_theme => ({
  choicesContainer: {
    width: "100%"
  }
}));

export default RadioSelection;
