import { useEffect } from "react";
import { maxAllowedMileage } from "../../../shared/utils/enum";
import { checkAnswerSet, groupBy } from "../../../shared/utils/Utils";

const useChecklist = (checklist, updateChecklist, setAllItemsSetStatus) => {
  useEffect(() => {
    if (!checklist.questionsResponses) updateChecklist({ questionsResponses: [] });
  }, [updateChecklist, checklist.questionsResponses]);

  const groupedStepQuestions =
    checklist.stepQuestions &&
    checklist.stepQuestions[checklist.nextStep] &&
    groupBy(checklist.stepQuestions[checklist.nextStep], "questionGroupName");

  const stepGroups = groupedStepQuestions && Object.keys(groupedStepQuestions);

  const getInitialChecklistQuestionResponse = (question, property) => {
    if (question && checklist.questionsResponses) {
      const initialQuestionReponse = checklist.questionsResponses.find(
        e => e.questionId === question.questionId
      );
      if (initialQuestionReponse) {
        switch (property) {
          case "observations":
            return initialQuestionReponse.observations;
          case "checklistTypeAnswerId":
            return initialQuestionReponse.checklistTypeAnswerId;
          default:
            break;
        }
      }
    }
  };

  const handleSaveChecklistQuestionResponse = newChecklistQuestionResponse => {
    let updated = false;
    if (checklist.questionsResponses) {
      checklist.questionsResponses.forEach(questionResponse => {
        if (questionResponse.questionId === newChecklistQuestionResponse.questionId) {
          questionResponse.checklistTypeAnswerId =
            newChecklistQuestionResponse.checklistTypeAnswerId;
          questionResponse.observations = newChecklistQuestionResponse.observations;
          updated = true;
        }
      });
      if (!updated) {
        checklist.questionsResponses.push(newChecklistQuestionResponse);
      }

      checkAllQuestionsAnswered() ? setAllItemsSetStatus(true) : setAllItemsSetStatus(false);
    }
  };

  const checkAllQuestionsAnswered = () => {
    let countItemsSet = 0;
    let countTotalItems = checklist.stepQuestions[checklist.nextStep].length;

    if (checklist.questionsResponses)
      checklist.stepQuestions[checklist.nextStep].forEach(stepQuestion => {
        if (stepQuestion) {
          const questionResponse = checklist.questionsResponses.find(
            _questionResponse => _questionResponse.questionId === stepQuestion.questionId
          );
          if (questionResponse) {
            const isCritical = !!stepQuestion.critical;
            if (checkAnswerSet(questionResponse, isCritical)) countItemsSet++;
          }
        }
      });
    return countTotalItems === countItemsSet && countTotalItems !== 0;
  };

  const handleChangeMileage = e => {
    const { value } = e.target;
    const parsedValue = parseInt(value.replace(".", ""));
    const newMileage =
      parsedValue && parsedValue <= maxAllowedMileage ? parsedValue : checklist.mileage;
    updateChecklist({
      ...checklist,
      mileage: parsedValue ? newMileage : null
    });
  };

  const handleChangeFuelLevel = (_, newValue) => {
    updateChecklist({
      ...checklist,
      fuelLevel: newValue
    });
  };

  const handleChangeChecklistObservations = e => {
    updateChecklist({
      ...checklist,
      checklistObservations: e.target.value
    });
  };

  return {
    handleSaveChecklistQuestionResponse,
    getInitialChecklistQuestionResponse,
    handleChangeChecklistObservations,
    handleChangeFuelLevel,
    groupedStepQuestions,
    handleChangeMileage,
    stepGroups
  };
};

export default useChecklist;
