import React from "react";
import {
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  CircularProgress
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import { selectMenuProps } from "../../../../shared/utils/enum";
import { RELEASED_CAR_STATUS_ID } from "../../../../shared/utils/Constants";
import {
  MaskedInputFactory,
  createFormattedNumberMask
} from "../../../../shared/components/MaskedInputFactory";
import CustomTextField from "../../../../shared/components/CustomTextField";
import { CustomSelect } from "../../../../shared/components/CustomSelect";

const StatusSegment = props => {
  const { control, errors, values, statusList, statusDetailsList, statusDetailsIsLoading } = props;

  return (
    <Grid item container spacing={2}>
      <Grid item xs={3}>
        <Controller
          name="statusId"
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth variant="outlined">
                <InputLabel shrink={!!field.value}>Status *</InputLabel>
                <Select
                  inputProps={{
                    "aria-label": "Selecione o status do veículo"
                  }}
                  label={values.statusId ? "Status *" : undefined}
                  {...field}
                  MenuProps={selectMenuProps}
                  variant="outlined"
                >
                  {statusList.map(status => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }}
        />
      </Grid>
      {values.statusId === RELEASED_CAR_STATUS_ID && (
        <>
          <Grid item xl={9} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Controller
                  name="statusDetailsId"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FormControl fullWidth variant="outlined">
                        <InputLabel shrink={!!field.value}>Destinação</InputLabel>
                        <CustomSelect
                          handleClearField={() => field.onChange(0)}
                          inputProps={{
                            "aria-label": "Selecione a destinação da baixa"
                          }}
                          label={values.statusDetailsId ? "Destinação" : undefined}
                          error={errors && !!errors[field.name]}
                          helperText={
                            errors && errors[field.name] ? errors[field.name].message : null
                          }
                          {...field}
                          MenuProps={selectMenuProps}
                          variant="outlined"
                          IconComponent={
                            statusDetailsIsLoading
                              ? () => <CircularProgress size={32} className="mr-3" />
                              : undefined
                          }
                        >
                          {statusDetailsList.map(statusDetails => (
                            <MenuItem key={statusDetails.id} value={statusDetails.id}>
                              {statusDetails.name}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </FormControl>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="statusStartDate"
                  control={control}
                  render={({ field: { ref, ...fieldProps } }) => {
                    return (
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                        <KeyboardDatePicker
                          autoComplete="off"
                          inputRef={ref}
                          {...fieldProps}
                          fullWidth
                          label="Data da Baixa"
                          variant="outlined"
                          format="dd/MM/yyyy"
                          placeholder="00/00/0000"
                          disableFuture
                          inputVariant="outlined"
                          cancelLabel="CANCELAR"
                          KeyboardButtonProps={{
                            "aria-label": "Selecione uma data"
                          }}
                          inputProps={{
                            "aria-label": "Informe a data da baixa"
                          }}
                          error={errors && !!errors[fieldProps.name]}
                          helperText={
                            errors && errors[fieldProps.name]
                              ? errors[fieldProps.name].message
                              : null
                          }
                        />
                      </MuiPickersUtilsProvider>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="statusCost"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        fullWidth
                        {...field}
                        label="Valor da Baixa"
                        error={errors && !!errors[field.name]}
                        helperText={
                          errors && errors[field.name] ? errors[field.name].message : null
                        }
                        placeholder="R$1.000,00"
                        type="text"
                        variant="outlined"
                        inputProps={{
                          "aria-label": "Digite o valor da baixa"
                        }}
                        InputProps={{
                          inputComponent: StatusCostMask
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="statusReason"
              control={control}
              render={({ field }) => {
                return (
                  <CustomTextField
                    fullWidth
                    fieldProps={field}
                    {...field}
                    multiline
                    label="Motivo da Baixa"
                    error={errors && !!errors[field.name]}
                    helperText={errors && errors[field.name] ? errors[field.name].message : null}
                    placeholder="Descreva aqui..."
                    type="text"
                    variant="outlined"
                    inputProps={{
                      "aria-label": "Digite o motivo da baixa"
                    }}
                    maxLength={255}
                  />
                );
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

const StatusCostMask = MaskedInputFactory({
  id: "statusCostMask",
  mask: createFormattedNumberMask({
    decimalPlaces: 2,
    prefix: "R$"
  })
});

export default StatusSegment;
