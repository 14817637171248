import CarService from "../../services/CarService";
import ChecklistService from "../../services/ChecklistService";
import BookingService from "../../services/BookingService";
import UserService from "../../services/UserService";

class ChecklistManager {
  async saveBookingChecklist(checklist, bookingId) {
    return BookingService.saveBookingChecklist(checklist, bookingId).then(
      answer => answer && answer.data
    );
  }

  async saveRecurringChecklist(checklist) {
    return CarService.saveRecurringChecklist(checklist).then(answer => answer && answer.data);
  }

  async uploadChecklistImages(checklistId, checklistFiles) {
    return ChecklistService.uploadChecklistImages(checklistId, checklistFiles);
  }

  async getUserById(userId) {
    return UserService.getUserById(userId).then(answer => answer && answer.data);
  }
}

export default new ChecklistManager();
