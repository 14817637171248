import React from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { RED, WHITE } from "../../../../theme";
import Loading from "../../../../shared/components/Loading";
import FormButton from "../../../../shared/components/FormButton";
import { messages } from "../../../../shared/utils/Constants";

const ActionSegment = props => {
  const {
    handleCancel,
    handleContinue,
    handleReturn,
    handleSubmit,
    loadingSubmit,
    disableContinue,
    disableSubmit,
    isFinalStep,
    isEdition
  } = props;
  const classes = useStyles();

  const ContinueButton = () => {
    return (
      <FormButton onClick={handleContinue}>
        <Button
          aria-label={`Continuar ${isEdition ? "edição" : "cadastro"}`}
          disabled={disableContinue}
          fullWidth
          color="primary"
          variant="contained"
        >
          {messages.CONTINUE_BUTTON_MESSAGE}
        </Button>
      </FormButton>
    );
  };

  const submitButtonMessage = isEdition
    ? messages.UPDATE_BUTTON_MESSAGE
    : messages.REGISTER_BUTTON_MESSAGE;

  const SubmitButton = () => {
    return (
      <FormButton onClick={handleSubmit}>
        <Button
          aria-label={isEdition ? "Salvar veículo" : "Cadastrar veículo"}
          disabled={disableSubmit || loadingSubmit}
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
        >
          {loadingSubmit ? (
            <Loading loading className={classes.loading} size={15} />
          ) : (
            submitButtonMessage
          )}
        </Button>
      </FormButton>
    );
  };

  const CancelButton = () => {
    return (
      <FormButton onClick={handleCancel}>
        <Button
          aria-label={isEdition ? "Cancelar edição de veículo" : "Cancelar cadastro de veículo"}
          fullWidth
          className={classes.cancelButton}
        >
          {messages.CANCEL_BUTTON_MESSAGE}
        </Button>
      </FormButton>
    );
  };

  const ReturnButton = () => {
    return (
      <FormButton onClick={handleReturn}>
        <Button aria-label={"Voltar cadastro"} fullWidth color="secondary">
          {messages.RETURN_BUTTON_MESSAGE}
        </Button>
      </FormButton>
    );
  };

  return (
    <Grid item container xs={12} className="pt-2 pb-2 pr-4 pl-4">
      <Grid item container xs={5}>
        <Grid item xs={8}>
          {isFinalStep && <ReturnButton />}
        </Grid>
      </Grid>
      <Grid item container xs={7} justify="flex-end">
        <Grid item xs={4} className="pr-2">
          <CancelButton />
        </Grid>
        <Grid item xs={4} className="pl-2">
          {isFinalStep ? <SubmitButton /> : <ContinueButton />}
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: RED
  },
  loading: {
    display: "flex",
    margin: "auto",
    color: WHITE
  }
}));

export default ActionSegment;
