import React, { useState } from "react";
import { Grid, makeStyles, Divider, Typography, Box } from "@material-ui/core";
import { ChevronDown, ChevronUp, User, Calendar, File, MapPin, Info } from "react-feather";
import { DARK_GRAY, GRAY, SECONDARY_COLOR, THIN_BLUE2 } from "../../theme";
import { MOBILE_WINDOW_WIDTH } from "../utils/Constants";
import { carTypes } from "../utils/enum";
import { formatStringField } from "../utils/Utils";

const CarInformation = props => {
  const { window, fields, data, carInfo, warningMessage } = props;

  const classes = useStyles();

  const [showData, setShowData] = useState(false);

  const getImageGridSize = () => (window.width < MOBILE_WINDOW_WIDTH ? 6 : undefined);
  const getHeaderVariant = () => (window.width < MOBILE_WINDOW_WIDTH ? "subtitle2" : "h6");
  const getContentSpacing = () => (window.width < MOBILE_WINDOW_WIDTH ? 3 : 5);

  return (
    <Box className={`p-10 ${classes.box}`}>
      <Grid item container alignItems="center">
        <Grid item xs={getImageGridSize()}>
          <img
            src={carTypes[0].src}
            className={`mr-10 ${classes.imageFit}`}
            alt={carTypes[0].alt}
          />
        </Grid>
        <Grid item xs container alignItems="center">
          <Grid item xs container>
            <Grid item xs={12}>
              <Typography variant="overline" component="h4">
                Dados do veículo
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={getHeaderVariant()} component="h4">
                {carInfo ?? "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs align="right">
            <Grid>
              {!showData && (
                <ChevronDown onClick={() => setShowData(true)} className="hover-icon" />
              )}
              {showData && <ChevronUp onClick={() => setShowData(false)} className="hover-icon" />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showData && (
        <Grid item container xs={12}>
          <Grid item xs={12} className="mb-10 mt-10">
            <Divider />
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={getContentSpacing()}
            className={classes.containerContent}
          >
            <Grid item>
              <CreateItem field={fields[0]} data={data[0]} Icon={User} />
            </Grid>
            <Grid item>
              <CreateItem field={fields[1]} data={data[1]} Icon={MapPin} />
            </Grid>
            <Grid item>
              <CreateItem field={fields[2]} data={data[2]} Icon={MapPin} />
            </Grid>
            <Grid item>
              <CreateItem field={fields[3]} data={data[3]} Icon={Calendar} />
            </Grid>
            <Grid item>
              <CreateItem field={fields[4]} data={data[4]} Icon={Calendar} />
            </Grid>
            <Grid item>
              <CreateItem field={fields[5]} data={data[5]} Icon={File} />
            </Grid>
          </Grid>
          <Grid item xs={12} className="mb-10 mt-10">
            <Divider />
          </Grid>
          <Grid item container xs={12}>
            {!!warningMessage && (
              <Typography variant="body2" className={classes.infoContent}>
                {<Info size={15} className={classes.iconContent} />}
                {warningMessage}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const CreateItem = ({ field, data, Icon }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Icon className={classes.infoIcon} />
      <Grid className="ml-4">
        <Typography className={classes.fieldContent}>{formatStringField(data)}</Typography>
        <Typography variant="caption" className={classes.fieldName}>
          {field}
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: THIN_BLUE2,
    borderRadius: "8px"
  },
  imageFit: {
    width: "-webkit-fill-available"
  },
  containerContent: {
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      display: "block"
    }
  },
  infoContent: {
    color: SECONDARY_COLOR,
    display: "flex",
    "& > svg": {
      marginRight: "16px"
    }
  },
  infoIcon: {
    color: SECONDARY_COLOR
  },
  fieldName: {
    color: GRAY
  },
  fieldContent: {
    color: DARK_GRAY
  }
}));

export default CarInformation;
