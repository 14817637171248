import React, { useEffect, useState, useRef } from "react";
import {
  makeStyles,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Collapse
} from "@material-ui/core";
import { LIGHT_GRAY2, DARK_GRAY, RED } from "../../../theme";
import useSecurityReport from "../customHooks/useSecurityReport";
import { messages } from "../../../shared/utils/Constants";
import { securityReportOtherCases } from "../../../shared/utils/enum";
import CustomTextField from "../../../shared/components/CustomTextField";

const SecondStep = props => {
  const { securityReport, setSecurityReport, setDisabledNextButton } = props;
  const [showOtherSubject, setShowOtherSubject] = useState(false);

  const classes = useStyles();

  const { occurrenceList, listOccurrence, subjectList, listSubject } = useSecurityReport();

  const listSubjectOptions = async value => {
    if (value > 0) await listSubject(securityReport.localId, value);
  };

  const showOrHideOtherSubject = value => {
    setShowOtherSubject(value === securityReportOtherCases.SUBJECT_OTHER);
  };

  const refListOccurrence = useRef(listOccurrence);
  const refSetDisabledNextButton = useRef(setDisabledNextButton);
  const refListSubjectOptions = useRef(listSubjectOptions);
  const refShowOrHideOtherSubject = useRef(showOrHideOtherSubject);
  const refLocalId = useRef(securityReport.localId);
  const refOccurrenceId = useRef(securityReport.occurrenceId);
  const refSubjectId = useRef(securityReport.subjectId);

  useEffect(() => {
    async function getData() {
      await refListOccurrence.current(refLocalId.current);
      if (refOccurrenceId.current > 0) {
        refListSubjectOptions.current(refOccurrenceId.current);
        refShowOrHideOtherSubject.current(refSubjectId.current);
      }
    }

    getData();
  }, []);

  useEffect(() => {
    const disabledByOccurrence = !securityReport.occurrenceId > 0;

    const disabledBySubject =
      !securityReport.subjectId > 0 || (showOtherSubject && !securityReport.subjectDescription);

    const disabledByDescription = !securityReport.description;

    refSetDisabledNextButton.current(
      disabledByOccurrence || disabledBySubject || disabledByDescription
    );
  }, [showOtherSubject, securityReport]);

  const handleChangeOccurrenceId = async value => {
    value = parseInt(value);

    listSubjectOptions(value);
    setSecurityReport({
      ...securityReport,
      occurrenceId: value,
      subjectId: null,
      subjectDescription: null
    });
  };

  const handleChangeSubject = value => {
    value = parseInt(value);

    showOrHideOtherSubject(value);
    setSecurityReport({ ...securityReport, subjectId: value, subjectDescription: null });
  };

  return (
    <>
      <FormControl fullWidth variant="outlined" className="mb-6 mt-6">
        <InputLabel>Ocorrência</InputLabel>
        <Select
          inputProps={{
            "aria-label": "Selecione a ocorrência"
          }}
          label="Ocorrência"
          variant="outlined"
          native
          value={securityReport.occurrenceId || 0}
          onChange={e => handleChangeOccurrenceId(e.target.value)}
        >
          <option value={0}>Selecione a ocorrência</option>
          {occurrenceList?.map(occurrence => (
            <option key={occurrence.id} value={occurrence.id}>
              {occurrence.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <Box severity="warning" className={`mb-6 ${classes.boxMessage}`}>
        <Typography variant="body2">{messages.ALERT_TITLE}</Typography>
        <Typography variant="body2">{messages.SECURITY_REPORT_ALERT_MESSAGE}</Typography>
      </Box>

      <FormControl fullWidth variant="outlined" className="mb-6">
        <InputLabel>Assunto</InputLabel>
        <Select
          disabled={!securityReport.occurrenceId > 0}
          inputProps={{
            "aria-label": "Selecionar o assunto"
          }}
          label="Assunto"
          variant="outlined"
          native
          value={securityReport.subjectId || 0}
          onChange={e => handleChangeSubject(e.target.value)}
        >
          <option value={0}>Selecionar o assunto</option>
          {subjectList?.map(subject => (
            <option key={subject.id} value={subject.id}>
              {subject.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <Collapse in={showOtherSubject}>
        <CustomTextField
          maxLength={255}
          className="mb-6"
          label="Descreva o assunto"
          placeholder="Descreva o assunto"
          value={securityReport.subjectDescription || ""}
          onChange={e =>
            setSecurityReport({ ...securityReport, subjectDescription: e.target.value })
          }
        />
      </Collapse>

      <CustomTextField
        label="Descrição"
        placeholder="Descreva seu relato/ocorrência"
        multiline
        maxLength={2000}
        rows={4}
        value={securityReport.description}
        onChange={e => setSecurityReport({ ...securityReport, description: e.target.value })}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  boxMessage: {
    display: "flex",
    flexDirection: "column",
    background: LIGHT_GRAY2,
    padding: "12px",
    borderRadius: "8px",
    "& p:first-child": {
      color: RED,
      fontWeight: 700,
      marginBottom: "4px"
    },
    "& p:last-child": {
      color: DARK_GRAY,
      fontWeight: 700,
      marginBottom: "8px"
    }
  }
}));

export default SecondStep;
