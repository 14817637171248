import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ChecklistQuestion from "../../../shared/components/ChecklistQuestion";
import { Grid, Typography } from "@material-ui/core";
import * as actions from "../../../store/ChecklistStore";
import useChecklist from "../customHooks/useChecklist";
import { accessType } from "../../../shared/utils/enum";
import { Redirect } from "react-router-dom";
import { routes } from "../../../shared/utils/Constants";
import NewChecklist from "./NewChecklist";

const CheckCarInterior = props => {
  const { checklist, updateChecklist, user } = props;

  const [allItemsSetStatus, setAllItemsSetStatus] = useState(false);
  const [redirect, setRedirect] = useState();

  const {
    handleSaveChecklistQuestionResponse,
    getInitialChecklistQuestionResponse,
    groupedStepQuestions,
    stepGroups
  } = useChecklist(checklist, updateChecklist, setAllItemsSetStatus);

  useEffect(() => {
    const isCommercialOrExecutive = [accessType.commercial, accessType.executive].includes(
      user.profile
    );
    const isBackOffice = user.profile === accessType.backoffice;

    const redirectTo = () => {
      if (isBackOffice) return routes.reportsManagement;
      return isCommercialOrExecutive ? routes.listChecklists : routes.listBookings;
    };

    !checklist.mileage && setRedirect(<Redirect to={redirectTo()} />);
  }, [checklist, user]);

  return (
    <NewChecklist
      stepIndex={1}
      disableButton={!allItemsSetStatus}
      previousStep={routes.checklistSteps.CHECK_CAR_INFORMATION.route}
      nextStep={routes.checklistSteps.CHECK_CAR_EXTERIOR.route}
    >
      <Grid item xs={12}>
        {stepGroups &&
          stepGroups.map((stepGroup, groupIndex) => {
            return (
              <Grid key={groupIndex}>
                <Grid item xs={12} className="mb-12">
                  <Typography variant="h5" className="pb-4">
                    {stepGroup}
                  </Typography>
                  <Typography variant="subtitle1">
                    Responda as perguntas a seguir selecionando as reações positivas ou negativas e
                    se necessário acrescente um comentário
                  </Typography>
                </Grid>
                {groupedStepQuestions[stepGroup] &&
                  groupedStepQuestions[stepGroup].map((question, questionIndex) => {
                    return (
                      <Grid key={questionIndex} className="pb-4">
                        <ChecklistQuestion
                          padding
                          step={2}
                          checklistQuestion={question}
                          critical={question.critical}
                          initialObservations={getInitialChecklistQuestionResponse(
                            question,
                            "observations"
                          )}
                          initialSelectedStatus={getInitialChecklistQuestionResponse(
                            question,
                            "checklistTypeAnswerId"
                          )}
                          updateChecklistQuestionResponses={handleSaveChecklistQuestionResponse}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            );
          })}
      </Grid>
      {redirect}
    </NewChecklist>
  );
};

const mapStateToProps = state => {
  return {
    checklist: state.checklist,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateChecklist: data => dispatch(actions.updateChecklist(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckCarInterior);
