import React, { useState } from "react";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as actions from "../../../store/BookingStore";
import { WHITE, PRIMARY_COLOR, ORANGE } from "../../../theme";
import { Home, Calendar, Users } from "react-feather";
import { routes } from "../../../shared/utils/Constants";
import { Layout } from "../../layout";

const ConfirmLongTrip = props => {
  const { deleteBooking, height } = props;
  const classes = useStyles({ screenHeight: height });
  const [redirect, setRedirect] = useState(null);

  window.onbeforeunload = null;

  return (
    <Layout>
      <Grid container className={classes.containerLongTrip}>
        <Grid item xs={12} md={4} align="Center" className={classes.titleItem}>
          <Users className={classes.titleIcon} />
          <Typography variant="h5" className={`pr-8 pl-8 ${classes.text}`}>
            Sua reserva foi enviada para aprovação.
          </Typography>
          <Typography variant="body2" className={`pr-8 pl-8 pt-6 pb-6 ${classes.text}`}>
            Acompanhe acessando "Minhas reservas"
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              deleteBooking();
              setRedirect(<Redirect to={routes.listBookings} />);
            }}
          >
            <Grid container>
              <Grid item xs={12} align="Center">
                <Calendar className={classes.buttonIcon} />
              </Grid>
              <Grid item xs={12} align="Center">
                <Typography variant="h5" className={classes.text}>
                  Minhas reservas
                </Typography>
              </Grid>
            </Grid>
          </Button>
          <Grid item xs={12} align="Center" className="pt-6" />
          <Button
            variant="contained"
            className={`${classes.buttonReturnHome} ${classes.button}`}
            onClick={() => {
              deleteBooking();
              setRedirect(<Redirect to={routes.home} />);
            }}
          >
            <Grid container>
              <Grid item xs={12} align="Center">
                <Home color={PRIMARY_COLOR} className={classes.buttonIcon} />
              </Grid>
              <Grid item xs={12} align="Center">
                <Typography variant="h5">Voltar à tela inicial</Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        {redirect}
      </Grid>
    </Layout>
  );
};
const useStyles = makeStyles(() => ({
  titleItem: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "68px",
    height: "172px"
  },
  containerLongTrip: props => ({
    display: "flex",
    backgroundColor: ORANGE,
    height: `${props.screenHeight}px`
  }),
  text: {
    color: WHITE
  },
  titleIcon: {
    color: WHITE,
    height: "54px",
    width: "54px"
  },
  buttonIcon: {
    height: "40px",
    width: "40px"
  },
  button: {
    width: "300px",
    height: "140px",
    borderRadius: "4px"
  },
  buttonReturnHome: {
    backgroundColor: WHITE
  }
}));

const mapStateToProps = state => {
  return {
    height: state.window.height
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteBooking: () => dispatch(actions.deleteBooking())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmLongTrip);
