import ExportService from "../../services/ExportService";
import { downloadResponseFile } from "../../shared/utils/RequestUtil";

class ReportChecklistManager {
    async exportChecklistsAndReports(startDate, endDate) {
        const response = await ExportService.exportChecklistsAndReports(startDate, endDate);
        downloadResponseFile(response, "Relat�rio de checklists.xlsx");
    }
}

export default new ReportChecklistManager();
