import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, makeStyles, Typography, Divider, IconButton, TextField } from "@material-ui/core";
import { GRAY, DARK_GRAY, RED, LIGHT_GREEN, PRIMARY_COLOR, SECONDARY_COLOR } from "../../../theme";
import { Smile, Frown, ChevronLeft, Calendar, Clock } from "react-feather";
import { Layout } from "../../layout";
import { format } from "date-fns";
import { Redirect } from "react-router-dom";
import { routes, messages, MOBILE_WINDOW_WIDTH } from "../../../shared/utils/Constants";
import { checklistTypeAnswer, marksFuelLevel } from "../../../shared/utils/enum";
import LevelSlider from "../../../shared/components/LevelSlider";
import {
  getXsGrid,
  getSubtitleVariant,
  getIconSize,
  getMarginLeft,
  getDateTimeVariant,
  getTitleVariant,
  getFuelNumber,
  getChevronSize
} from "../responsiveAdaptors";

const CheckOutView = props => {
  const classes = useStyles();

  const { window, checklist, previousRoute } = props;

  window.onbeforeunload = function () {
    return messages.ALERT_RELOAD_PAGE;
  };

  const [redirect, setRedirect] = useState();

  useEffect(() => {
    !checklist.checkOutResponses && setRedirect(<Redirect to={routes.root} />);
  }, [checklist]);

  const handleRedirect = () => {
    setRedirect(<Redirect to={previousRoute} />);
  };

  return (
    <Layout showHeader>
      <Grid container className="mt-8 mb-8 pl-4 pr-4">
        <Grid item container alignItems="center" className="mt-4 mr-12 pr-2 mb-12">
          <IconButton onClick={() => handleRedirect()} className="ml-2" aria-label="Voltar">
            <ChevronLeft size={getChevronSize(window)} color={PRIMARY_COLOR} />
          </IconButton>
          <Typography
            variant={getTitleVariant(window)}
            component="h2"
            className={classes.pageTitle}
          >
            Detalhes do Check-out
          </Typography>
        </Grid>
        <Grid container justify="center">
          <Grid item container xs={12} sm={10} md={8} lg={6} xl={5}>
            <Grid item container className={classes.dateTime}>
              <Grid item xs={getXsGrid(window)} className={classes.mobileView}>
                <Typography variant={getSubtitleVariant(window)}>
                  Data e Horário da Realização
                </Typography>
              </Grid>
              <Grid item xs={getXsGrid(window)}>
                <Grid container className={classes.mobileView}>
                  <Calendar
                    size={getIconSize(window)}
                    className={`${classes.iconsDetails} ${
                      classes.dateTimeDetailsDisplay
                    } ${getMarginLeft(window)} mr-4`}
                  />
                  <Typography
                    variant={getDateTimeVariant(window)}
                    className={`${classes.detailsDateTime} ${classes.dateTimeDetailsDisplay}`}
                  >
                    {checklist.checkoutDateTime &&
                      format(new Date(checklist.checkoutDateTime), "dd/MM/yyyy")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={getXsGrid(window)}>
                <Grid container className={classes.mobileView}>
                  <Clock
                    size={getIconSize(window)}
                    className={`${classes.iconsDetails} ${
                      classes.dateTimeDetailsDisplay
                    } ${getMarginLeft(window)} mr-4`}
                  />
                  <Typography
                    variant={getDateTimeVariant(window)}
                    className={`${classes.detailsDateTime} ${classes.dateTimeDetailsDisplay}`}
                  >
                    {checklist.checkoutDateTime &&
                      format(new Date(checklist.checkoutDateTime), "HH:mm")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="mb-10 mt-10">
              <Divider className="divider" />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={classes.questionsText}>
                Quilometragem na entrega:
              </Typography>
            </Grid>
            <Grid item xs={12} className="mt-2">
              <TextField
                fullWidth
                disabled
                variant="outlined"
                value={
                  checklist.checkOutResponses && `${checklist.checkOutResponses.mileage} KM rodados`
                }
                className={classes.mileageText}
              ></TextField>
            </Grid>
            <Grid item xs={12} className="mt-6">
              <Typography variant="subtitle1" className={classes.questionsText}>
                Combustível na entrega:
              </Typography>
            </Grid>
            <Grid item xs={12} className="mt-2">
              <Grid item xs={12} align="center" className="pl-4 pr-4">
                {checklist.checkOutResponses && (
                  <LevelSlider
                    value={getFuelNumber(checklist.checkOutResponses.fuelLevel)}
                    marks={marksFuelLevel}
                    step={25}
                    min={25}
                    className={classes.slider}
                  />
                )}
              </Grid>
            </Grid>

            {checklist.checkOutResponses &&
              checklist.checkOutResponses.checklistQuestionResponses.map((item, index) => {
                const isOk = item.checklistTypeAnswerId === checklistTypeAnswer.ok;
                const labelClass = isOk
                  ? classes.dynamicOkQuestionsLabel
                  : classes.dynamicNotOkQuestionsLabel;
                const dividerClass = isOk ? classes.dynamicOkDivider : classes.dynamicNotOkDivider;
                return (
                  <Grid item container xs={12} className="mt-10" key={index}>
                    <Grid item xs>
                      <Typography variant="body1" className={labelClass}>
                        {item.questionLabel}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} align="right">
                      {item.checklistTypeAnswerId === checklistTypeAnswer.ok ? (
                        <Smile size={28} color={LIGHT_GREEN} />
                      ) : (
                        <Frown size={28} color={RED} />
                      )}
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                      <Divider className={dividerClass} />
                    </Grid>
                    {item.observations && (
                      <Grid item container xs={12}>
                        <Grid item xs={12} className="mt-10 mb-10">
                          <Typography variant="h5">Observações gerais</Typography>
                        </Grid>
                        <Grid item xs={12} className="mb-2">
                          <Typography
                            variant="subtitle1"
                            className={`${classes.questionsText}  ${classes.overflow}`}
                          >
                            {item.observations}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className="mb-6">
                          <Divider className={classes.endDivider} />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>

      {redirect}
    </Layout>
  );
};

const useStyles = makeStyles(theme => ({
  pageTitle: {
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      margin: "auto"
    }
  },
  dateTime: {
    alignItems: "center"
  },
  mobileView: {
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      justifyContent: "center",
      textAlign: "center"
    }
  },
  iconsDetails: {
    color: SECONDARY_COLOR
  },
  dateTimeDetailsDisplay: {
    display: "flex",
    marginBottom: "auto",
    marginTop: "auto"
  },
  questionsText: {
    color: GRAY
  },
  detailsDateTime: {
    color: DARK_GRAY
  },
  mileageText: {
    "& .MuiInputBase-input": {
      color: DARK_GRAY
    }
  },
  slider: {
    "&.MuiSlider-root": {
      cursor: "unset"
    }
  },
  dynamicOkQuestionsLabel: {
    color: LIGHT_GREEN
  },
  dynamicNotOkQuestionsLabel: {
    color: RED
  },
  dynamicOkDivider: {
    backgroundColor: LIGHT_GREEN
  },
  dynamicNotOkDivider: {
    backgroundColor: RED
  },
  endDivider: {
    backgroundColor: PRIMARY_COLOR
  },
  overflow: {
    overflowWrap: "break-word"
  }
}));

const mapStateToProps = state => {
  return {
    window: state.window,
    checklist: state.checklist,
    previousRoute: state.router.route
  };
};

export default connect(mapStateToProps, null)(CheckOutView);
