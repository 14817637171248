import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";

const StatusBox = props => {
  const { boxStyle, text, textColor, align, className } = props;

  const classes = useStyles({ align });

  return (
    <Box align="center" className={`${classes.boxLabels} ${className}`} style={boxStyle}>
      <Typography className={classes.labelTexts} style={{ color: textColor }}>
        {text}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  boxLabels: ({ align }) => ({
    border: "1px solid",
    borderRadius: 4,
    padding: 5,
    width: "fit-content",
    height: "fit-content",
    alignSelf: align
  }),
  labelTexts: {
    fontSize: 12,
    letterSpacing: 1.5,
    fontWeight: 600
  }
}));

export default StatusBox;
