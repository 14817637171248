import React from "react";
import { Grid, TextField, FormControl, InputLabel, MenuItem, makeStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { Calendar } from "react-feather";
import { selectMenuProps } from "../../../../shared/utils/enum";
import { MaskedInputFactory } from "../../../../shared/components/MaskedInputFactory";
import { CustomSelect } from "../../../../shared/components/CustomSelect";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { GRAY, SECONDARY_COLOR } from "../../../../theme";
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";

const LicensingSegment = props => {
  const { control, errors, values, licenseStatusList, shouldDisable } = props;
  const classes = useStyles();

  return (
    <Grid item container spacing={2}>
      <Grid item xs>
        <Controller
          name="contractStartDate"
          control={control}
          render={({ field: { ref, ...fieldProps } }) => {
            return (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <KeyboardDatePicker
                  autoComplete="off"
                  inputRef={ref}
                  {...fieldProps}
                  required
                  fullWidth
                  label="Data de Aquisição"
                  variant="outlined"
                  format="dd/MM/yyyy"
                  disableFuture
                  placeholder="00/00/0000"
                  inputVariant="outlined"
                  cancelLabel="CANCELAR"
                  disabled={shouldDisable}
                  className={classes.keyboardPicker}
                  keyboardIcon={
                    <Calendar
                      className={`${classes.pickerIcon} ${
                        shouldDisable ? classes.disabledPickerColor : classes.pickerColor
                      }`}
                    />
                  }
                  inputProps={{
                    "aria-label": "Informe a data de aquisição do veículo"
                  }}
                  error={errors && !!errors[fieldProps.name]}
                  helperText={
                    errors && errors[fieldProps.name] ? errors[fieldProps.name].message : null
                  }
                />
              </MuiPickersUtilsProvider>
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="contractValidity"
          control={control}
          render={({ field: { ref, ...fieldProps } }) => {
            return (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <KeyboardDatePicker
                  autoComplete="off"
                  inputRef={ref}
                  {...fieldProps}
                  fullWidth
                  label="Vigência"
                  variant="outlined"
                  format="dd/MM/yyyy"
                  placeholder="00/00/0000"
                  inputVariant="outlined"
                  cancelLabel="CANCELAR"
                  className={classes.keyboardPicker}
                  keyboardIcon={
                    <Calendar className={`${classes.pickerIcon} ${classes.pickerColor}`} />
                  }
                  inputProps={{
                    "aria-label": "Informe a data de vigência do contrato"
                  }}
                  error={errors && !!errors[fieldProps.name]}
                  helperText={
                    errors && errors[fieldProps.name] ? errors[fieldProps.name].message : null
                  }
                />
              </MuiPickersUtilsProvider>
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="carDocument"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                fullWidth
                {...field}
                label="Renavan"
                disabled={shouldDisable}
                error={errors && !!errors[field.name]}
                helperText={errors && errors[field.name] ? errors[field.name].message : null}
                placeholder="12345678901"
                type="text"
                variant="outlined"
                inputProps={{
                  "aria-label": "Digite o documento do carro",
                  maxLength: 11
                }}
                InputProps={{
                  inputComponent: CarDocumentMask
                }}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="licenseStatusId"
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth variant="outlined">
                <InputLabel shrink={!!field.value}>Licenciamento</InputLabel>
                <CustomSelect
                  handleClearField={() => field.onChange(0)}
                  inputProps={{
                    "aria-label": "Selecione o status do licenciamento do veículo"
                  }}
                  label={values.licenseStatusId ? "Licenciamento" : undefined}
                  error={errors && !!errors[field.name]}
                  helperText={errors && errors[field.name] ? errors[field.name].message : null}
                  {...field}
                  MenuProps={selectMenuProps}
                  variant="outlined"
                >
                  {licenseStatusList.map(licenseStatus => (
                    <MenuItem key={licenseStatus.id} value={licenseStatus.id}>
                      {licenseStatus.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

const CarDocumentMask = MaskedInputFactory({
  id: "carDocumentMask",
  mask: Array.from({ length: 11 }, () => {
    return /\d/;
  })
});

const useStyles = makeStyles(theme => ({
  pickerIcon: {
    [theme.breakpoints.up(1280)]: {
      width: "20px",
      height: "20px"
    },
    [theme.breakpoints.down(1280)]: {
      width: "16px",
      height: "16px"
    }
  },
  pickerColor: {
    color: SECONDARY_COLOR
  },
  disabledPickerColor: {
    color: GRAY
  },
  keyboardPicker: {
    "& .MuiIconButton-root": {
      padding: "unset"
    }
  }
}));

export default LicensingSegment;
