const eventoBloqueio = {
  reserva: 1,
  agendamento: 2,
  entregaMaterial: 4,
  fechamentoRotina: 5,
  encaixe: 13,
  entregaAlmoco: 16,
  deslocamentoAutomatico: 17,
  migracaoDeAgenda: 18,
  deslocamentoManual: 24
};

export const formatarHorarios = (colunas, semanas) => {
  const agendamentos = colunas.filter(
    x => x.evento.id === eventoBloqueio.agendamento || x.evento.id === eventoBloqueio.encaixe
  );

  const eventoRotina = colunas.find(
    x => x.evento.id !== eventoBloqueio.agendamento && x.evento.id !== eventoBloqueio.encaixe
  );

  if (agendamentos.length > 0) {
    const agendamentosFiltrados = semanas.colunas.filter(
      i =>
        i.horarioReferencia >= agendamentos[0].horarioReferencia &&
        i.horarioReferencia < agendamentos[0].horarioFim &&
        (i.evento.id === eventoBloqueio.agendamento || i.evento.id === eventoBloqueio.encaixe)
    );

    if (agendamentosFiltrados.length > 1) {
      let eventoReferencia =
        agendamentosFiltrados.find(l => l.evento.id === eventoBloqueio.agendamento) ||
        agendamentosFiltrados[0];

      let textoTooltip = [];

      agendamentosFiltrados.forEach(t => {
        textoTooltip.push(t.textoTooltip.find(i => i));
      });

      eventoReferencia.textoTooltip = textoTooltip;
      eventoReferencia.colSpan =
        eventoRotina ||
        semanas.colunas
          .filter(
            e =>
              e.evento.id !== eventoBloqueio.agendamento && e.evento.id !== eventoBloqueio.encaixe
          )
          .some(
            c =>
              c.horarioInicio < eventoReferencia.horarioFim &&
              eventoReferencia.horarioInicio < c.horarioFim
          )
          ? 1
          : 2;

      let novaColuna = [];
      novaColuna.push(eventoReferencia);
      eventoRotina && novaColuna.push(eventoRotina);
      return novaColuna;
    }
  }
  return colunas;
};

export const obterColSpanCelulaVazia = (horarioReferencia, colunas) => {
  const celulasIntersecao = colunas.filter(
    c =>
      c.horarioReferencia <= horarioReferencia.format("HH:mm") &&
      c.horarioFim > horarioReferencia.format("HH:mm")
  );

  if (celulasIntersecao.length === 0) {
    return 2;
  } else if (celulasIntersecao.length === 1 && celulasIntersecao[0].colSpan === 1) {
    return 1;
  }

  return 0;
};
