import React, { useState } from "react";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as actions from "../../../store/BookingStore";
import { WHITE, PRIMARY_COLOR, SECONDARY_COLOR } from "../../../theme";
import { MOBILE_WINDOW_WIDTH, routes } from "../../../shared/utils/Constants";
import { Layout } from "../../layout";
import { accessType } from "../../../shared/utils/enum";
import { ReactComponent as CarIcon } from "../../../shared/img/ConfirmedBookingCarIcon.svg";

const ConfirmShortTrip = props => {
  const { deleteBooking, profile } = props;
  const classes = useStyles();

  const isFacilities = profile === accessType.siteFacilities;
  const [redirect, setRedirect] = useState(null);

  window.onbeforeunload = null;

  return (
    <Layout>
      <Grid container alignItems="center" className={classes.container}>
        <Grid item container justify="center" className={classes.content}>
          <Grid item container align="center" xs={12} md={7} direction="column" className="mb-10">
            <Grid item className="mb-10">
              <CarIcon />
            </Grid>
            <Grid item className="mt-2">
              <Typography variant="h5" color="inherit" className={classes.title}>
                Sua reserva foi confirmada!
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="inherit" className={classes.description}>
                Antes de retirar o carro, é obrigatório a realização do checkin. Acesse "Minhas
                Reservas" para visualizar detalhes, cancelar ou realizar o check-in.
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item className={classes.button}>
              {isFacilities && (
                <Button
                  className={classes.calendarButton}
                  color="secondary"
                  onClick={() => {
                    deleteBooking();
                    setRedirect(<Redirect to={routes.calendar} />);
                  }}
                >
                  <Typography variant="subtitle2" color="secondary">
                    VOLTAR PARA O CALENDÁRIO
                  </Typography>
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  deleteBooking();
                  setRedirect(<Redirect to={routes.listBookings} />);
                }}
              >
                <Typography variant="subtitle2" color="inherit">
                  ACESSAR MINHAS RESERVAS
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {redirect}
    </Layout>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: PRIMARY_COLOR,
    height: "100vh",
    color: WHITE,
    padding: "14px"
  },
  content: {
    maxHeight: "100vh"
  },
  calendarButton: {
    border: "solid 1px",
    color: SECONDARY_COLOR,
    paddingRight: "13px",
    paddingLeft: "13px"
  },
  title: {
    fontWeight: 700
  },
  description: {
    fontWeight: 400
  },
  button: {
    [theme.breakpoints.up(MOBILE_WINDOW_WIDTH)]: {
      marginRight: "16px"
    },
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      marginBottom: "16px"
    }
  }
}));

const mapStateToProps = state => {
  return {
    profile: state.user.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteBooking: () => dispatch(actions.deleteBooking())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmShortTrip);
