import React, { useState, useEffect, useCallback } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import RadioSelection from "../../../shared/components/RadioSelection";
import { connect } from "react-redux";
import * as actions from "../../../store/BookingStore";
import BookingManager from "../BookingManager";
import { messages, routes } from "../../../shared/utils/Constants";
import { carType, tripType, tripTypeList } from "../../../shared/utils/enum";
import { Redirect } from "react-router-dom";
import CustomTextField from "../../../shared/components/CustomTextField";
import NewBooking from "./NewBooking";
import { SECONDARY_COLOR } from "../../../theme";
import Loading from "../../../shared/components/Loading";

const SelectCarAndTripType = props => {
  const { booking, updateBooking, siteId } = props;
  const classes = useStyles();

  const [carModels, setCarModels] = useState();
  const [selectedTripType, setSelectedTripType] = useState(booking.tripType);
  const [selectedCarModelId, setSelectedCarModelId] = useState(booking.carModelId || "");
  const [selectedCarTypeId, setSelectedCarTypeId] = useState();
  const [selectedCarName, setSelectedCarName] = useState(booking.selectedCarName || "");
  const [destination, setDestination] = useState(booking.destination || "");
  const [redirect, setRedirect] = useState();

  const getCarModelList = useCallback(async site => {
    const result = await BookingManager.getCarModelList(site);

    if (result) {
      let carTypeDetails;
      result.forEach(item => {
        carTypeDetails = carType && Object.values(carType).find(type => type.text === item.carType);

        item.icon = carTypeDetails.icon;
        item.name = item.carType + " - " + item.model;
        item.carTypeId = carTypeDetails.id;
      });

      setCarModels(result);
    }
  }, []);

  useEffect(() => {
    !booking.nextStep && setRedirect(<Redirect to={routes.bookingSteps.SELECT_DRIVER.route} />);
  }, [booking]);

  useEffect(() => {
    if (siteId) getCarModelList(siteId);
  }, [siteId, getCarModelList]);

  useEffect(() => {
    if (!carModels || !carModels.length) return;

    const carTypeInModelsList = carModels.find(type => type.id === selectedCarModelId);
    setSelectedCarName(carTypeInModelsList?.name);
    setSelectedCarTypeId(carTypeInModelsList?.carTypeId);

    if (!selectedTripType) setSelectedTripType(tripTypeList[0].id);

    if (!selectedCarModelId || !carTypeInModelsList) setSelectedCarModelId(carModels[0].id);
  }, [carModels, selectedTripType, selectedCarModelId]);

  const handleSubmit = () => {
    updateBooking({
      ...booking,
      tripType: selectedTripType,
      carType: selectedCarTypeId,
      carModelId: selectedCarModelId,
      carTypeName: selectedCarName,
      destination: destination,
      nextStep: routes.bookingSteps.SELECT_DATE.id
    });

    setRedirect(<Redirect to={routes.bookingSteps.SELECT_DATE.route} />);
  };

  const handlePreviousStep = () =>
    setRedirect(<Redirect to={routes.bookingSteps.SELECT_SITE.route} />);

  return (
    <NewBooking
      disabled={!destination || selectedCarModelId === "" || selectedTripType === tripType.long}
      redirect={redirect}
      handleNext={handleSubmit}
      handlePrevious={handlePreviousStep}
    >
      <Grid container item xs={12}>
        <Grid item xs={12} className="mb-4">
          <Typography variant="h5">Selecione a duração da sua reserva:</Typography>
        </Grid>
        <Grid item xs={12}>
          <RadioSelection optionsList={[tripTypeList[0]]} value={tripType.short} />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} className="mb-4 mt-3">
          <Typography variant="h5">Selecione o tipo de carro:</Typography>
        </Grid>
        <Grid item xs={12}>
          {carModels ? (
            <RadioSelection
              optionsList={carModels}
              handleChange={e => setSelectedCarModelId(parseInt(e.target.value))}
              value={selectedCarModelId}
              emptyListMessage={messages.NO_AVAILABLE_CARS}
            />
          ) : (
            <Loading />
          )}
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} className="mb-4 mt-3">
          <Typography variant="h5">Qual o local de destino?</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            required
            maxLength={255}
            value={destination}
            color={SECONDARY_COLOR}
            InputProps={{ className: classes.destinationField }}
            placeholder="Digite o local de destino*"
            onChange={e => setDestination(e.target.value)}
            requiredLabel="Campo obrigatório*"
          />
        </Grid>
      </Grid>
    </NewBooking>
  );
};

const useStyles = makeStyles(() => ({
  destinationField: {
    height: "80px",
    paddingLeft: "10px"
  }
}));

const mapStateToProps = state => {
  return {
    booking: state.booking,
    siteId: state.booking.siteId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateBooking: data => dispatch(actions.updateBooking(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCarAndTripType);
