import { makeStyles, IconButton } from "@material-ui/core";
import React from "react";
import { X } from "react-feather";
import { SECONDARY_COLOR } from "../../theme";

const CloseIcon = props => {
  const { color = SECONDARY_COLOR, onClick } = props;

  const classes = useStyles();

  return (
    <IconButton className={`${classes.closeIcon} mr-2 mt-2`} onClick={onClick}>
      <X color={color} />
    </IconButton>
  );
};

const useStyles = makeStyles(() => ({
  closeIcon: {
    position: "absolute",
    right: 0
  }
}));
export default CloseIcon;
