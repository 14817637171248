import React, { useState, useEffect } from "react";
import { Divider, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import StepButtons from "./StepButtons";
import { connect } from "react-redux";
import * as actions from "../../../store/BookingStore";
import { format } from "date-fns";
import BookingManager from "../BookingManager";
import { formType, tripType, carTypes } from "../../../shared/utils/enum";
import ptBR from "date-fns/locale/pt-BR";
import { Layout } from "../../layout/";
import { MOBILE_WINDOW_WIDTH, routes } from "../../../shared/utils/Constants";
import { GRAY, PRIMARY_COLOR, THIN_BLUE2, SECONDARY_COLOR, WHITE } from "../../../theme";
import { Redirect } from "react-router-dom";
import CustomTextField from "../../../shared/components/CustomTextField";
import { ChevronLeft, ChevronsUp, ChevronsDown, User, MapPin } from "react-feather";

const SelectDetails = props => {
  const { window, booking, updateBooking, userName } = props;
  const classes = useStyles();
  const carIcon = carTypes.find(type => type.id === booking.carType);
  const [reason, setReason] = useState("");
  const [redirect, setRedirect] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    !booking.nextStep && setRedirect(<Redirect to={routes.bookingSteps.SELECT_DRIVER.route} />);
  }, [booking]);

  const saveBooking = async () =>
    BookingManager.saveBooking({
      bookingTypeId: booking.tripType,
      startJourney: booking.startDateTime,
      finishJourney: booking.endDateTime,
      destination: booking.destination,
      reason,
      driverId: booking.driverId,
      schedulerId: booking.schedulerId,
      carTypeId: booking.carModelId,
      site: {
        id: booking.siteId
      }
    });

  const handleSubmit = async () => {
    setLoading(true);

    updateBooking({
      ...booking,
      reason
    });

    const data = await saveBooking();

    if (data) {
      const route =
        booking.tripType === tripType.long
          ? routes.confirmTrip.longTrip
          : routes.confirmTrip.shortTrip;

      setRedirect(<Redirect to={route} />);
    }

    setLoading(false);
  };

  const handlePreviousStep = () => {
    setRedirect(<Redirect to={routes.bookingSteps.SELECT_DATE.route} />);
  };

  const isMobile = window.width < MOBILE_WINDOW_WIDTH;
  const getRootGrid = () => (isMobile ? 3 : 10);
  const getImageGridSize = () => (isMobile ? 6 : undefined);
  const getHeaderVariant = () => (isMobile ? "subtitle2" : "h6");
  const getContentSpacing = () => (isMobile ? 2 : 5);

  return (
    <Layout showHeader formType={formType.booking}>
      <Grid container className={`p-${getRootGrid()}`}>
        <Grid item container spacing={1} className={classes.header}>
          {!isMobile && (
            <Grid item>
              <IconButton onClick={() => handlePreviousStep()} size="small">
                <ChevronLeft size={36} color={PRIMARY_COLOR} />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <Grid item>
              <Typography variant="h4" className={classes.headerContent}>
                Confirmação da Reserva
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" className={classes.headerContent}>
                Confira os dados e confirme sua reserva:
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container className={`mt-10 ${classes.details}`}>
          <Grid item container alignItems="center">
            {carIcon && (
              <Grid item xs={getImageGridSize()}>
                <img src={carIcon.src} alt={carIcon.alt} className={`mr-8 ${classes.imageFit}`} />
              </Grid>
            )}
            <Grid item xs>
              <Grid item>
                <Grid>
                  <Typography variant="overline">DADOS DO VEÍCULO</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid>
                  <Typography variant={getHeaderVariant()}>{booking.carTypeName}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mb-10 mt-7">
            <Divider className="divider" />
          </Grid>
          <Grid container item spacing={getContentSpacing()} className={classes.containerContent}>
            <Grid container item spacing={2} alignItems="center" className={classes.cardDetails}>
              <Grid item>
                <ChevronsUp size={24} color={SECONDARY_COLOR} />
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="subtitle1">
                    {booking.startDateTime &&
                      format(booking.startDateTime, "dd/MM/yyyy 'às' HH:mm", { locale: ptBR })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption" className={classes.label}>
                    Retirada
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item spacing={2} alignItems="center" className={classes.cardDetails}>
              <Grid item>
                <ChevronsDown size={24} color={SECONDARY_COLOR} />
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="subtitle1">
                    {booking.endDateTime &&
                      format(booking.endDateTime, "dd/MM/yyyy 'às' HH:mm", { locale: ptBR })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption" className={classes.label}>
                    Devolução
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item spacing={2} alignItems="center" className={classes.cardDetails}>
              <Grid item>
                <User size={24} color={SECONDARY_COLOR} />
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="subtitle1">{userName}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption" className={classes.label}>
                    Reservado por
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item spacing={2} alignItems="center" className={classes.cardDetails}>
              <Grid item>
                <User size={24} color={SECONDARY_COLOR} />
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="subtitle1">{booking.driverName}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption" className={classes.label}>
                    Condutor
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item spacing={2} alignItems="center" className={classes.cardDetails}>
              <Grid item>
                <MapPin size={24} color={SECONDARY_COLOR} />
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="subtitle1">{booking.siteName}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption" className={classes.label}>
                    Unidade
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mb-10 mt-10">
            <Divider className="divider" />
          </Grid>
          <Grid container>
            <Grid item>
              <Grid item>
                <Typography variant="subtitle1">{booking.destination}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" className={classes.label}>
                  Local de destino
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <CustomTextField
              rows={5}
              multiline
              size="medium"
              InputProps={{ className: classes.reason }}
              value={reason}
              maxLength={255}
              onChange={event => setReason(event.target.value)}
              placeholder="Observações ou justificativas da reserva *"
              requiredLabel="Campo obrigatório*"
            />
          </Grid>
          <Grid container direction="row-reverse" className="mt-10">
            <Grid item xs={12} md={7} lg={6} xl={5}>
              <StepButtons
                isLastStep
                confirmText={!isMobile && "Confirmar reserva"}
                onPreviousStep={handlePreviousStep}
                onNextStep={handleSubmit}
                disabled={loading || reason.length < 1}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {redirect}
    </Layout>
  );
};

const useStyles = makeStyles(theme => ({
  label: {
    color: GRAY
  },
  details: {
    background: THIN_BLUE2,
    borderRadius: 8,
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      marginLeft: "20px",
      marginRight: "20px",
      marginBottom: "28px",
      padding: "32px"
    },
    [theme.breakpoints.up(MOBILE_WINDOW_WIDTH)]: {
      padding: "40px"
    }
  },
  cardDetails: {
    width: "fit-content"
  },
  reason: {
    "&.MuiInputBase-root": {
      background: WHITE
    }
  },
  header: {
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      marginTop: "40px"
    }
  },
  headerContent: {
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      textAlign: "center"
    }
  },
  imageFit: {
    width: "-webkit-fill-available"
  },
  containerContent: {
    [theme.breakpoints.down(MOBILE_WINDOW_WIDTH)]: {
      display: "block"
    }
  }
}));

const mapStateToProps = state => {
  return {
    window: state.window,
    booking: state.booking,
    userName: state.user.name
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateBooking: data => dispatch(actions.updateBooking(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectDetails);
