import React from "react";
import { Grid, Typography, Step, Stepper, StepLabel, makeStyles } from "@material-ui/core";
import MainSegment from "./MainSegment";
import ModelSegment from "./ModelSegment";
import ContractSegment from "./ContractSegment";
import LicensingSegment from "./LicensingSegment";
import CheckboxSegment from "./CheckboxSegment";
import GeneralSegment from "./GeneralSegment";
import StatusSegment from "./StatusSegment";
import UserSegment from "./UserSegment";
import EditionSegment from "../../../../shared/components/ManagementPage/EditionSegment";
import { LIGHTER_GRAY, MID_GREEN, BRIGHT_BLUE } from "../../../../theme";
import { ChevronRight } from "react-feather";

const ModalContent = props => {
  const {
    control,
    errors,
    fleetTypeList,
    siteList,
    brandList,
    carModelList,
    yearList,
    providerList,
    colorList,
    fuelList,
    licenseStatusList,
    statusList,
    statusDetailsList,
    values,
    carModelIsLoading,
    statusDetailsIsLoading,
    isEdition,
    licensePlateIsLoading,
    driverData,
    driverDataIsLoading,
    isDriver,
    isAdmin
  } = props;

  const classes = useStyles();

  const stepsLabels = ["Dados do veículo", "Dados do condutor"];
  const renderStep = (index, label) => {
    let isActive = false;
    let isCompleted = false;
    if (index === 0) {
      isActive = !values.isFinalStep;
      isCompleted = !isActive;
    } else if (index === 1) {
      isActive = values.isFinalStep;
    }
    return (
      <Step key={index} active={isActive} completed={isCompleted}>
        <StepLabel className={classes.elementStyle}>{label}</StepLabel>
      </Step>
    );
  };
  const shouldDisable = !isAdmin && isEdition;

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Stepper
          activeStep={values.isFinalStep ? 1 : 0}
          connector={<ChevronRight size={18} className="ml-4 mr-4" />}
          className={classes.stepper}
        >
          {stepsLabels.map((label, index) => {
            return renderStep(index, label);
          })}
        </Stepper>
      </Grid>
      {!values.isFinalStep && (
        <>
          <Grid item xs={12}>
            <MainSegment
              control={control}
              errors={errors}
              values={values}
              fleetTypeList={fleetTypeList}
              siteList={siteList}
              licensePlateIsLoading={licensePlateIsLoading}
              shouldDisable={shouldDisable}
            />
          </Grid>
          <Grid item xs={12}>
            <ModelSegment
              control={control}
              errors={errors}
              values={values}
              brandList={brandList}
              carModelList={carModelList}
              yearList={yearList}
              carModelIsLoading={carModelIsLoading}
              shouldDisable={shouldDisable}
            />
          </Grid>
          <Grid item xs={12}>
            <GeneralSegment
              control={control}
              errors={errors}
              values={values}
              colorList={colorList}
              fuelList={fuelList}
              shouldDisable={shouldDisable}
            />
          </Grid>
          <Grid item xs={12}>
            <ContractSegment
              control={control}
              errors={errors}
              values={values}
              providerList={providerList}
              shouldDisable={shouldDisable}
            />
          </Grid>
          <Grid item xs={12}>
            <LicensingSegment
              control={control}
              errors={errors}
              values={values}
              licenseStatusList={licenseStatusList}
              shouldDisable={shouldDisable}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxSegment
              control={control}
              errors={errors}
              values={values}
              shouldDisable={shouldDisable}
            />
          </Grid>
          <Grid item xs={12}>
            <StatusSegment
              control={control}
              errors={errors}
              values={values}
              statusList={statusList}
              statusDetailsList={statusDetailsList}
              statusDetailsIsLoading={statusDetailsIsLoading}
            />
          </Grid>
        </>
      )}

      {values.isFinalStep && (
        <Grid item xs={12}>
          <UserSegment
            control={control}
            errors={errors}
            driverData={driverData}
            driverDataIsLoading={driverDataIsLoading}
            isDriver={isDriver}
          />
        </Grid>
      )}

      {isEdition && values.isFinalStep && (
        <Grid item xs={12}>
          <EditionSegment control={control} />
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography variant="caption">*campos obrigatórios</Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  stepper: {
    backgroundColor: LIGHTER_GRAY,
    justifyContent: "center"
  },
  elementStyle: {
    "& .MuiStepLabel-label": {
      fontWeight: 600,
      fontSize: "20px"
    },
    "& .MuiStepIcon-root": {
      fontSize: "32px"
    },
    "& .MuiStepLabel-iconContainer": {
      paddingRight: "16px"
    },
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: BRIGHT_BLUE
    },
    "& .MuiStepLabel-label.MuiStepLabel-completed": {
      color: MID_GREEN
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: BRIGHT_BLUE
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: MID_GREEN
    }
  }
}));

export default ModalContent;
