import React from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import Loading from "../../../shared/components/Loading";

const DetailsButtonsByFac = props => {
  const {
    isCheckOutActive,
    isCheckInActive,
    loadingCheckOutResponses,
    loadingCheckInResponses,
    handleGetCheckOutInfos,
    handleGetCheckInInfos
  } = props;

  const classes = useStyles();

  return (
    <Grid container direction="row" className="mt-8 pt-2 pb-4">
      <Grid item className="pr-4">
        <Button
          className={classes.buttonGeneral}
          variant="outlined"
          color="secondary"
          disabled={!isCheckInActive || loadingCheckInResponses}
          onClick={handleGetCheckInInfos}
        >
          {loadingCheckInResponses ? (
            <Loading loading className={classes.loading} size={25} />
          ) : (
            "VISUALIZAR CHECK-IN"
          )}
        </Button>
      </Grid>
      <Grid item className="pl-4">
        <Button
          className={classes.buttonGeneral}
          variant="outlined"
          color="secondary"
          disabled={!isCheckOutActive || loadingCheckOutResponses}
          onClick={handleGetCheckOutInfos}
        >
          {loadingCheckOutResponses ? (
            <Loading loading className={classes.loading} size={25} />
          ) : (
            "VISUALIZAR CHECK-OUT"
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  buttonGeneral: {
    width: "256px"
  },
  loading: {
    display: "flex",
    margin: "auto"
  }
}));

export default DetailsButtonsByFac;
