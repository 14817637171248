import React from "react";
import { Grid } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

const CourseSegment = props => {
  const { control, errors, required } = props;

  return (
    <>
      <Grid item container xs={4}>
        <Grid item xs={12}>
          <Controller
            name="courseExpireDate"
            control={control}
            render={({ field: { ref, ...fieldProps } }) => {
              return (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <KeyboardDatePicker
                    autoComplete="off"
                    inputRef={ref}
                    {...fieldProps}
                    fullWidth
                    label={"Vencimento CDD".concat(required ? " *" : "")}
                    disablePast
                    variant="outlined"
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    cancelLabel="CANCELAR"
                    KeyboardButtonProps={{
                      "aria-label": "Selecione uma data"
                    }}
                    inputProps={{
                      "aria-label":
                        "Selecione a data de vencimento do curso de direção defensiva".concat(
                          required ? " *" : ""
                        )
                    }}
                    InputLabelProps={{ shrink: true }}
                    error={errors && !!errors[fieldProps.name]}
                    helperText={
                      errors && errors[fieldProps.name]
                        ? errors[fieldProps.name].message
                        : null
                    }
                  />
                </MuiPickersUtilsProvider>
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CourseSegment;
