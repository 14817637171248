import { useEffect, useState, useRef } from "react";
import { tabsListChecklists } from "../../../shared/utils/enum";
import ChecklistAndReportManager from "../ChecklistAndReportManager";

const useChecklistAndReport = (userId, activeTab) => {
  const [checklists, setChecklists] = useState([]);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);

  const listChecklists = async () => {
    setLoading(true);

    if (userId) {
      const response = await ChecklistAndReportManager.listRecurringChecklistsByUserId(userId);
      if (response) setChecklists(response);
    }

    setLoading(false);
  };

  const listReports = async () => {
    setLoading(true);

    if (userId) {
      const response = await ChecklistAndReportManager.listReportsByUserId(userId);
      if (response) setReports(response);
    }

    setLoading(false);
  };

  const refListChecklists = useRef(listChecklists);
  const refListReports = useRef(listReports);

  useEffect(() => {
    if (activeTab === tabsListChecklists.securityReports.value) refListReports.current();
    else refListChecklists.current();
  }, [activeTab]);

  return { checklists, reports, loading };
};

export default useChecklistAndReport;
