import React, { useCallback, useEffect, useState } from "react";
import { Grid, Paper, Typography, makeStyles, Divider } from "@material-ui/core";
import { formatDateTimeToLiteral } from "../../utils/Utils";
import { GRAY, SECONDARY_COLOR } from "../../../theme";
import HistoryCardTag from "./HistoryCardTag";
import { userManagementProfiles } from "../../utils/enum";
import VerticalChevron from "../VerticalChevron";

const HistoryCard = props => {
  const {
    oldData,
    newData,
    isExpanded,
    setIsExpanded,
    automaticEditionReason,
    HistoryCardContent,
    hasAdditionalData = false,
    getAdditionalData,
    TagProps
  } = props;

  const classes = useStyles({ isExpanded });

  const [isLoadingAdditionalData, setIsLoadingAdditionalData] = useState(false);
  const [oldAdditionalData, setOldAdditionalData] = useState({});
  const [newAdditionalData, setNewAdditionalData] = useState({});

  const loadAdditionalData = useCallback(async () => {
    setIsLoadingAdditionalData(true);

    const { newAdditionalDataToLoad, oldAdditionalDataToLoad } = await getAdditionalData(
      oldData,
      newData
    );

    setOldAdditionalData({ ...oldAdditionalDataToLoad, isLoaded: true });
    setNewAdditionalData({ ...newAdditionalDataToLoad, isLoaded: true });

    setIsLoadingAdditionalData(false);
  }, [getAdditionalData, oldData, newData]);

  useEffect(() => {
    if (
      isExpanded &&
      !oldAdditionalData.isLoaded &&
      !newAdditionalData.isLoaded &&
      hasAdditionalData
    ) {
      loadAdditionalData();
    }
  }, [isExpanded, oldAdditionalData, newAdditionalData, loadAdditionalData, hasAdditionalData]);

  const isFromAd = !newData.historyEntry.user.name;

  const getEditedBy = () => {
    if (isFromAd) return " · Edição Automática";

    const entryType = oldData ? "Editado" : "Cadastrado";

    let profileName;

    if (newData.historyEntry.user.profile?.profileName)
      profileName = `(${
        Object.values(userManagementProfiles).find(
          x => x.name === newData.historyEntry.user.profile.profileName
        ).label
      })`;

    return ` · ${entryType} por: ${newData.historyEntry.user.name} ${profileName || ""}`;
  };

  const getEditionReason = () => {
    if (isFromAd && oldData) return automaticEditionReason;

    return newData.historyEntry.reason || "--";
  };

  return (
    <Paper className={`mt-6 mb-6 ${classes.root}`}>
      <Grid
        container
        alignItems="center"
        onClick={() => setIsExpanded(!isExpanded)}
        className={classes.cardHeader}
      >
        <Grid item xs={9} className="mt-6 mb-6">
          <Typography component="h3" variant="h5" className="ml-4">
            <b>{formatDateTimeToLiteral(newData.historyEntry.createdAt)}</b>
            {getEditedBy()}
          </Typography>
          <Typography component="h4" variant="body1" className={`ml-4 ${classes.reason}`} noWrap>
            {`Motivo da edição: ${getEditionReason()}`}
          </Typography>
        </Grid>
        <Grid container item xs={3} alignItems="center" justify="flex-end">
          <HistoryCardTag isCreation={!oldData} isFromAd={isFromAd} {...TagProps} />
          <VerticalChevron className="mr-2" value={isExpanded} setValue={setIsExpanded} />
        </Grid>
      </Grid>
      {isExpanded && (
        <Grid container>
          <Grid item xs={12}>
            <Divider className="ml-4 mr-4 divider" />
          </Grid>
          <Grid item xs={12} className="mt-4 mb-4 pl-4 pr-4">
            <HistoryCardContent
              isCreation={!oldData}
              newData={{ ...newData, ...newAdditionalData }}
              oldData={oldData && { ...oldData, ...oldAdditionalData }}
              isLoadingAdditionalData={isLoadingAdditionalData}
            />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

const useStyles = makeStyles(() => ({
  root: ({ isExpanded }) => ({
    borderLeft: isExpanded ? `4px solid ${SECONDARY_COLOR}` : undefined
  }),
  cardHeader: {
    cursor: "pointer"
  },
  reason: {
    color: GRAY
  }
}));

export default HistoryCard;
