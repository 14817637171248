import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Grid, Button, Typography, makeStyles } from "@material-ui/core";
import { Info } from "react-feather";
import { RED } from "../../theme";
import { routes } from "../../shared/utils/Constants";
import * as bookingActions from "../../store/BookingStore";
import * as checklistActions from "../../store/ChecklistStore";
import { formType, accessType } from "../../shared/utils/enum";

const StepButtons = props => {
  const {
    booking,
    disabled,
    stepType,
    nextStep,
    checklist,
    customRoute,
    saveBooking,
    previousStep,
    saveChecklist,
    updateBooking,
    deleteBooking,
    updateChecklist,
    deleteChecklist,
    userProfile
  } = props;

  const classes = UseStyles();
  const isCommercialOrExecutive = [accessType.commercial, accessType.executive].includes(
    userProfile
  );
  const isBackOffice = accessType.backoffice === userProfile;

  const [redirect, setRedirect] = useState(null);

  const onClickBackButton = () => {
    const checkIfFirstStep =
      (stepType === formType.booking && booking.nextStep === 1) ||
      (stepType === formType.checklist && checklist.nextStep === 1);

    if (checkIfFirstStep) {
      stepType === formType.checklist && deleteChecklist();
      stepType === formType.booking && deleteBooking();

      let redirect;
      if (isCommercialOrExecutive) redirect = routes.listChecklists;
      else if (isBackOffice) redirect = routes.reportsManagement;
      else redirect = customRoute || routes.listChecklists;
      setRedirect(<Redirect to={redirect} />);
    } else {
      if (stepType === formType.booking) {
        updateBooking({ ...booking, nextStep: booking.nextStep - 1 });
        setRedirect(<Redirect to={routes.bookingSteps + (booking.nextStep - 1)} />);
      } else if (stepType === formType.checklist) {
        updateChecklist({ ...checklist, nextStep: checklist.nextStep - 1 });
        setRedirect(<Redirect to={previousStep} />);
      }
    }

    window.scrollTo(0, 0);
  };

  const onClickContinueButton = () => {
    if (stepType === formType.booking) {
      updateBooking({ ...booking, nextStep: booking.nextStep + 1 });
      setRedirect(<Redirect to={routes.bookingSteps + (booking.nextStep + 1)} />);
    } else if (stepType === formType.checklist) {
      updateChecklist({ ...checklist, nextStep: checklist.nextStep + 1 });
      setRedirect(<Redirect to={nextStep} />);
    }

    window.scrollTo(0, 0);
  };

  const onClickConfirmButton = () => {
    stepType === formType.booking && saveBooking();
    stepType === formType.checklist && saveChecklist();
  };

  const checkLastStep =
    (stepType === formType.booking && parseInt(booking.nextStep) === 5) ||
    (stepType === formType.checklist && parseInt(checklist.nextStep) === 4);

  const confirmDisable =
    booking && stepType === formType.booking ? !booking.reason || !booking.destination : disabled;

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={6}>
        <Button variant="outlined" color="secondary" fullWidth onClick={onClickBackButton}>
          VOLTAR
        </Button>
      </Grid>
      <Grid item xs={6}>
        {checkLastStep ? (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={onClickConfirmButton}
            disabled={confirmDisable}
          >
            FINALIZAR
          </Button>
        ) : (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={onClickContinueButton}
          >
            CONTINUAR
          </Button>
        )}
      </Grid>
      {disabled && (
        <Grid item container xs={12} className={`${classes.labelDisplay} mt-2`}>
          <Grid item className={classes.iconDisplay}>
            <Info size={15} className={classes.labelText} />
          </Grid>
          <Grid item className="ml-4">
            <Typography variant="body2" className={classes.labelText}>
              Oops... ainda existem itens para preencher
            </Typography>
          </Grid>
        </Grid>
      )}

      {redirect}
    </Grid>
  );
};

const UseStyles = makeStyles(_theme => ({
  labelText: {
    color: RED
  },
  labelDisplay: {
    justifyContent: "center"
  },
  iconDisplay: {
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto"
  }
}));

const mapStateToProps = state => {
  return {
    booking: state.booking,
    checklist: state.checklist,
    userProfile: state.user.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateBooking: data => dispatch(bookingActions.updateBooking(data)),
    deleteBooking: () => dispatch(bookingActions.deleteBooking()),
    updateChecklist: data => dispatch(checklistActions.updateChecklist(data)),
    deleteChecklist: () => dispatch(checklistActions.deleteChecklist())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepButtons);
