import React, { useRef } from "react";

const FormButton = props => {
  const { children, onClick } = props;

  const buttonChild = Array.isArray(children) ? children[0] : children;

  const mouseDownRef = useRef(false);

  const handleMouseUp = () => {
    if (mouseDownRef.current) {
      mouseDownRef.current = false;
      onClick && onClick();
    }
  }

  const handleMouseDown = (event) => {
    //left mouse click
    if (event.button === 0 && buttonChild && !buttonChild.props.disabled) {
      mouseDownRef.current = true;
      event.preventDefault();
    }
  }

  return (
    <div
      style={{ height: "100%" }}
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
    >
      {children}
    </div>
  );
}

export default FormButton;