import React from "react";
import { Typography, Grid, makeStyles, Divider } from "@material-ui/core";
import { Trash2 } from "react-feather";
import { GRAY, RED } from "../../../theme";

const ImagePreview = props => {
  const { image = {}, handleRemoveImage, divider } = props;
  const classes = useStyles();

  return (
    <Grid>
      {divider && <Divider className="mb-4" />}
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <img alt={image.name} className={classes.imagePreview} src={image.preview} />
        </Grid>
        <Grid item className={`${classes.imageContent} pl-4`}>
          <Typography className={classes.imageName} variant="subtitle2">
            {image.name}
          </Typography>
        </Grid>
        <Grid item>
          <Trash2 className={classes.removeButton} onClick={handleRemoveImage} color={RED} />
        </Grid>
      </Grid>
      <Divider className="mt-4 mb-4" />
    </Grid>
  );
};

const useStyles = makeStyles(_theme => ({
  imageContent: {
    flex: 1
  },
  imagePreview: {
    maxWidth: 88,
    maxHeigth: 88,
    borderRadius: 4
  },
  removeButton: {
    cursor: "pointer"
  },
  imageName: {
    color: GRAY
  }
}));

export default ImagePreview;
