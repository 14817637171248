import { Grid, makeStyles, Typography } from "@material-ui/core";
import { toastTypes } from "../utils/enum";
import { DARK_GRAY } from "../../theme";

const MessageBox = props => {
  const { type = toastTypes.error, children } = props;
  const classes = useStyles({ type });

  const Icon = () => type.icon;

  return (
    <Grid container alignItems="center" className={`pt-2 pb-2 pl-4 pr-4 ${classes.messageBox}`}>
      <Grid container item className={`mr-4 ${classes.iconItem}`} alignItems="center">
        <Icon />
      </Grid>
      <Grid item className={classes.messageGrid}>
        <Typography className={classes.message} variant="caption" component="span">
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  messageBox: ({ type }) => ({
    backgroundColor: type.primaryColor,
    borderRadius: 8,
    border: `1px solid ${type.secondaryColor}`,
    flexWrap: "nowrap"
  }),
  iconItem: {
    width: "auto"
  },
  message: {
    color: DARK_GRAY
  }
}));

export default MessageBox;
