import React, { useState } from "react";
import { useLocation } from "react-router";
import { Grid, Typography, Tabs, Tab } from "@material-ui/core";
import { Layout } from "../../layout/";
import DynamicContent from "./DynamicContent";
import { tabsListChecklists } from "../../../shared/utils/enum";
import { sidebarItemsNames } from "../../../shared/utils/Constants";

const ListChecklistsAndReports = () => {
  const query = new URLSearchParams(useLocation().search);
  const [activeTab, setActiveTab] = useState(parseInt(query.get("tab")) || 0);

  window.onbeforeunload = null;

  return (
    <Layout showHeader>
      <Grid item container xs={12} className="pl-6 pr-6 pt-4">
        <Grid item xs={12} className="pb-8 pt-8">
          <Typography variant="h6">{sidebarItemsNames.listChecklists}</Typography>
        </Grid>
        <Grid item xs={12} className="pb-8">
          <Tabs value={activeTab} onChange={(_e, value) => setActiveTab(value)} centered>
            {Object.values(tabsListChecklists).map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12} className="pb-6">
          <DynamicContent activeTab={activeTab} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ListChecklistsAndReports;
