import { useEffect } from "react";
import { resetManagementPage } from "../../store/ManagementPageStore";
import { useDispatch, useSelector } from "react-redux";

const usePageState = props => {
  const { pathname } = props;
  const dispatch = useDispatch();
  const storedPathname = useSelector(state => state.managementPage.pathname);

  useEffect(() => {
    if (storedPathname !== pathname) {
      dispatch(resetManagementPage({ pathname }));
    }
  }, [pathname, storedPathname, dispatch]);
};

export default usePageState;
