import React, { useState } from "react";
import { SwipeableDrawer, Grid, Divider, makeStyles } from "@material-ui/core";
import { WHITE, PRIMARY_COLOR, SECONDARY_COLOR } from "../../../theme";
import { connect } from "react-redux";
import UserInfo from "./UserInfo";
import SidebarListItems from "./SidebarListItems";
import ConfirmationModal from "../../../shared/components/ConfirmationModal";
import { Redirect, useLocation } from "react-router-dom";
import { formType as formTypeEnum, checklistTypes, accessType } from "../../../shared/utils/enum";
import { useLogoutContext } from "../../../contexts/LogoutContext";
import * as checklistActions from "../../../store/ChecklistStore";
import * as bookingActions from "../../../store/BookingStore";
import PrivateCarManager from "../PrivateCarManager";
import { groupBy } from "../../../shared/utils/Utils";
import {
  feedbackFleetManagementLink,
  feedbackSafetyReportLink,
  sidebarItemsNames
} from "../../../shared/utils/Constants";

const Sidebar = props => {
  const classes = useStyles({ isMobile: window.innerWidth <= 768 });

  const {
    openDrawer,
    setOpenDrawer,
    user,
    formType,
    checklist,
    deleteChecklist,
    deleteBooking,
    updateChecklist
  } = props;

  const logout = useLogoutContext();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [itemRoute, setItemRoute] = useState("");
  const [itemName, setItemName] = useState("");

  const isChecklistForm = formType === formTypeEnum.checklist;
  const isBookingForm = formType === formTypeEnum.booking;
  const isSecurityReportForm = formType === formTypeEnum.securityReport;

  const isCommercial = [
    accessType.commercial,
    accessType.executive,
    accessType.backoffice
  ].includes(user.profile);

  const modalTitle = () => {
    if (isChecklistForm) return "Sair do checklist";
    return isBookingForm
      ? "Sair da reserva"
      : isSecurityReportForm && "Sair do relato de segurança";
  };

  const toggleDrawer = open => event => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpenDrawer(open);
  };

  const handleLogout = () => {
    logout();
    sessionStorage.clear();
  };

  const handleFeedback = () => {
    window.open(isCommercial ? feedbackSafetyReportLink : feedbackFleetManagementLink);
  };

  const handleStartChecklist = async route => {
    if (user.privateCar.id) {
      const data = await PrivateCarManager.listChecklistModelQuestionsByCarId(
        user.privateCar.id,
        checklistTypes.RECURRING
      );

      if (data) {
        const checklistQuestions = groupBy(data, "step");
        const checklistModelId = data.shift()?.checklistModelId;
        updateChecklist({
          ...checklist,
          checklistModelId: checklistModelId,
          stepQuestions: checklistQuestions
        });

        setRedirect(<Redirect to={route} />);
      }
    }
  };

  const handleCommonClicks = route => {
    setRedirect(<Redirect to={route} />);
  };

  const handleChooseClick = (name, route) => {
    switch (name) {
      case sidebarItemsNames.logout:
        handleLogout();
        break;
      case sidebarItemsNames.feedback:
        handleFeedback();
        break;
      case sidebarItemsNames.checklistCar:
        handleStartChecklist(route);
        break;
      default:
        handleCommonClicks(route);
    }

    setOpenDrawer(false);
  };

  const confirmExit = () => {
    if (isChecklistForm) deleteChecklist();
    if (isBookingForm) deleteBooking();
    handleChooseClick(itemName, itemRoute);
  };

  const onClickItem = (name, route) => {
    if (location.pathname !== route) {
      !formType || name === sidebarItemsNames.feedback
        ? handleChooseClick(name, route)
        : setOpenModal(true);
      setItemRoute(route);
      setItemName(name);
    }
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={openDrawer}
      onOpen={toggleDrawer(!openDrawer)}
      onClose={toggleDrawer(!openDrawer)}
      className={classes.drawer}
      disableDiscovery
    >
      <Grid container>
        <Grid container item xs={12} className={classes.headerSidebarBackgroundColor}>
          <Grid item xs={12} className="pt-2 pb-1">
            <UserInfo user={user} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <SidebarListItems
            setOpenDrawer={setOpenDrawer}
            formType={formType}
            setOpenModal={setOpenModal}
            onClickItem={onClickItem}
          />
        </Grid>
        {redirect}
      </Grid>

      <ConfirmationModal
        openedModal={openModal}
        title={modalTitle()}
        subtitle="Tem certeza que deseja sair? Todos os dados preenchidos serão perdidos"
        secondaryButtonText="NÃO"
        secondaryAction={() => setOpenModal(!openModal)}
        handleOpenClose={() => setOpenModal(!openModal)}
        primaryButtonText="SIM"
        primaryAction={() => confirmExit()}
        shouldExit
      />
    </SwipeableDrawer>
  );
};

const useStyles = makeStyles(() => ({
  headerSidebarBackgroundColor: {
    backgroundColor: PRIMARY_COLOR
  },
  drawer: props => ({
    "& .MuiDrawer-paperAnchorLeft": {
      backgroundColor: SECONDARY_COLOR,
      width: props.isMobile ? "75%" : "25%"
    }
  }),
  divider: {
    height: 2,
    backgroundColor: WHITE
  }
}));
const mapDispatchToProps = dispatch => {
  return {
    deleteChecklist: () => dispatch(checklistActions.deleteChecklist()),
    deleteBooking: () => dispatch(bookingActions.deleteBooking()),
    updateChecklist: data => dispatch(checklistActions.updateChecklist(data))
  };
};

const mapStateToProps = state => {
  return {
    user: state.user,
    checklist: state.checklist
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
