import { useState, useEffect } from "react";
import { checklistTypes, marksFuelLevel } from "../../../shared/utils/enum";
import { addThousandSeparator, groupBy } from "../../../shared/utils/Utils";
import { routes } from "../../../shared/utils/Constants";
import { Redirect } from "react-router-dom";

import BookingDetailsManager from "../BookingDetailsManager";

const useChecklistResponse = (
  setRedirect,
  bookingId,
  checklist,
  updateChecklist,
  checkoutDateTime,
  checkinDateTime
) => {
  const [loadingCheckInResponses, setLoadingCheckInResponses] = useState(false);
  const [loadingCheckOutResponses, setLoadingCheckOutResponses] = useState(false);

  useEffect(() => {
    if (checkoutDateTime) updateChecklist({ checkoutDateTime: checkoutDateTime });
    if (checkinDateTime) updateChecklist({ checkinDateTime: checkinDateTime });
  }, [checkoutDateTime, checkinDateTime, updateChecklist]);

  const getData = async checklistType =>
    BookingDetailsManager.getChecklistResponseByBookingId(bookingId, checklistType);

  const handleGetCheckInInfos = async () => {
    setLoadingCheckInResponses(true);
    const data = await getData(checklistTypes.CHECKIN);

    if (data) {
      const formattedData = {
        fuelLevel: marksFuelLevel.find(item => item.value === data.fuelLevel).label,
        checklistQuestionResponses: groupBy(data.checklistQuestionResponses, "questionGroupName"),
        mileage: addThousandSeparator(data.mileage),
        checklistObservations: data.checklistObservations
      };

      updateChecklist({ ...checklist, checkInResponses: formattedData });
      setRedirect(<Redirect to={routes.checkInView} />);
    }

    setLoadingCheckInResponses(false);
  };

  const handleGetCheckOutInfos = async () => {
    setLoadingCheckOutResponses(true);
    const data = await getData(checklistTypes.CHECKOUT);

    if (data) {
      const formattedData = {
        fuelLevel: marksFuelLevel.find(item => item.value === data.fuelLevel).label,
        checklistQuestionResponses: [...data.checklistQuestionResponses],
        mileage: addThousandSeparator(data.mileage)
      };

      updateChecklist({ ...checklist, checkOutResponses: formattedData });
      setRedirect(<Redirect to={routes.checkOutView} />);
    }

    setLoadingCheckOutResponses(false);
  };

  return {
    handleGetCheckInInfos,
    handleGetCheckOutInfos,
    loadingCheckInResponses,
    loadingCheckOutResponses
  };
};

export default useChecklistResponse;
