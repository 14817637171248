import UserService from "../../services/UserService";

class UserHistoryManager {
  async getUserHistory(userId) {
    if (!userId) return;

    const response = await UserService.getUserHistory(userId);

    if (Array.isArray(response.data)) return response.data;

    return [];
  }
}

export default new UserHistoryManager();
