import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { GRAY } from "../../../theme";
import Modal from "../../../shared/components/Modal";
import DeniedAccessIcon from "../../../shared/img/DeniedAccessIcon.svg";
import { connect } from "react-redux";

const ExpiredSection = ({ isAuthenticated, logout }) => {
  const [openedModal, setOpenedModal] = useState(false);

  useEffect(() => {
    !isAuthenticated && setOpenedModal(true);
  }, [isAuthenticated]);

  const classes = useStyles();

  return (
    <Modal
      primaryButton={{
        action: () => {
          window.onbeforeunload = null;
          logout();
        },
        text: "FECHAR"
      }}
      openedModal={openedModal}
      title="Sessão expirada!"
      titleIcon={<img src={DeniedAccessIcon} alt="Denied Access" aria-hidden="true" />}
    >
      <Typography align="center" className={classes.colorSubtitle}>
        Sua sessão expirou!
      </Typography>
    </Modal>
  );
};

const useStyles = makeStyles(() => ({
  colorSubtitle: {
    color: GRAY
  }
}));

const mapStateToProps = state => {
  return {
    isAuthenticated: state.user.isAuthenticated
  };
};

export default connect(mapStateToProps, null)(ExpiredSection);
