import { isAfter, startOfDay, addHours } from "date-fns";
import * as Yup from "yup";
import { messages } from "../../../../shared/utils/Constants";
import { cnhIsRequired } from "../../../../shared/utils/Utils";
import { driverProfiles } from "../../../../shared/utils/enum";

const createValidationSchema = () => {
  return Yup.object().shape(
    {
      isEditable: Yup.boolean(),
      adUser: Yup.string()
        .matches(/(^[ACac]{1}\d{6}$)/, messages.INVALID_AD_USER)
        .required(messages.FIELD_REQUIRED),
      profileId: Yup.number(),
      licenseTypeId: Yup.mixed().when("isEditable", {
        is: isEditable => isEditable,
        then: Yup.mixed().when("licenseExpireDate", {
          is: licenseExpireDate => cnhIsRequired(null, null, licenseExpireDate),
          then: Yup.number().min(1, messages.FIELD_REQUIRED_FOR_DRIVER)
        }),
        otherwise: Yup.mixed().when(["profileId", "licenseExpireDate"], {
          is: (profileId, licenseExpireDate) => cnhIsRequired(profileId, null, licenseExpireDate),
          then: Yup.number().min(1, messages.FIELD_REQUIRED_FOR_DRIVER)
        })
      }),
      licenseExpireDate: Yup.date()
        .typeError(messages.INVALID_DATE)
        .nullable()
        .when("isEditable", {
          is: isEditable => isEditable,
          then: Yup.date()
            .typeError(messages.INVALID_DATE)
            .nullable()
            .when("licenseTypeId", {
              is: licenseTypeId => cnhIsRequired(null, licenseTypeId, null),
              then: Yup.date()
                .typeError(messages.INVALID_DATE)
                .nullable()
                .required(messages.FIELD_REQUIRED_FOR_DRIVER)
            }),
          otherwise: Yup.date()
            .typeError(messages.INVALID_DATE)
            .nullable()
            .when(["profileId", "licenseTypeId"], {
              is: (profileId, licenseTypeId) => cnhIsRequired(profileId, licenseTypeId, null),
              then: Yup.date()
                .typeError(messages.INVALID_DATE)
                .nullable()
                .required(messages.FIELD_REQUIRED_FOR_DRIVER)
            })
            .test({
              test: value => (value ? isAfter(addHours(value, 1), startOfDay(new Date())) : true),
              message: messages.INVALID_PAST_DATE
            })
        }),
      courseExpireDate: Yup.date()
        .typeError(messages.INVALID_DATE)
        .nullable()
        .when(["profileId", "isEditable"], {
          is: (profileId, isEditable) => !isEditable && driverProfiles.includes(profileId),
          then: Yup.date()
            .nullable()
            .typeError(messages.INVALID_DATE)
            .required(messages.FIELD_REQUIRED_FOR_DRIVER)
            .test({
              test: value => (value ? isAfter(addHours(value, 1), startOfDay(new Date())) : true),
              message: messages.INVALID_PAST_DATE
            }),
          otherwise: Yup.date().typeError(messages.INVALID_DATE).nullable()
        }),
      reasonForEdit: Yup.string()
        .nullable()
        .when("isEditable", { is: true, then: Yup.string().required(messages.FIELD_REQUIRED) })
    },
    [["licenseTypeId"], ["licenseExpireDate"], ["licenseTypeId", "licenseExpireDate"]]
  );
};

export default createValidationSchema;
