import { useState, useEffect, useCallback, useRef } from "react";
import { startOfWeek, endOfWeek } from "date-fns";
import CalendarManager from "../CalendarManager";
import { dateTimeViews, bookingStatus } from "../../../shared/utils/enum";
import { formatCurrentDate } from "../../../shared/utils/Utils";

const useCalendarScreen = (
  selectedDateViewTab,
  selectedDate,
  selectedWeek,
  setSelectedDateViewType,
  poleId
) => {
  const [dateTimeBookings, setDateTimeBookings] = useState([]);
  const [fleetList, setFleetList] = useState([]);

  const listBookings = async () => {
    const isWeekViewSelected = selectedDateViewTab === dateTimeViews.weekView.id;

    let startDateTime = isWeekViewSelected
      ? new Date(selectedWeek.newDate)
      : new Date(selectedDate);
    let finishDateTime = isWeekViewSelected
      ? new Date(selectedWeek.newDate)
      : new Date(selectedDate);

    startDateTime.setHours(0, 0, 0);
    finishDateTime.setHours(23, 59, 59);

    if (isWeekViewSelected) {
      startDateTime = startOfWeek(startDateTime);
      finishDateTime = endOfWeek(finishDateTime);
    }

    const data = await CalendarManager.listBookingsFiltered(
      null,
      startDateTime,
      finishDateTime,
      poleId
    );

    const filteredData = data.filter(
      booking =>
        ![bookingStatus.CANCELED, bookingStatus.EXPIRED].includes(
          booking.historyStatus[booking.historyStatus.length - 1].status.id
        )
    );
    setDateTimeBookings(filteredData);
  };

  const refListBookings = useRef(listBookings);

  useEffect(() => {
    refListBookings.current = listBookings;
  });

  useEffect(() => {
    refListBookings.current();
  }, [selectedDateViewTab, selectedDate, selectedWeek]);

  const handleChangeView = useCallback(
    typeId => {
      const selectedCurrentDateView =
        typeId === dateTimeViews.dayView.id
          ? selectedDate
          : dateTimeViews.weekView.id && selectedWeek.newDate;

      selectedCurrentDateView &&
        setSelectedDateViewType(formatCurrentDate(selectedCurrentDateView, typeId));
    },
    [setSelectedDateViewType, selectedDate, selectedWeek]
  );

  useEffect(() => {
    handleChangeView(selectedDateViewTab);
  }, [selectedDateViewTab, handleChangeView]);

  const sortList = (list) => {
    return list.sort((a, b) => {
      if (a.carSiteName > b.carSiteName) return 1;
      else if (a.carSiteName < b.carSiteName) return -1;
      else return 0;
    })
  } 

  useEffect(() => {
    const listFleetByPoleId = async () => {
      const data = await CalendarManager.listFleetByPoleId(poleId);
      
      if (data && Array.isArray(data)) {
        setFleetList(sortList(data));
      }
    };

    !selectedDateViewTab && listFleetByPoleId();
  }, [selectedDateViewTab, handleChangeView, poleId]);

  return { dateTimeBookings, fleetList, listBookings };
};

export default useCalendarScreen;
