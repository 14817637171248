export const formatFilters = filtersData => {
  const selectedLicense = filtersData?.licenseStatus?.filter(item => item.isSelected) || [];
  const selectedCourse = filtersData?.courseStatus?.filter(item => item.isSelected) || [];
  const selectedHasCar = filtersData?.hasCar?.filter(item => item.isSelected) || [];
  const selectedUserSituation = filtersData?.userSituation?.filter(item => item.isSelected) || [];

  let isValidLicense = null;
  if (selectedLicense.length === 1) isValidLicense = selectedLicense[0].value;

  let isValidCourse = null;
  if (selectedCourse.length === 1) isValidCourse = selectedCourse[0].value;

  let hasCar = null;
  if (selectedHasCar.length === 1) hasCar = selectedHasCar[0].value;

  let isActive = null;
  if (selectedUserSituation.length === 1) isActive = selectedUserSituation[0].value;

  return {
    profileIds: filtersData?.profileList?.filter(item => item.isSelected).map(item => item.id),
    isValidLicense,
    isValidCourse,
    hasCar,
    isActive
  };
};
