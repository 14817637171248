import React from "react";
import { MID_GREEN, BRIGHT_BLUE, PALE_BLUE_GRAY } from "../../theme";
import { Grid, makeStyles, Paper } from "@material-ui/core";

const Stepper = props => {
  const { step, size = 4 } = props;

  const classes = useStyles({ size });

  const decideStep = (_i, _step) => {
    return _i < _step ? classes.previous : classes.next;
  }

  return (
    <Grid container align="center">
      <Grid container item xs={12} className="pr-4 pl-4">
        {[...Array(size)].map((_e, i) => (
          <span key={i} className={`${classes.step} pl-1 pr-1`}>
            <Paper
              variant="outlined"
              square
              className={`${
                i === step ? classes.active : decideStep(i, step)
              }`}
            />
          </span>
        ))}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(_theme => ({
  step: props => ({
    width: `calc(100% / ${props.size})`,
    "& > div": {
      height: "6px",
      borderRadius: "8px",
      border: "0"
    }
  }),
  previous: {
    backgroundColor: MID_GREEN
  },
  active: {
    backgroundColor: BRIGHT_BLUE
  },
  next: {
    backgroundColor: PALE_BLUE_GRAY
  }
}));

export default Stepper;
