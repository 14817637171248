import React, { useCallback, useEffect, useState } from "react";
import { Grid, IconButton, makeStyles, Paper, Typography } from "@material-ui/core";
import { ChevronLeft } from "react-feather";
import { useHistory } from "react-router-dom";
import UserDetailsContent from "../../../shared/components/Details/UserDetailsContent";
import Loading from "../../../shared/components/Loading";
import StatusBox from "../../../shared/components/StatusBox";
import { noStatusStyle, accessType } from "../../../shared/utils/enum";
import { PRIMARY_COLOR } from "../../../theme";
import { Layout } from "../../layout";
import UserDetailsManager from "../UserDetailsManager";
import AssociatedVehicles from "./AssociatedVehicles";
import { connect } from "react-redux";

const UserDetails = props => {
  const {
    location: {
      state: { userId }
    },
    loggedUserProfile
  } = props;

  const classes = useStyles();
  const history = useHistory();

  const [userData, setUserData] = useState({});
  const [vehiclesData, setVehiclesData] = useState([]);
  const [userDataIsLoading, setUserDataIsLoading] = useState(false);

  const canSeeCars =
    loggedUserProfile === accessType.centralFacilities ||
    loggedUserProfile === accessType.adminFacilities;

  const getUserDetails = useCallback(
    async id => {
      setUserDataIsLoading(true);
      const user = await UserDetailsManager.getUserById(id);

      if (user && canSeeCars) {
        const vehicles = await UserDetailsManager.getVehiclesByUserAd(user.adUser);
        setVehiclesData(vehicles);
      }

      setUserData(user);
      setUserDataIsLoading(false);
    },
    [canSeeCars]
  );

  useEffect(() => {
    getUserDetails(userId);
  }, [userId, getUserDetails]);

  const mountHeader = () => {
    if (!userData) return;

    return (
      <Grid container alignItems="baseline">
        <Typography variant="h5" component="h3" className={classes.headerMain}>
          {userData.name}
        </Typography>
        <Typography variant="h6" component="h3" className={`ml-4 mr-8 ${classes.headerInfo}`}>
          {userData.role?.name}
        </Typography>
        {!userData.active && (
          <StatusBox
            boxStyle={noStatusStyle.style}
            text="USUÁRIO INATIVO"
            textColor={noStatusStyle.style.textColor}
            align="center"
          />
        )}
      </Grid>
    );
  };

  return (
    <Layout showHeader>
      <Grid item container className="mt-5 mr-8" alignItems="center">
        <IconButton onClick={() => history.goBack()} className="ml-2 mr-2 mt-4" aria-label="Voltar">
          <ChevronLeft size={32} color={PRIMARY_COLOR} />
        </IconButton>
        <Typography variant="h4" component="h2" className="mt-3">
          Detalhes do Usuário
        </Typography>
      </Grid>
      {userDataIsLoading ? (
        <Loading loading />
      ) : (
        <Grid container>
          <Grid item container xs={12} className="mt-5 mb-5">
            <Paper className={`mr-8 ml-8 ${classes.paper}`}>
              {userData && (
                <Grid item container xs={12} className="mt-8 mb-8">
                  <Grid container className="ml-8 mr-8">
                    {mountHeader()}
                  </Grid>
                  <Grid item container xs={12} className="mt-6 mb-6 ml-8 mr-8">
                    <UserDetailsContent userData={userData} hideTitle hideNameAndRole />
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
          {canSeeCars && (
            <Grid item xs={12} container>
              <Grid item container className="mt-5 mr-8 ml-8 mb-5" alignItems="center">
                <Typography variant="h4" component="h2">
                  Veículos vinculados
                </Typography>
              </Grid>
              <Grid item xs={12} container className="mt-5 mr-8 ml-8 mb-5">
                <AssociatedVehicles vehiclesData={vehiclesData} />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Layout>
  );
};

const useStyles = makeStyles(() => ({
  paper: {
    width: "100%"
  },
  headerMain: {
    fontWeight: "bold"
  },
  headerInfo: {
    fontWeight: "normal"
  }
}));

const mapStateToProps = state => {
  return {
    loggedUserProfile: state.user.profile
  };
};

export default connect(mapStateToProps, null)(UserDetails);
