import React from "react";
import { Grid, TextField, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { selectMenuProps } from "../../../../shared/utils/enum";
import {
  MaskedInputFactory,
  createFormattedNumberMask
} from "../../../../shared/components/MaskedInputFactory";
import { CustomSelect } from "../../../../shared/components/CustomSelect";

const GeneralSegment = props => {
  const { control, errors, values, colorList, fuelList, shouldDisable } = props;

  return (
    <Grid item container spacing={2}>
      <Grid item xs>
        <Controller
          name="chassisNumber"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                fullWidth
                {...field}
                label="Chassi"
                disabled={shouldDisable}
                error={errors && !!errors[field.name]}
                helperText={errors && errors[field.name] ? errors[field.name].message : null}
                placeholder="1234567890abcdefg"
                type="text"
                variant="outlined"
                inputProps={{
                  "aria-label": "Digite o número do chassi",
                  maxLength: 17
                }}
                InputProps={{
                  inputComponent: ChassisMask
                }}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="odometer"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                fullWidth
                {...field}
                label="Odômetro"
                error={errors && !!errors[field.name]}
                helperText={errors && errors[field.name] ? errors[field.name].message : null}
                placeholder="Digite a quilometragem atual (KM)"
                type="text"
                variant="outlined"
                inputProps={{
                  "aria-label": "Digite o odômetro do carro",
                  maxLength: 7
                }}
                InputProps={{
                  inputComponent: OdometerMask
                }}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="colorId"
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth variant="outlined" disabled={shouldDisable}>
                <InputLabel shrink={!!field.value}>Cor</InputLabel>
                <CustomSelect
                  clear={!shouldDisable}
                  handleClearField={() => field.onChange(0)}
                  inputProps={{
                    "aria-label": "Selecione a cor do veículo"
                  }}
                  label={values.colorId ? "Cor" : undefined}
                  error={errors && !!errors[field.name]}
                  helperText={errors && errors[field.name] ? errors[field.name].message : null}
                  {...field}
                  MenuProps={selectMenuProps}
                  variant="outlined"
                >
                  {colorList.map(color => (
                    <MenuItem key={color.id} value={color.id}>
                      {color.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="fuelId"
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth variant="outlined" disabled={shouldDisable}>
                <InputLabel shrink={!!field.value}>Combustível</InputLabel>
                <CustomSelect
                  clear={!shouldDisable}
                  handleClearField={() => field.onChange(0)}
                  inputProps={{
                    "aria-label": "Selecione o combustível do veículo"
                  }}
                  label={values.fuelId ? "Combustível" : undefined}
                  error={errors && !!errors[field.name]}
                  helperText={errors && errors[field.name] ? errors[field.name].message : null}
                  {...field}
                  MenuProps={selectMenuProps}
                  variant="outlined"
                >
                  {fuelList.map(fuel => (
                    <MenuItem key={fuel.id} value={fuel.id}>
                      {fuel.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            );
          }}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name="immobilized"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                fullWidth
                {...field}
                label="Imobilizado"
                disabled={shouldDisable}
                error={errors && !!errors[field.name]}
                helperText={errors && errors[field.name] ? errors[field.name].message : null}
                placeholder="Digite o código do SAP"
                type="text"
                variant="outlined"
                inputProps={{
                  "aria-label": "Digite o código de imobilizado do SAP"
                }}
                InputProps={{
                  inputComponent: ImmobilizedMask
                }}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

const OdometerMask = MaskedInputFactory({
  id: "odometerMask",
  mask: createFormattedNumberMask()
});

const ChassisMask = MaskedInputFactory({
  id: "chassisMask",
  mask: Array.from({ length: 17 }, () => {
    return /[0-9a-zA-Z]/;
  })
});

const ImmobilizedMask = MaskedInputFactory({
  id: "immobilizedMask",
  mask: Array.from({ length: 8 }, () => {
    return /\d/;
  })
});

export default GeneralSegment;
